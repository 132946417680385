import React from 'react';
import ReactDOMServer from 'react-dom/server';
import ReactTooltip from 'react-tooltip';

import { StyledTooltip } from './styles';

import { unique } from '@controlrooms/utils';

interface TooltipProps {
  className?: string;
  children: React.ReactNode;
  delay?: string;
  label: string | React.ReactElement;
  offset?: string;
  place?: string;
  type?: 'primary' | 'secondary';
  testid?: string;
  theme?: string;
}

export const Tooltip = React.forwardRef<HTMLSpanElement, TooltipProps>(
  ({ className, children, delay, label, offset, place, type = 'primary', testid, theme }, ref) => {
    const _label = typeof label === 'string' ? label : ReactDOMServer.renderToString(label);
    const id = unique();

    return (
      <>
        <ReactTooltip id={id} />
        <StyledTooltip
          data-tip={_label}
          data-testid={testid}
          data-for={id}
          data-html={typeof label !== 'string'}
          delayShow={delay}
          className={className}
          data-offset={offset}
          data-place={place}
          type={type}
          _theme={theme}
          ref={ref}
        >
          {children}
        </StyledTooltip>
      </>
    );
  },
);

import styled from 'styled-components';

import { breakpoints } from '../../global-styles';

export const StyledFilterContainer = styled.div`
  background: ${({ theme }) => theme.sidebar.filtersBackground};
  height: 100%;
  .sort {
    font-size: 11px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    line-height: 17px;
    padding: 5px 20px 5px 15px;
    color: ${({ theme }) => theme.sidebar.filtersColor};

    ${breakpoints.xl} {
      font-size: 13px;
    }
  }
`;

// Severity Filter Styling

export const SeverityFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;

  .label {
    font-weight: 400;
    font-size: 11px;
    font-family: 'Open Sans', sans-serif;
    padding-right: 12px;
    color: ${({ theme }) => theme.slider.labelColor};
  }
`;

export const StyledSlider = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 2px;

  .level-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    font-family: 'Open Sans', sans-serif;
  }
  .severity-label {
    padding-right: 10px;
    padding-left: 5px;
    color: ${({ theme }) => theme.slider.labelColor};
  }
`;

export const StyledColorSquare = styled.div`
  width: 10px;
  height: 12px;
  mix-blend-mode: normal;
  filter: blur(0.25px);
  border-radius: 0;
  background: ${(props) => props.color && `${props.theme.slider.filters[props.color].background}`};
  box-shadow: ${(props) => props.color && `${props.theme.slider.filters[props.color].boxShadow}`};
`;

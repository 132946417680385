import React, { createContext, useCallback, useContext, useMemo } from 'react';

import { MONITOR_MIN_ZOOM } from '../components/timeline-actions/timeline-actions';
import { ChartInteractionUtil } from '../utils/chart-interaction';

import { AppContext } from './app-context';

import { TimeRanges, Zoomable, ZoomTo } from '@controlrooms/models';

interface ContextProps {
  zoomIn: Zoomable;
}

const defaultState = {
  zoomIn: () => null,
};

export const MonitorChartContext = createContext<ContextProps>(defaultState);

const MonitorChartContextProvider: React.FC = ({ children }) => {
  const { setMonitorTimeSelection } = useContext(AppContext);

  const zoomIn = useCallback(
    (params?: Partial<ZoomTo>) => {
      setMonitorTimeSelection((prevTimeSelection) => {
        const [newStartTime, newEndTime] = ChartInteractionUtil.inCalculateBounds(
          prevTimeSelection,
          MONITOR_MIN_ZOOM,
          params,
        );

        return {
          ...prevTimeSelection,
          startTime: newStartTime,
          endTime: newEndTime,
          streamingTimeInSeconds: undefined,
          timeRange: TimeRanges.CUSTOM,
        };
      });
    },
    [setMonitorTimeSelection],
  );

  const monitorChartState = useMemo(
    () => ({
      zoomIn,
    }),
    [zoomIn],
  );

  return (
    <MonitorChartContext.Provider value={monitorChartState}>
      {children}
    </MonitorChartContext.Provider>
  );
};

export default MonitorChartContextProvider;

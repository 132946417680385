export const checkOverflowUtil = (el: HTMLDivElement): boolean => {
  const curOverflow: string = el.style.overflow;

  if (!curOverflow || curOverflow === 'visible') el.style.overflow = 'hidden';

  const isOverflowing: boolean =
    el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;

  el.style.overflow = curOverflow;

  return isOverflowing;
};

import Request from './request';

import { ApiResult, Plant, PlantWithStates } from '@controlrooms/models';

export interface FolderSearchPayload {
  top_folder?: number;
  ensemble_family_id?: string;
  start_time?: string;
  end_time?: string;
}

const SERVICE_ROOT = '/tag-explorer/api/v1';

export const getFolders = (payload: FolderSearchPayload): Promise<ApiResult<Plant>> =>
  Request.post(`${SERVICE_ROOT}/folders_with_tags`, payload, { timeout: 15000 });

export const getLimitFolders = (
  payload: FolderSearchPayload,
): Promise<ApiResult<PlantWithStates>> =>
  Request.post(`${SERVICE_ROOT}/folders_with_tags_with_limits`, payload, {
    timeout: 15000,
  });

export const getLatestBatch = (): Promise<ApiResult<{ timestamp: string | null }>> =>
  Request.post(`${SERVICE_ROOT}/latest`, {}, { timeout: 15000 });

export const getEarliestBatch = (): Promise<ApiResult<{ timestamp: string | null }>> =>
  Request.post(`${SERVICE_ROOT}/earliest`, {}, { timeout: 15000 });

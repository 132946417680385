import { RefObject, useEffect } from 'react';

type Callback = (event: MouseEvent) => void;

/**
 * Perform action when click detected outside of provided element ref
 *
 * @param ref Ref of the element to monitor for outside clicks
 * @param callback The callback handler
 */
export const useClickOutside = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  callback: Callback,
) => {
  useEffect(() => {
    // Run callback if click outside
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback(event);
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
};

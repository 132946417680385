import { css, DefaultTheme, FlattenInterpolation, ThemeProps } from 'styled-components';

import { StyledCheckbox } from '@controlrooms/components';

export const subTitleCss = css`
  font-size: 9px;
  font-family: 'Open Sans', sans-serif;
  line-height: 13px;
  color: ${({ theme }) => theme.systemBrowser.header.search.subTextColor};
  margin: 8px 22px;
  .dynamic-icon {
    display: inline-block;
    svg {
      fill: ${({ theme }) => theme.systemBrowser.header.search.iconColor};
    }
  }
`;

export const highlightCss = css`
  mark {
    color: ${({ theme }) => theme.systemBrowser.header.search.textHighlightColor};
    font-weight: 700;
    background: none;
  }
`;

export const navIconCss = css`
  .nav-icon {
    margin-right: 5px;
    padding: 5px;
    transform: rotate(270deg);
    visibility: hidden;
  }

  &:hover {
    .arrow-icon,
    .nav-icon {
      visibility: visible;

      svg {
        stroke: ${({ theme }) => theme.sidebar.folders.accordionClosedBorder};
      }
    }

    .nav-icon {
      stroke: none;
      fill: #579ea3;
    }
  }
`;

export const rowSharedCss: FlattenInterpolation<ThemeProps<DefaultTheme>> = css`
  &,
  .header,
  .content > div {
    &:hover,
    &.hover,
    &.selected,
    &.checked {
      background: ${({ theme }) => theme.sidebar.folders.selected.backgroundGradient};

      ${StyledCheckbox}:first-of-type {
        visibility: visible;

        svg > path {
          fill: ${({ theme }) => theme.checkbox.checkColor};
        }
      }
    }

    &.hover,
    &:hover {
      background: ${({ theme }) => theme.sidebar.folders.hover.backgroundGradient};

      ${StyledCheckbox}:first-of-type {
        border: 1px solid ${({ theme }) => theme.sidebar.folders.hover.checkboxBorderColor};
      }
    }

    &:nth-of-type(even) {
      &,
      .header {
        background-color: ${({ theme }) => theme.sidebar.folders.evenBackgroundColor};
      }
    }
  }

  &.search-result {
    ${StyledCheckbox} {
      border: 1px transparent;
    }
  }
`;

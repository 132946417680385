import styled from 'styled-components';

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 6px;
  border: solid 1px #ccc;
  background: ${({ theme }) => theme.backgroundColor};
  padding: 20px;
  color: ${({ theme }) => theme.textColor};
`;

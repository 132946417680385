import { useAuth0 } from '@auth0/auth0-react';
import { Dispatch, SetStateAction } from 'react';
import { useSearchParams } from 'react-router-dom';

import Background from '../../../../assets/confirm-email-background.svg';
import Foreground from '../../../../assets/confirm-email-foreground.svg';
import { useAnalytics } from '../../../analytics';
import { QueryParams } from '../../../constants/paths';

import { StyledConfirmEmail, StyledCheckboxRow, StyledModalLink } from './styles';

import { Checkbox } from '@controlrooms/components';

interface ConfirmEmailModalProps {
  acceptTerms: boolean;
  onTermsChange: Dispatch<SetStateAction<boolean>>;
}

export const ConfirmEmailModal: React.FC<ConfirmEmailModalProps> = ({
  acceptTerms,
  onTermsChange,
}) => {
  const { user } = useAuth0();
  const [searchParams, setSearchParams] = useSearchParams();
  const { track } = useAnalytics();

  const handleTermsChange = () => {
    onTermsChange(!acceptTerms);
  };

  return (
    <StyledConfirmEmail>
      {user?.email_verified ? (
        <div>
          <h2>You’re almost there!</h2>
          <p>
            Hi, {user?.given_name}. We’re looking forward to showing you what ControlRooms can do.
            <br /> Please review and accept our Terms & Conditions to get started.
          </p>
        </div>
      ) : (
        <div>
          <h2>Confirm your email</h2>
          <p>
            We’ve sent a confirmation link to you at {user?.email}. <br />
            Click that link to see what ControlRooms can do.
          </p>
        </div>
      )}
      <StyledCheckboxRow>
        <Checkbox className="terms-checkbox" checked={acceptTerms} onChange={handleTermsChange} />
        <p>
          I agree to the{' '}
          <StyledModalLink
            onClick={() => {
              track('User', {
                acceptTermsAndConditions: 'yes',
              });
              const _params = new URLSearchParams(searchParams);
              _params.set(QueryParams.TERMS_CONDITIONS, 'true');
              setSearchParams(_params);
            }}
          >
            Terms of Service
          </StyledModalLink>{' '}
          and{' '}
          <StyledModalLink
            onClick={() => {
              track('User', {
                acceptPrivacyPolicy: 'yes',
              });
              const _params = new URLSearchParams(searchParams);
              _params.set(QueryParams.PRIVACY_POLICY, 'true');
              setSearchParams(_params);
            }}
          >
            Privacy Policy
          </StyledModalLink>
        </p>
      </StyledCheckboxRow>
      <img className="banner-background" src={Background} alt="Control Rooms" height={155} />
      <img className="banner-foreground" src={Foreground} alt="Control Rooms" height={155} />
    </StyledConfirmEmail>
  );
};

import { ApiTimeServicePayload } from './api';

export interface TagSearchMinMaxPayload extends ApiTimeServicePayload {
  folder?: number;
  search_key?: string;
  interval: number;
}

export interface TagResampleMinMaxPayload extends ApiTimeServicePayload {
  folder?: number;
  tag_names?: string[];
  interval: number;
  ensemble_family_id?: string;
}

export interface AnomalousTagsPayload extends ApiTimeServicePayload {
  folders?: number[];
  preset?: number;
  sort_order: AnomalySort;
  show_limits?: boolean;
  ensemble_family_id?: string;
}

export interface TimeSearchPayload extends ApiTimeServicePayload {
  startTime: string;
  endTime: string;
  tag_name: string;
  searchStartTime?: string;
  searchEndTime?: string;
}

export interface HiddenTag {
  tag: string;
  folder: number;
}

export interface ViewFilters {
  dataTestId?: string;
  label: string;
  value: ViewFiltersValue;
  checked: boolean;
}

export enum ViewFiltersValue {
  FREQUENT_VALUE = 'frequent-value',
  HIGH_VALUE = 'high',
  HIGH_HIGH_VALUE = 'high_high',
  LOW_VALUE = 'low',
  LOW_LOW_VALUE = 'low_low',
  NOTES = 'notes',
  SHIFT_INDICATORS = 'shift-indicators',
  TAGS_NO_DATA = 'tags-no-data',
}

export enum ModeType {
  FREQUENT = 'frequent',
  HLIMIT = 'high-limit',
  HHLIMIT = 'high-high-limit',
  LLIMIT = 'low-limit',
  LLLIMIT = 'low-low-limit',
}

export enum AnomalySort {
  SEVERITY = 'severity',
  ALPHA = 'alpha',
  EARLIEST = 'earliest',
  MAX_CHANGE = 'maxchange',
}

export enum TimeSearchSort {
  RELEVANCE = 'relevance',
  TIME = 'time',
}

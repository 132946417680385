import dayjs from 'dayjs';
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import { ANALYZE_MIN_ZOOM } from '../components/timeline-actions/timeline-actions';
import { useHideTag } from '../hooks/tags';
import { ChartInteractionUtil } from '../utils/chart-interaction';
import { TimeRangeUtil } from '../utils/time';

import { AppContext } from './app-context';

import {
  AnomalySort,
  HiddenTag,
  ViewFilters,
  ViewFiltersValue,
  TimeRanges,
  Zoomable,
  ZoomTo,
  AnalyzeView,
} from '@controlrooms/models';
import { TimeUtils } from '@controlrooms/utils';

export const chartHeightIncrements = [70, 90, 110, 130, 150, 170, 200];

interface ContextProps {
  chartHeight: number;
  chartSortKey: AnomalySort;
  hiddenTags: HiddenTag[];
  chartViewFilters: ViewFilters[];
  hideTag: (folder: number, tag: string, type: string) => void;
  unhideTagsByFolder: (folder: number) => void;
  unhideTagsByName: (folder: number, tag_name: string) => void;
  changeChartViewFilters: (currentValue: ViewFiltersValue) => void;
  setChartHeight: Dispatch<SetStateAction<number>>;
  setChartSortKey: Dispatch<SetStateAction<AnomalySort>>;
  setHiddenTags: Dispatch<SetStateAction<HiddenTag[]>>;
  setChartViewFilters: Dispatch<SetStateAction<ViewFilters[]>>;
  increaseChartHeight: () => void;
  decreaseChartHeight: () => void;
  panNext: () => void;
  panPrevious: () => void;
  zoomIn: Zoomable;
  zoomOut: () => void;
  jumpToNow: () => void;
}

const defaultState = {
  chartHeight: chartHeightIncrements[1],
  chartSortKey: AnomalySort.SEVERITY,
  hiddenTags: [],
  chartViewFilters: [
    {
      label: 'Frequent Value',
      value: ViewFiltersValue.FREQUENT_VALUE,
      checked: false,
      dataTestId: 'frequent-value',
    },
    {
      label: 'High High Value',
      value: ViewFiltersValue.HIGH_HIGH_VALUE,
      checked: true,
      dataTestId: 'high_high',
    },
    {
      label: 'High Value',
      value: ViewFiltersValue.HIGH_VALUE,
      checked: true,
      dataTestId: 'high',
    },

    {
      label: 'Low Value',
      value: ViewFiltersValue.LOW_VALUE,
      checked: true,
      dataTestId: 'low',
    },
    {
      label: 'Low Low Value',
      value: ViewFiltersValue.LOW_LOW_VALUE,
      checked: true,
      dataTestId: 'low-low',
    },
  ],
  hideTag: () => null,
  unhideTagsByFolder: () => null,
  unhideTagsByName: () => null,
  setChartHeight: () => null,
  setChartSortKey: () => null,
  setHiddenTags: () => null,
  changeChartViewFilters: () => null,
  setChartViewFilters: () => null,
  increaseChartHeight: () => null,
  decreaseChartHeight: () => null,
  panNext: () => null,
  panPrevious: () => null,
  zoomIn: () => null,
  zoomOut: () => null,
  jumpToNow: () => null,
};

export const AnalyzeChartContext = createContext<ContextProps>(defaultState);

interface AnalyzeRouteState {
  view?: AnalyzeView;
}

const AnalyzeChartContextProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const view = (location.state as AnalyzeRouteState)?.view;
  const { mutateAsync: snoozeTag } = useHideTag();

  const {
    analyzeSessionStorage,
    setAnalyzeSessionStorage,
    setAnalyzeTimeSelection,
    recordTimelineHistory,
    analyzeTimeSelection: { streamingTimeInSeconds },
    currentEnvironment,
    newestLoadedData,
  } = useContext(AppContext);
  const [chartHeight, setChartHeight] = useState<number>(defaultState.chartHeight);
  const [chartSortKey, setChartSortKey] = useState<AnomalySort>(defaultState.chartSortKey);
  const [hiddenTags, setHiddenTags] = useState<HiddenTag[]>(
    view?.hiddenTags ?? (analyzeSessionStorage.hiddenTags || defaultState.hiddenTags),
  );

  const [chartViewFilters, setChartViewFilters] = useState<ViewFilters[]>(
    defaultState.chartViewFilters,
  );

  useEffect(() => {
    setAnalyzeSessionStorage((prevState) => ({
      ...prevState,
      hiddenTags: hiddenTags,
    }));
    // eslint-disable-next-line
  }, [hiddenTags]);

  const increaseChartHeight = useCallback(() => {
    const index = chartHeightIncrements.indexOf(chartHeight);

    if (index !== chartHeightIncrements.length - 1) {
      const newHeight = chartHeightIncrements[index + 1];
      setChartHeight(newHeight);
    }
  }, [chartHeight, setChartHeight]);

  const decreaseChartHeight = useCallback(() => {
    const index = chartHeightIncrements.indexOf(chartHeight);

    if (index !== 0) {
      const newHeight = chartHeightIncrements[index - 1];
      setChartHeight(newHeight);
    }
  }, [chartHeight, setChartHeight]);

  const zoomIn = useCallback(
    (params?: Partial<ZoomTo>) => {
      setAnalyzeTimeSelection((prevTimeSelection) => {
        recordTimelineHistory(prevTimeSelection);
        const [newStartTime, newEndTime] = ChartInteractionUtil.inCalculateBounds(
          prevTimeSelection,
          ANALYZE_MIN_ZOOM,
          params,
        );
        return {
          ...prevTimeSelection,
          startTime: newStartTime,
          endTime: newEndTime,
          streamingTimeInSeconds: undefined,
          timeRange: TimeRanges.CUSTOM,
        };
      });
    },
    [setAnalyzeTimeSelection, recordTimelineHistory],
  );

  const zoomOut = useCallback(
    () =>
      setAnalyzeTimeSelection((prevTimeSelection) => {
        recordTimelineHistory(prevTimeSelection);
        const [newStartTime, newEndTime] =
          ChartInteractionUtil.outCalculateBounds(prevTimeSelection);

        return {
          ...prevTimeSelection,
          startTime: newStartTime,
          endTime: newEndTime,
          streamingTimeInSeconds: undefined,
          timeRange: TimeRanges.CUSTOM,
        };
      }),
    [setAnalyzeTimeSelection, recordTimelineHistory],
  );

  const panPrevious = useCallback(() => {
    setAnalyzeTimeSelection((prevTimeSelection) => {
      recordTimelineHistory(prevTimeSelection);
      const [newStartTime, newEndTime] =
        ChartInteractionUtil.panLeftCalculateBounds(prevTimeSelection);

      return {
        ...prevTimeSelection,
        startTime: newStartTime,
        endTime: newEndTime,
        streamingTimeInSeconds: undefined,
        timeRange: TimeRanges.CUSTOM,
      };
    });
  }, [setAnalyzeTimeSelection, recordTimelineHistory]);

  const panNext = useCallback(() => {
    // disable pan next if in relative mode
    if (streamingTimeInSeconds) return;

    // TODO: disabled for ux testable - needs more ticket detail
    // const newEndTime = dayjs(endTime).add(panStep, 's');

    setAnalyzeTimeSelection((prevTimeSelection) => {
      recordTimelineHistory(prevTimeSelection);
      const [newStartTime, newEndTime] =
        ChartInteractionUtil.panRightCalculateBounds(prevTimeSelection);

      return {
        ...prevTimeSelection,
        startTime: newStartTime,
        endTime: newEndTime,
        streamingTimeInSeconds: undefined,
        timeRange: TimeRanges.CUSTOM,
        // TODO: disabled for ux testable - needs more ticket detail
        // endTime: dayjs().diff(newEndTime, 'seconds') <= 0 ? dayjs() : newEndTime,
      };
    });
  }, [setAnalyzeTimeSelection, recordTimelineHistory, streamingTimeInSeconds]);

  const jumpToNow = useCallback(() => {
    // disable jump to now if in relative mode
    if (streamingTimeInSeconds) return;

    //if batch env, go to latest not into streaming mode
    if (
      currentEnvironment?.isBatch &&
      !currentEnvironment?.isStreaming &&
      newestLoadedData?.timestamp
    )
      setAnalyzeTimeSelection((prevTimeSelection) => {
        recordTimelineHistory(prevTimeSelection);
        const latestTime = TimeUtils.toTimezone(
          newestLoadedData.timestamp,
          prevTimeSelection.timezone,
        );
        const diff = latestTime.diff(prevTimeSelection.endTime);
        const newStartTime = prevTimeSelection.startTime.add(diff);
        return {
          ...prevTimeSelection,
          endTime: latestTime,
          startTime: newStartTime,
          relative: false,
          nowSelected: true,
          streamingTimeInSeconds: undefined,
        };
      });
    else
      setAnalyzeTimeSelection((prevTimeSelection) => {
        recordTimelineHistory(prevTimeSelection);
        const now = dayjs();
        const diff = now.diff(prevTimeSelection.endTime);
        const newStartTime = prevTimeSelection.startTime.add(diff);
        const streamingSeconds = now.diff(newStartTime, 'seconds');
        return {
          ...prevTimeSelection,
          relative: true,
          endTime: now,
          startTime: newStartTime,
          nowSelected: true,
          streamingTimeInSeconds: now.diff(newStartTime, 'seconds'),
          timeRange: TimeRangeUtil.calculateTimePreset('value', streamingSeconds)
            ? TimeRanges.PRESET
            : TimeRanges.CUSTOM,
        };
      });
  }, [
    newestLoadedData,
    currentEnvironment,
    recordTimelineHistory,
    setAnalyzeTimeSelection,
    streamingTimeInSeconds,
  ]);

  const hideTag = useCallback(
    (folder: number, tag: string, type: string) => {
      if (type === 'private') {
        snoozeTag({ folder, tag });
      }
      setHiddenTags([
        ...hiddenTags,
        {
          folder,
          tag,
        },
      ]);
    },
    [hiddenTags, snoozeTag],
  );

  const unhideTagsByFolder = useCallback(
    (folder) => {
      const newHiddenTagsState = hiddenTags.filter((tag) => tag.folder !== folder);
      setHiddenTags(newHiddenTagsState);
    },
    [hiddenTags],
  );

  const unhideTagsByName = useCallback(
    (folder, tag_name) => {
      const newHiddenTagsState = hiddenTags.filter(
        (tag) => tag.folder !== folder && tag.tag !== tag_name,
      );
      setHiddenTags(newHiddenTagsState);
    },
    [hiddenTags],
  );

  const changeChartViewFilters = useCallback((currentValue) => {
    setChartViewFilters((prevState) => {
      return prevState.map((option) =>
        option.value === currentValue ? { ...option, checked: !option.checked } : option,
      );
    });
  }, []);

  const analyzeChartState = useMemo(
    () => ({
      chartHeight,
      chartSortKey,
      hiddenTags,
      hideTag,
      unhideTagsByFolder,
      unhideTagsByName,
      chartViewFilters,
      changeChartViewFilters,
      setChartViewFilters,
      setHiddenTags,
      setChartHeight,
      setChartSortKey,
      increaseChartHeight,
      decreaseChartHeight,
      panNext,
      panPrevious,
      zoomIn,
      zoomOut,
      jumpToNow,
    }),
    [
      chartHeight,
      chartSortKey,
      hiddenTags,
      hideTag,
      unhideTagsByFolder,
      unhideTagsByName,
      chartViewFilters,
      changeChartViewFilters,
      increaseChartHeight,
      decreaseChartHeight,
      panNext,
      panPrevious,
      zoomIn,
      zoomOut,
      jumpToNow,
    ],
  );

  return (
    <AnalyzeChartContext.Provider value={analyzeChartState}>
      {children}
    </AnalyzeChartContext.Provider>
  );
};

export default AnalyzeChartContextProvider;

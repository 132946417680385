import { Role } from '../constants/auth';

import Request from './request';

import { ApiResult, CRUser, DeleteView, IDemoToken, PersistView } from '@controlrooms/models';

export interface InviteUserParams {
  emails: string[];
  content: string;
  role: Role;
}

export interface UserProfileParams {
  first_name: string;
  last_name: string;
  password: string;
}

const SERVICE_ROOT = '/accounts-v2';
const ACCOUNTS_CUSTOMIZE = '/user-customize/accounts-customize';

export const getUser = (): Promise<ApiResult<CRUser>> => Request.get(`${SERVICE_ROOT}/users/me`);

export const updateUser = (
  user: Partial<
    Pick<
      CRUser,
      'preferences' | 'accepted_terms' | 'phone_number' | 'secondary_email' | 'sms_number'
    >
  >,
): Promise<ApiResult<CRUser>> => Request.patch(`${SERVICE_ROOT}/users/me`, user);

export const inviteUsers = ({
  emails,
  content,
  role,
}: InviteUserParams): Promise<ApiResult<{ message: string }>> => {
  const payload = {
    emails,
    invite_content: content,
    role_set: role,
  };
  return Request.post(`${SERVICE_ROOT}/users/invite`, payload);
};

export const updateUserProfile = (
  params: Partial<UserProfileParams>,
): Promise<ApiResult<{ message: string }>> => {
  return Request.patch(`${SERVICE_ROOT}/users/profile`, params);
};

export const resendVerificationEmail = (): Promise<ApiResult<{ message: string }>> => {
  return Request.post(`${SERVICE_ROOT}/users/resend-verification`, {});
};

export const getDemoToken = (): Promise<ApiResult<IDemoToken>> =>
  Request.get(`${SERVICE_ROOT}/auth0/users/auth/demo`);

export const createUserCustomView = (view: PersistView): Promise<ApiResult<string>> => {
  return Request.post(`${ACCOUNTS_CUSTOMIZE}/views`, view);
};

export const updateUserCustomView = (view: PersistView): Promise<ApiResult<string>> => {
  const viewId = view?.view_id;
  return Request.put(`${ACCOUNTS_CUSTOMIZE}/views/${viewId}`, view);
};

export const deleteUserCustomView = (view: DeleteView): Promise<ApiResult<string>> => {
  const viewId = view?.view_id;
  return Request.delete(`${ACCOUNTS_CUSTOMIZE}/views/${viewId}`);
};

import { TimeLineTheme } from 'styled-components';

import { colors } from '@controlrooms/design-tokens';

export const timelineLightTheme: TimeLineTheme = {
  majorTickColor: colors.k['55'],
  minorTickColor: colors.k['65'],
  timeLabel: colors.k['30'],
  horizontalLine: colors.content['neutral-3'],
};

export const timelineDarkTheme: TimeLineTheme = {
  majorTickColor: colors.k['90'],
  minorTickColor: colors.k['40'],
  timeLabel: colors.k['30'],
  horizontalLine: colors.k['20'],
};

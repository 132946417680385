import { useCallback, useEffect, useState } from 'react';

export const useNetworkStatus = (): boolean => {
  const [isOnline, setNetworkStatus] = useState(window.navigator.onLine);

  const updateNetworkStatus = useCallback(() => {
    setNetworkStatus(window.navigator.onLine);
  }, [setNetworkStatus]);

  useEffect(() => {
    window.addEventListener('offline', updateNetworkStatus);
    window.addEventListener('online', updateNetworkStatus);

    return () => {
      window.removeEventListener('offline', updateNetworkStatus);
      window.removeEventListener('online', updateNetworkStatus);
    };
  });

  return isOnline;
};

import { HeaderThemeV2 } from 'styled-components';

import { colors } from '@controlrooms/design-tokens';

export const headerV2Dark: HeaderThemeV2 = {
  tenantHeaderBg: 'linear-gradient(90deg, #253D3F 0%, #0A1315 100%)',
  content: {
    color: colors.content['neutral-1'],
  },
  background: '#0A1315',
};
export const headerV2Light: HeaderThemeV2 = {
  tenantHeaderBg: 'linear-gradient(90deg, #C7D4D2 0%, #E4E5E1 100%)',
  content: {
    color: colors.content['neutral-6'],
  },
  background: '#0A1315',
};

import styled from 'styled-components';

import { colors } from '@controlrooms/design-tokens';

export const StyledActions = styled.div`
  display: flex;
  height: 24px;
  align-items: center;
  div.group {
    display: flex;
    height: 24px;
    border-radius: 12px;
    border: 1px solid ${({ theme }) => theme.chart.actionBorderColor};
    padding: 6px 4px 6px 6px;
  }

  button {
    background: transparent;
    border: 0;
    cursor: pointer;

    svg path {
      fill: ${colors.content['neutral-3']};
    }

    &:hover {
      svg path {
        fill: ${({ theme }) => theme.systemBrowser.header.search.textHighlightColor};
      }
    }

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
`;

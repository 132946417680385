import styled from 'styled-components';

import { Icon } from '@controlrooms/components';

export const ModalBodyContainer = styled.div`
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 20px 30px;
`;

export const ExportHeader = styled.h3`
  color: ${({ theme }) => theme.exportModal.headerColor};
  font-size: 17px;
  line-height: 19px;
  font-family: 'Space Grotesk', sans-serif;
  font-weight: 400;
  margin-top: 16px;
  margin-bottom: 10px;
`;

export const SharableLinkHeader = styled.h5`
  color: ${({ theme }) => theme.exportModal.headerColor};
  font-weight: 400;
  font-family: 'Space Grotesk', sans-serif;
  margin-bottom: 15px;
  margin-top: 15px;
`;

export const SharableLinkContainer = styled.div`
  display: flex;
  border: 1px solid ${({ theme }) => theme.exportModal.borderColor};
  padding: 8px;
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
  margin-left: 2px;
`;

export const SharableLink = styled.span`
  color: ${({ theme }) => theme.exportModal.linkColor};
  font-size: 11px;
  font-family: 'Open Sans', sans-serif;
`;

export const CopyLinkIcon = styled(Icon)`
  cursor: pointer;
`;

// Teams
export const ShareText = styled.text`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  text-align: left;
  line-height: 19px;
  margin-bottom: 20px;

  //todo: need light color variant
  color: ${({ theme }) => theme.exportModal.shareText};
`;

export const ShareLabel = styled.text`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 13px;
  margin-bottom: 4px;

  //todo: need light color variant
  color: ${({ theme }) => theme.exportModal.shareLabel};
`;

export const ExportSocials = styled.div`
  img {
    width: 36px;
    height: 33px;
    cursor: pointer;
  }
`;

export const ScreenshotContainer = styled.div`
  width: 450px;
  background: #e6e3e1;
  border: 3px solid #e6e3e1;
  border-radius: 2px;

  .info-area {
    color: black;
  }

  .view-name {
    font-weight: bold;
  }

  .subsystem-display {
    font-size: 11px;
  }
`;

export const CanvasContainer = styled.div`
  width: 450px;
  height: 150px;

  img {
    width: 450px;
  }
`;

import React, { forwardRef, Ref, useState, useRef, useCallback } from 'react';

import { CheckboxChange, ViewOptionRecord } from '../../context/monitor-filtering-context';
import { StyledLi, SubMenuContainer, SubMenuUl } from '../monitor-header/filtering/styles';

import { Checkbox, Icon } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { useClickOutside } from '@controlrooms/hooks';

interface LabelCheckboxListProps {
  option: ViewOptionRecord;
  checkboxStatusById?: Record<string, boolean>;
  handleCheckboxChange: (props: CheckboxChange) => void;
  parentRef?: Ref<HTMLElement>;
}

export const LabelCheckboxList = forwardRef<HTMLDivElement, LabelCheckboxListProps>(
  ({ option, checkboxStatusById, handleCheckboxChange, parentRef }, forwardedRef) => {
    const [subViewOptionsOpen, setSubViewOptionsOpen] = useState(false);
    const [selectedSubSelection, setSelectedSubSelection] = useState<ViewOptionRecord | null>(null);

    const subMenuRef = useRef(null);

    useClickOutside(subMenuRef, () => {
      setSubViewOptionsOpen(false);
      setSelectedSubSelection(null);
    });

    const internalRef = useRef<HTMLDivElement | null>(null);

    const [position, setPosition] = useState({ top: 0, left: 0 });

    const handleClick = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
      subSelection: ViewOptionRecord,
    ) => {
      if (!subSelection.child_types || !subSelection.child_types.length) {
        return;
      }

      const targetElement = event.target as Element;
      const rect = targetElement.getBoundingClientRect();
      const parentRect = (
        parentRef as React.MutableRefObject<HTMLElement>
      )?.current?.getBoundingClientRect();

      if (!parentRect) return;

      setPosition({
        top: rect.top - parentRect?.top + window.scrollY,
        left: parentRect.width,
      });

      setSubViewOptionsOpen(true);
      setSelectedSubSelection(subSelection);
    };

    const setRefs = useCallback(
      (instance: HTMLDivElement | null) => {
        internalRef.current = instance;
        if (forwardedRef) {
          if (typeof forwardedRef === 'function') {
            forwardedRef(instance);
          } else {
            (forwardedRef as React.MutableRefObject<HTMLDivElement | null>).current = instance;
          }
        }
      },
      [forwardedRef],
    );

    return (
      <div>
        <div ref={setRefs}>
          {option.child_types?.map((subSelection) => (
            <div key={subSelection.label_type_id}>
              <StyledLi>
                <Checkbox
                  className="sub-checkbox"
                  checked={
                    checkboxStatusById ? checkboxStatusById[subSelection.label_type_id] : false
                  }
                  onChange={() =>
                    handleCheckboxChange({
                      optionValue: option.label_type_id,
                      subOptionValue: subSelection.label_type_id,
                    })
                  }
                />
                <div
                  style={{ marginLeft: '.5rem', flexGrow: 1 }}
                  onClick={(event) => handleClick(event, subSelection)}
                >
                  {subSelection.type_name}
                </div>
                {!!subSelection.child_types?.length && (
                  <Icon
                    name={ICONS.Chevron}
                    rotate={-90}
                    height="6"
                    color="white"
                    style={{ marginRight: '.5rem' }}
                  />
                )}
              </StyledLi>
              {selectedSubSelection?.label_type_id === subSelection.label_type_id && (
                <SubMenuContainer position={{ ...position }}>
                  <SubMenuUl isOpen={subViewOptionsOpen} ref={subMenuRef}>
                    {selectedSubSelection.child_types?.map((subSubSelection) => (
                      <StyledLi key={subSubSelection.label_type_id}>
                        <Checkbox
                          className="sub-sub-checkbox"
                          checked={
                            checkboxStatusById
                              ? checkboxStatusById[subSubSelection.label_type_id]
                              : false
                          }
                          onChange={() =>
                            handleCheckboxChange({
                              optionValue: option.label_type_id,
                              subOptionValue: selectedSubSelection.label_type_id,
                              subSubOptionValue: subSubSelection.label_type_id,
                            })
                          }
                        />
                        <div style={{ marginLeft: '.5rem' }}>{subSubSelection.type_name}</div>
                      </StyledLi>
                    ))}
                  </SubMenuUl>
                </SubMenuContainer>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  },
);

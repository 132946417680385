import React, { useCallback, useContext, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useQueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Route, Routes } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import styled, { ThemeProvider } from 'styled-components';

import { Auth0HistoryProvider } from '../../components/auth/auth0-history-provider';
import DemoRoute from '../../components/auth/demo-route';
import { ErrorFallback } from '../../components/error-fallback/error-fallback';
import { Paths } from '../../constants/paths';
import AppContextProvider from '../../context/app-context';
import AuthorizationContextProvider from '../../context/authorization-context';
import EnsembleContextProvider from '../../context/ensemble-context';
import GlobalContextProvider, { GlobalContext } from '../../context/global-context';
import HelpHeroContextProvider from '../../context/help-hero-context';
import TenantContextProvider from '../../context/tenant-context';
import { useTheme } from '../../hooks/accounts';
import { useDefaultEnsembleModels, useEnsembleModelsQuery } from '../../hooks/tags';
import { ErrorPage, PageNotFound } from '../../pages/not-found/error-page';
import { Analyze, Monitor, Redirect, Share } from '../../pages/routes';
import { getDemoToken } from '../../services/accounts';
import { setDemoTokenFetcher } from '../../services/api';
import { TokenUtil } from '../../utils/token';
// import { Header } from '../home/components/header-components/header';

export const AppContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  height: calc(100vh);
  overflow-y: auto;
  overflow-x: hidden;
  background: rgb(0, 0, 0);
  -webkit-box-pack: start;
  justify-content: flex-start;
`;

const App: React.FC = () => {
  const [theme] = useTheme();
  useEnsembleModelsQuery();
  useDefaultEnsembleModels();
  return (
    <ThemeProvider theme={theme}>
      <React.Suspense fallback={<>Hello</>}>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
            // reset the state of your app so the error doesn't happen again
          }}
        >
          <HelpHeroContextProvider>
            <AppContextProvider>
              <Routes>
                <Route index element={<DemoRoute component={Redirect} />} />
                <Route path={'redirect'} element={<DemoRoute component={Redirect} />} />
                <Route path={'t/:tenantId/monitor'} element={<DemoRoute component={Monitor} />} />
                <Route path={'t/:tenantId/analyze'} element={<DemoRoute component={Analyze} />} />
                <Route path={'t/:tenantId/:hash'} element={<DemoRoute component={Share} />} />
                <Route
                  path={Paths.NOT_FOUND}
                  element={
                    <ErrorPage>
                      <PageNotFound />
                    </ErrorPage>
                  }
                />
              </Routes>
            </AppContextProvider>
          </HelpHeroContextProvider>
        </ErrorBoundary>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
      </React.Suspense>
    </ThemeProvider>
  );
};

export const DemoWrapper: React.FC = () => {
  const { demoAccessToken, setDemoAccessToken } = useContext(GlobalContext);

  const demoTokenFetcher = useCallback(async () => {
    try {
      const acc_token = await getDemoToken();
      setDemoAccessToken(acc_token.result?.access_token);
    } catch (error) {
      console.error('Demo token fetch error', error);
    }
  }, [setDemoAccessToken]);

  useEffect(() => {
    if (!demoAccessToken) {
      demoTokenFetcher();
    }
  }, [demoAccessToken, demoTokenFetcher]);

  useEffect(() => {
    setDemoTokenFetcher(async () => {
      if (!demoAccessToken || TokenUtil.isTokenExpired(demoAccessToken)) {
        const acc_token = await getDemoToken();
        setDemoAccessToken(acc_token.result?.access_token);
        const token = acc_token.result.access_token;
        return token;
      } else {
        return demoAccessToken;
      }
    });
  }, [demoAccessToken, setDemoAccessToken]);

  if (!demoAccessToken) return <div>Loading ...</div>;

  return (
    <TenantContextProvider>
      <Auth0HistoryProvider>
        <AuthorizationContextProvider>
          <EnsembleContextProvider>
            <App />
          </EnsembleContextProvider>
        </AuthorizationContextProvider>
      </Auth0HistoryProvider>
    </TenantContextProvider>
  );
};

export const Demo: React.FC = () => {
  const { boot } = useIntercom();
  const queryClient = useQueryClient();

  useEffect(() => {
    boot();
    (async () => {
      await queryClient.resetQueries();
    })();
  });

  return (
    <>
      <AppContainer>
        <GlobalContextProvider>
          <DemoWrapper />
        </GlobalContextProvider>
      </AppContainer>
      {/* <Header /> */}
      {/* <Header isDemo={true} /> */}
    </>
  );
};

export default Demo;

export enum DateFormats {
  TIME = 'HH:mm',
  TIME_SECONDS = 'HH:mm:ss',
  DATE = "MMM D 'YY",
  DATETIME = 'HH:mm:ss MM/DD/YYYY',
  DATETIME_ALT = 'MM/DD/YYYY HH:mm:ss',
  DATETIME_ZONE = 'HH:mm:ss MM/DD/YYYY z',
}

export enum Fields {
  TIMERANGE = 'timerange',
  PRESET = 'preset',
  CUSTOM_TIMEFRAME = 'customTimeframe',
  TIMEFRAME = 'timeframe',
  END_DATE = 'endDate',
  TIMEZONE = 'timezone',
}

export enum TimeRanges {
  PRESET = 'preset',
  CUSTOM = 'custom',
}

export type TimeRangesType = typeof TimeRanges[keyof typeof TimeRanges];

export const TimePresetKeys = {
  LAST_HOUR: 'last-hour',
  LAST_FOUR_HOURS: 'last-4-hours',
  LAST_TWELVE_HOURS: 'last-12-hours',
  LAST_DAY: 'last-day',
  LAST_WEEK: 'last-week',
  LAST_THIRTY_DAYS: 'last-30-days',
  LAST_MONTH: 'last-month',
  LAST_QUARTER: 'last-quarter',
  SIX_MONTHS: 'six-months',
  LAST_YEAR: 'last-year',
  ALL_TIME: 'all-time',
  CUSTOM: 'custom',
  MORNING_REPORT: 'morning-report',
  EVENING_REPORT: 'evening-report',
};

export type TimePresetKeyType = typeof TimePresetKeys[keyof typeof TimePresetKeys];

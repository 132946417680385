import React from 'react';

import { Icon } from '../icon';

import { StyledButton } from './styles';
import { ButtonProps } from './types';

import { ICONS } from '@controlrooms/constants';

export const Button: React.FC<ButtonProps> = ({ children, ...props }) => {
  return (
    <StyledButton {...props}>
      {props.iconName && (
        <Icon
          color={props.color}
          data-testid={props.testId}
          name={props.iconName as typeof ICONS[keyof typeof ICONS]}
        />
      )}
      <span>{children}</span>
      {(props.buttonType === 'menu' || props.buttonType === 'menu-text') && (
        <Icon name="chevron" className="menu-button-icon" rotate={0} width="14" />
      )}
    </StyledButton>
  );
};

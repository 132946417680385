import { RadioButtonTheme } from 'styled-components';

import { colors } from '@controlrooms/design-tokens';

export const radioButtonDarkTheme: RadioButtonTheme = {
  backgroundColor: colors.k[5],
  borderColor: colors.focus.dark[3],
  borderColorChecked: colors.focus.dark[6],
  backgroundColorChecked: colors.focus.dark[6],
  boxShadowChecked: 'inset 1px 2px 2px rgba(0, 0, 0, 0.5)',
  before: {
    borderColor: colors.focus.dark[2],
  },
  after: {
    backgroundColor: colors.focus.dark[1],
  },
  label: {
    color: colors.content['neutral-3'],
    colorChecked: colors.content['neutral-1'],
  },
  hover: {
    boxShadow: colors.effects['hover-glow'],
    border: colors.focus.dark[4],
    before: {
      borderColor: colors.focus.dark[2],
    },
    checked: {
      after: {
        backgroundColor: colors.focus.dark[1],
        boxShadow: colors.effects['hover-glow'],
      },
    },
  },
};

// TODO: light theming colors
export const radioButtonLightTheme: RadioButtonTheme = {
  backgroundColor: colors.container['b-g-1'],
  borderColor: colors.focus.light[4],
  borderColorChecked: colors.focus.light[3],
  backgroundColorChecked: colors.container['b-g-1'],
  boxShadowChecked: 'inset 1px 2px 2px rgba(0, 0, 0, 0.5)',
  before: {
    borderColor: colors.content['neutral-2'],
  },
  after: {
    backgroundColor: colors.focus.light[2],
  },
  label: {
    color: colors.content['neutral-4'],
    colorChecked: colors.content['neutral-5'],
  },
  hover: {
    boxShadow: colors.effects['hover-glow'],
    border: colors.focus.light[2],
    before: {
      borderColor: colors.focus.dark[2],
    },
    checked: {
      after: {
        backgroundColor: colors.focus.light[1],
        boxShadow: colors.effects['hover-glow'],
      },
    },
  },
};

import styled from 'styled-components';

import { Icon, Li } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';

export const ButtonGroup = styled.div`
  display: flex;
  span {
    margin-right: 10px;
  }
`;

export const ChevronContainer = styled.div`
  padding: none;
  margin-top: 7px;
`;

export const ListContainer = styled.div`
  margin-bottom: 5px;
`;

export const TextInputLi = styled(Li)`
  margin: 0 1rem 1rem 1rem;
  display: flex;
  align-items: center;

  &:hover {
    background: none;
  }
`;

export const Button = styled.button`
  font-family: inherit;
  display: flex;
  padding: 0px;
  margin-top: 3px;
  background-color: inherit;
  border: none;
  color: ${({ theme }) => theme.modal.panel.color};
  width: 80%;
  font-weight: 200;
  font-size: 13px;
  line-height: 19px;
  cursor: pointer;
  .select-label {
    &:hover {
      color: ${({ theme }) => theme.timeDisplay.primaryTextHover};
      cursor: ${({ disabled }) => disabled && 'default'};
    }
  }
`;

export const CalendarButton = styled(Button)`
  margin-top: 0;
  padding: 2px;

  ${(props) => {
    if (props.disabled) {
      return `
        cursor: default;
      `;
    }
  }}
`;

export const Calendar = styled(Icon).attrs((props) => ({
  color: props.theme.timeDisplay.chevronColor, // TODO: calendar color
  name: ICONS.Calendar,
}))``;

import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useLocation, matchPath } from 'react-router-dom';

import { useAnalytics } from '../../analytics';
import {
  ShiftReportGroupOptions,
  ShiftReportPresetOptions,
} from '../../constants/time-selection-form';
import { ErrorText } from '../error-text/error-text';
import { Chevron } from '../time-display/styles';
import { RangeInputMask } from '../timeframe-modal/time-range-selector/range-input-mask';
import {
  Button,
  ButtonGroup,
  ChevronContainer,
  ListContainer,
} from '../timeframe-modal/time-range-selector/styles';

import {
  ModalBodyContainer,
  Header,
  MenuHeaderContainer,
  MenuHeader,
  TimeInputContainer,
  StyledOptionsGroup,
  StyledLi,
} from './styles';

import { RadioButton, Ul } from '@controlrooms/components';
import { Fields, TimeRanges, TimePresetKeyType } from '@controlrooms/models';

interface Props {
  isError?: boolean;
}

export const ShiftReportModal: React.FC<Props> = ({ isError }) => {
  const [isTimePresetsOpen, setIsTimePresetsOpen] = useState(false);
  const { track } = useAnalytics();

  const { pathname } = useLocation();
  const { control, setValue } = useFormContext();

  const handleCloseDropdown = (value: TimePresetKeyType) => {
    setValue(Fields.PRESET, value);
    setIsTimePresetsOpen(false);
  };

  const selectMapper = () =>
    ShiftReportGroupOptions.map((groupOption) => (
      <div key={groupOption.label}>
        <MenuHeaderContainer>
          <MenuHeader>{groupOption.label.toUpperCase()}</MenuHeader>
        </MenuHeaderContainer>
        <ListContainer>
          {groupOption.options
            .filter(({ paths }) => paths.some((path) => matchPath(path, pathname)))
            .map(
              ({ value, label, timeLabel }) =>
                label.length > 0 && (
                  <StyledLi
                    data-testid={`${value}-button`}
                    key={label}
                    onClick={() => {
                      track('Shift Report', {
                        presetSelected: label,
                        timeLabel: timeLabel,
                      });
                      handleCloseDropdown(value);
                    }}
                  >
                    {label}
                    <span>{timeLabel}</span>
                  </StyledLi>
                ),
            )}
        </ListContainer>
      </div>
    ));

  const getCurrentLabel = (value: TimePresetKeyType) => {
    const currentOption = ShiftReportPresetOptions.find((opt) => opt.value === value);
    const contructedLabel = `${currentOption?.label} ${currentOption?.timeLabel}`;

    return value ? contructedLabel : 'Select a Time';
  };

  return (
    <ModalBodyContainer>
      <Header>Select a preset or custom timeframe</Header>
      <Controller
        control={control}
        name="type"
        render={({ field: { onChange, value } }) => (
          <>
            <RadioButton
              data-testid="preset"
              id={TimeRanges.PRESET}
              value={TimeRanges.PRESET}
              checked={value === TimeRanges.PRESET}
              onChange={(e) => {
                onChange(e.target.id);
                setIsTimePresetsOpen(false);
              }}
            >
              Preset
              <div onClick={() => onChange(TimeRanges.PRESET)}>
                <Controller
                  control={control}
                  name={Fields.PRESET}
                  render={({ field: { value: presetValue } }) => (
                    <StyledOptionsGroup isInactive={value !== TimeRanges.PRESET}>
                      <Button
                        data-testid="select-preset-report-button"
                        type="button"
                        aria-haspopup="listbox"
                        aria-expanded={isTimePresetsOpen}
                        disabled={value !== TimeRanges.PRESET}
                        onClick={(evt) => {
                          evt.stopPropagation();
                          setIsTimePresetsOpen(!isTimePresetsOpen);
                        }}
                      >
                        <ButtonGroup>
                          <span>{getCurrentLabel(presetValue)}</span>
                          <ChevronContainer>
                            <Chevron />
                          </ChevronContainer>
                        </ButtonGroup>
                      </Button>
                      <Ul isOpen={isTimePresetsOpen}>{selectMapper()}</Ul>
                    </StyledOptionsGroup>
                  )}
                />
              </div>
            </RadioButton>
            <RadioButton
              data-testid="custom-timeframe"
              id={TimeRanges.CUSTOM}
              value={TimeRanges.CUSTOM}
              checked={value === TimeRanges.CUSTOM}
              onChange={(e) => {
                onChange(e.target.id);
                setIsTimePresetsOpen(false);
              }}
            >
              Timeframe
              <TimeInputContainer>
                <RangeInputMask disabled={value !== TimeRanges.CUSTOM} />
              </TimeInputContainer>
            </RadioButton>
          </>
        )}
        rules={{ required: true }}
      />
      {isError && <ErrorText text="An error occurred while generating the report. Please retry." />}
    </ModalBodyContainer>
  );
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames';
import React, { useContext, useRef, useState } from 'react';

import AlertModal from '../../components/alert-modal/alert-modal';
import { AuthorizationContext } from '../../context/authorization-context';
import { Analyze } from '../routes';

import { StyledSubFolder } from './styles';

import { Icon, Checkbox, Tooltip, OptionsGroup, Button, Ul, Li } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { useClickOutside } from '@controlrooms/hooks';
import { SubSystemAnomalous } from '@controlrooms/models';

// TODO: resolve the below any types
interface Props {
  subsystem: SubSystemAnomalous;
  handleCheckboxCheck: any;
  handleAnalyzeLink: any;
  isChecked: boolean;
  showAnalyzeLink: boolean;
  wideSpacing: boolean;
  lastSystem: boolean;
}

export const SubFolder: React.FC<Props> = ({
  subsystem,
  handleCheckboxCheck,
  handleAnalyzeLink,
  isChecked,
  wideSpacing,
  lastSystem,
}) => {
  return (
    <StyledSubFolder
      style={{
        height: wideSpacing ? 60 : 30,
      }}
      className={classNames('subfolder', { selected: isChecked })}
    >
      <div className="folder-content">
        <Icon className="drag-handle" name={ICONS.RowDragHandle} width="6" height="10" />
        <Checkbox
          className="folder-checkbox"
          id={`folder-checkbox-${subsystem.folder}`}
          checked={isChecked}
          dataTestId={`system-view-checkbox-${subsystem.name}`}
          onChange={handleCheckboxCheck}
        />
        <div
          data-testid={`system-view-${subsystem.name}`}
          className="description-wrapper"
          onClick={handleCheckboxCheck}
        >
          <Tooltip
            label={subsystem?.name && subsystem?.name?.length > 40 ? subsystem.name : ''}
            place="top"
          >
            {subsystem.name}
          </Tooltip>
        </div>
        <span className={classNames('system-id')} onClick={handleAnalyzeLink}>
          {subsystem.description && subsystem.name && <span>:&nbsp;</span>}
          {subsystem.description}
        </span>
      </div>
      <div className="folder-menu">
        <SubSystemMenu
          lastSystem={lastSystem}
          handleAnalyzeLink={handleAnalyzeLink}
          targetId={subsystem?.folder}
          name={subsystem.name}
        />
        <Tooltip label="Go To Analyze" className="analyze-link">
          <button
            data-testid={`go-to-analyze-${subsystem.name}`}
            type="button"
            onMouseOver={() => Analyze.preload()}
            onClick={handleAnalyzeLink}
          >
            <Icon name={ICONS.AnalyzeLink} width="13" height="10" />
          </button>
        </Tooltip>
      </div>
    </StyledSubFolder>
  );
};

const SubSystemMenu = ({
  handleAnalyzeLink,
  targetId,
  name,
  lastSystem,
}: {
  handleAnalyzeLink: any;
  targetId: number;
  name: string;
  lastSystem: boolean;
}) => {
  const [isMenuSelectOpen, setIsMenuSelectOpen] = useState(false);
  const { canUserReadAlert } = useContext(AuthorizationContext);

  const ulRef = useRef(null);
  useClickOutside(ulRef, () => setIsMenuSelectOpen(false));

  const handleMenuClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    setIsMenuSelectOpen(!isMenuSelectOpen);
  };

  return (
    <OptionsGroup
      className="menu-select"
      style={{
        position: 'fixed',
        zIndex: 1,
        left: 270,
      }}
    >
      <Button
        buttonType="icon"
        buttonSize="small"
        iconName="menu"
        data-testid={`subsystem_view_menu_${name}`}
        className="no-border"
        onClick={(e) => handleMenuClick(e)}
        aria-haspopup="listbox"
        aria-expanded={isMenuSelectOpen}
      />
      <Ul
        showOnTop={lastSystem}
        isOpen={isMenuSelectOpen}
        position="absolute"
        className="dropdown"
        ref={ulRef}
      >
        <Li data-testid="view-tags" key="view-in-analyze">
          <div onMouseOver={() => Analyze.preload()} onClick={handleAnalyzeLink}>
            View in Analyze
          </div>
        </Li>
        {canUserReadAlert && (
          <Li data-testid={`${name}-manage-alert`} key="manage-alert">
            <AlertModal targetType="system" targetId={targetId}>
              <div>Manage Alerts</div>
            </AlertModal>
          </Li>
        )}
        {/* <Li data-testid="more-actions" key="more-actions">
          <span>More Actions</span>
        </Li> */}
      </Ul>
    </OptionsGroup>
  );
};

import { useEffect, useRef, useState } from 'react';
import { FieldError, FormProvider, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import Background from '../../../../assets/invite-background.svg';
import Foreground from '../../../../assets/invite-foreground.svg';
import { darkTheme, lightTheme } from '../../../../theme';
import { useAnalytics } from '../../../analytics';
import { Role } from '../../../constants/auth';
import { QueryParams } from '../../../constants/paths';
import { useInviteUsers } from '../../../hooks/accounts';
import { useGetTenantConfig } from '../../../hooks/tenants';

import { StyledInvite } from './styles';

import { Button, Li, OptionsGroup, TextInput, Ul } from '@controlrooms/components';
import { TextInputType } from '@controlrooms/components';
import { useClickOutside } from '@controlrooms/hooks';

export interface InviteFormData {
  emails: string[];
  invite_content: string;
  template: string;
  role_set: Role.DATA | Role.OBSERVER;
}

enum Fields {
  EMAILS = 'emails',
  INVITE_CONTENT = 'invite_content',
  ROLE_SET = 'role_set',
  TEMPLATE = 'template',
}

enum TemplateOptions {
  CHECK_THIS_OUT = 'CHECK_THIS_OUT',
  HELP_WITH_DATA = 'HELP_WITH_DATA',
}

type TemplateInvite = {
  [key: string]: {
    label: string;
    role: Role.OBSERVER | Role.DATA;
    key: TemplateOptions;
    placeholder: string;
    dataTestId?: string;
  };
};

const TemplateConfiguration: TemplateInvite = {
  [TemplateOptions.CHECK_THIS_OUT]: {
    dataTestId: 'invite-template-list-check-this-out',
    key: TemplateOptions.CHECK_THIS_OUT,
    label: 'Check out ControlRooms',
    role: Role.OBSERVER,
    placeholder: `Give this a spin! ControlRooms is the first AI-powered monitoring tool that surfaces data anomalies from large-scale plants in real-time so we can improve troubleshooting and achieve true observability over operational data.`,
  },
  [TemplateOptions.HELP_WITH_DATA]: {
    dataTestId: 'invite-template-list-help-with-data',
    key: TemplateOptions.HELP_WITH_DATA,
    label: 'Help me add data to ControlRooms',
    role: Role.DATA,
    placeholder: `I need some help… I went thru the ControlRooms demo, and it looks like it might help us troubleshoot faster. We can use it for free on batch data, but I need help getting it in there. Can you help me get this done?`,
  },
};

export const InviteModal: React.FC<{ sandbox: boolean; onSubmit: () => void }> = ({ onSubmit }) => {
  const { mutateAsync: inviteUsers } = useInviteUsers();
  const { data: tenantConfig } = useGetTenantConfig();
  const [isTemplateSelectOpen, setIsTemplateSelectOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const { track } = useAnalytics();

  const ulRef = useRef(null);
  useClickOutside(ulRef, () => setIsTemplateSelectOpen(false));

  const getDefaultTemplateLabelOnTenant = (): string | undefined => {
    const tenantDefaultTemplateLabel = tenantConfig?.inviteTemplate?.default;
    if (tenantDefaultTemplateLabel) return TemplateConfiguration[tenantDefaultTemplateLabel].label;
  };

  const methods = useForm<InviteFormData>({
    mode: 'onBlur',
    defaultValues: {
      [Fields.TEMPLATE]: getDefaultTemplateLabelOnTenant(),
      [Fields.EMAILS]: [] as string[],
      [Fields.INVITE_CONTENT]: '',
      [Fields.ROLE_SET]:
        searchParams.get(QueryParams.ROLE) === Role.OBSERVER ? Role.OBSERVER : Role.DATA,
    },
  });

  const setRole = (role: Role.DATA | Role.OBSERVER) => {
    methods.setValue(Fields.ROLE_SET, role as never);
    methods.setValue(Fields.INVITE_CONTENT, getPlaceHolder() as never);
    methods.setValue(Fields.TEMPLATE, getTemplateLabelOnRoleChange(role) as never);
    setIsTemplateSelectOpen(false);
    methods.trigger();
  };

  const getPlaceHolder = (): string => {
    return methods.getValues(Fields.ROLE_SET) === Role.DATA
      ? TemplateConfiguration[TemplateOptions.HELP_WITH_DATA].placeholder
      : TemplateConfiguration[TemplateOptions.CHECK_THIS_OUT].placeholder;
  };

  const getTemplateLabelOnRoleChange = (role: string): string | undefined => {
    return role === Role.OBSERVER
      ? TemplateConfiguration[TemplateOptions.CHECK_THIS_OUT].label
      : TemplateConfiguration[TemplateOptions.HELP_WITH_DATA].label;
  };

  // Set initial template on page load
  // eslint-disable-next-line
  useEffect(() => methods.setValue(Fields.INVITE_CONTENT, getPlaceHolder() as never), []);

  const submit = () => {
    const emailsVal: string = methods.getValues(Fields.EMAILS);
    const inviteContentVal: string = methods.getValues(Fields.INVITE_CONTENT);

    const splitEmails: string[] =
      emailsVal.length > 0
        ? emailsVal
            .split(',')
            .filter((e) => e.trim().length)
            .map((e) => e.trim())
        : [];

    const errors = methods.formState.errors;
    if (Object.values(errors).length) return false;

    const formData = {
      emails: splitEmails,
      content: inviteContentVal as string,
      role: methods.getValues(Fields.ROLE_SET),
    };

    inviteUsers(formData);
    track('Invite', {
      submit: 'clicked',
      recipientEmail: splitEmails,
      content: inviteContentVal,
      roleOfUser: methods.getValues(Fields.ROLE_SET),
      templateSelected: methods.getValues(Fields.TEMPLATE),
    });
    onSubmit();
  };

  const getEmailError = (): string | undefined => {
    const errors: FieldError | undefined = methods?.formState?.errors[
      Fields.EMAILS
    ] as unknown as FieldError;
    return errors !== undefined ? errors.message : undefined;
  };

  return (
    <StyledInvite>
      <FormProvider {...methods}>
        <ThemeProvider theme={darkTheme}>
          <div className="content-container">
            <h2>Invite a colleague to ControlRooms</h2>
            <p>
              Have a colleague who might be interested in ControlRooms? Need some help from your
              data specialist? Shoot them a note below!
            </p>
            <div className="form-container">
              <ThemeProvider theme={lightTheme}>
                <TextInput
                  data-testid="invite-email-input"
                  label="Recipients"
                  className="recipients"
                  placeholder="Email, comma separated"
                  onKeyDown={(e: React.KeyboardEvent) => {
                    e.stopPropagation();
                  }}
                  {...methods.register(Fields.EMAILS, {
                    required: 'Field is required',
                    pattern: {
                      value: /^([A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\s?,?\s?)+$/i,
                      message: 'Email addresses must be valid',
                    },
                  })}
                  errorMessage={getEmailError()}
                />
              </ThemeProvider>
              <div className="template-selection">
                {' '}
                Template:{' '}
                <OptionsGroup className="template-select">
                  <Button
                    buttonSize="large"
                    buttonType="menu"
                    type="button"
                    data-testid="invite-template"
                    aria-haspopup="listbox"
                    aria-expanded={isTemplateSelectOpen}
                    onClick={(evt) => {
                      evt.stopPropagation();
                      setIsTemplateSelectOpen(!isTemplateSelectOpen);
                    }}
                  >
                    {methods.getValues(Fields.TEMPLATE)}
                  </Button>
                  <Ul isOpen={isTemplateSelectOpen} className="template-dropdown" ref={ulRef}>
                    {tenantConfig?.inviteTemplate?.allowed?.map((option) => {
                      const { label, key, role, dataTestId } = TemplateConfiguration[option];
                      return (
                        <Li key={key} className="template-menu-item">
                          <Button
                            data-testid={dataTestId}
                            buttonType="text"
                            buttonSize="small"
                            onClick={() => setRole(role)}
                          >
                            {label}
                          </Button>
                        </Li>
                      );
                    })}
                  </Ul>
                </OptionsGroup>
              </div>
              <ThemeProvider theme={lightTheme}>
                <TextInput
                  data-testid="invite-textbox-input"
                  className="message"
                  type={TextInputType.MULTILINE}
                  onKeyDown={(e: React.KeyboardEvent) => {
                    e.stopPropagation();
                  }}
                  {...methods.register(Fields.INVITE_CONTENT, {
                    required: {
                      value: true,
                      message: 'Required field',
                    },
                  })}
                  errorMessage={
                    methods.formState.errors[Fields.INVITE_CONTENT] &&
                    methods.formState?.errors[Fields.INVITE_CONTENT]?.message
                  }
                />
              </ThemeProvider>
            </div>
            <Button
              data-testid="invite-send-button"
              buttonSize="large"
              buttonType="primary"
              className="submit"
              onClick={methods.handleSubmit(submit)}
            >
              Send Invite
            </Button>
          </div>
          <img className="banner-background" src={Background} alt="Control Rooms" height={250} />
          <img className="banner-foreground" src={Foreground} alt="Control Rooms" height={250} />
        </ThemeProvider>
      </FormProvider>
    </StyledInvite>
  );
};

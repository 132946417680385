import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import Nav1 from '../../../../../assets/nav1.png';
import { useAnalytics } from '../../../../analytics';
import { QueryParams } from '../../../../constants/paths';
import { useCreateTenant } from '../../../../hooks/batch';
import { useSwitchTenants } from '../../../../hooks/tenants';

import { StyledSandboxBanner } from './styles';

import { Icon } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';

export const Sandbox: React.FC = () => {
  const { mutateAsync } = useCreateTenant();
  const [switchTenant] = useSwitchTenants();
  const { user } = useAuth0();
  const [searchParams, setSearchParams] = useSearchParams();
  const { track } = useAnalytics();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleUploadClick = async () => {
    if (!user?.email_verified) {
      const params = new URLSearchParams(searchParams);
      params.append(QueryParams.CONFIRM_EMAIL, 'true');
      setSearchParams(params);
    } else {
      const { result } = await mutateAsync();
      track('Sandbox - Banner', {
        getStartedBanner: 'clicked',
        switchedTenantId: result.tenant,
      });
      switchTenant(result.tenant, new URLSearchParams({ [QueryParams.TRIAL_UPLOAD]: 'true' }));
    }
  };

  return (
    <StyledSandboxBanner>
      <div className="content">
        {/* Try this out with your data for free
        <span data-testid="upload-data-banner" className="upload-data" onClick={handleUploadClick}>
          <Icon name={ICONS.Upload} width="17" height="17" /> Get Started
        </span> */}
        <span data-testid="upload-data-banner" className="upload-data">
          <Icon name={ICONS.Calendar} width="17" height="17" />{' '}
          <a target="_blank" href="https://calendly.com/controlroomsai/30min?back=1&month=2023-10">
            Schedule a Demo With A Process Engineer.
          </a>
        </span>
      </div>

      <div className="banner">
        <img src={Nav1} alt="banner" />
      </div>
    </StyledSandboxBanner>
  );
};

import styled from 'styled-components';

export const StyledSandboxBanner = styled.div`
  display: flex;

  .upload-data {
    color: ${({ theme }) => theme.linkColor};
    font-size: 13px;
    text-decoration: underline;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > .content {
    display: flex;
    align-items: center;
    margin-right: 0;
    margin-top: 0;
  }

  .banner {
    margin-left: -80px;
  }
`;

import React from 'react';
import styled from 'styled-components';

const Error = styled.div`
  color: #cc3311;
  font-size: 13px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  line-height: 13px;
  margin-top: 10px;
`;

interface ErrorTextProps {
  text?: string;
}

export const ErrorText: React.FC<ErrorTextProps> = ({ text }) => <Error>{text}</Error>;

import { useRef } from 'react';

/**
 * Returns true if it is the first render, otherwise false.
 */
export const useFirstRender = (): boolean => {
  const ref = useRef(true);
  const firstRender = ref.current;
  ref.current = false;
  return firstRender;
};

import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';

export const useKeyPress = (
  keys: string[],
  callback: CallableFunction,
  keyUpCallback?: CallableFunction,
) => {
  const callbackRef = useRef(callback);
  const keyUpCallbackRef = useRef(keyUpCallback);
  useLayoutEffect(() => {
    callbackRef.current = callback;
    keyUpCallbackRef.current = keyUpCallback;
  });

  // handle what happens on key press
  const handleKeyPress = useCallback(
    (event) => {
      // check if one of the key is part of the ones we want
      if (keys.some((key) => event.key === key)) {
        callbackRef.current(event);
      }
    },
    [keys],
  );

  // handle what happens on key up
  const handleKeyUp = useCallback(
    (event) => {
      if (!keyUpCallbackRef.current) return;

      // check if one of the key is part of the ones we want
      if (keys.some((key) => event.key === key)) {
        keyUpCallbackRef.current(event);
      }
    },
    [keys],
  );

  useEffect(() => {
    const targetNode = document;
    if (!targetNode) return;

    // attach the event listener(s)
    targetNode.addEventListener('keydown', handleKeyPress);
    handleKeyUp && targetNode.addEventListener('keyup', handleKeyUp);

    // remove the event listener(s)
    return () => {
      if (!targetNode) return;
      targetNode.removeEventListener('keydown', handleKeyPress);
      handleKeyUp && targetNode.removeEventListener('keydown', handleKeyUp);
    };
  }, [handleKeyPress, handleKeyUp]);
};

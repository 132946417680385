import { GRADIENTS } from '@controlrooms/constants';

export const colors = {
  heatmap: {
    dark: {
      [GRADIENTS.g1]: {
        color1: '#202C2E',
        color2: '#273133',
        color3: '#303D40',
      },
      [GRADIENTS.g2]: {
        color1: '#324A4D',
        color2: '#3E6166',
        color3: '#456D73',
      },
      [GRADIENTS.g3]: {
        color1: '#6DD9B5',
        color2: '#A0ECC6',
        color3: '#B4FFCD',
        color4: '#E4FFAE',
      },
      [GRADIENTS.selected]: {
        color1: '#0A1415',
        color2: '#0A1415',
        color3: '#2E4547',
      },
      [GRADIENTS.hover]: {
        color1: '#0B0D0D',
        color2: '#041314',
        color3: '#0E1E20',
        color4: '#3C9197',
      },
      [GRADIENTS.limits]: {
        color1: 'rgba(255, 113, 113, 0.3)',
        color2: 'rgba(255, 113, 113, 1)',
      },
      solid: {
        color1: '#2D3A3C',
        color2: '#4E8188',
        color3: '#B0FFB0',
      },
    },
    light: {
      [GRADIENTS.g1]: {
        color1: '#B8CFD1',
        color2: '#BAD0D1',
        color3: '#C9D8D9',
        color4: '#D0DFE0',
      },
      [GRADIENTS.g2]: {
        color1: '#8097A5',
        color2: '#90ABB2',
        color3: '#9CB5C8',
      },
      [GRADIENTS.g3]: {
        color1: '#261A2D',
        color2: '#32273D',
        color3: '#4A435D',
      },
      [GRADIENTS.selected]: {
        color1: '#0A1415',
        color2: '#4A9D93',
        color3: '#2E4547',
      },
      [GRADIENTS.hover]: {
        color1: '#0B0D0D',
        color2: '#041314',
        color3: '#0E1E20',
        color4: '#3C9197',
      },
      [GRADIENTS.limits]: {
        color1: 'rgba(255, 177, 177, 1)',
        color2: 'rgba(239, 62, 62, 1)',
      },
      solid: {
        color1: '#CFDEDF',
        color2: '#688D92',
        color3: '#3E354D',
      },
    },
    glow: {
      'secondary-shadows': '',
      'secondary-blur': 'blur(8px)',
      'primary-shadows': '',
      'primary-blur': 'blur(2px)',
      'hot-shadows': ' 0px 0px 12px #ff5592',
      'hot-blur': 'blur(3px)',
    },
    browser: {
      edge: {
        glow: {
          shadows: ' 0px 8px 24px rgba(250, 255, 218, 0.53), -2px 0px 3px rgba(0, 0, 0, 0.3)',
          blur: 'blur(2px)',
        },
      },
    },
  },
  anomalies: {
    dark: {
      1: '#2D3A3C',
      2: '#4E8188',
      3: '#B0FFB0',
    },
    light: {
      1: '#CFDEDF',
      2: '#688D92',
      3: '#3E354D',
      4: '#7DCCD7',
    },
  },
  focus: {
    dark: {
      1: '#C1EFF9',
      2: '#93BBC3',
      3: '#4D696F',
      4: '#2B3A3D',
      5: '#1F3033',
      6: '#0A1315',
    },
    light: {
      1: '#245D68',
      2: '#3C6A73',
      3: '#7199A1',
      4: '#8FB8C0',
      5: '#C3DBE0',
    },
  },
  select: {
    dark: {
      'static-solid': '#556644',
      'time-range-grid': 'rgba(66, 114, 117, 0.15000000596046448)',
      'row-hover-grid': 'linear-gradient(180deg, #0D171A 0%, #1C3236 92.71%, #426D75 100%)',
      'row-select':
        'linear-gradient(180deg, #151717 0%, #1B1E1F 71.61%, #1B1E1F 93.75%, #3C555A 100%)',
    },
    light: {
      'static-solid': '#c8d8c3',
      'time-range-grid': 'rgba(186, 251, 255, 0.15000000596046448)',
      'row-hover-grid':
        'linear-gradient(180deg, #E0F4F9 0%, #BAE3E3 75%, #B4E0DE 93.75%, #579EA3 100%)',
      'row-select':
        'linear-gradient(180deg, #F9F8F4 0%, #E3EDEF 75%, #E2EBED 93.75%, #B7D4DA 100%)',
    },
  },
  content: {
    'neutral-1': '#faffda',
    'neutral-2': '#bdc0a9',
    'neutral-3': '#a0a38d',
    'neutral-4': '#737566',
    'neutral-5': '#464740',
    'neutral-6': '#242522',
    'warning-1': '#A80000',
  },
  button: {
    dark: {
      'ui-button-1': `linear-gradient(180deg, #224B4E 0%, #29494B 49.48%, #1F3133 100%, #1F3133 100%)`,
      'ui-button-2': `linear-gradient(180deg, #222320 0%, #191A18 100%)`,
    },
    light: {
      'ui-button-1': `linear-gradient(180deg, #224B4E 0%, #29494B 49.48%, #1F3133 100%, #1F3133 100%)`,
      'ui-button-2': 'linear-gradient(180deg, #FCFCFA 0%, #E6E3E1 100%)',
    },
  },
  container: {
    'b-g-1': '#FCFCFA',
    'b-g-2': '#F9F8F4',
    'b-g-3': '#E6E3E1',
    'b-g-4': '#D9D5D1',
    'b-g-5': '#CBC8C4',
    'b-g-6': '#B7B3AE',
    'b-g-7': '#555351',
    'b-g-8': '#302E2C',
    'b-g-9': '#66592F',
    'b-g-10': '#FFFAEB',
    dark: {
      gradients: {
        1: `linear-gradient(180deg, #302E2C 0%, #3E4B4D 46.88%, #5B7274 100%)`,
      },
    },
  },
  datavis: {
    'pink-1-30': 'rgba(255, 113, 113, 0.30000001192092896)',
    'pink-1': '#FFB1B1',
    'pink-2': '#FF7171',
    'orange-1': '#cdb25e',
    'orange-2': '#9D7953',
    'green-1': '#A5B280',
    'green-2': '#90A685',
    'green-3': '#7E9774',
    'green-4': '#56665C',
    'purple-1': '#BFA8D0',
    'purple-2': '#493459',
    'purple-3': '#73528C',
  },
  error: {
    1: '#B24F4F',
  },
  k: {
    100: '#ffffff',
    97: '#f7f7f7',
    95: '#f2f2f2',
    90: '#e6e6e6',
    85: '#d9d9d9',
    80: '#cccccc',
    65: '#b3b3b3',
    55: '#8c8c8c',
    40: '#666666',
    30: '#4d4d4d',
    20: '#333333',
    12: '#1f1f1f',
    10: '#1a1a1a',
    8: '#141414',
    5: '#0d0d0d',
    3: '#080808',
    0: '#000000',
  },
  effects: {
    'hover-glow': ' 0px 0px 8px #80f7ff, 0px 0px 2px #ffffff',
    'field-inner-shadow': 'inset 1px 2px 4px rgba(0, 0, 0, 0.35)',
    'modal-blur-shadows': '',
    'modal-blur-blur': 'blur(16px)',
  },
  'grid-rows': {
    dark: {
      'annotation-row-inner-shadow':
        'inset 0px 4px 4px rgba(0, 0, 0, 0.2),inset 0px 1px 0px rgba(0, 0, 0, 0.5)',
      'row-inner-shadow': 'inset 0px 4px 4px rgba(0, 0, 0, 0.3)',
      'row-inner-shadow-focus':
        'inset 0px 4px 8px rgba(0, 0, 0, 0.6),inset 1px 0px 0px #000000,inset 0px 1px 0px #579ea3',
      'row-inner-shadow-heatmap':
        'inset 0px 4px 4px rgba(0, 0, 0, 0.3),inset 1px 0px 0px #000000,inset 0px 1px 0px rgba(26, 26, 26, 0.5)',
      'browser-row-inner-shadow': 'inset 0px 1px 0px rgba(0, 0, 0, 0.6)',
    },
    light: {
      'row-inner-shadow': 'inset 0px 4px 4px rgba(0, 0, 0, 0.08)',
      'browser-row-inner-shadow':
        'inset 0px 2px 3px rgba(0, 0, 0, 0.1),inset 0px 1px 0px rgba(0, 0, 0, 0.05)',
      'row-inner-shadow-heatmap':
        'inset 0px 4px 4px rgba(0, 0, 0, 0.06),inset 1px 0px 0px #000000,inset 0px 1px 0px rgba(26, 26, 26, 0.5)',
      'row-header-hover':
        'linear-gradient(360deg, rgba(193, 239, 249, 0) 0%, rgba(84, 215, 223, 0.54) 100%)',
    },
  },
  elevation: {
    dark: {
      1: '0px 1px 2px rgba(0, 0, 0, 0.3)',
      2: '0px 8px 12px rgba(0, 0, 0, 0.6)',
      3: '0px 12px 18px #000000',
    },
    light: {
      1: '0px 1px 2px rgba(0, 0, 0, 0.85)',
      2: '0px 8px 12px rgba(0, 0, 0, 0.2)',
      3: '0px 12px 18px rgba(0, 0, 0, 0.25)',
    },
  },
  field: {
    dark: {
      'inner-shadow': 'inset 1px 2px 4px rgba(0, 0, 0, 0.35)',
    },
    light: {
      'inner-shadow': 'inset 1px 2px 4px rgba(0, 0, 0, 0.35)',
    },
  },
  tags: {
    dark: {
      [GRADIENTS.tagHeaderHover]: {
        color1: '#1F201B',
        color2: '#426D75',
        color3: '#5796A3',
      },
    },
    light: {
      [GRADIENTS.tagHeaderHover]: {
        color1: '#1F201B',
        color2: '#426D75',
        color3: '#5796A3',
      },
    },
  },
};

export interface CRTenant {
  deleted: boolean;
  last_updated: string;
  loc_id: number;
  preferences: CRTimingConfig;
  prod_env: number;
  tenant_id: number;
  tenant_name: string;
  timezone: string;
  env_status: EnvStatus;
}

export enum EnvStatus {
  RUNNING = 'RUNNING',
  RUNNING_WITH_DATA = 'RUNNING_WITH_DATA',
  CREATING = 'CREATING',
}

export interface CREnvironment {
  env_desc: string;
  env_id: number;
  is_batch: boolean;
  is_subscription: boolean;
  is_trial: boolean;
  status: string;
  tenant_id: number;
}

export interface CRTimingConfig {
  resampling_interval_sec: number;
  initial_timeframe_sec?: number;
  monitor_anomaly_thresholds: CRMonitorAnomalyThresholds;
  monitor_error_thresholds: Array<CRMonitorErrorThreshold>;
  monitor_time_thresholds: Array<CRTimeThreshold>;
  analyze_time_thresholds: Array<CRTimeThreshold>;
}

export interface CRMonitorAnomalyThresholds {
  high: number;
  medium: number;
  low: number;
  none: number;
}

export interface CRMonitorErrorThreshold {
  error: string;
  threshold: number;
}

export interface CRTimeThreshold {
  window_time_in_minutes: number;
  interval_in_seconds: number;
}

export interface CRTenantConfig {
  banner: string;
  invite_template: {
    allowed: string[];
    default: string;
  };
  show_invite: boolean;
  show_upload_link: boolean;
}

import React from 'react';

import { StyledMenu } from './menu-dropdown-style';

interface Props {
  open: boolean;
}

const MenuDrop: React.FC<Props> = ({ open, children }) => {
  return <StyledMenu open={open}>{children}</StyledMenu>;
};

export default MenuDrop;

import classNames from 'classnames';
import React from 'react';

import { StyledSwitch } from './styles';

interface IProps {
  className?: string;
  id?: string;
  selected?: boolean | null;
  disabled?: boolean;
  dataTestId?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>, selected: boolean) => void;
}

export const Switch: React.FC<IProps> = ({
  className,
  selected,
  disabled,
  onClick,
  dataTestId,
}) => {
  // Should need selected field as boolean value or else it considered as indeterminate state
  const buttonClassNames = classNames({
    switch: true,
    'switch--on': selected,
    'switch--off': selected === false,
    className,
  });
  const content = (
    <StyledSwitch>
      <button
        className={buttonClassNames}
        disabled={disabled}
        data-testid={selected ? `switch_${dataTestId}_on` : `switch_${dataTestId}_off`}
        onClick={(e) => {
          e.stopPropagation();
          onClick && onClick(e, !selected);
        }}
      >
        <span data-testid="switch-track" className="switch__track">
          <span className="switch__toggle" />
        </span>
      </button>
    </StyledSwitch>
  );

  return content;
};

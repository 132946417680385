import { useEffect, useRef, useState } from 'react';

import { AuthRender } from '../../../../app/components/auth/auth-render';
import { CollapsibleContent } from '../../../../app/components/collapsible-folder/styles';
import { Role } from '../../../../app/constants/auth';
import {
  CheckboxChange,
  ViewOptionRecord,
  useMonitorFilteringContext,
} from '../../../context/monitor-filtering-context';
import { StyledFiltering } from '../../chart-header/styles';
import { LabelCheckboxList } from '../../heatmap/label-checkbox-list';
import { Chevron } from '../../time-display/styles';

import { Button, ChevronContainer, StyledCollapsibleBar, ViewOptionsUl } from './styles';

import { Checkbox, Icon, OptionsGroup } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { useClickOutside } from '@controlrooms/hooks';

export const Filtering = () => {
  const [isViewOptionsOpen, setIsViewOptionsOpen] = useState(false);
  const { viewOptions, handleCheckboxChange, checkboxStatus } = useMonitorFilteringContext();

  const optionsMenuRef = useRef<HTMLUListElement>(null);

  useClickOutside(optionsMenuRef, () => setIsViewOptionsOpen(false));

  return (
    <StyledFiltering>
      <div>
        <OptionsGroup style={{ margin: 0, padding: 0 }}>
          <AuthRender roles={[Role.GLOBAL_LABEL_VIEWER, Role.GLOBAL_LABEL_EDITOR]}>
            <Button
              className="group-tooltip-button"
              aria-haspopup="listbox"
              onClick={() => setIsViewOptionsOpen((prev) => !prev)}
            >
              <span className="label">View Options</span>
              <ChevronContainer>
                <Chevron />
              </ChevronContainer>
            </Button>
          </AuthRender>
          <ViewOptionsUl
            isOpen={isViewOptionsOpen}
            ref={optionsMenuRef}
            className="scrollable-container"
          >
            {viewOptions.map((option) => (
              <CollapsibleItem
                key={option.label_type_id}
                option={option}
                checkboxStatusById={checkboxStatus}
                handleCheckboxChange={handleCheckboxChange as (props: CheckboxChange) => void}
                isViewOptionsOpen={isViewOptionsOpen}
              />
            ))}
          </ViewOptionsUl>
        </OptionsGroup>
      </div>
    </StyledFiltering>
  );
};

interface CollapsibleItemProps {
  option: ViewOptionRecord;
  checkboxStatusById: Record<string, boolean>;
  handleCheckboxChange: (props: CheckboxChange) => void;
  isViewOptionsOpen: boolean;
}

const CollapsibleItem = ({
  option,
  checkboxStatusById,
  handleCheckboxChange,
  isViewOptionsOpen,
}: CollapsibleItemProps) => {
  const [isOpen, setIsOpen] = useState(option.type_name === 'Useful' ? true : false);
  const [height, setHeight] = useState<number | undefined>();

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen && ref.current) {
      setHeight(ref.current.scrollHeight);
    } else {
      setHeight(0);
    }
  }, [isOpen, isViewOptionsOpen]);

  const parentRef = useRef<HTMLDivElement>(null);

  // Check if some, but not all children are checked
  const isIndeterminate = option.child_types
    ? option.child_types.some((child) => checkboxStatusById[child.label_type_id]) &&
      !option.child_types.every((child) => checkboxStatusById[child.label_type_id])
    : false;

  return (
    <div ref={parentRef}>
      <StyledCollapsibleBar open={isOpen} key={option.label_type_id}>
        <div className="sidebar-collapse">
          <div className="colapsbtn" onClick={() => setIsOpen(!isOpen)}>
            <div className="arrow-icon">
              <Icon name={ICONS.Arrow} width="8" height="7" />
            </div>
          </div>
        </div>
        <Checkbox
          checked={!isIndeterminate && checkboxStatusById[option.label_type_id]}
          indeterminate={isIndeterminate}
          onChange={() => {
            handleCheckboxChange({ optionValue: option.label_type_id, selectChildren: true });
          }}
        />{' '}
        <Button onClick={() => setIsOpen(!isOpen)} style={{ marginLeft: '.5rem' }}>
          {option.type_name}
        </Button>
      </StyledCollapsibleBar>
      <CollapsibleContent data-testid="collapsible-content" open={isOpen} style={{ height }}>
        <LabelCheckboxList
          option={option}
          checkboxStatusById={checkboxStatusById}
          handleCheckboxChange={handleCheckboxChange}
          ref={ref}
          parentRef={parentRef}
        />
      </CollapsibleContent>
    </div>
  );
};

import styled from 'styled-components';

export const RadioButtonLabel = styled.label<{ checked: boolean }>`
  cursor: pointer;
  align-self: center;
  font-style: normal;
  font-weight: ${({ checked }) => (checked ? '400' : '300')};
  font-size: 13px;
  font-family: 'Open Sans', sans-serif;
  line-height: 13px;
  margin-left: 10px;
  padding-top: 4px;
  flex: 1 5 auto;
`;

export const RadioButtonStyle = styled.input`
  cursor: pointer;
  display: grid;
  box-sizing: border-box;
  place-content: center;
  appearance: none;
  margin: 0;
  width: 12px;
  height: 12px;
  background-color: ${({ theme }) => theme.radioButton.backgroundColor};
  border: 1px solid
    ${({ checked, theme }) =>
      checked ? theme.radioButton.borderColorChecked : theme.radioButton.borderColor};
  border-radius: 50%;
  transform: translateY(3.5px);

  &:before {
    content: '';
    position: fixed;
    width: 6px;
    height: 6px;
    background-color: inherit;
    border: 1px solid ${({ theme }) => theme.radioButton.before.borderColor};
    border-radius: 50%;
    transform: translateY(1px) translateX(1px);
    opacity: 20%;
  }
  &:after {
    content: '';
    width: 6px;
    height: 6px;
    background-color: ${({ theme }) => theme.radioButton.after.backgroundColor};
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
  }
  &:checked {
    box-shadow: ${({ theme }) => theme.radioButton.boxShadowChecked};
    &::after {
      transform: scale(1);
      border: none;
    }
  }
`;

export const RadioButtonContainer = styled.div<{ checked: boolean }>`
  display: flex;
  cursor: pointer;
  margin-bottom: 0.5rem;

  &:hover ${RadioButtonStyle} {
    box-shadow: ${({ theme }) => theme.radioButton.hover.boxShadow};
    border: 1px solid ${({ theme }) => theme.radioButton.hover.border};
    &::before {
      border-color: ${({ theme }) => theme.radioButton.hover.before.borderColor};
      opacity: 100;
    }
    &::after {
      background-color: ${({ theme }) => theme.radioButton.hover.checked.after.backgroundColor};
      box-shadow: ${({ theme }) => theme.radioButton.hover.checked.after.boxShadow};
    }
  }

  label {
    color: ${({ checked, theme }) =>
      checked ? theme.radioButton.label.colorChecked : theme.radioButton.label.color};
  }
`;

export const RadioHeader = styled.label`
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 17px;
  color: ${({ theme }) => theme.modal.panel.headerColor};
`;

import { ApiListResult, ApiResult } from '../api';
import {
  CREnvironment,
  CRMonitorAnomalyThresholds,
  CRMonitorErrorThreshold,
  CRTenant,
  CRTenantConfig,
  CRTimeThreshold,
  CRTimingConfig,
  EnvStatus,
} from '../api/tenant';

import { CustomHeader } from './tag';

export class Tenant {
  id: number;
  name: string;
  environmentId: number;
  locationId: number;
  preferences: TenantConfiguration;
  timezone: string;
  selected?: boolean;
  status: EnvStatus;

  constructor(init: CRTenant) {
    this.id = +init.tenant_id;
    this.name = init.tenant_name;
    this.environmentId = init.prod_env;
    this.locationId = init.loc_id;
    this.timezone = init.timezone;
    this.preferences = new TenantConfiguration(init.preferences);
    this.status = init.env_status;
  }
}

export class TenantResult implements ApiListResult<Tenant> {
  result: Tenant[];
  request: Record<string, string | number>;
  headers: CustomHeader;

  constructor(init: ApiListResult<CRTenant>) {
    this.result = init.result?.map((d) => new Tenant(d)) ?? [];
    this.request = init.request as Record<string, string | number>;
    this.headers = init.headers;
  }
}

export class Environment {
  id: number;
  description: string;
  tenantId: number;
  isBatch: boolean;
  isStreaming: boolean;
  isTrial: boolean;
  isSubscription: boolean;

  constructor(init: CREnvironment) {
    this.id = init.env_id;
    this.description = init.env_desc;
    this.tenantId = init.tenant_id;
    this.isBatch = init.is_batch;
    this.isStreaming = !init.is_batch;
    this.isTrial = init.is_trial;
    this.isSubscription = init.is_subscription;
  }
}

export class EnvironmentResult implements ApiResult<Environment> {
  result: Environment;
  request: Record<string, string | number>;
  headers: CustomHeader;

  constructor(init: ApiResult<CREnvironment>) {
    this.result = new Environment(init.result);
    this.request = init.request as Record<string, string | number>;
    this.headers = init.headers;
  }
}

const NO_DATA = 'No Data';

export class TenantConfiguration {
  resamplingIntervalSeconds: number;
  initialTimeframeSec?: number;
  monitorNoData: number;
  monitorAnomalyThresholds: MonitorAnomalyThresholds;
  monitorErrorThresholds: Record<number, MonitorErrorThreshold>;
  monitorTimeThresholds: Array<TimeThreshold>;
  analyzeTimeThresholds: Array<TimeThreshold>;

  constructor(init: CRTimingConfig) {
    const noDataValue = init.monitor_error_thresholds.find((t) => t.error === NO_DATA);
    if (!noDataValue) throw new Error('Missing No Data Threshold');

    this.resamplingIntervalSeconds = init.resampling_interval_sec;
    this.initialTimeframeSec = init.initial_timeframe_sec;
    this.monitorNoData = noDataValue.threshold;
    this.monitorAnomalyThresholds = new MonitorAnomalyThresholds(init.monitor_anomaly_thresholds);
    this.monitorErrorThresholds = init.monitor_error_thresholds
      .filter((t) => t.error !== NO_DATA)
      .reduce<Record<number, MonitorErrorThreshold>>((acc, t) => {
        acc[t.threshold] = new MonitorErrorThreshold(t);
        return acc;
      }, {});
    this.monitorTimeThresholds = init.monitor_time_thresholds.map((t) => new TimeThreshold(t));
    this.analyzeTimeThresholds = init.analyze_time_thresholds.map((t) => new TimeThreshold(t));
  }
}

export class MonitorAnomalyThresholds {
  high: number;
  medium: number;
  low: number;
  none: number;

  constructor(init: CRMonitorAnomalyThresholds) {
    this.high = init.high;
    this.medium = init.medium;
    this.low = init.low;
    this.none = init.none;
  }
}

export class MonitorErrorThreshold {
  error: string;
  threshold: number;

  constructor(init: CRMonitorErrorThreshold) {
    this.error = init.error;
    this.threshold = init.threshold;
  }
}

export class TimeThreshold {
  rangeMinutes: number;
  intervalSeconds: number;

  constructor(init: CRTimeThreshold) {
    this.rangeMinutes = init.window_time_in_minutes;
    this.intervalSeconds = init.interval_in_seconds;
  }
}

export class TenantConfig {
  showInvite: boolean;
  showUpload: boolean;
  banner: string;
  inviteTemplate: {
    allowed: string[];
    default: string;
  };
  constructor(init: CRTenantConfig) {
    this.showInvite = init.show_invite;
    this.showUpload = init.show_upload_link;
    this.banner = init.banner;
    this.inviteTemplate = init.invite_template;
  }
}

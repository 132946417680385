import { useAuth0 } from '@auth0/auth0-react';
import dayjs from 'dayjs';
import { useContext, useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';

import { useViewContext } from '../../app-v2/context/view-context';
import { Role } from '../constants/auth';
import { useRoles } from '../context/authorization-context';
import { EnsembleContext } from '../context/ensemble-context';
import { getAnomalies } from '../services/anomalies';

import { useLabelsAPI } from './accounts-customize';
import { FolderSort, usePlantFoldersWithModel } from './folders';
import { useTenants } from './tenants';

import { AnomalyPayload, ParentTag, Plant, TimeRange, TimeThreshold } from '@controlrooms/models';
import { ObjectUtils } from '@controlrooms/utils';
import { IntervalUtil } from '@controlrooms/utils/src/chart-utils/interval';

export const ANOMALIES_KEY = 'anomalies';

interface AnomalyParams extends TimeRange {
  streamingTimeInSeconds?: number;
}

export const useHeatmap = (
  { startTime, endTime, streamingTimeInSeconds }: AnomalyParams,
  sort: FolderSort = FolderSort.DEFAULT,
  flooredRequest = false,
  selectedFolders: number[] = [],
  pinnedTags: ParentTag[] = [],
) => {
  const {
    viewState: { selectedEnsemble: viewEnsemble },
    getQueryKeyWithViewId,
  } = useViewContext();
  const { selectedEnsemble: appEnsemble } = useContext(EnsembleContext);
  const selectedEnsemble = viewEnsemble ?? appEnsemble;
  const { currentTenant } = useTenants();
  const tenantConfig = currentTenant?.preferences;
  const isStreaming = Boolean(streamingTimeInSeconds);
  const duration = endTime?.diff(startTime, 'seconds') ?? 0;
  const formattedStartTime = flooredRequest
    ? dayjs(startTime).second(0).millisecond(0).toISOString()
    : startTime?.toISOString() ?? undefined;
  const formattedEndTime = endTime?.toISOString() ?? undefined;

  let interval = IntervalUtil.DEFAULT_INTERVAL;
  if (startTime && endTime) {
    interval = IntervalUtil.fromThresholds(
      startTime,
      endTime,
      tenantConfig?.monitorTimeThresholds as Array<TimeThreshold>,
    );
  }

  const { isLoading: isFoldersLoading, data: folders } = usePlantFoldersWithModel(sort);

  const subsystemIds = useMemo(() => getSubsystemIds(folders), [folders]) as number[];
  const pinnedFolderIds = pinnedTags.map((tag) => tag.folder);
  const selectedFolderIds = selectedFolders.length ? selectedFolders : subsystemIds;
  const folderIds = [...new Set([...pinnedFolderIds, ...selectedFolderIds])];

  const { useLabeledEventsInRangeQuery } = useLabelsAPI();
  const labeledEventsResult = useLabeledEventsInRangeQuery(
    formattedStartTime,
    formattedEndTime,
    interval,
  );

  const { userRoles } = useRoles();
  const { user } = useAuth0();

  const payload = {
    folders: folderIds,
    interval,
  } as AnomalyPayload;

  if (isStreaming) {
    payload.preset = streamingTimeInSeconds;
  } else {
    payload.start_time = formattedStartTime;
    payload.end_time = formattedEndTime;
  }

  if (selectedEnsemble?.family_id) {
    payload.ensemble_family_id = selectedEnsemble?.family_id;
  }

  const key = [getQueryKeyWithViewId(ANOMALIES_KEY), payload, selectedEnsemble?.family_id];
  const result = useInfiniteQuery(
    key,
    ({ pageParam = { start_time: formattedStartTime, end_time: formattedEndTime } }) =>
      getAnomalies({ ...payload, ...pageParam }),
    {
      enabled:
        ObjectUtils.areNonNull(
          payload as unknown as Record<string, unknown>,
          Object.keys(payload),
        ) && Boolean(folderIds?.length),
      retry: isStreaming ? 1 : undefined,
      refetchInterval: isStreaming ? IntervalUtil.refetchMsFromInterval(interval) : false,
      getPreviousPageParam: ({ request }) => {
        const { start_time } = request;

        // TODO - stop if before data start?
        return {
          start_time: dayjs(start_time).subtract(duration, 'seconds').toISOString(),
          end_time: dayjs(start_time).toISOString(),
        };
      },
      getNextPageParam: ({ request }) => {
        if (isStreaming) return undefined;

        const { end_time } = request;
        // TODO - stop if future?

        return {
          start_time: dayjs(end_time).toISOString(),
          end_time: dayjs(end_time).add(duration, 'seconds').toISOString(),
        };
      },
    },
  );

  const canViewLabeledEvents =
    user?.email_verified &&
    (userRoles.includes(Role.GLOBAL_LABEL_EDITOR) || userRoles.includes(Role.GLOBAL_LABEL_VIEWER));

  if (canViewLabeledEvents) {
    return {
      ...result,
      labeledEvents: labeledEventsResult.data?.result.labeled_events ?? [],
      isLoading: result.isLoading || isFoldersLoading || labeledEventsResult.isLoading,
      interval,
    };
  }

  return {
    ...result,
    labeledEvents: [],
    isLoading: result.isLoading || isFoldersLoading,
    interval,
  };
};

export const getSubsystemIds = (folders: Plant | undefined) => {
  if (!folders) return [];
  return folders?.subfolders
    .reduce(
      (acc: Array<number[]>, system) => [
        ...acc,
        system.subfolders.reduce(
          (subsystemIds: number[], subsystem) => [...subsystemIds, subsystem.folder],
          [],
        ),
      ],
      [],
    )
    .flat();
};

import React from 'react';

import MenuIcon from '../../pages/home/Menu.svg';

import { StyledBurger } from './burgerStyled';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const Burger: React.FC<Props> = ({ open, setOpen }) => {
  return (
    <StyledBurger open={open} onClick={() => setOpen(!open)}>
      <img src={MenuIcon} alt="hamburg-icon" height={35} width={25}></img>
    </StyledBurger>
  );
};

export default Burger;

import { CheckboxTheme } from 'styled-components';

import { colors } from '@controlrooms/design-tokens';

export const checkboxDarkTheme: CheckboxTheme = {
  borderColor: colors.focus.dark[3],
  boxInnerShadow: '1px 2px 2px 0px #00000080 inset',
  backgroundColor: colors.focus.dark[5],
  checkColor: '#C1EFF9',
  boxShadow: '0px 0px 8px 0px #80F7FF',
};

export const checkboxLightTheme: CheckboxTheme = {
  borderColor: colors.focus.light[4],
  boxInnerShadow: '1px 2px 2px 0px #00000080 inset',
  backgroundColor: colors.k[100],
  checkColor: '#3C6A73',
  boxShadow: '0px 0px 8px 0px #80F7FF',
};

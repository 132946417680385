import { useMutation } from 'react-query';

import { BatchDeleteParams, createTenant, deleteData } from '../services/batch';

export const useDeleteData = () => {
  return useMutation((params: BatchDeleteParams | undefined) => deleteData(params));
};

export const useCreateTenant = () => {
  return useMutation(createTenant);
};

import styled, { css } from 'styled-components';

import { ButtonProps } from './types';

export const ButtonGroup = styled.div`
  display: flex;
  button {
    margin-right: 6px;
  }
`;

export const StyledButton = styled.button<
  Pick<ButtonProps, 'buttonSize' | 'iconName' | 'iconSize' | 'buttonType'>
>`
  display: flex;
  align-items: center;
  padding: 0 8px;
  justify-content: center;
  line-height: 17px;

  .menu-button-icon {
    margin-left: 5px;

    svg {
      margin-top: 2px;
      fill: ${({ theme }) => theme.timeDisplay.chevronColor};
    }
  }

  &:hover {
    cursor: pointer;
  }

  span {
    font-weight: 600;
  }
  ${({ buttonType }) =>
    (buttonType === 'primary' || buttonType === 'secondary' || buttonType === 'menu') &&
    css`
      border-radius: 4px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
      cursor: pointer;
      &:hover {
        box-shadow: ${({ theme }) => theme.button.boxShadow};
      }
      &:active {
        background: ${({ theme }) => theme.button.selectedBackgroundColor};
      }
    `}
  ${(props) =>
    (props.buttonType === 'primary' || props.buttonType === 'menu') &&
    css`
      background: ${({ theme }) => theme.button.primaryBackgroundColor};
      border: 1px solid ${({ theme }) => theme.button.borderColorPrimary};
      color: ${({ theme }) => theme.button.primaryTextColor};
    `}

${(props) =>
    props.buttonType === 'secondary' &&
    css`
      background: ${({ theme }) => theme.button.secondaryBackgroundColor};
      color: ${({ theme }) => theme.button.secondaryTextColor};
      border: 1px solid ${({ theme }) => theme.button.borderColorSecondary};
    `}
${(props) =>
    (props.buttonType === 'text' || props.buttonType === 'menu-text') &&
    css`
      background: none;
      border: none;
      color: ${({ theme }) => theme.button.textButton.textColor};
      &:hover {
        filter: drop-shadow(0px 0px 0px #ffffff);
        box-shadow: none;
        border: none;
      }
    `}

${(props) =>
    props.buttonType === 'menu' &&
    css`
      display: inline-flex;

      svg {
        fill: ${({ theme }) => theme.button.textButton.textColor};
      }
    `}

${(props) =>
    props.buttonType === 'menu-text' &&
    css`
      display: inline-flex;
    `}

${(props) =>
    props.buttonType === 'link' &&
    css`
      color: ${({ theme }) => theme.linkColor};
      text-decoration: underline;
      background: none;
      border: none;
      padding: 0;
      display: inline-block;
      &:hover {
        filter: drop-shadow(0px 0px 0px #ffffff);
        box-shadow: none;
        border: none;
      }
    `}

${(props) =>
    props.buttonSize === 'small' &&
    css`
      height: 24px;
      font-size: 11px;
      font-family: 'Open Sans', sans-serif;
      svg {
        height: 11px;
        width: 11px;
        margin-right: 2px;
      }
    `}

${(props) =>
    props.buttonSize === 'small' &&
    props.buttonType !== 'link' &&
    css`
      padding: 0 8px;
    `}


${(props) =>
    props.buttonSize === 'large' &&
    css`
      height: 30px;
      font-size: 13px;
      font-family: 'Open Sans', sans-serif;
      svg {
        height: 18px;
        width: 18px;
        margin-right: 4px;
      }
      span {
        font-size: 13px;
      }
    `}

  ${(props) =>
    props.buttonSize === 'large' &&
    props.buttonType === 'icon' &&
    css`
      height: 24px;
      width: 24px;
      svg {
        height: 24px;
        width: 24px;
      }
    `}

${(props) =>
    props.disabled === true &&
    css`
      background: ${({ theme }) => theme.button.disabledBackgroundColor};
      border: 1px solid ${({ theme }) => theme.button.borderColorSecondary};
      color: ${({ theme }) => theme.button.disabledTextColor};
      cursor: default;

      &:hover {
        box-shadow: none;
      }
      &:active {
        background: ${({ theme }) => theme.button.disabledBackgroundColor};
      }
    `}
`;

import { ModalTheme } from 'styled-components';

import { colors } from '@controlrooms/design-tokens';

export const modalDarkTheme: ModalTheme = {
  contentBackgroundColor: colors.container['b-g-8'],
  primaryBackground: colors.container['b-g-8'],
  overlayBackgroundColor: colors.container['b-g-8'],
  secondaryBackground: `linear-gradient(180deg, #302e2c 0%, #3e4b4d 46.88%, #4D696F 100%)`,
  header: {
    borderColor: colors.content['neutral-5'],
    headerBackgroundColor: colors.container['b-g-8'],
    headerTextColor: colors.k['100'],
    subHeaderColor: colors.content['neutral-2'],
    headerInfoColor: colors.content['neutral-3'],
    contentBackgroundColor: colors.container['b-g-2'],
    color: colors.content['neutral-6'],
  },
  footer: {
    borderColor: colors.content['neutral-5'],
    secondaryBackground: colors.focus.dark[3],
    button: {
      color: colors.content['neutral-1'],
    },
    cancelButton: {
      background: 'linear-gradient(180deg, #222320 0%, #191a18 100%)',
    },
    updateButton: {
      background: 'linear-gradient(180deg, #224b4e 0%, #1f3133 49.48%, #1f3133 100%)',
    },
    closeButton: {
      color: '#c4c4c4',
    },
  },
  panel: {
    firstChildBackgroundColor: colors.content['neutral-5'],
    headerColor: colors.content['neutral-2'],
    selectedTextColor: colors.content['neutral-1'],
    unselectedTextColor: colors.content['neutral-2'],
    optionsColor: colors.content['neutral-2'],
    color: colors.content['neutral-1'],
    searchColor: colors.k[40],
    searchBackgroundColor: colors.k[5],
    searchBorderColor: colors.k[12],
    liBackground: 'linear-gradient(180deg, #0a1415 0%, #0a1415 92.71%, #2e4547 100%)',
  },
  input: {
    background: colors.k[5],
    borderColor: colors.k[10],
  },
  navigation: {
    color: colors.content['neutral-3'],
    activeColor: colors.k[100],
  },
  containerBorderColor: colors.content['neutral-5'],
};

// These are the same as dark mode.
export const modalLightTheme: ModalTheme = {
  primaryBackground: colors.container['b-g-2'],
  secondaryBackground: `linear-gradient(180deg, #F9F8F5 0%, #BAC6C9 46.88%, #8FB8C0 100%)`,
  overlayBackgroundColor: colors.container['b-g-8'],
  contentBackgroundColor: colors.container['b-g-2'],
  header: {
    borderColor: colors.content['neutral-2'],
    headerBackgroundColor: colors.container['b-g-2'],
    headerTextColor: colors.k['5'],
    subHeaderColor: colors.content['neutral-5'],
    headerInfoColor: colors.content['neutral-3'],
    contentBackgroundColor: colors.container['b-g-2'],
    color: colors.content['neutral-6'],
  },
  footer: {
    secondaryBackground: colors.focus.light[4],
    borderColor: colors.container['b-g-5'],
    button: {
      color: colors.content['neutral-1'],
    },
    cancelButton: {
      background: 'linear-gradient(180deg, #222320 0%, #191a18 100%)',
    },
    updateButton: {
      background: 'linear-gradient(180deg, #224b4e 0%, #1f3133 49.48%, #1f3133 100%)',
    },
    closeButton: {
      color: '#c4c4c4',
    },
  },
  panel: {
    firstChildBackgroundColor: colors.content['neutral-5'],
    headerColor: colors.content['neutral-2'],
    selectedTextColor: colors.content['neutral-1'],
    unselectedTextColor: colors.content['neutral-2'],
    optionsColor: colors.content['neutral-2'],
    color: colors.content['neutral-5'],
    searchColor: colors.k[40],
    searchBackgroundColor: colors.container['b-g-1'],
    searchBorderColor: colors.k[65],
    liBackground: 'linear-gradient(180deg, #0a1415 0%, #0a1415 92.71%, #2e4547 100%)',
  },
  input: {
    background: colors.k[5],
    borderColor: colors.k[10],
  },
  navigation: {
    color: colors.content['neutral-4'],
    activeColor: colors.content['neutral-6'],
  },
  containerBorderColor: colors.content['neutral-5'],
};

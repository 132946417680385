import React, { useState, useEffect } from 'react';

import { Icon } from '../icon';

import { CheckboxContainer, HiddenCheckbox, StyledCheckbox, StyledLabel } from './styles';

import { ICONS } from '@controlrooms/constants';
interface IProps {
  className?: string;
  id?: string;
  checked?: boolean;
  labelWrap?: boolean;
  dataTestId?: string;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  onBlur?: (e: React.FormEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FormEvent<HTMLInputElement>) => void;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  customOnChange?: (e: React.FormEvent<HTMLInputElement>) => void;
}

export const FormCheckbox = React.forwardRef<HTMLInputElement, IProps>(
  (
    {
      className,
      id,
      checked,
      labelWrap = true,
      dataTestId,
      disabled,
      value,
      onChange,
      customOnChange,
      ...props
    }: IProps,
    ref,
  ) => {
    const [isChecked, setIsChecked] = useState(checked);
    const handleOnchange = (e: React.ChangeEvent<HTMLInputElement>): void => {
      customOnChange && customOnChange(e);
      setIsChecked(!isChecked);
    };
    const content = (
      <CheckboxContainer className={className} id={id} data-testid={dataTestId}>
        <HiddenCheckbox
          {...props}
          onChange={(e) => {
            onChange && onChange(e);
            handleOnchange(e);
          }}
          checked={value}
          value={value}
          disabled={disabled}
          ref={ref as React.RefObject<HTMLInputElement>}
        />
        <StyledCheckbox>
          {isChecked && <Icon name={ICONS.Checkmark} width="8.6" height="6.79" />}
        </StyledCheckbox>
      </CheckboxContainer>
    );

    useEffect(() => {
      setIsChecked(checked);
    }, [checked]);

    return labelWrap ? <StyledLabel>{content}</StyledLabel> : <>{content}</>;
  },
);

import { ConfirmEmailTheme } from 'styled-components';

import { colors } from '@controlrooms/design-tokens';

export const confirmEmailDarkTheme: ConfirmEmailTheme = {
  checkboxBorderColor: colors.focus.dark[3],
};

export const confirmEmailLightTheme: ConfirmEmailTheme = {
  checkboxBorderColor: colors.focus.light[4],
};

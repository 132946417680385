import { SearchTheme } from 'styled-components';

import { colors } from '@controlrooms/design-tokens';

export const searchDarkTheme: SearchTheme = {
  content: {
    textColor: colors.content['neutral-3'],
  },
  checkboxBorder: colors.focus.dark[3],
};

export const searchLightTheme: SearchTheme = {
  content: {
    textColor: colors.content['neutral-5'],
  },
  checkboxBorder: colors.focus.light[4],
};

import { Route, Routes } from 'react-router-dom';

import { Ably } from '../../../app/components/ably';
import AuthRoute from '../../../app/components/auth/auth-route';
import { FileUpload } from '../../../app/components/file-upload';
import { Paths } from '../../../app/constants/paths';
import AblyChannelProvider from '../../../app/context/ably-channel-context';
import { ErrorPage, PageNotFound } from '../../../app/pages/not-found/error-page';
import { Redirect } from '../../../app/pages/routes';
import GlobalStyle from '../../../theme/globalStyles';

import { LayoutV2 } from './ContentView';

export const RoutesV2: React.FC = () => {
  return (
    <div style={{ display: 'flex' }}>
      <AblyChannelProvider>
        <GlobalStyle />
        <Ably />
        <FileUpload />
        <Routes>
          <Route index element={<AuthRoute component={Redirect} />} />
          <Route path={'redirect'} element={<AuthRoute component={Redirect} />} />
          <Route path={'t/:tenantId/*'} element={<AuthRoute component={LayoutV2} />} />
          <Route
            path={Paths.NOT_FOUND}
            element={
              <ErrorPage>
                <PageNotFound />
              </ErrorPage>
            }
          />
        </Routes>
      </AblyChannelProvider>
    </div>
  );
};

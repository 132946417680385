import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import NumberFormat, { NumberFormatValues } from 'react-number-format';

import { TextInput } from '../text-input';

const getSubstringAsInt = (value: string, length: number) =>
  parseInt(value.substring(value.length - length, value.length), 10);

/**
 * Check if the entered date value is allowed.
 * @param value the string value
 * @param floatValue the float value
 */
const isDateAllowed = ({ value }: NumberFormatValues): boolean => {
  switch (value.length) {
    case 0:
      return true;
    // Month
    case 1:
      return getSubstringAsInt(value, 1) <= 1;
    case 2:
      return getSubstringAsInt(value, 2) <= 12;
    // Day
    case 3:
      return getSubstringAsInt(value, 1) <= 3;
    case 4:
      return getSubstringAsInt(value, 2) <= 31;
    // Year
    case 5:
    case 6:
    case 7:
    case 8:
      return true;
    default:
      return false;
  }
};

/**
 * Check if the entered time value is allowed.
 * @param value the string value
 * @param floatValue the float value
 */
const isTimeAllowed = ({ value }: NumberFormatValues): boolean => {
  switch (value.length) {
    case 0:
      return true;
    // Hour
    case 1:
      return getSubstringAsInt(value, 1) <= 2;
    case 2:
      return getSubstringAsInt(value, 2) <= 23;
    // Minute
    case 3:
    case 5:
      return getSubstringAsInt(value, 1) <= 5;
    // Second
    case 4:
    case 6:
      return getSubstringAsInt(value, 2) <= 59;
    default:
      return false;
  }
};

const dateTimeConfig = {
  time: {
    format: '##:##:##',
    placeholder: 'HH:mm:ss',
    mask: ['H', 'H', 'm', 'm', 's', 's'],
    isAllowed: isTimeAllowed,
  },
  date: {
    format: '##/##/####',
    placeholder: 'MM/DD/YYYY',
    mask: ['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y'],
    isAllowed: isDateAllowed,
  },
};

interface TimeInputMaskProps {
  name: string;
  label: string;
  onChange?: (newVal: string) => void;
  dateString: string;
  type: 'date' | 'time';
  dataTestId?: string;
}

export const DateTimeInputMask: React.FC<TimeInputMaskProps> = ({
  name,
  label,
  onChange,
  dateString,
  type = 'date',
  dataTestId,
}) => {
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext();

  useEffect(() => {
    if (dayjs(dateString, 'MM/DD/YYYY').isValid()) {
      const currentValue = getValues(name);
      const time = dayjs(currentValue, 'MM/DD/YYYY HH:mm:ss').format('HH:mm:ss');
      setValue(name, `${dateString} ${time}`);
    }
  }, [name, dateString, getValues, setValue]);

  return (
    <Controller
      render={({ field }) => (
        <NumberFormat
          data-testid={dataTestId}
          className="time-input-mask"
          id={name}
          customInput={TextInput}
          format={dateTimeConfig[type]?.format}
          placeholder={dateTimeConfig[type]?.placeholder}
          mask={dateTimeConfig[type]?.mask}
          isAllowed={dateTimeConfig[type]?.isAllowed}
          label={label}
          errorMessage={errors?.[field.name]?.message}
          onValueChange={({ formattedValue, value }) => {
            field.onChange(formattedValue);
            if (value.length === 8 && dayjs(formattedValue, 'MM/DD/YYYY').isValid() && onChange) {
              const date = dayjs(formattedValue, 'MM/DD/YYYY').format('MM/DD/YYYY');
              onChange(date);
            }
          }}
          {...field}
        />
      )}
      name={name}
      control={control}
      rules={{
        validate: (value: string) => {
          return value.match(/^[^a-zA-Z]+$/) ? undefined : 'Incomplete time range';
        },
      }}
    />
  );
};

import { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { useAlertsAPI } from '../../../app/hooks/alerts';

import {
  Button,
  Modal,
  ModalContents,
  ModalContext,
  ModalOpenButton,
  TextInput,
} from '@controlrooms/components';

interface Webhook {
  channel_name: string;
  webhook_url: string;
}

export const defaultWebhookValue: Webhook = {
  channel_name: '',
  webhook_url: '',
};

const WebhookRegistration = styled.div`
  padding: 20px;
  width: 100%;
  .go-back {
    font-family: Open Sans;
    font-size: 13px;
    font-weight: 400;
    line-height: 23px;
    color: var(--heatmap-light-02-solid, #688d92);
    cursor: pointer;
    text-decoration: underline;
  }
  .form-control {
    margin-bottom: 10px;
    input {
      height: 24px;
    }
  }
  .button-wrapper {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
  }
`;

export const WebhookModal: React.FC = ({ children }) => {
  const { isOpen, setIsOpen } = useContext(ModalContext);
  const [testConnectionSuccess, setTestConnectionSuccess] = useState<boolean>(false);
  const { testTeamsWebHookMutation } = useAlertsAPI();
  const { mutateAsync: testTeamsWebHook } = testTeamsWebHookMutation;

  const { registerTeamsWebHookMutation } = useAlertsAPI();
  const { mutateAsync: registerTeamsWebHook } = registerTeamsWebHookMutation;

  const methods = useForm<Webhook>({
    mode: 'onBlur',
    defaultValues: defaultWebhookValue,
  });

  const errors = methods.formState.errors;

  const handleTestConnection = async () => {
    try {
      setTestConnectionSuccess(false);
      const response = await testTeamsWebHook({
        channel_name: 'test',
        webhook_url: methods.getValues('webhook_url'),
      });
      if (response) {
        setTestConnectionSuccess(true);
      } else {
        setTestConnectionSuccess(false);
      }
    } catch (error) {
      console.log(error);
      setTestConnectionSuccess(false);
    }
  };

  const handleRegistration = async () => {
    try {
      const webhookFormData = methods.getValues();
      await registerTeamsWebHook([webhookFormData]);
      methods.resetField('channel_name');
      methods.resetField('webhook_url');
      setIsOpen(false);
    } catch (error) {
      console.log('error in registration>>>>', error);
      methods.setError('webhook_url', new Error('Something went wrong!'));
    }
    setTestConnectionSuccess(false);
  };

  return (
    <FormProvider {...methods}>
      <Modal unmountOnExit open={isOpen}>
        <ModalOpenButton>{children}</ModalOpenButton>
        <ModalContents
          title={`Teams Webhook`}
          styles={{ content: { width: '500px' } }}
          closeButtonText="Cancel"
          confirmButtonText="Register"
          closeButtonCallback={() => {
            methods.resetField('channel_name');
            methods.resetField('webhook_url');
            setIsOpen(false);
            setTestConnectionSuccess(false);
          }}
          confirmButtonCallback={handleRegistration}
          confirmButtonDisabled={!testConnectionSuccess}
          shouldCloseOnEsc={false}
        >
          <WebhookRegistration>
            <TextInput
              data-testid="webhook-name"
              label="Name"
              {...methods.register('channel_name', {
                validate: (value) => {
                  if (!value) {
                    return 'Required field';
                  }
                  return true;
                },
              })}
              errorMessage={errors?.channel_name?.message || ''}
              errorId="name-required"
            />
            <TextInput
              data-testid="webhook-url"
              label="Webhook URL"
              {...methods.register('webhook_url', {
                validate: (value) => {
                  if (!value) {
                    return 'Required field';
                  }
                  return true;
                },
              })}
              errorMessage={errors?.webhook_url?.message || ''}
              errorId="url-required"
            />
            <div className="button-wrapper">
              <Button
                data-testid="test-connection"
                buttonSize="small"
                buttonType="secondary"
                onClick={methods.handleSubmit(handleTestConnection)}
              >
                Test connection
              </Button>
            </div>
          </WebhookRegistration>
        </ModalContents>
      </Modal>
    </FormProvider>
  );
};

import Request from './request';

import { ApiResult, Filter, LabeledEvent, LabeledEventType } from '@controlrooms/models';

const SERVICE_ROOT = '/user-customize/accounts-customize';

export const createLabelType = (
  data: Partial<LabeledEventType>,
): Promise<ApiResult<{ message: string }>> => {
  return Request.post(`${SERVICE_ROOT}/label-type`, data);
};

export const updateLabelType = (
  data: Partial<LabeledEventType>,
): Promise<ApiResult<{ message: string }>> => {
  return Request.patch(`${SERVICE_ROOT}/label-type`, data);
};

export const getLabelType = (labelTypeId: number): Promise<ApiResult<LabeledEventType>> =>
  Request.get(`${SERVICE_ROOT}/label-type/${labelTypeId}`);

// Extend LabeledEventType to include subLabels
export interface LabeledEventTypeWithSubLabels extends LabeledEventType {
  subLabels?: LabeledEventType[];
}

// Return events in sentiment arrays
export const getLabelTypes = async () => Request.get(`${SERVICE_ROOT}/label-type`);

export const deleteLabelType = (labelTypeId: number): Promise<ApiResult<{ message: string }>> =>
  Request.delete(`${SERVICE_ROOT}/label-type/${labelTypeId}`);

export const createLabeledEvent = (
  data: Partial<LabeledEvent>,
): Promise<ApiResult<{ message: string }>> => Request.post(`${SERVICE_ROOT}/labeled-events`, data);

export const updateLabeledEvent = (
  data: Partial<LabeledEvent>,
): Promise<ApiResult<{ message: string }>> => Request.patch(`${SERVICE_ROOT}/labeled-events`, data);

export const deleteLabeledEvent = (
  labeledEventId: number,
): Promise<ApiResult<{ message: string }>> =>
  Request.delete(`${SERVICE_ROOT}/labeled-events/${labeledEventId}`);

export const getLabeledEvents = (): Promise<ApiResult<LabeledEvent[]>> =>
  Request.get(`${SERVICE_ROOT}/labeled-events`);

export const getLabeledEventsInRange = ({
  start_time,
  end_time,
  interval,
}: {
  start_time: string;
  end_time: string;
  interval: number;
}): Promise<ApiResult<{ labeled_events: LabeledEvent[] }>> => {
  return Request.get(`${SERVICE_ROOT}/labeled-events`, {
    params: { start_time, end_time, resolution: interval },
  });
};

export const getLabeledEventsByLabelTypeId = (
  labelTypeId: number,
): Promise<ApiResult<LabeledEvent[]>> =>
  Request.get(`${SERVICE_ROOT}/label_type/${labelTypeId}/labeled_events`);

export const getFilters = (): Promise<ApiResult<Filter[]>> => Request.get(`${SERVICE_ROOT}/filter`);

export const updateFilters = (data: Partial<Filter>): Promise<ApiResult<{ message: string }>> =>
  Request.post(`${SERVICE_ROOT}/filter`, data);

import { configureAbly } from '@ably-labs/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useEffect } from 'react';

import { AblyChannelContext } from '../../context/ably-channel-context';
import { useNotificationChannels } from '../../hooks/notification';
import { getAblyToken } from '../../services/notifications';

import { TokenRequest } from '@controlrooms/models';

export const Ably: React.FC = () => {
  const { isAuthenticated } = useAuth0();
  const { data: channels } = useNotificationChannels();
  const { setChannels } = useContext(AblyChannelContext);

  useEffect(() => {
    if (!isAuthenticated) return;

    configureAbly({
      authCallback: async (tokenParams, callback) => {
        try {
          const token = await getAblyToken();
          callback('', token.result as TokenRequest);
        } catch (error) {
          callback(error as string, '');
        }
      },
    });
  }, [isAuthenticated]);

  useEffect(() => {
    channels && setChannels(channels);
  }, [channels, setChannels]);

  return <></>;
};

import { AxiosError } from 'axios';
import { useContext, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { useViewContext } from '../../../app-v2/context/view-context';
import { AuthorizationContext } from '../../context/authorization-context';
import { EnsembleContext } from '../../context/ensemble-context';
import { useUserId } from '../../hooks/accounts';
import { useAlertsAPI } from '../../hooks/alerts';
import { useEnsembleModelsQuery } from '../../hooks/tags';
import { HeaderContainer } from '../browser-filter/styles';

import { DurationTitle, EnsembleFamilyTitle, SnoozeTitle, defaultAlertValue } from './alert-modal';
import { AlertSummary } from './alert-summary';
import {
  ActiveSelection,
  ConditionError,
  ContentTitle,
  ContentWrapper,
  DisableWrapper,
  EditAlert,
  InfoCard,
  LeftColumn,
  RightColumn,
  TabGroup,
  Tabs,
} from './styles';
import { TeamsWebhookWrapper as TeamsWebhook } from './teams-webhook-picker';

import {
  Button,
  FormCheckbox,
  Icon,
  Li,
  OptionsGroup,
  RadioButton,
  Switch,
  TextInput,
  Ul,
} from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { useClickOutside } from '@controlrooms/hooks';
import {
  Alert,
  AlertConditions as IAlertConditions,
  AlertViewSummary,
  AlertCondition,
  Ensemble,
} from '@controlrooms/models';

export enum AlertConditions {
  'ANOMALIES_DETECTED' = 'anomalies_detected',
  'IOW_LIMIT_EXCEEDED' = 'iow_limit_exceeded',
  'SPC_LIMIT_EXCEEDED' = 'spc_limit_exceeded',
}

enum AlertConditionsLabel {
  'ANOMALIES_DETECTED' = 'Anomalies detected',
  'IOW_LIMIT_EXCEEDED' = 'Limit exceeded',
  'SPC_LIMIT_EXCEEDED' = 'SPC Limit exceeded',
}

interface AlertModalFormProps {
  alertList: Alert[];
  targetType: string;
  targetId: string | number;
  multipleEdit?: boolean;
}

export const AlertForm: React.FC<AlertModalFormProps> = ({
  alertList,
  targetType,
  targetId,
  multipleEdit,
}) => {
  const [alert, setAlert] = useState<Alert>(alertList[0]);
  const [loading, setLoading] = useState<boolean>();
  const [showPermissionError, setShowPermissionError] = useState(false);
  const [isTeamsSelected, setIsTeamsSelected] = useState(true);
  const [selectedEmailAction, setSelectedEmailAction] = useState('overwrite');
  const userId = useUserId();
  const { userHasGlobalAlertEditRole } = useContext(AuthorizationContext);
  const { getAlertByTargetIDMutation, getAlertSummaryMutation } = useAlertsAPI();
  const { mutateAsync: getAlertByTargetID } = getAlertByTargetIDMutation;
  const { mutateAsync: getAlertSummary } = getAlertSummaryMutation;

  const {
    viewState: { selectedEnsemble: viewEnsemble },
  } = useViewContext();
  const { selectedEnsemble: appEnsemble } = useContext(EnsembleContext);
  const selectedEnsemble = viewEnsemble ?? appEnsemble;
  const { ensembleFamilies } = useEnsembleModelsQuery();
  const [ensembleFamily, setEnsembleFamily] = useState<Ensemble | null>(selectedEnsemble);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const filtersRef = useRef(null);
  const toggleViewActions = () => setIsFiltersOpen((prevState) => !prevState);
  useClickOutside(filtersRef, () => setIsFiltersOpen(false));

  const {
    register,
    reset,
    formState: { errors },
    getValues,
    setValue,
    trigger,
    setError,
    clearErrors,
    watch,
  } = useFormContext();

  const getSwitchStateForMultipleItem = (arr: (boolean | null)[]) => {
    let hasTrue = false;
    let hasFalse = false;

    for (const value of arr) {
      if (value) hasTrue = true;
      else hasFalse = true;
      if (hasTrue && hasFalse) return null;
    }

    if (hasTrue) return true;
    return hasFalse ? false : null;
  };

  useEffect(() => {
    if (multipleEdit) {
      setMultipleAlertData();
    } else {
      getAlertDetail();
    }

    return () => {
      reset({});
    };
    // eslint-disable-next-line
  }, []);

  const resetEnsembleFamilyData = (ensembleFamilyId: string | undefined) => {
    const ensembleFamily = ensembleFamilies.find(
      (ef) => ef.family_id === ensembleFamilyId,
    ) as Ensemble;
    setEnsembleFamily(ensembleFamily);
  };

  const setMultipleAlertData = () => {
    if (!alertList || alertList.length === 0) {
      return;
    }

    const teamsSelectionStates = alertList.map(
      (al) => al.alert_destination.teams_destination.selected,
    );

    setLoading(true);

    const [firstAlert, ...restAlerts]: Alert[] = alertList;

    const formValue: Alert = {
      ...defaultAlertValue,
    };

    alertList.map((alert) => {
      (formValue.alert_view_summary as AlertViewSummary).name = 'Current selection';
      ((formValue.alert_view_summary as AlertViewSummary).pinned_tag_count as number) += alert
        .alert_view_summary?.pinned_tag_count as number;
      ((formValue.alert_view_summary as AlertViewSummary).system_count as number) += alert
        .alert_view_summary?.system_count as number;
      ((formValue.alert_view_summary as AlertViewSummary).tag_count as number) += alert
        .alert_view_summary?.tag_count as number;
    });

    const showPermissionError = alertList.every((alert) => alert?.user_id == userId);
    setShowPermissionError(!showPermissionError);

    const shouldCopyDisable = restAlerts.every((alert) => alert.disabled === firstAlert.disabled);
    const teamsMultiSelectionsState = getSwitchStateForMultipleItem(teamsSelectionStates);
    const shouldCopyEnsembleFamily = restAlerts.every(
      (alert) => alert.ensemble_family_id === firstAlert.ensemble_family_id,
    );

    formValue.disabled = shouldCopyDisable ? firstAlert.disabled : null;
    formValue.alert_destination.teams_destination.selected = teamsMultiSelectionsState;
    formValue.ensemble_family_id = shouldCopyEnsembleFamily
      ? firstAlert.ensemble_family_id
      : selectedEnsemble?.family_id;

    Object.keys(formValue.alert_conditions).forEach((conditionKey) => {
      const condition = conditionKey as keyof IAlertConditions;

      ['selected', 'duration', 'snooze_duration'].forEach((prop) => {
        const key = prop as keyof AlertCondition;
        const shouldCopy = restAlerts.every(
          (alert) =>
            alert.alert_conditions[condition][key] === firstAlert.alert_conditions[condition][key],
        );

        formValue.alert_conditions[condition][key] = shouldCopy
          ? firstAlert.alert_conditions[condition][key]
          : null;
      });
    });
    reset(formValue);
    setAlert(formValue);
    resetEnsembleFamilyData(formValue.ensemble_family_id);
    setLoading(false);
  };

  const getAlertDetail = async () => {
    if (alert?.id) {
      setLoading(false);
      reset(alert);
      resetEnsembleFamilyData(alert.ensemble_family_id);
    } else {
      setLoading(true);
      getAlertByTargetID({ targetId, targetType })
        .then(({ result }) => {
          setAlert(result);
          reset(result);
          resetEnsembleFamilyData(result.ensemble_family_id);
          setLoading(false);
        })
        .catch((error: AxiosError) => {
          if (error?.response?.status === 404) {
            getAlertSummary({ targetId, targetType })
              .then(({ result }) => {
                setAlert({ ...alert, alert_view_summary: result });
                reset({
                  ...alert,
                  target_name: result.name,
                  target_id: targetId,
                  target_type: targetType,
                  alert_view_summary: result,
                  ensemble_family_id: selectedEnsemble?.family_id,
                });
                setLoading(false);
              })
              .catch(handleAlertSummaryError);
          } else {
            handleAlertSummaryError();
          }
        });
    }
  };

  const handleAlertSummaryError = () => {
    setError('apiError', {
      type: 'custom',
      message: `Error occurred while fetching ${targetType} summary. Please try again`,
    });
  };

  const onEmailActionChange = (selectedAction: string) => {
    setSelectedEmailAction(selectedAction);
    setValue('alert_destination.email_destination.email_action', selectedAction, {
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const handleOnOptionChange = (option: Ensemble) => {
    setEnsembleFamily(option);
    setValue('ensemble_family_id', option.family_id, { shouldDirty: true, shouldTouch: true });
    setIsFiltersOpen(false);
  };

  return (
    <EditAlert>
      {errors['apiError']?.message && (
        <InfoCard type="error">
          <Icon className="icon" name={ICONS.SystemDown} />
          <span data-testid="alert-error">{errors['apiError']?.message}</span>
        </InfoCard>
      )}
      {showPermissionError && !userHasGlobalAlertEditRole && (
        <InfoCard type="info">
          <Icon className="icon" name={ICONS.Info} />
          <span data-testid="permission-info">
            You do not have permission to edit some of what you selected, but you can continue to
            edit what you do have permission for below.
          </span>
        </InfoCard>
      )}
      {!loading && <AlertSummary alert={alert} targetType={targetType} />}
      <ContentWrapper>
        <LeftColumn>
          <ActiveSelection>
            <ContentTitle>Turn alert on/off</ContentTitle>
            <div className="active-wrapper">
              <Switch
                selected={
                  typeof getValues('disabled') === 'boolean' ? !getValues('disabled') : null
                }
                onClick={(e, selected) => {
                  setValue('disabled', !selected, {
                    shouldDirty: true,
                    shouldTouch: true,
                  });
                  trigger('disabled');
                }}
                dataTestId={'alerts_form'}
              />
              <span className="active">Active</span>
            </div>
          </ActiveSelection>
          <DisableWrapper disabled={getValues('disabled')}>
            <ContentTitle>Alert fires when...</ContentTitle>
            <div className="conditions">
              {Object.entries(alert?.alert_conditions).map(([key, condition], index) => {
                if (key === AlertConditions.SPC_LIMIT_EXCEEDED || !condition) return;
                return (
                  <div className="alert-config" key={key}>
                    <div className="checkbox">
                      <FormCheckbox
                        dataTestId={`${key}-checkbox`}
                        {...register(`alert_conditions.${key}.selected`, {
                          validate: (value) => {
                            if (multipleEdit) {
                              return true;
                            }
                            const conditions = getValues([
                              `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.selected`,
                              `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.selected`,
                            ]);
                            if (!value) {
                              clearErrors([
                                `alert_conditions.${key}.duration`,
                                `alert_conditions.${key}.snooze_duration`,
                              ]);
                            }
                            if (conditions?.includes(true)) {
                              clearErrors([
                                `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.selected`,
                                `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.selected`,
                              ]);
                              return true;
                            } else {
                              return 'At least one condition is required';
                            }
                          },
                        })}
                        checked={condition.selected}
                        disabled={errors['apiError']?.message || loading}
                        customOnChange={() => {
                          trigger([`alert_conditions.${key}.selected`]);
                        }}
                      />
                      <span>
                        {
                          AlertConditionsLabel[
                            key.toUpperCase() as keyof typeof AlertConditionsLabel
                          ]
                        }
                      </span>
                    </div>
                    <div className="duration-field">
                      <div className="wrapper">
                        <DurationTitle />
                        <div style={{ display: 'flex', marginTop: '6px' }}>
                          <TextInput
                            type="number"
                            data-testid={`${key}-edit-duration`}
                            value={watch(`alert_conditions.${key}.duration`)}
                            className="duration"
                            label=""
                            placeholder=""
                            errorId={
                              key === AlertConditions.ANOMALIES_DETECTED
                                ? `input--${alert?.alert_view_summary?.name}-anomalies-duration-error`
                                : `input--${alert?.alert_view_summary?.name}-limits-duration-error`
                            }
                            disabled={!getValues(`alert_conditions.${key}.selected`)}
                            {...register(`alert_conditions.${key}.duration`, {
                              setValueAs: (v) => {
                                if (!v) return null;
                                return parseInt(v, 10);
                              },
                              validate: (value) => {
                                if (parseInt(value, 10) <= 0) {
                                  return 'Must be greater than 0';
                                }
                                if (multipleEdit) {
                                  return true;
                                }
                                if (getValues(`alert_conditions.${key}.selected`) && !value) {
                                  return 'Required field';
                                }
                                return true;
                              },
                            })}
                            errorMessage={
                              `alert_conditions.${key}.duration`
                                .split('.')
                                .reduce((v, k) => (v || {})[k], errors)?.message || ''
                            }
                          />
                          <div className="actions">
                            <Button
                              buttonSize="small"
                              buttonType="icon"
                              iconName={ICONS.ArrowUp}
                              disabled={!getValues(`alert_conditions.${key}.selected`)}
                              onClick={() => {
                                setValue(
                                  `alert_conditions.${key}.duration`,
                                  parseInt(getValues(`alert_conditions.${key}.duration`) || 0, 10) +
                                    1,
                                  { shouldDirty: true, shouldTouch: true },
                                );
                                trigger(`alert_conditions.${key}.duration`);
                              }}
                              data-testid={`${key}-increment-duration`}
                              tabIndex={-1}
                            />
                            <Button
                              buttonSize="small"
                              buttonType="icon"
                              iconName={ICONS.ArrowDown}
                              disabled={!getValues(`alert_conditions.${key}.selected`)}
                              onClick={() => {
                                setValue(
                                  `alert_conditions.${key}.duration`,
                                  parseInt(getValues(`alert_conditions.${key}.duration`) || 0, 10) -
                                    1,
                                  { shouldDirty: true, shouldTouch: true },
                                );
                                trigger(`alert_conditions.${key}.duration`);
                              }}
                              data-testid={`${key}-decrement-duration`}
                              tabIndex={-1}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="wrapper">
                        <SnoozeTitle />
                        <div style={{ display: 'flex', marginTop: '6px' }}>
                          <TextInput
                            type="number"
                            data-testid={`${key}-edit-snooze`}
                            value={watch(`alert_conditions.${key}.snooze_duration`)}
                            className="duration"
                            label=""
                            placeholder=""
                            disabled={!getValues(`alert_conditions.${key}.selected`)}
                            errorId={
                              key === AlertConditions.ANOMALIES_DETECTED
                                ? `input--${alert?.alert_view_summary?.name}-anomalies-snooze-duration-error`
                                : `input--${alert?.alert_view_summary?.name}-limits-snooze-duration-error`
                            }
                            {...register(`alert_conditions.${key}.snooze_duration`, {
                              setValueAs: (v) => {
                                if (!v) return null;
                                return parseInt(v, 10);
                              },
                              validate: (value) => {
                                if (parseInt(value, 10) <= 0) {
                                  return 'Must be greater than 0';
                                }
                                if (multipleEdit) {
                                  return true;
                                }
                                if (getValues(`alert_conditions.${key}.selected`) && !value) {
                                  return 'Required field';
                                }
                                return true;
                              },
                            })}
                            errorMessage={
                              `alert_conditions.${key}.snooze_duration`
                                .split('.')
                                .reduce((v, k) => (v || {})[k], errors)?.message || ''
                            }
                          />
                          <div className="actions">
                            <Button
                              buttonSize="small"
                              buttonType="icon"
                              iconName={ICONS.ArrowUp}
                              disabled={!getValues(`alert_conditions.${key}.selected`)}
                              onClick={() => {
                                setValue(
                                  `alert_conditions.${key}.snooze_duration`,
                                  parseInt(
                                    getValues(`alert_conditions.${key}.snooze_duration`) || 0,
                                    10,
                                  ) + 1,
                                  { shouldDirty: true, shouldTouch: true },
                                );
                                trigger(`alert_conditions.${key}.snooze_duration`);
                              }}
                              data-testid={`${key}-increment-snooze-duration`}
                              tabIndex={-1}
                            />
                            <Button
                              buttonSize="small"
                              buttonType="icon"
                              iconName={ICONS.ArrowDown}
                              disabled={!getValues(`alert_conditions.${key}.selected`)}
                              onClick={() => {
                                setValue(
                                  `alert_conditions.${key}.snooze_duration`,
                                  parseInt(
                                    getValues(`alert_conditions.${key}.snooze_duration`) || 0,
                                    10,
                                  ) - 1,
                                  { shouldDirty: true, shouldTouch: true },
                                );
                                trigger(`alert_conditions.${key}.snooze_duration`);
                              }}
                              data-testid={`${key}-decrement-snooze-duration`}
                              tabIndex={-1}
                            />
                          </div>
                        </div>
                      </div>
                      {index === 0 && (
                        <div className="wrapper">
                          <EnsembleFamilyTitle />
                          <OptionsGroup className="filter-select">
                            <Button
                              data-testid="browser-filter"
                              buttonSize="small"
                              buttonType="menu"
                              className="filter-title"
                              onClick={toggleViewActions}
                              aria-haspopup="listbox"
                              aria-expanded={isFiltersOpen}
                            >
                              {ensembleFamily?.family_name || ''}
                            </Button>
                            <Ul isOpen={isFiltersOpen} className="filter-dropdown" ref={filtersRef}>
                              <HeaderContainer>ENSEMBLE FAMILIES</HeaderContainer>
                              {ensembleFamilies?.map((option) => {
                                const isSelected = ensembleFamily?.family_id === option.family_id;
                                return (
                                  <Li
                                    className={`filter-menu-item ${
                                      !isSelected ? 'unselected' : ''
                                    }`}
                                    data-testid={`ensemble_family_${option.family_id}`}
                                    onClick={() => {
                                      handleOnOptionChange(option);
                                    }}
                                  >
                                    {isSelected && (
                                      <Icon
                                        data-testid={`${option.family_id}-checkmark`}
                                        name={ICONS.CheckmarkDefault}
                                      />
                                    )}
                                    <span className="ensemble-family">{option.family_name}</span>
                                  </Li>
                                );
                              })}
                            </Ul>
                          </OptionsGroup>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            <ConditionError data-testid="alert-condition-error">
              {`alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.selected`
                .split('.')
                .reduce((v, k) => (v || {})[k], errors)?.message || ''}
            </ConditionError>
          </DisableWrapper>
        </LeftColumn>
        <RightColumn>
          <DisableWrapper disabled={getValues('disabled')}>
            <ContentTitle>Recipients</ContentTitle>
            <TabGroup>
              <Tabs
                isActive={isTeamsSelected}
                onClick={() => {
                  setIsTeamsSelected(true);
                }}
                data-testid="teams-tab-alert"
              >
                <Icon name={ICONS.Teams} />
                Microsoft Teams
              </Tabs>
              <Tabs
                isActive={!isTeamsSelected}
                onClick={() => {
                  setIsTeamsSelected(false);
                }}
                data-testid="email-tab-alert"
              >
                <Icon name={ICONS.Envelope} />
                Email
              </Tabs>
            </TabGroup>
            {isTeamsSelected && (
              <>
                {loading === false && alert.alert_destination.teams_destination && (
                  <TeamsWebhook
                    selected={alert.alert_destination.teams_destination.selected}
                    teamsChannels={alert.alert_destination.teams_destination.teams_channels}
                  />
                )}
              </>
            )}
            {!isTeamsSelected && (
              <>
                <div className="email-action">
                  <div style={{ display: 'flex' }}>How should the following emails be handled?</div>
                  <div className="options">
                    <RadioButton
                      data-testid="alert-email-overwrite"
                      className="tz-radio"
                      onChange={() => onEmailActionChange('overwrite')}
                      checked={selectedEmailAction === 'overwrite'}
                      id="alert-email-overwrite"
                      value="overwrite"
                    >
                      Overwrite
                    </RadioButton>
                    <RadioButton
                      data-testid="alert-email-append"
                      className="tz-radio"
                      onChange={() => onEmailActionChange('append')}
                      checked={selectedEmailAction === 'append'}
                      id="alert-email-append"
                      value="append"
                    >
                      Append
                    </RadioButton>
                    <RadioButton
                      data-testid="alert-email-remove"
                      className="tz-radio"
                      onChange={() => onEmailActionChange('remove')}
                      checked={selectedEmailAction === 'remove'}
                      id="alert-email-remove"
                      value="remove"
                    >
                      Remove
                    </RadioButton>
                  </div>
                </div>
                <div className="selection-field" style={{ padding: '8px' }}>
                  {getValues('alert_destination.email_destination.emails')?.map((email: string) => (
                    <span key={email} className="selected-email" data-testid={email}>
                      <span style={{ marginRight: '4px' }}>{email}</span>
                      <Icon
                        onClick={() => {
                          const updatedEmails = getValues(
                            'alert_destination.email_destination.emails',
                          );

                          const emailIndex = updatedEmails.indexOf(email);
                          if (emailIndex !== -1) {
                            updatedEmails.splice(emailIndex, 1);
                          }
                          setValue(
                            'alert_destination.email_destination.emails',
                            [...(updatedEmails || [])],
                            {
                              shouldDirty: true,
                              shouldTouch: true,
                            },
                          );
                          trigger();
                        }}
                        name={ICONS.Close}
                        width="8"
                        height="8"
                      />
                    </span>
                  ))}
                  <TextInput
                    data-testid={`email-input`}
                    value={watch(`emailInput`)}
                    type="multiline"
                    {...register(`emailInput`, {
                      validate: (value) => {
                        if (
                          value
                            ?.split(',')
                            .find((email: string) => !/.+@.+\.[A-Za-z]+$/.test(email))
                        ) {
                          return 'Please enter valid emails';
                        }
                        if (
                          getValues('alert_destination.email_destination.emails')?.includes(value)
                        ) {
                          return 'Duplicate email. Please enter different email';
                        }
                        return true;
                      },
                    })}
                    onKeyDown={(event) => {
                      const emailInput = getValues('emailInput');
                      const emailList =
                        getValues('alert_destination.email_destination.emails') || [];
                      event.stopPropagation();
                      if (event.key === 'Enter') {
                        event.preventDefault();

                        if (/.+@.+\.[A-Za-z]+$/.test(emailInput)) {
                          if (!emailList.includes(emailInput)) {
                            setValue(
                              'alert_destination.email_destination.emails',
                              [...emailList, emailInput],
                              {
                                shouldDirty: true,
                                shouldTouch: true,
                              },
                            );
                            setValue('emailInput', '');
                          }
                        }
                        trigger();
                      }
                    }}
                    errorId={`${
                      getValues('alert_destination.email_destination.emails')?.includes(
                        getValues(`emailInput`),
                      )
                        ? 'duplicate_email_error'
                        : 'invalid_email_error'
                    }`}
                    errorMessage={errors['emailInput']?.message || ''}
                  />
                </div>
              </>
            )}
          </DisableWrapper>
        </RightColumn>
      </ContentWrapper>
    </EditAlert>
  );
};

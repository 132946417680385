import { SidebarTheme, SidebarThemeV2 } from 'styled-components';

import { GRADIENTS } from '@controlrooms/constants';
import { colors } from '@controlrooms/design-tokens';

export const sidebarDarkTheme: SidebarTheme = {
  filtersBackground: colors.k[10],
  filtersColor: colors.content['neutral-1'],
  boxShadow: colors.effects['field-inner-shadow'],
  backgroundColor: colors.k[5],
  headers: {
    backgroundColor: colors.content['neutral-5'],
    gridRowInnerShadow: colors['grid-rows'].dark['row-inner-shadow'],
    gridRowHeaderHoverColor: colors['grid-rows'].light['row-header-hover'],
    heatmap: {
      backgroundColor: colors.content['neutral-5'],
      gridRowInnerShadow: 'inset 5px 5px 4px rgb(0 0 0 / 45%)',
    },
  },
  footer: {
    backgroundColor: colors.content['neutral-6'],
  },
  folders: {
    backgroundColor: colors.k[5],
    evenBackgroundColor: colors.k[8],
    textColor: colors.k[55],
    isAnomalous: colors.k[90],
    boxShadow: colors['grid-rows'].dark['browser-row-inner-shadow'],
    headerBackgroundColor: colors.content['neutral-5'],
    headerTextColor: colors.k[90],
    borderColor: colors.k[5],
    descriptionColor: colors.k[80],
    nameColor: colors.k[65],
    nameBackground: colors.k[12],
    chipColor: `linear-gradient(0deg, ${colors.heatmap.dark[GRADIENTS.g1].color1}, ${
      colors.heatmap.dark[GRADIENTS.g1].color2
    } 53.65%, ${colors.heatmap.dark[GRADIENTS.g1].color3} 100%)`,
    accordionArrowClosed: '',
    accordionArrowExpanded: colors.content['neutral-4'],
    accordionClosedBorder: '#8FB8C0',
    accordionClosedFill: colors.container['b-g-1'],
    selected: {
      backgroundGradient: colors.select.dark['row-select'],
    },
    hover: {
      checkboxBorderColor: colors.focus.dark[3],
      backgroundGradient: colors.select.dark['row-hover-grid'],
      nameBackgroundColor: colors.focus.dark[4],
      textColor: colors.k[100],
    },
  },
};

export const sidebarLightTheme: SidebarTheme = {
  boxShadow: colors.effects['field-inner-shadow'],
  filtersBackground: colors.container['b-g-2'],
  filtersColor: colors.content['neutral-6'],
  backgroundColor: colors.container['b-g-2'],
  headers: {
    backgroundColor: colors.container['b-g-3'],
    gridRowInnerShadow: colors['grid-rows'].light['row-inner-shadow'],
    gridRowHeaderHoverColor: colors['grid-rows'].light['row-header-hover'],
    heatmap: {
      backgroundColor: colors.container['b-g-4'],
      gridRowInnerShadow: 'inset 3px 2px 4px rgb(0 0 0 / 10%)',
    },
  },
  footer: {
    backgroundColor: colors.container['b-g-2'],
  },
  folders: {
    backgroundColor: colors.k[100],
    evenBackgroundColor: colors.k[97],
    textColor: colors.k[20],
    isAnomalous: colors.k[0],
    boxShadow: 'none',
    headerBackgroundColor: '#E6E3E1',
    headerTextColor: colors.content['neutral-6'],
    borderColor: colors.container['b-g-3'],
    descriptionColor: colors.k[20],
    chipColor: 'linear-gradient(0deg, #2A4745 0%, #233D30 30.21%, #2B635F 100%)',
    accordionArrowClosed: '',
    accordionArrowExpanded: colors.content['neutral-3'],
    accordionClosedBorder: '#8FB8C0',
    accordionClosedFill: colors.container['b-g-1'],
    nameColor: colors.k[20],
    nameBackground: colors.k[80],
    selected: {
      backgroundGradient: colors.select.light['row-select'],
    },
    hover: {
      checkboxBorderColor: colors.focus.light[4],
      backgroundGradient: colors.select.light['row-hover-grid'],
      nameBackgroundColor: colors.focus.light[3],
      textColor: colors.k[20],
    },
  },
};

export const sidebarV2Dark: SidebarThemeV2 = {
  parentSystemBackground: colors.content['neutral-5'],
  systemBackground: colors.k[5],
  tag: {
    tagColor: colors.k[12],
    tagOddBg: colors.k[8],
    tagEvenBg: colors.k[5],
  },
};
export const sidebarV2Light: SidebarThemeV2 = {
  parentSystemBackground: 'transparent',
  systemBackground: colors.k[100],
  tag: {
    tagColor: colors.k[80],
    tagOddBg: colors.k[97],
    tagEvenBg: colors.k[100],
  },
};

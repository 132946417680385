interface GenericArray {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any[] | any; // Change 'any' to more specific types if you know them
}

export class ObjectUtils {
  public static areNonNull(object: Record<string, unknown>, keys: string[]): boolean {
    return !keys.some((key) => object[key] === null || object[key] === undefined);
  }

  public static requireNonNull(object: Record<string, unknown>, keys: string[]): void {
    const missing = keys.filter((key) => object[key] === null || object[key] === undefined);
    if (missing.length) {
      throw new Error('Missing required object keys');
    }
  }

  /**
   * Flatten an array of nested object.
   *
   * @param array the array
   * @param property the object property containing the nested objects
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static flatten<T extends GenericArray>(array: T[], property: string): any[] {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let flat: any[] = [];

    array.forEach((a: T) => {
      flat.push(a);
      if (Array.isArray(a[property as keyof T]) && a[property]?.length) {
        flat = flat.concat(this.flatten<T>(a[property], property));
      }
    });

    return flat;
  }
  public static groupBy<T>(
    array: T[],
    propertyOrFunction: ((item: T) => string) | string,
  ): Record<string, T[]> {
    return array.reduce((accumulator: Record<string, T[]>, element: T) => {
      // Determine the key under which to store the element
      const key =
        typeof propertyOrFunction === 'function'
          ? propertyOrFunction(element)
          : (element[propertyOrFunction as keyof T] as unknown as string);

      // Initialize the group for the key if it does not exist
      if (!accumulator[key]) {
        accumulator[key] = [];
      }

      // Add the current element to the group
      accumulator[key].push(element);

      return accumulator;
    }, {});
  }
}

import styled from 'styled-components';

import { colors } from '@controlrooms/design-tokens';

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  .disabled {
    background: none;
    fill: none;
    box-shadow: none;
  }
`;

export const StyledLabel = styled.label`
  display: flex;
`;

// Hide checkbox visually but remain accessible to screen readers.
export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  display: none;
`;

export const StyledCheckbox = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid
    ${({ isDisabled, theme }) =>
      isDisabled ? colors.content['neutral-4'] : theme.checkbox.borderColor};
  border-radius: 3px;
  width: 12px;
  height: 12px;
  background: ${({ isDisabled, theme }) => (isDisabled ? 'none' : theme.checkbox.backgroundColor)};
  box-shadow: ${({ isDisabled, theme }) => (isDisabled ? 'none' : theme.checkbox.boxInnerShadow)};
  svg > path,
  svg > rect {
    fill: ${({ isDisabled, theme }) => (isDisabled ? 'none' : theme.checkbox.checkColor)};
  }
  :hover {
    box-shadow: ${({ isDisabled, theme }) => (isDisabled ? 'none' : theme.checkbox.boxShadow)};
  }
`;

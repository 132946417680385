import React, {
  Context,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useLabelsAPI, getTypeLabelsById } from '../../../app/hooks/accounts-customize';
import { AnalyzeContext, AnalyzeContextProps } from '../../context/analyze-context';
import { MonitorContext, MonitorContextProps } from '../../context/monitor-context';

import { LabelTooltip } from './styles';

import { useClickOutside } from '@controlrooms/hooks';

const TOOLTIP_OFFSET_Y = 35;

export const LabelTooltipComponent: React.FC = () => {
  type MonitorOrAnalyzeContext = Context<MonitorContextProps | AnalyzeContextProps>;

  let currentContext: Partial<MonitorOrAnalyzeContext> =
    AnalyzeContext as Partial<MonitorOrAnalyzeContext>;

  const isMonitorPage = (): boolean => {
    const pattern = /app\/t\/\d+\/monitor/;
    return pattern.test(location.pathname);
  };

  if (isMonitorPage()) {
    currentContext = MonitorContext as Partial<MonitorOrAnalyzeContext>;
  }

  const { labelTooltipData } = useContext(currentContext as MonitorOrAnalyzeContext);
  const tooltipRef = useRef() as RefObject<HTMLDivElement>;
  const initialElement = document.getElementById(labelTooltipData?.targetElementId as string);
  const initialPosition = initialElement?.getBoundingClientRect();
  const [adjustedLabelTooltipPosition, setAdjustedLabelTooltipPosition] = useState<{
    x: number;
    y: number;
  }>({ x: (initialPosition?.x as number) - 1000, y: (initialPosition?.y || 0) + TOOLTIP_OFFSET_Y });

  const { setLabelTooltipData } = useContext(currentContext as MonitorOrAnalyzeContext);

  const { useLabelTypesQuery } = useLabelsAPI();

  const labelTypesQuery = useLabelTypesQuery();

  const findTypeName = (labelIds: number[]): string | null => {
    if (labelTypesQuery.isSuccess && labelTypesQuery.data?.result.label_types) {
      return getTypeLabelsById(labelTypesQuery.data?.result.label_types, labelIds);
    }
    return null;
  };

  useClickOutside(tooltipRef, () => setLabelTooltipData(undefined));

  const handleResize = useCallback(() => {
    if (!tooltipRef.current || !labelTooltipData) {
      return;
    }

    const targetBcr = document
      .getElementById(labelTooltipData?.targetElementId as string)
      ?.getBoundingClientRect();

    const x = targetBcr?.x || 0;

    const getAdjustedTooltipPosition = (x: number) => {
      return x + (targetBcr?.width || 0) / 2 - (tooltipRef.current?.clientWidth || 0) / 2;
    };

    if (
      adjustedLabelTooltipPosition.x === getAdjustedTooltipPosition(x) &&
      adjustedLabelTooltipPosition.y === (initialPosition?.y || 0) - TOOLTIP_OFFSET_Y
    ) {
      return;
    }

    // Ensure tooltip is not offscreen
    const viewportWidth = window.innerWidth;
    const tooltipWidth = tooltipRef.current?.clientWidth || 0;
    const tooltipHeight = tooltipRef.current?.clientHeight || 0;
    const tooltipX = getAdjustedTooltipPosition(x);

    if (tooltipX + tooltipWidth > viewportWidth) {
      setAdjustedLabelTooltipPosition({
        x: viewportWidth - tooltipWidth,
        y: (initialPosition?.y || 0) - TOOLTIP_OFFSET_Y - tooltipHeight,
      });
      return;
    }

    setAdjustedLabelTooltipPosition({
      x: getAdjustedTooltipPosition(x),
      y: (initialPosition?.y || 0) - TOOLTIP_OFFSET_Y,
    });
  }, [labelTooltipData, adjustedLabelTooltipPosition, tooltipRef, initialPosition]);

  useEffect(() => {
    if (!labelTooltipData) {
      // Reset tooltip position offscreen when tooltip is closed
      // Prevents jumping when tooltip is reopened
      setAdjustedLabelTooltipPosition({
        x: -1000,
        y: 0,
      });
      return;
    }

    handleResize();
    // Adjust tooltip position on screen resize
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // Note: Specifically isabling react-hooks/exhaustive-deps fails in the CI linter. Hacky fix here.
    // eslint-disable-next-line
  }, [labelTooltipData]);

  if (!labelTooltipData) {
    return null;
  }

  return (
    <LabelTooltip ref={tooltipRef} labelTooltipPosition={adjustedLabelTooltipPosition}>
      {findTypeName(labelTooltipData?.eventTypeIds as number[])}
    </LabelTooltip>
  );
};

import styled from 'styled-components';

export const StyledSwitch = styled.div`
  --switch-transition: translateX(9px);

  display: flex;

  .switch {
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: relative;
    padding: 0;
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
    .switch__track {
      background: ${({ theme }) => theme.switch.switchTrack.background};
      border: 1px solid ${({ theme }) => theme.switch.switchTrack.borderColor};
      box-shadow: ${({ theme }) => theme.switch.switchTrack.boxShadow};
      border-radius: 10px;
      display: flex;
      width: 30px;
      height: 14px;
      transition: all 300ms;
      .leftToggle,
      .rightToggle {
        display: block;
        height: 100%;
        width: 50%;
      }
    }

    .switch__toggle {
      display: block;
      position: absolute;
      width: 8px;
      height: 2px;
      left: 12px;
      top: 7px;
      background: ${({ theme }) => theme.switch.switchToggle.background};
      box-shadow: ${({ theme }) => theme.switch.switchToggle.boxShadow};
      border-radius: 10px;
      transition: all 300ms;
    }

    &--on {
      .switch__toggle {
        transform: var(--switch-transition);
        background: ${({ theme }) => theme.switch.switchToggle.hover.background};
        border: 1px solid ${({ theme }) => theme.switch.switchToggle.selected.borderColor};
        box-shadow: ${({ theme }) => theme.switch.switchToggle.selected.boxShadow};
        width: 10px;
        height: 8px;
        left: 8px;
        border-radius: 10px;
        top: 3px;
      }

      .switch__track {
        background: ${({ theme }) => theme.switch.switchTrack.selected.background};
      }
    }

    &--off {
      .switch__toggle {
        transform: var(--switch-transition);
        background: transparent;
        border: 1px solid ${({ theme }) => theme.switch.switchToggle.borderColor};
        box-shadow: ;
        width: 10px;
        height: 8px;
        left: -6px;
        border-radius: 10px;
        top: 3px;
      }

      .switch__track {
        // background: transparent;
        // border: 1px solid ${({ theme }) => theme.switch.switchTrack.borderColor};
      }
    }

    &:hover {
      .switch__track {
        box-shadow: ${({ theme }) => theme.switch.switchToggle.selected.boxShadow};
        border: 1px solid ${({ theme }) => theme.switch.switchToggle.hover.borderColor};
      }
    }
  }
`;

import { HeaderThemeSystem } from 'styled-components';

import { colors } from '@controlrooms/design-tokens';

export const headerDarkThemeSystem: HeaderThemeSystem = {
  titleColor: colors.content['neutral-2'],
  background: 'linear-gradient(180deg, #191A18 0%, #232221 100%)',
  boxShadow: colors.elevation.dark[3],
  pinIconFill: colors.content['neutral-5'],
  closeIconFill: colors.content['neutral-5'],
  closeIconFillHover: colors.focus.dark[2],
  closedEyeFill: colors.content['neutral-3'],
  hiddenTagsCountColor: colors.content['neutral-3'],
  backgroundPinned:
    'linear-gradient(180deg, #090c16 0%, #0f251f 79.17%, #132b11 92.45%, #16310c 100%)',
  topBorderRight: `1px solid ${colors.focus.dark[3]}`,
  titleColorHover: colors.content['neutral-1'],
  topBackgroundHover:
    'linear-gradient( 360deg, rgba(255, 255, 255, 0) 0%, rgba(128, 247, 255, 0.17) 100%)',
  topBackgroundHoverPinned:
    'linear-gradient(360deg, rgba(255, 255, 255, 0) 0%, rgba(128, 247, 255, 0.17) 100%)',
};

export const headerLightThemeSystem: HeaderThemeSystem = {
  titleColor: colors.content['neutral-6'],
  background: colors.container['b-g-4'],
  boxShadow: colors.elevation.light[3],
  pinIconFill: colors.content['neutral-6'],
  closeIconFill: colors.content['neutral-6'],
  closeIconFillHover: colors.content['neutral-6'],
  closedEyeFill: colors.content['neutral-5'],
  hiddenTagsCountColor: colors.content['neutral-5'],
  backgroundPinned: `linear-gradient(180deg, ${colors.container['b-g-6']} #BBB8B4 0%, ${colors.container['b-g-5']} 100%)`,
  topBorderRight: `1px solid ${colors.focus.dark[3]}`,
  titleColorHover: colors.content['neutral-6'],
  topBackgroundHover:
    'linear-gradient( 360deg, rgba(255, 255, 255, 0) 0%, rgba(128, 247, 255, 0.17) 100%)',
  topBackgroundHoverPinned:
    'linear-gradient(360deg, rgba(255, 255, 255, 0) 0%, rgba(128, 247, 255, 0.17) 100%)',
};

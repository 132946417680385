import { useCallback, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Loading } from '../../../app/components/loading/loading';
import {
  FolderSort,
  useFlatFolders,
  usePlantFolders,
  useTagsByName,
} from '../../../app/hooks/folders';
import { useHiddenTags } from '../../../app/hooks/tags';
import { AnalyzeContext } from '../../context/analyze-context';
import { SearchContext } from '../../context/search-context';
import { useViewContext } from '../../context/view-context';
import BrowserFilter from '../browser-filter/browser-filter';

import { Search } from './search';
import { SearchView } from './search-view';
import { BackButton, BrowserHeaderSection } from './styles/styles';
import { SystemBrowserContainer } from './styles/system-styles';
import { TagViewContainer } from './styles/tag-styles';
import { SystemView } from './system-view';
import { TagView } from './tag-view';
import { ViewHiddenTag } from './view-hidden-tag';

import { Icon } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { ParentTag, FlattenedSubSystem, HiddenTags, ViewType } from '@controlrooms/models';

// should this go somewhere more central?

export const SystemBrowser = () => {
  // context
  const { pinnedTags, selectedFolders, currentFolder, setCurrentFolder, showHiddenTags } =
    useContext(AnalyzeContext);
  const {
    searchTerm,
    setSearchTerm,
    setIsSearchDebounce,
    setSearchInputValue,
    setSearchTermOnNavigate,
    searchTermOnNavigate,
  } = useContext(SearchContext);

  const [search] = useSearchParams();

  const { isLoading: isFoldersLoading, data: folders } = usePlantFolders(FolderSort.DEFAULT);
  const { data: flatFolders } = useFlatFolders(FolderSort.DEFAULT, {
    folderId: currentFolder as number,
  });
  const { data: hiddenTags } = useHiddenTags();
  const { setViewState } = useViewContext();
  const { data: tagsByName } = useTagsByName();

  const iowHiddenTags =
    hiddenTags?.result.map((t: HiddenTags) => ({ tag_name: t.tag_name, folder: t.folder })) || [];
  const isReport = search.get('report');

  // handlers
  const handleCheckboxCheck = useCallback(
    (id: number, tag?: ParentTag) => {
      // do we need to include folder id with tag for full unique id?
      if (tag) {
        setViewState((prev) => {
          const currentView = prev;
          const prevPinnedTags = currentView.view[ViewType.ANALYZE]?.pinnedTags || [];
          currentView.view[ViewType.ANALYZE].pinnedTags = prevPinnedTags.some(
            ({ folder, name }) => folder === tag.folder && name === tag.name,
          )
            ? prevPinnedTags.filter(
                ({ folder, name }) => !(folder === tag.folder && name === tag.name),
              )
            : [tag, ...prevPinnedTags];
          return {
            ...prev,
            currentView,
          };
        });

        return;
      }
      // TODO: determine if all pinned tags need to be explicitly set when a folder is selected
      // or can we control that behavior in all relevant parts of the app with selected folder alone?
      setViewState((prev) => {
        const currentView = prev;
        if (currentView.view[ViewType.ANALYZE].selectedFolders.includes(id)) {
          currentView.view[ViewType.ANALYZE].selectedFolders = selectedFolders.filter((x) => {
            return x !== id;
          });
        } else {
          currentView.view[ViewType.ANALYZE].selectedFolders = [id, ...selectedFolders];
        }
        //Set pinned tags for selected tags for Investigate view
        if (currentView.view[ViewType.INVESTIGATE].selectedTags[id]) {
          const pinnedSelectedTags = pinnedTags
            .filter((t) => t.folder === id)
            .map((t) => {
              return tagsByName[t.name];
            });
          if (pinnedSelectedTags.length > 0) {
            currentView.view[ViewType.INVESTIGATE].selectedTags = {
              ...currentView.view[ViewType.INVESTIGATE].selectedTags,
              [id]: pinnedSelectedTags,
            };
          } else {
            delete currentView.view[ViewType.INVESTIGATE].selectedTags[id];
          }
        }
        return {
          ...prev,
          currentView,
        };
      });
    },
    [pinnedTags, selectedFolders, setViewState, tagsByName],
  );

  const handlePinAll = useCallback(
    (isChecked: boolean) => {
      const allTags = flatFolders?.[0]?.tags || [];
      setViewState((prev) => {
        const currentView = prev;
        const currentPinnedTags = currentView.view[ViewType.ANALYZE].pinnedTags || [];

        const tagsNotPinned = allTags.filter((t) => {
          return !currentPinnedTags.some((pt) => pt.name === t.name && pt.folder === t.folder);
        });
        // if checked add all tags, if unchecked remove all tags
        currentView.view[ViewType.ANALYZE].pinnedTags = isChecked
          ? [...currentPinnedTags, ...tagsNotPinned]
          : currentPinnedTags.filter((t) => {
              return !allTags.some((at) => at.name === t.name && at.folder === t.folder);
            });
        currentView.isDirty = true;
        return {
          ...prev,
          currentView,
        };
      });
    },
    [flatFolders, setViewState],
  );

  const handleBack = () => {
    setCurrentFolder(null);
    setIsSearchDebounce(false);
    setSearchInputValue(searchTermOnNavigate);
    setSearchTerm(searchTermOnNavigate);
  };

  // TODO: what do we want to show here while loading?
  if (isFoldersLoading) {
    return <Loading overlay small={true} />;
  }

  const handleSearchNav = (fid: string | number) => {
    setSearchInputValue('');
    setSearchTermOnNavigate(searchTerm);
    setCurrentFolder(fid);
  };

  const browserView = () => {
    if (searchTerm) {
      return (
        <div className="browser-wrapper">
          <BrowserHeaderSection>
            <BrowserFilter />
            <Search />
            <div className="sub-title">
              <Icon name={ICONS.SelectTrend} width="9" height="9" className="dynamic-icon" /> Select
              trends to keep in the view.
            </div>
          </BrowserHeaderSection>
          <TagViewContainer>
            <SearchView
              handleCheckboxCheck={handleCheckboxCheck}
              pinnedTags={pinnedTags}
              onNav={handleSearchNav}
            />
          </TagViewContainer>
        </div>
      );
    }

    if (currentFolder) {
      return (
        <div className="browser-wrapper">
          <BrowserHeaderSection>
            <h3 data-testid="tag" className="system-view-title">
              Tag View
            </h3>
            <Search />
            <div className="sub-title">
              <div>
                <Icon name={ICONS.SelectTrend} width="9" height="9" className="dynamic-icon" />{' '}
                Select trends to keep in the view.
              </div>
              <div className="lower">
                <ViewHiddenTag hiddenTags={iowHiddenTags} />
              </div>
            </div>
            <BackButton
              data-testid="systems-back-button"
              className="back-button"
              onClick={() => handleBack()}
            >
              <Icon name={ICONS.Back} width="11" height="11" className="browser-header-back" />{' '}
              Systems
            </BackButton>
          </BrowserHeaderSection>
          <TagViewContainer>
            <TagView
              folders={flatFolders as FlattenedSubSystem[]}
              hiddenTags={iowHiddenTags as HiddenTags[]}
              handleCheckboxCheck={handleCheckboxCheck}
              handlePinAll={handlePinAll}
              pinnedTags={pinnedTags}
              selectedFolders={selectedFolders}
              showHiddenFlag={showHiddenTags}
            />
          </TagViewContainer>
        </div>
      );
    }

    return (
      <div className="browser-wrapper">
        <BrowserHeaderSection>
          <BrowserFilter />
          <Search />
          <div className="sub-title">
            <div>
              <Icon name={ICONS.Dynamic} width="9" height="9" className="dynamic-icon" /> Select
              systems for dynamic view.
            </div>
            <div className="lower">
              <ViewHiddenTag hiddenTags={iowHiddenTags} />
            </div>
          </div>
        </BrowserHeaderSection>
        {showHiddenTags ? (
          <TagViewContainer>
            <TagView
              folders={flatFolders as FlattenedSubSystem[]}
              hiddenTags={iowHiddenTags as HiddenTags[]}
              handleCheckboxCheck={handleCheckboxCheck}
              handlePinAll={handlePinAll}
              pinnedTags={pinnedTags}
              selectedFolders={selectedFolders}
              showHiddenFlag={showHiddenTags}
            />
          </TagViewContainer>
        ) : (
          <SystemView
            handleCheckboxCheck={handleCheckboxCheck}
            onNav={setCurrentFolder}
            plant={folders}
            selectedFolders={selectedFolders}
          />
        )}
      </div>
    );
  };

  if (isReport) return <></>;

  return <SystemBrowserContainer>{browserView()}</SystemBrowserContainer>;
};

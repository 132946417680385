import { useAuth0 } from '@auth0/auth0-react';
import React, { useState } from 'react';
import styled from 'styled-components';

import Logo from '../../../assets/controlrooms_dark.png';
import { useAnalytics } from '../../analytics';
import { useResendEmailConfrimation } from '../../hooks/accounts';

import { Button } from '@controlrooms/components';

type Prop = {
  message: string;
};

type ErrorPageProp = {
  children: React.ReactNode;
};

const ErrorPageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
  flex-direction: column;
  font-size: 19px;
  font-weight: 300;
  font-family: 'Open Sans', sans-serif;
  .login-logo {
    width: 300px;
    margin-bottom: 20px;
  }
`;

const ErrorHeader = styled.p`
  font-size: 22px;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  margin: 5px;
  text-align: center;
`;
const ErrorContent = styled.div`
  font-size: 14px;
  font-weight: 300;
  font-family: 'Open Sans', sans-serif;
  text-align: center;
`;
const HomeBtn = styled.button`
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  text-decoration: none;
  color: ${({ theme }) => theme.linkColor};
  background: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
`;

export const PageNotFound = () => {
  return (
    <>
      <ErrorHeader>404! Page not found.</ErrorHeader>
      <ErrorContent>
        <p>We're sorry, the page you requested could not be found.</p>
      </ErrorContent>
    </>
  );
};

export const Error: React.FC<Prop> = ({ message }) => {
  return (
    <>
      <ErrorHeader>Oops! Something went wrong.</ErrorHeader>
      <ErrorContent>{message}</ErrorContent>
    </>
  );
};

export const VerifyUser: React.FC = () => {
  const { mutateAsync: resendConfrimationEmail } = useResendEmailConfrimation();
  const [mailSent, setMailStatus] = useState(false);
  const [disabled, disableBtn] = useState(false);
  return (
    <>
      {mailSent ? (
        <ErrorContent>Verification email has been sent to your registered account.</ErrorContent>
      ) : (
        <>
          <ErrorHeader>Your account has been created!</ErrorHeader>
          <ErrorContent>
            <p>One more step before you can sign in, please verify your email.</p>
          </ErrorContent>
          <Button
            buttonType="primary"
            disabled={disabled}
            onClick={async () => {
              disableBtn(true);
              try {
                await resendConfrimationEmail();
                setMailStatus(true);
              } catch (error) {
                console.log('Error: ', error);
              } finally {
                disableBtn(false);
              }
            }}
            buttonSize="large"
          >
            Resend Verification Email
          </Button>
        </>
      )}
    </>
  );
};

export const ErrorPage: React.FC<ErrorPageProp> = ({ children }) => {
  const { logout } = useAuth0();
  const { track } = useAnalytics();
  return (
    <ErrorPageWrapper>
      <img className="login-logo" src={Logo} alt="Control Rooms" width="100" />
      {children}
      <ErrorContent>
        Return to{' '}
        <HomeBtn
          type="button"
          onClick={() => {
            track('User Logout via Error Page');
            sessionStorage.clear();
            logout({
              returnTo: window.location.origin,
            });
          }}
        >
          homepage
        </HomeBtn>
      </ErrorContent>
    </ErrorPageWrapper>
  );
};

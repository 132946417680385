import { LimitTheme } from 'styled-components';

import { colors } from '@controlrooms/design-tokens';

export const limitDarkTheme: LimitTheme = {
  backgroundColor: colors.k[5],
  limitsColBgColor: colors.container['b-g-8'],
  limitsSecondaryColBgColor: 'linear-gradient(180deg, #222320 0%, #191A18 100%)',
  tagHeaderBgColor: 'linear-gradient(360deg, #364B4D 0%, #464740 35.94%)',
  tagIdBgColor: '#333333',
  limitConfBorder: '1px solid #464740',
  slideDownBorder: '2px solid #464740',
  chartBgColor: '#222320',
  searchTextBorder: '1px solid #1F1F1F',
  limitConfTable: {
    tableHeaderTextColor: '#8C8C8C',
    tableBgColor: 'linear-gradient(180deg, #222320 0%, #191A18 100%)',
    tableHoverColor: 'linear-gradient(180deg, #111F22 0%, #121F22 71.61%, #132124 93.75%)',
  },
  content: {
    text: colors.content['neutral-1'],
    searchText: '#0D0D0D',
    tagHeaderSystemText: '#CCCCCC',
    tagHeaderText: '#E6E6E6',
  },
};

export const limitLightTheme: LimitTheme = {
  backgroundColor: colors.container['b-g-3'],
  limitsColBgColor: colors.container['b-g-2'],
  limitsSecondaryColBgColor: 'linear-gradient(180deg, #FCFCFA 0%, #E6E3E1 100%)',
  tagHeaderBgColor: 'linear-gradient(180deg, #FFFFFF 0%, #F7FEFF 93.75%, #F0FDFF 100%)',
  tagIdBgColor: '#CCCCCC',
  limitConfBorder: '1px solid #D9D5D1',
  slideDownBorder: '2px solid #D9D5D1',
  chartBgColor: '#FCFCFA',
  searchTextBorder: '1px solid #B3B3B3',
  limitConfTable: {
    tableHeaderTextColor: colors.k[40],
    tableBgColor: '#FFFFFF',
    tableHoverColor: 'linear-gradient(180deg, #E2EBED 0%, #E2EBED 73.96%, #DBE6E8 100%)',
  },
  content: {
    text: colors.content['neutral-6'],
    searchText: '#FCFCFA',
    tagHeaderSystemText: '#0D0D0D',
    tagHeaderText: '#0D0D0D',
  },
};

import { useAnalytics } from '../../../../analytics';

import { StyledNotificationBanner } from './styles';

import { Button } from '@controlrooms/components';

export enum NotificationSource {
  FILE_UPLOAD = 'file_upload',
}

export interface NotificationData {
  tenant_id: string;
  message_id: string;
  time: string;
  type: string;
  source: string;
  payload: {
    start_time: string;
    end_time: string;
    message: string;
    file_request_id?: string;
  };
}

export const NotificationBanner: React.FC<{
  notificationData: NotificationData | string;
  onDismiss?: () => void;
}> = ({ notificationData, onDismiss }) => {
  const { track } = useAnalytics();
  const dismiss = () => {
    onDismiss && onDismiss();
    track('Banner', {
      dismissBanner: 'clicked',
    });
  };

  return (
    <StyledNotificationBanner>
      <div className="content upload">
        {notificationData && (
          <div data-testid="notification" className="notification">
            {typeof notificationData === 'string' ? (
              <div>{notificationData}</div>
            ) : (
              <div>
                {(notificationData as NotificationData)?.source ===
                  NotificationSource.FILE_UPLOAD.toUpperCase() &&
                  ((notificationData as NotificationData)?.payload?.message || '')}
              </div>
            )}
            <Button
              data-tesid="close-x"
              iconName="close-x"
              buttonType="text"
              buttonSize="small"
              onClick={dismiss}
            ></Button>
          </div>
        )}
      </div>
    </StyledNotificationBanner>
  );
};

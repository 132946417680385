import { Dispatch, SetStateAction, useEffect, useState } from 'react';

/**
 * Sync state to session storage so that it persists through a page refresh.
 * Usage is similar to useState except we pass in a session storage key so that we can default to that value on page load instead of the specified initial value.
 *
 * Since the session storage API isn't available in server-rendering environments, we check that typeof window !== "undefined" to make SSR and SSG work properly.
 *
 * @param key the data key
 * @param initialValue the initial state value
 */
export const useSessionStorage = <T>(
  key: string,
  initialValue: T,
): [T, Dispatch<SetStateAction<T>>] => {
  const [value, setValue] = useState<T>(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      const item = sessionStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      sessionStorage.setItem(key, JSON.stringify(value));
    }
  }, [value, setValue, key]);

  return [value, setValue];
};

import { FunctionUtils, CallBack } from './function-utils';
import { NumberUtils } from './number-utils';
import { ObjectUtils } from './object-utils';
import { TimeUtils } from './time-utils';

/**
 * Generate a unique identifier
 */
const unique = () =>
  Math.random().toString(36).padEnd(11, '0').substring(2, 11) +
  Math.random().toString(36).padEnd(9, 'x').substring(2, 9);

export { FunctionUtils, type CallBack, NumberUtils, ObjectUtils, TimeUtils, unique };

export * from './chart-utils';
export * from './dom-utils';
export * from './event-utils';
export * from './limit-utils';
export * from './heatmap-utils';
export * from './location-utils';
export * from './folder-utils';

import { useAuth0 } from '@auth0/auth0-react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useState, useContext, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { matchPath, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { useAnalytics } from '../../analytics';
import { Paths } from '../../constants/paths';
import { TimePresetMap, getTimePresetMapKey } from '../../constants/time-selection-form';
import { AppContext } from '../../context/app-context';
// import { RangeInputMask } from '../timeframe-modal/time-range-selector/range-input-mask';
import { TimeRangeSelector } from '../timeframe-modal/time-range-selector';
import { TimezoneSelector } from '../timeframe-modal/timezone-selector';

import { StyledTimeRangeFilter } from './styles';

import { Button, TextInput, TextInputType } from '@controlrooms/components';
import {
  DateFormats,
  Fields,
  TimePresetKeyType,
  TimeRangesType,
  TimeRanges,
  TimePresetKeys,
} from '@controlrooms/models';
import { TimeUtils } from '@controlrooms/utils';

dayjs.extend(customParseFormat);
interface FormProps {
  timerange: TimeRangesType;
  preset: TimePresetKeyType | undefined;
  customTimeframe: string;
  timeframe: [dayjs.Dayjs, dayjs.Dayjs] | [];
  timezone: string;
  tagsCount: number;
}

const GridColumn = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  padding: 10px;
`;

const GridRow = styled.div`
  display: grid;
  background: ${({ theme }) => theme.reportFilterbackgroundColor};
  padding: 0px 10px;
`;

const DateRange = styled.div`
  padding: 0px 10px;
`;
const TimeZone = styled.div`
  padding: 0px 10px;
`;
const TagCount = styled.div`
  padding: 0px 10px;
`;
const ButtonSection = styled.div`
  padding: 0px 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
`;

export const ReportsFilter: React.FC = () => {
  const [isTimePresetsOpen, setIsTimePresetsOpen] = useState(false);
  const [isOtherTimezonesOpen, setIsOtherTimezonesOpen] = useState(false);
  const { pathname } = useLocation();
  const { logout } = useAuth0();
  const { track } = useAnalytics();

  useEffect(() => {
    if (isTimePresetsOpen) {
      track('Reports Filter', {
        openTimePresets: 'clicked',
      });
    }
    if (isOtherTimezonesOpen) {
      track('Reports Filter', {
        openOtherTimezones: 'clicked',
      });
    }
  }, [isOtherTimezonesOpen, isTimePresetsOpen, track]);

  const {
    analyzeTimeSelection,
    monitorTimeSelection,
    recordTimelineHistory,
    setAnalyzeTimeSelection,
    setMonitorTimeSelection,
    setTagsFilterCount,
  } = useContext(AppContext);

  const { startTime, endTime, timezone, streamingTimeInSeconds, timeRange } = matchPath(
    Paths.ANALYZE,
    pathname,
  )
    ? analyzeTimeSelection
    : monitorTimeSelection;
  const setTimeSelection = matchPath(Paths.ANALYZE, pathname)
    ? setAnalyzeTimeSelection
    : setMonitorTimeSelection;

  const methods = useForm<FormProps>({
    mode: 'onChange',
    defaultValues: {
      [Fields.TIMERANGE]: timeRange ?? TimeRanges.PRESET,
      [Fields.PRESET]:
        getTimePresetMapKey(streamingTimeInSeconds) ?? TimePresetKeys.LAST_TWELVE_HOURS,
      [Fields.CUSTOM_TIMEFRAME]:
        timeRange === TimeRanges.CUSTOM
          ? `${TimeUtils.toTimezone(startTime, timezone).format(
              DateFormats.DATETIME_ALT,
            )} - ${TimeUtils.toTimezone(endTime, timezone).format(DateFormats.DATETIME_ALT)}`
          : '',
      [Fields.TIMEFRAME]:
        timeRange === TimeRanges.CUSTOM
          ? [TimeUtils.toTimezone(startTime, timezone), TimeUtils.toTimezone(endTime, timezone)]
          : [],
      [Fields.TIMEZONE]: timezone,
    },
  });

  const downloadReport = () => {
    const errors = methods.formState.errors;
    if (Object.values(errors).length) return false;
    const _tagcount = methods.getValues('tagsCount');

    track('Reports Filter', {
      downloadReport: 'clicked',
      tagCount: _tagcount,
    });
    if (methods.getValues(Fields.TIMERANGE as 'timerange') === TimeRanges.PRESET) {
      const _streamSeconds = TimePresetMap.get(
        methods.getValues(Fields.PRESET) as TimePresetKeyType,
      ) as number;
      const now = dayjs();
      setTimeSelection((prevState) => {
        recordTimelineHistory(prevState);
        return {
          ...prevState,
          startTime: now.subtract(_streamSeconds, 'seconds'),
          endTime: now,
          timezone: methods.getValues(Fields.TIMEZONE as 'timezone'),
          timeRange: TimeRanges.PRESET,
          streamingTimeInSeconds: _streamSeconds,
          relative: true,
        };
      });
      setTagsFilterCount(_tagcount);
      return true;
    } else {
      const _timezone = methods.getValues(Fields.TIMEZONE as 'timezone');
      const _timeframe = methods.getValues(Fields.CUSTOM_TIMEFRAME as 'customTimeframe');

      if (!_timeframe || _timeframe.length === 0) {
        methods.setError(Fields.CUSTOM_TIMEFRAME as 'customTimeframe', { type: 'validate' });
        return false;
      }
      const _tokens = _timeframe.split(' - ');
      if (!_timeframe.match(/^[^a-zA-Z]+$/) || _tokens.length !== 2) {
        methods.setError(Fields.CUSTOM_TIMEFRAME as 'customTimeframe', { type: 'validate' });
        return false;
      }

      const _start = dayjs(_tokens[0], DateFormats.DATETIME_ALT);
      if (!_start.isValid()) {
        methods.setError(Fields.CUSTOM_TIMEFRAME as 'customTimeframe', {
          type: 'custom',
          message: 'Invalid start time',
        });
        return false;
      }

      const _end = dayjs(_tokens[1], DateFormats.DATETIME_ALT);
      if (!_end.isValid()) {
        methods.setError(Fields.CUSTOM_TIMEFRAME as 'customTimeframe', {
          type: 'custom',
          message: 'Invalid end time',
        });
        return false;
      }

      methods.clearErrors();
      setTimeSelection((prevState) => {
        recordTimelineHistory(prevState);
        return {
          ...prevState,
          timezone: _timezone,
          startTime: _start.tz(_timezone, true),
          endTime: _end.tz(_timezone, true),
          timeRange: TimeRanges.CUSTOM,
          streamingTimeInSeconds: 0,
          relative: false,
        };
      });
      setTagsFilterCount(_tagcount);
      return true;
    }
  };

  return (
    <StyledTimeRangeFilter data-testid="timerange-filter">
      <FormProvider {...methods}>
        <GridRow>
          <p>Filters:</p>
        </GridRow>
        <GridRow>
          <GridColumn>
            <DateRange>
              <TimeRangeSelector
                isSelectOpen={isTimePresetsOpen}
                setIsSelectOpen={setIsTimePresetsOpen}
              />
            </DateRange>
            <TimeZone>
              <TimezoneSelector
                setIsSelectOpen={setIsOtherTimezonesOpen}
                isSelectOpen={isOtherTimezonesOpen}
              />
            </TimeZone>
            <TagCount>
              <TextInput
                className="tagCount"
                label="Tag Count"
                id="tagsCount"
                {...methods.register('tagsCount')}
                type={TextInputType.NUMBER}
              />
            </TagCount>
            <ButtonSection>
              <Button
                buttonSize="large"
                buttonType="secondary"
                id="generateButton"
                onClick={downloadReport}
              >
                Generate Report
              </Button>
              <Button
                buttonSize="large"
                buttonType="secondary"
                id="logoutButton"
                onClick={() => {
                  track('User Logout', {
                    logoutUser: true,
                    returnTo: window.location.origin,
                  });
                  sessionStorage.clear();
                  logout({
                    returnTo: window.location.origin,
                  });
                }}
              >
                Logout
              </Button>
            </ButtonSection>
          </GridColumn>
        </GridRow>
      </FormProvider>
    </StyledTimeRangeFilter>
  );
};

import styled, { css } from 'styled-components';

import { customScrollPrimary } from '../../../app/global-styles';
import { customTextStyle } from '../../components/alert-modal/styles';

export const AlertLayout = styled.div`
  display: flex;
  flex: 1;
  height: 100vh;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background: ${({ theme }) => theme.alert.backgroundColor};
  width: calc(100vw - 50px);
  overflow-x: scroll;
`;

export const HeadingContainer = styled.div`
  background: ${({ theme }) => theme.alert.backgroundColor};
  display: grid;
  padding: 0 30px;
`;

export const HeadingWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  justify-self: center;
  padding: 24px 0;
  width: 100%;
  max-width: 1320px;
  margin-right: 19px;
  z-index: 1;
  button {
    height: 30px;
    > div {
      margin-top: 2px;
    }
  }
`;

export const AlertContainer = styled.div`
  height: 100%;
  padding: 0 30px;
  background: ${({ theme }) => theme.alert.backgroundColor};
  display: grid;
  ${customScrollPrimary}
`;

export const AlertWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-self: center;
  align-self: flex-start;
  width: 100%;
  max-width: 1320px;
`;

export const Heading = styled.h2`
  color: ${({ theme }) => theme.alert.content.text};
  font-family: Space Grotesk;
  font-size: 31px;
  font-style: normal;
  font-weight: 300;
  line-height: 37px;
  margin: 0px;
`;

export const SearchHeading = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 12px;
  .search-heading {
    ${customTextStyle({ family: 'Space Grotesk', size: 19, lineHeight: 23 })}
    color: ${({ theme }) => theme.alert.content.text};
    margin: 0px;
  }
  div {
    ${customTextStyle({ size: 13, lineHeight: 23 })}
    color: var(--heatmap-light-02-solid, #688D92);
    cursor: pointer;
  }
`;

export const SubHeading = styled.div`
  ${customTextStyle({ family: 'Space Grotesk', size: 23, lineHeight: 23 })}
  color: ${({ theme }) => theme.alert.content.text};
  margin-bottom: 8px;
`;

export const ShowAll = styled.div<{ showAll: boolean }>`
  display: flex;
  padding-top: 5px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  margin-bottom: 20px;
  cursor: pointer;
  svg {
    transition: transform 0.2s linear;

    transform: rotate(180deg);
    fill: #7199a1;
  }
  > span {
    ${customTextStyle({ size: 13, lineHeight: 19, weight: 600 })}
    color: var(--focus-light-focus-3, #7199A1);
  }
  ${(props) =>
    !props.showAll &&
    css`
      svg {
        transform: rotate(0deg);
      }
    `}
`;

export const NoAlertCreatedWrap = styled.div`
  display: flex;
  height: 50%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  h1 {
    color: ${({ theme }) => theme.alert.content.text};
    font-size: 30px;
    text-align: center;
    font-weight: 200;
  }
`;

export const AlertHeader = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  margin-bottom: 8px;
  span {
    display: flex;
    ${customTextStyle({})}
    color: ${({ theme }) => theme.alert.content.tableHeader};
  }
  .type-custom {
    box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.2);
    width: 188px;
  }
  .duration-info {
    cursor: pointer;
  }
`;

export const AlertCard = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-grow: 1;
  min-height: 31px;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 2px;
  background: ${({ theme }) => theme.alert.alertBackgroundColor};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  margin-bottom: 2px;
  padding: 10px 0;
  cursor: pointer;

  :hover {
    box-shadow: 0px 0px 8px 0px #80f7ff, 0px 0px 2px 0px #fff;
    .alert-edit {
      display: flex;
    }
  }

  .content {
    padding: 0 12px;
    ${customTextStyle({ size: 11, lineHeight: 13 })}
    color: ${({ theme }) => theme.alert.content.text};

    &.disabled {
      color: #999;
    }
  }

  .alert-name {
    display: flex;
    flex-direction: column;
    padding: 0 12px;
    gap: 2px;
    .content {
      padding: 0;
    }
    .target-id {
      border-radius: 4px;
      background: ${({ theme }) => theme.alert.table.targetNameBg};
      padding: 1px 5px;
      margin-right: 4px;
      ${customTextStyle({ size: 11, lineHeight: 14, weight: 400 })}
    }
    .target-name {
      ${customTextStyle({ size: 13, lineHeight: 19, weight: 600 })}
      color: ${({ theme }) => theme.alert.content.text};
    }
  }

  .conditions {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .word-wrap {
    display: inline-block;
    word-break: break-word;
  }

  .destination {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .list {
      display: flex;
      align-items: flex-start;
      color: ${({ theme }) => theme.alert.content.text};
      gap: 4px;
      button {
        margin-left: 5px;
      }
      svg {
        width: 11px;
      }
      > div {
        padding-top: 2px;
      }
    }
  }

  .active-switch {
    width: 42px;
    padding: 0 14px 0 12px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
    gap: 2px;
  }

  .alert-edit {
    display: none;
  }

  ${(props) =>
    !props.isActive &&
    css`
      background: ${({ theme }) => theme.alert.alertSecondaryBgColor};
      .content {
        color: ${({ theme }) => theme.alert.content.inactive};
      }
      .alert-name {
        span {
          color: ${({ theme }) => theme.alert.content.inactive} !important;
        }
      }
    `}
`;

export const AlertConfirmationStyle = styled.div`
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  width: 250px;
  padding: 24px 40px 36px 40px;
  color: ${({ theme }) => theme.alert.modal.textColor};
  white-space: pre-line;
`;

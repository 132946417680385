import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';

export interface FileDataProps {
  file: File;
  progress: number;
  status: string | null;
}

interface ContextProps {
  updateProgress: (fileId: string, progress: number | null, status?: string | null) => void;
  averageProgress: number;
  files: Map<string, FileDataProps>;
  setFiles: Dispatch<SetStateAction<Map<string, FileDataProps>>>;
  tenantName: string;
  setTenantName: Dispatch<SetStateAction<string>>;
  resetFilesAndProgress: () => void;
}

const defaultState = {
  updateProgress: () => null,
  averageProgress: 0,
  files: new Map(),
  setFiles: () => null,
  tenantName: '',
  setTenantName: () => null,
  resetFilesAndProgress: () => null,
};

export const FileUploadContext = createContext<ContextProps>(defaultState);

const FileUploadContextProvider: React.FC = ({ children }) => {
  const [files, setFiles] = useState<Map<string, FileDataProps>>(defaultState.files);
  const [tenantName, setTenantName] = useState<string>(defaultState.tenantName);

  const updateProgress = useCallback(
    (fileId: string, progress: number | null, status?: string | null) => {
      return setFiles((prevFiles) => {
        const notNullableProgress = progress || 0;
        const notNullablePrevmap = prevFiles?.get(fileId)?.progress || 0;
        const fileUploadStatus = status || (notNullableProgress < 100 ? 'Uploading' : 'Ingesting');

        // Ensure progress bar only goes in one direction
        if (notNullableProgress > notNullablePrevmap || status === 'Complete') {
          const fileData = prevFiles.get(fileId);
          return new Map(prevFiles).set(fileId, {
            ...fileData,
            progress,
            status: fileUploadStatus,
          } as FileDataProps);
        }
        return prevFiles;
      });
    },
    [],
  );

  const averageProgress = useMemo(() => {
    return (
      Array.from(files.values()).reduce<number>((acc, file) => acc + (file?.progress ?? 0), 0) /
      files.size
    );
  }, [files]);

  const resetFilesAndProgress = useCallback(() => {
    files.forEach((file, key) => {
      if (file.progress) {
        files.delete(key);
      }
    });
    setFiles(new Map(files));
  }, [files]);

  const value = useMemo(() => {
    return {
      updateProgress,
      averageProgress,
      files,
      setFiles,
      tenantName,
      setTenantName,
      resetFilesAndProgress,
    };
  }, [averageProgress, updateProgress, files, tenantName, resetFilesAndProgress]);

  return <FileUploadContext.Provider value={value}>{children}</FileUploadContext.Provider>;
};

export default FileUploadContextProvider;

import styled from 'styled-components';

import { StyledAccountShared } from '../shared-styles';

import { StyledInputWrapper, StyledLabel } from '@controlrooms/components';
import { colors } from '@controlrooms/design-tokens';

export const StyledInvite = styled(StyledAccountShared)`
  display: flex;
  justify-content: end;
  background: linear-gradient(180deg, #242522 0%, #486866 46.88%, #737566 100%);
  padding-bottom: 1.5rem;
  color: ${colors.content['neutral-1']};
  font-weight: 300;

  p {
    font-weight: 300;
  }

  ${StyledInputWrapper} ${StyledLabel} label {
    color: ${colors.content['neutral-1']};
  }

  .banner-background {
    mix-blend-mode: screen;
    margin-top: 40px;
    position: absolute;
    left: 0;
    top: 160px;
  }

  .banner-foreground {
    position: absolute;
    left: 0;
    top: 200px;
  }

  .form-container {
    width: 370px;
    margin: 0 auto;
    text-align: left;
    margin-bottom: 1.5rem;
  }

  .submit {
    font-family: 'Open Sans', sans-serif;
    margin: 0 auto;
  }

  h2 {
    margin-top: 38px;
    margin-bottom: 0.5rem;
  }

  .content-container {
    margin: 0 40px 0 230px;
    z-index: 2;
  }

  .recipients {
    margin-bottom: 1.5rem;
  }

  .template-selection {
    margin-bottom: 0.5rem;
  }

  .template-select {
    display: inline-flex;
    button {
      width: auto;
    }
  }
`;

import { useContext } from 'react';

import { AnalyzeContext } from '../../context/analyze-context';

import { Icon, Tooltip } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { HiddenTags } from '@controlrooms/models';

interface iViewHiddenTag {
  hiddenTags: HiddenTags[];
}
export const ViewHiddenTag = ({ hiddenTags }: iViewHiddenTag) => {
  const { showHiddenTags, setShowHiddenTags } = useContext(AnalyzeContext);
  return (
    <div className="lower">
      {hiddenTags.length ? (
        <Tooltip label={`${showHiddenTags ? 'Hide' : 'View'} hidden tags`}>
          <button
            className={`un-hidden-tags ${showHiddenTags ? 'active' : ''}`}
            onClick={() => {
              setShowHiddenTags(!showHiddenTags);
            }}
          >
            <>
              {hiddenTags.length}
              <Icon name={ICONS.ClosedEye} height="14px" width="14px" className="closed-eye-icon" />
            </>
          </button>
        </Tooltip>
      ) : (
        ''
      )}
    </div>
  );
};

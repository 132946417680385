import dayjs from 'dayjs';
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import { MONITOR_MIN_ZOOM } from '../components/timeline-actions/timeline-actions';
import { ChartInteractionUtil } from '../utils/chart-interaction';
import { TimeRangeUtil } from '../utils/time';

import { AppContext } from './app-context';
import { useTenant } from './tenant-context';

import {
  SeverityFilter,
  Zoomable,
  ZoomTo,
  MonitorView,
  PersistTimeSelection,
  TimeRanges,
  GroupTooltipData,
  MonitorSavedView,
} from '@controlrooms/models';
import { TimeUtils } from '@controlrooms/utils';

export interface MonitorContextProps {
  updateView: (view: MonitorSavedView) => void;
  selectedFolders: number[];
  selectedViewTitle: string;
  setSelectedFolders: Dispatch<SetStateAction<number[]>>;
  groupTooltipData?: GroupTooltipData;
  setGroupTooltipData: Dispatch<SetStateAction<GroupTooltipData | undefined>>;
  labelTooltipData?: GroupTooltipData;
  setLabelTooltipData: Dispatch<SetStateAction<GroupTooltipData | undefined>>;
  panNext: () => void;
  panPrevious: () => void;
  zoomIn: Zoomable;
  zoomOut: () => void;
  jumpToNow: () => void;
  severityFilter: number;
  setSeverityFilter: Dispatch<SetStateAction<number>>;
  showMonitorLimits: boolean;
  setShowMonitorLimits: Dispatch<SetStateAction<boolean>>;
  setSelectedViewTitle: Dispatch<SetStateAction<string>>;
}

const defaultState = {
  updateView: () => null,
  selectedFolders: [],
  selectedViewTitle: 'All Systems',
  severityFilter: SeverityFilter.LOW,
  groupTooltipData: undefined,
  setGroupTooltipData: () => undefined,
  labelTooltipData: undefined,
  setLabelTooltipData: () => undefined,
  panNext: () => null,
  panPrevious: () => null,
  zoomIn: () => null,
  zoomOut: () => null,
  jumpToNow: () => null,
  showMonitorLimits: false,
  setSelectedFolders: () => null,
  setSeverityFilter: () => null,
  setShowMonitorLimits: () => null,
  setSelectedViewTitle: () => null,
};

export const MonitorContext = createContext<MonitorContextProps>(defaultState);

interface MonitorRouteState {
  view?: MonitorView;
}

const MonitorContextProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const view = (location.state as MonitorRouteState)?.view;
  const { tenant } = useTenant();

  const {
    monitorSessionStorage,
    setMonitorSessionStorage,
    setMonitorTimeSelection,
    resetStorageState,
    monitorTimeSelection: { streamingTimeInSeconds },
    currentEnvironment,
    newestLoadedData,
  } = useContext(AppContext);

  const [selectedFolders, setSelectedFolders] = useState<number[]>(
    view?.selectedFolders ?? monitorSessionStorage?.selectedFolders,
  );

  const [selectedViewTitle, setSelectedViewTitle] = useState<string>(
    monitorSessionStorage?.selectedViewTitle,
  );

  const [severityFilter, setSeverityFilter] = useState<number>(
    monitorSessionStorage?.severityFilter,
  );

  const [showMonitorLimits, setShowMonitorLimits] = useState<boolean>(
    monitorSessionStorage?.showMonitorLimits || defaultState.showMonitorLimits,
  );

  useEffect(() => {
    if (tenant === 0) {
      resetStorageState();
    }
  }, [tenant, resetStorageState]);

  useEffect(() => {
    setMonitorSessionStorage((prevState) => ({
      ...prevState,
      selectedFolders,
      severityFilter,
      showMonitorLimits,
      selectedViewTitle,
    }));
    // eslint-disable-next-line
  }, [selectedFolders, severityFilter, showMonitorLimits, selectedViewTitle]);

  const [groupTooltipData, setGroupTooltipData] = useState<GroupTooltipData>();
  const [labelTooltipData, setLabelTooltipData] = useState<GroupTooltipData>();

  const updateView = useCallback(
    (view: MonitorSavedView) => {
      if (
        view.timeSelection.timeRange === TimeRanges.PRESET &&
        view.timeSelection.streamingTimeInSeconds
      ) {
        view.timeSelection.endTime = dayjs().toISOString();
        view.timeSelection.startTime = dayjs()
          .subtract(view.timeSelection.streamingTimeInSeconds, 'seconds')
          .toISOString();
      }
      setMonitorTimeSelection(PersistTimeSelection.toTimeSelection(view.timeSelection));
      setSelectedFolders(view.selectedFolders);
      setSelectedViewTitle(view.name || '');
      setSeverityFilter(view.severityFilter ? view.severityFilter : SeverityFilter.LOW);
      setShowMonitorLimits(view.showMonitorLimits || false);
    },
    [setMonitorTimeSelection],
  );

  const zoomIn = useCallback(
    (params?: Partial<ZoomTo>) => {
      setMonitorTimeSelection((prevTimeSelection) => {
        const [newStartTime, newEndTime] = ChartInteractionUtil.inCalculateBounds(
          prevTimeSelection,
          MONITOR_MIN_ZOOM,
          params,
        );

        return {
          ...prevTimeSelection,
          startTime: newStartTime,
          endTime: newEndTime,
          streamingTimeInSeconds: undefined,
          timeRange: TimeRanges.CUSTOM,
        };
      });
    },
    [setMonitorTimeSelection],
  );

  const zoomOut = useCallback(
    () =>
      setMonitorTimeSelection((prevTimeSelection) => {
        const [newStartTime, newEndTime] =
          ChartInteractionUtil.outCalculateBounds(prevTimeSelection);

        return {
          ...prevTimeSelection,
          startTime: newStartTime,
          endTime: newEndTime,
          streamingTimeInSeconds: undefined,
          timeRange: TimeRanges.CUSTOM,
        };
      }),
    [setMonitorTimeSelection],
  );

  const panPrevious = useCallback(() => {
    setMonitorTimeSelection((prevTimeSelection) => {
      const [newStartTime, newEndTime] =
        ChartInteractionUtil.panLeftCalculateBounds(prevTimeSelection);

      return {
        ...prevTimeSelection,
        startTime: newStartTime,
        endTime: newEndTime,
        streamingTimeInSeconds: undefined,
        timeRange: TimeRanges.CUSTOM,
      };
    });
  }, [setMonitorTimeSelection]);

  const panNext = useCallback(() => {
    // disable pan next if in relative mode
    if (streamingTimeInSeconds) return;

    // TODO: disabled for ux testable - needs more ticket detail
    // const newEndTime = dayjs(endTime).add(panStep, 's');

    setMonitorTimeSelection((prevTimeSelection) => {
      const [newStartTime, newEndTime] =
        ChartInteractionUtil.panRightCalculateBounds(prevTimeSelection);

      return {
        ...prevTimeSelection,
        startTime: newStartTime,
        endTime: newEndTime,
        streamingTimeInSeconds: undefined,
        timeRange: TimeRanges.CUSTOM,
        // TODO: disabled for ux testable - needs more ticket detail
        // endTime: dayjs().diff(newEndTime, 'seconds') <= 0 ? dayjs() : newEndTime,
      };
    });
  }, [setMonitorTimeSelection, streamingTimeInSeconds]);

  const jumpToNow = useCallback(() => {
    // disable jump to now if in relative mode
    if (streamingTimeInSeconds) return;

    //if batch env, go to latest not into streaming mode
    if (
      currentEnvironment?.isBatch &&
      !currentEnvironment?.isStreaming &&
      newestLoadedData?.timestamp
    )
      setMonitorTimeSelection((prevTimeSelection) => {
        const latestTime = TimeUtils.toTimezone(
          newestLoadedData.timestamp,
          prevTimeSelection.timezone,
        );
        const diff = latestTime.diff(prevTimeSelection.endTime);
        const newStartTime = prevTimeSelection.startTime.add(diff);
        return {
          ...prevTimeSelection,
          endTime: latestTime,
          startTime: newStartTime,
          relative: false,
          nowSelected: true,
          streamingTimeInSeconds: undefined,
        };
      });
    else
      setMonitorTimeSelection((prevTimeSelection) => {
        const now = dayjs();
        const diff = now.diff(prevTimeSelection.endTime);
        const newStartTime = prevTimeSelection.startTime.add(diff);
        const streamingSeconds = now.diff(newStartTime, 'seconds');
        return {
          ...prevTimeSelection,
          endTime: now,
          startTime: newStartTime,
          relative: true,
          nowSelected: true,
          streamingTimeInSeconds: streamingSeconds,
          timeRange: TimeRangeUtil.calculateTimePreset('value', streamingSeconds)
            ? TimeRanges.PRESET
            : TimeRanges.CUSTOM,
        };
      });
  }, [newestLoadedData, currentEnvironment, setMonitorTimeSelection, streamingTimeInSeconds]);

  const monitorState = useMemo(
    () => ({
      updateView,
      selectedFolders,
      setSelectedFolders,
      groupTooltipData,
      setGroupTooltipData,
      labelTooltipData,
      setLabelTooltipData,
      panNext,
      panPrevious,
      zoomIn,
      zoomOut,
      severityFilter,
      setSeverityFilter,
      jumpToNow,
      showMonitorLimits,
      setShowMonitorLimits,
      selectedViewTitle,
      setSelectedViewTitle,
    }),
    [
      panNext,
      panPrevious,
      selectedFolders,
      groupTooltipData,
      labelTooltipData,
      updateView,
      zoomIn,
      zoomOut,
      severityFilter,
      jumpToNow,
      showMonitorLimits,
      selectedViewTitle,
      setSelectedViewTitle,
    ],
  );

  return <MonitorContext.Provider value={monitorState}>{children}</MonitorContext.Provider>;
};

export default MonitorContextProvider;

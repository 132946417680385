import { useQuery } from 'react-query';

import { getChannels } from '../services/notifications';

export const useNotificationChannels = () => {
  const key = ['user-channels'];
  return useQuery(key, getChannels, {
    select: ({ result }) => result.channels,
    cacheTime: 8 * 60 * 60 * 1000, // 8 hours,
    staleTime: 8 * 60 * 60 * 1000, // 8 hours,
  });
};

import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { useAnalytics } from '../../analytics';
import { useFlatFolders, FolderSort } from '../../hooks/folders';
import { RangeInputMask } from '../timeframe-modal/time-range-selector/range-input-mask';

import { Select } from '@controlrooms/components';
import { RadioButton } from '@controlrooms/components';
import { Size, text } from '@controlrooms/design-tokens';

const ModalBodyContainer = styled.div`
  width: 500px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  text-align: center;
  padding-bottom: 40px;
  text-align: left;
`;

const SelectContainer = styled.div`
  margin-bottom: 10px;
`;

const DeleteDataHeader = styled.h2`
  color: ${({ theme }) => theme.modal.panel.color};
  font-size: ${text.size[Size.HEADER_19]};
  line-height: 13px;
  font-size: 11px;
  font-weight: 400;
  margin-bottom: 0;
`;

const TimeInputContainer = styled.div`
  margin-top: 8px;
  width: 350px;
`;

const FooterInfoContainer = styled.div`
  text-align: end;
  color: ${({ theme }) => theme.fileUploadModal.footer.footerInfoColor};
  font-size: 11px;
  font-family: 'Open Sans', sans-serif;
  line-height: 13px;
`;

const ALL_OPTION = { value: 'all', label: 'All Folders', dataTestId: 'all-folders' };

export const DeleteDataModal: React.FC = () => {
  const { data: folders, isLoading } = useFlatFolders(FolderSort.ALPHA_ASC_DESCRIPTION);

  const { control } = useFormContext();
  const { track } = useAnalytics();

  if (isLoading || !folders) return null;
  const formattedFolders = folders.map((folder) => ({
    value: folder.description,
    label: folder.description,
    subLabel: folder.name,
    dataTestId: folder.name,
  }));

  const newFolders = [ALL_OPTION, ...formattedFolders];

  return (
    <ModalBodyContainer>
      <DeleteDataHeader>Delete data from:</DeleteDataHeader>
      <Controller
        control={control}
        name="folder"
        render={({ field: { onChange } }) => (
          <SelectContainer data-testid="data-delete-selection">
            <Select
              options={newFolders}
              onChange={(e) => {
                track('Delete Data Modal', {
                  selectedOption: e.value,
                  for: 'folder',
                });
                onChange(e.value);
              }}
            />
          </SelectContainer>
        )}
      />
      <Controller
        control={control}
        name="type"
        render={({ field: { onChange, value } }) => (
          <>
            <RadioButton
              data-testid="delete-timeframe-data"
              id="timeframe"
              value="timeframe"
              checked={value === 'timeframe'}
              onChange={(e) => {
                track('Delete Data Modal', {
                  selectedOption: e.target.id,
                  for: 'type',
                });
                onChange(e.target.id);
              }}
            >
              Timeframe
              <TimeInputContainer>
                <RangeInputMask disabled={value !== 'timeframe'} />
              </TimeInputContainer>
            </RadioButton>
            <RadioButton
              data-testid="delete-all-data"
              id="delete-all"
              value="delete-all"
              checked={value === 'delete-all'}
              onChange={(e) => {
                track('Delete Data Modal', {
                  deleteAllData: 'clicked',
                });
                onChange(e.target.id);
              }}
            >
              Delete all data
            </RadioButton>
          </>
        )}
        rules={{ required: true }}
      />
    </ModalBodyContainer>
  );
};

export const FooterInfo: React.FC = () => {
  return <FooterInfoContainer>This cannot be undone</FooterInfoContainer>;
};

import dayjs from 'dayjs';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { useAnalytics } from '../../analytics';
import { Paths } from '../../constants/paths';
import { AnalyzeChartContext } from '../../context/analyze-chart-context';
import { AppContext } from '../../context/app-context';
import { MonitorContext } from '../../context/monitor-context';
import { windowWidthBreakpoints } from '../../global-styles';

import { StyledActions } from './styles';

import { Icon, Tooltip } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { useKeyPress } from '@controlrooms/hooks';

export const ANALYZE_MIN_ZOOM = dayjs.duration(1, 's').as('seconds');
export const MONITOR_MIN_ZOOM = dayjs.duration(1, 'h').as('seconds');

export const TimelineActions: React.FC = () => {
  const { pathname } = useLocation();

  const [zoomInDisabled, setZoomInDisabled] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // TODO: disabled for ux testable - needs more ticket detail
  // const [panNextDisabled, setPanNextDisabled] = useState(false);

  const {
    timeSelectionHistory,
    setTimelineHistory,
    setAnalyzeTimeSelection,
    analyzeTimeSelection,
    monitorTimeSelection,
    isStreaming,
    currentEnvironment,
  } = useContext(AppContext);
  const {
    zoomIn: analyzeZoomIn,
    zoomOut: analyzeZoomOut,
    panNext: analyzePanNext,
    panPrevious: analyzePanPrevious,
    jumpToNow: analyzeJumpToNow,
  } = useContext(AnalyzeChartContext);
  const {
    zoomIn: monitorZoomIn,
    zoomOut: monitorZoomOut,
    panNext: monitorPanNext,
    panPrevious: monitorPanPrevious,
    jumpToNow: monitorJumpToNow,
  } = useContext(MonitorContext);

  const isAnalyze =
    !!matchPath(Paths.ANALYZE, pathname) || !!matchPath(Paths.DEMO_ANALYZE, pathname);
  const { track } = useAnalytics();

  const handleZoomIn = isAnalyze ? analyzeZoomIn : monitorZoomIn;
  const handleZoomOut = isAnalyze ? analyzeZoomOut : monitorZoomOut;
  const handlePanNext = isAnalyze ? analyzePanNext : monitorPanNext;
  const handlePanPrevious = isAnalyze ? analyzePanPrevious : monitorPanPrevious;
  const handleJumpToNow = isAnalyze ? analyzeJumpToNow : monitorJumpToNow;

  const { startTime, endTime } = isAnalyze ? analyzeTimeSelection : monitorTimeSelection;

  const getRangeDiff = useCallback(
    () => dayjs(endTime).diff(dayjs(startTime), 'seconds'),
    [endTime, startTime],
  );

  const handleUndo = () => {
    const resetTo = timeSelectionHistory.pop();
    if (resetTo) setAnalyzeTimeSelection(resetTo);
    setTimelineHistory(timeSelectionHistory ? [...timeSelectionHistory] : []);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // set zoom to disabled on monitor and analyze when reaching max zoom durations
    const rangeDiff: number = getRangeDiff();
    const isAnalyzeZoom =
      !!matchPath(Paths.ANALYZE, pathname) || !!matchPath(Paths.DEMO_ANALYZE, pathname);
    setZoomInDisabled(
      isAnalyzeZoom ? rangeDiff <= ANALYZE_MIN_ZOOM : rangeDiff <= MONITOR_MIN_ZOOM,
    );

    // TODO: disabled for ux testable - needs more ticket detail
    // const newEndTime = dayjs(endTime).add(panStep, 's');
    // const isEndTimeInFuture: boolean = dayjs().diff(newEndTime, 'seconds') < 0;
    // setPanNextDisabled(isEndTimeInFuture || streamingTimeInSeconds !== undefined);
  }, [startTime, endTime, getRangeDiff, pathname]);

  useKeyPress(['=', '+'], handleZoomIn);
  useKeyPress(['-', '_'], handleZoomOut);
  useKeyPress(['u', 'U'], handleUndo);
  useKeyPress([','], handlePanPrevious);
  useKeyPress(['.'], handlePanNext);

  return (
    <>
      {isAnalyze ? (
        <StyledActions>
          <button
            data-testid="undo"
            type="button"
            onClick={() => {
              track('Chart Actions - Undo Timeshift', {
                undoTimeshift: 'clicked',
                currentPage: isAnalyze ? 'Analyze' : 'Monitor',
              });
              handleUndo();
            }}
            disabled={!timeSelectionHistory.length}
          >
            <Tooltip
              label={
                timeSelectionHistory.length && windowWidth > windowWidthBreakpoints.mobile ? (
                  <ShortcutTooltip label="Undo Timeshift" shortcut={'Press the "u" key.'} />
                ) : (
                  <ShortcutTooltip label="Undo Timeshift" />
                )
              }
            >
              <Icon name={ICONS.Undo} width="16" height="16" />
            </Tooltip>
          </button>
        </StyledActions>
      ) : null}

      <StyledActions>
        <div className="group">
          <button
            data-testid="zoom-out"
            id="zoomOut"
            type="button"
            onClick={() => {
              track('Chart Actions - Zoom Out', {
                zoomOut: 'clicked',
                currentPage: isAnalyze ? 'Analyze' : 'Monitor',
              });
              handleZoomOut();
            }}
          >
            <Tooltip
              label={
                windowWidth > windowWidthBreakpoints.mobile ? (
                  <ShortcutTooltip label="Zoom Out" shortcut={'Press the "-" key.'} />
                ) : (
                  <ShortcutTooltip label="Zoom Out" />
                )
              }
            >
              <Icon name={ICONS.ZoomOut} width="16" height="16" />
            </Tooltip>
          </button>
          <button
            data-testid="zoom-in"
            id="zoomIn"
            type="button"
            onClick={() => {
              track('Chart Actions - Zoom In', {
                zoomIn: 'clicked',
                currentPage: isAnalyze ? 'Analyze' : 'Monitor',
              });
              handleZoomIn();
            }}
            disabled={zoomInDisabled}
          >
            <Tooltip
              label={
                windowWidth > windowWidthBreakpoints.mobile ? (
                  <ShortcutTooltip
                    label="Zoom In"
                    shortcut={
                      isAnalyze
                        ? 'Double click, click & drag on a trend or press the "+" key.'
                        : 'Double click on the timeline, press the "+" key.'
                    }
                  />
                ) : (
                  <ShortcutTooltip label="Zoom In" />
                )
              }
            >
              <Icon name={ICONS.ZoomIn} width="16" height="16" />
            </Tooltip>
          </button>
          <button
            data-testid="pan-left"
            id="panLeft"
            type="button"
            onClick={() => {
              track('Chart Actions - Pan Previous', {
                panPrevious: 'clicked',
                currentPage: isAnalyze ? 'Analyze' : 'Monitor',
              });
              handlePanPrevious();
            }}
          >
            {isAnalyze ? (
              <Tooltip label="Pan to earlier time">
                <Icon name={ICONS.PanLeft} width="12" height="12" />
              </Tooltip>
            ) : (
              <Tooltip label="Pan to earlier time">
                <Icon name={ICONS.PanLeft} width="12" height="12" />
              </Tooltip>
            )}
          </button>
          <button
            // TODO: disabled for ux testable - needs more ticket detail
            // disabled={panNextDisabled}
            id="panRight"
            type="button"
            data-testid="pan-right"
            onClick={() => {
              track('Chart Actions - Pan Next', {
                panNext: 'clicked',
                currentPage: isAnalyze ? 'Analyze' : 'Monitor',
              });
              handlePanNext();
            }}
            disabled={
              isAnalyze
                ? !!analyzeTimeSelection.streamingTimeInSeconds
                : !!monitorTimeSelection.streamingTimeInSeconds
            }
          >
            {isAnalyze ? (
              <Tooltip label="Pan to later time">
                <Icon name={ICONS.PanRight} width="12" height="12" />
              </Tooltip>
            ) : (
              <Tooltip label="Pan to later time">
                <Icon name={ICONS.PanRight} width="12" height="12" />
              </Tooltip>
            )}
          </button>
          {isStreaming}
          <button
            id="now"
            data-testid="jump-to-now"
            type="button"
            onClick={() => {
              track('Chart Actions - Jump to Now', {
                jumpToNow: 'clicked',
                currentPage: isAnalyze ? 'Analyze' : 'Monitor',
              });
              handleJumpToNow();
            }}
          >
            <Tooltip label={`Jump to ${currentEnvironment?.isBatch ? `latest` : `now`}`}>
              <Icon name={ICONS.Now} width="16" height="16" />
            </Tooltip>
          </button>
        </div>
      </StyledActions>
    </>
  );
};

const ShortcutTooltip: React.FC<{ label: string; shortcut?: string }> = ({ label, shortcut }) => {
  return (
    <>
      {`${label}.`}
      <br />
      {shortcut && <i>{`Shortcut: ${shortcut}`}</i>}
    </>
  );
};

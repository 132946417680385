import React, { createContext, useCallback, useMemo } from 'react';

import { MONITOR_MIN_ZOOM } from '../../app/components/timeline-actions/timeline-actions';
import { ChartInteractionUtil } from '../../app/utils/chart-interaction';

import { useViewContext } from './view-context';

import { TimeRanges, Zoomable, ZoomTo } from '@controlrooms/models';

interface ContextProps {
  zoomIn: Zoomable;
}

const defaultState = {
  zoomIn: () => null,
};

export const MonitorChartContext = createContext<ContextProps>(defaultState);

const MonitorChartContextProvider: React.FC = ({ children }) => {
  const { updateTimeSelection, viewState } = useViewContext();
  const { timeSelection } = viewState;

  const zoomIn = useCallback(
    (params?: Partial<ZoomTo>) => {
      const [newStartTime, newEndTime] = ChartInteractionUtil.inCalculateBounds(
        timeSelection,
        MONITOR_MIN_ZOOM,
        params,
      );
      updateTimeSelection(
        {
          ...timeSelection,
          startTime: newStartTime,
          endTime: newEndTime,
          streamingTimeInSeconds: undefined,
          timeRange: TimeRanges.CUSTOM,
        },
        true,
      );
    },
    [timeSelection, updateTimeSelection],
  );

  const monitorChartState = useMemo(
    () => ({
      zoomIn,
    }),
    [zoomIn],
  );

  return (
    <MonitorChartContext.Provider value={monitorChartState}>
      {children}
    </MonitorChartContext.Provider>
  );
};

export default MonitorChartContextProvider;

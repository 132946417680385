import styled from 'styled-components';

import { StyledButton } from '@controlrooms/components';
import { colors, text } from '@controlrooms/design-tokens';

export const StyledNotificationBanner = styled.div`
  display: flex;

  ${StyledButton} {
    display: inline-block;
  }

  .notification {
    display: flex;
    align-items: center;
    justify-content: end;
    font-size: 13px;
  }

  .content.upload {
    width: 453px;
    font-size: ${text.size['17-data']};
    text-align: right;
    margin-right: 20px;
    margin-top: 0;

    .upload-status {
      position: relative;
      top: -13px;
    }

    .status-text {
      font-size: ${text.size['11-data']};
      margin-top: 0.25rem;
      color: ${colors.focus.dark[2]};
    }

    progress {
      border-radius: 7px;
      width: 100%;
      height: 1px;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    }
    progress::-webkit-progress-bar {
      background-color: ${colors.k[8]};
      border-radius: 7px;
    }
    progress::-webkit-progress-value {
      background-color: ${colors.focus.dark[2]};
      border-radius: 7px;
      box-shadow: 0px 0px 2px #ffffff, 0px 0px 8px #80f7ff;
    }
  }
`;

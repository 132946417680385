import styled from 'styled-components';

import { colors } from '@controlrooms/design-tokens';

export const StyledAccountShared = styled.div`
  background: linear-gradient(180deg, #302e2c 0%, #3e4b4d 46.88%, #5b7274 100%);
  width: 100%;
  text-align: center;

  h2 {
    font-weight: 300;
    font-size: 31px;
    color: ${colors.k[100]};
    margin-top: 54px;
  }

  p {
    font-weight: 400;
    font-size: 13px;
    font-family: 'Open Sans', sans-serif;
    line-height: 25px;
  }
`;

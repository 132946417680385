import { matchPath } from 'react-router-dom';

export const getAppRouteParams = (path: string) => {
  const appTenantRoute = matchPath({ path: '/app/t/:tenantId/*' }, path);
  const demoTenantRoute = matchPath({ path: '/demo/t/:tenantId/*' }, path);

  return appTenantRoute || demoTenantRoute;
};

export const isDemoApp = (path: string) => {
  const demoTenantRoute =
    matchPath({ path: '/demo/t/:tenantId/*' }, path) || matchPath({ path: '/demo/redirect' }, path);

  return !!demoTenantRoute;
};

import React from 'react';
import { useContext } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { Paths } from '../../constants/paths';
import { AppContext } from '../../context/app-context';

import { ActionsWrapper, StyledHeaderActions } from './styles';

import { timezones } from '@controlrooms/constants';
import { DateFormats } from '@controlrooms/models';
import { TimeUtils } from '@controlrooms/utils';

interface Props {
  children?: React.ReactNode;
}

const customStyle = {
  spanTime1: {
    display: 'inline-block',

    width: '100%',
  },

  spanTime2: {
    display: 'inline-block',

    width: '100%',
  },

  spanDate1: {
    fontSize: '14px',

    lineHeight: '22px',
  },

  spanDiv1: {
    fontSize: '18px',

    lineHeight: '23px',

    marginRight: '5px',

    width: '104px',
  },

  spanDiv2: {
    fontSize: '18px',

    lineHeight: '23px',

    marginRight: '5px',

    width: '115px',
  },

  dateTimeHeader: {
    display: 'flex',

    textAlign: 'center' as const,

    color: '#666666',
  },

  hyphen: {
    marginRight: '10px',
  },

  timezone: {
    fontSize: '15px',

    marginLeft: '10px',
  },
};

export const ReportChartHeader: React.FC<Props> = ({ children }) => {
  const { pathname } = useLocation();

  const { analyzeTimeSelection, monitorTimeSelection } = useContext(AppContext);

  const { startTime, endTime, timezone } = matchPath(Paths.ANALYZE, pathname)
    ? analyzeTimeSelection
    : monitorTimeSelection;

  const selectedTimeZone = timezones.find((z) => z.utc.includes(timezone));

  return (
    <div>
      <StyledHeaderActions isReport={true}>
        <ActionsWrapper>
          <div>{children}</div>
        </ActionsWrapper>

        <div style={customStyle.dateTimeHeader}>
          <div style={customStyle.spanDiv1}>
            <span style={customStyle.spanTime1}>
              {TimeUtils.toTimezone(startTime, timezone).format(DateFormats.TIME)}
            </span>

            <span style={customStyle.spanDate1}>
              {TimeUtils.toTimezone(startTime, timezone).format(DateFormats.DATE)}
            </span>
          </div>

          <span style={customStyle.hyphen}>-</span>

          <div style={customStyle.spanDiv2}>
            <span style={customStyle.spanTime2}>
              {TimeUtils.toTimezone(endTime, timezone).format(DateFormats.TIME)}
            </span>

            <span style={customStyle.spanDate1}>
              {TimeUtils.toTimezone(endTime, timezone).format(DateFormats.DATE)}
            </span>

            <span style={customStyle.timezone}>{selectedTimeZone?.abbr}</span>
          </div>
        </div>
      </StyledHeaderActions>
    </div>
  );
};

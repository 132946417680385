import React, { createContext, Dispatch, SetStateAction, useMemo, useState } from 'react';

interface ContextProps {
  setDemoAccessToken: Dispatch<SetStateAction<string>>;
  demoAccessToken: string;
}

const defaultState = {
  setDemoAccessToken: () => null,
  demoAccessToken: '',
};

export const GlobalContext = createContext<ContextProps>(defaultState);

const GlobalContextProvider: React.FC = ({ children }) => {
  const [demoAccessToken, setDemoAccessToken] = useState<string>('');

  const appState = useMemo(
    () => ({
      setDemoAccessToken,
      demoAccessToken,
    }),
    // eslint-disable-next-line
    [demoAccessToken],
  );

  return <GlobalContext.Provider value={appState}>{children}</GlobalContext.Provider>;
};

export default GlobalContextProvider;

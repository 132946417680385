import styled from 'styled-components';

import { highlightCss } from '../analyze-system-browser/styles/shared-styles';

import { Li } from '@controlrooms/components';
import { colors } from '@controlrooms/design-tokens';

export const AlertSearchWrapper = styled.div`
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.alert.search.inputBorder};
  background: ${({ theme }) => theme.alert.search.inputBackground};
  /* UI/Light/Field Inner Shadow */
  box-shadow: 1px 2px 4px 0px ${({ theme }) => theme.alert.search.boxShadow};
  padding-left: 8px;
`;

export const SearchContainer = styled.div`
  display: flex;
  .search-icon {
    svg {
      path {
        fill: ${({ theme }) => theme.alert.search.searchIcon};
      }
    }
  }
  input {
    padding: 0;
    width: unset;
    font-size: 11px;
    font-weight: 400;
    line-height: 13px;
  }
  .option-container {
    position: relative;
    padding: 0;
    margin: 0;
    ul {
      width: 190px;
      position: absolute;
      top: 6px;
    }
  }
  ${highlightCss}
`;

export const HeaderContainer = styled.div`
  color: ${colors.k[40]};
  cursor: default;
  display: flex;
  font-size: 9px;
  font-style: normal;
  font-weight: 700;
  line-height: 13px;
  margin-bottom: 5px;
  padding: 4px 0 4px 12px;
  text-transform: uppercase;
  border-bottom: 1px solid ${colors.content['neutral-3']};
`;

export const StyledLi = styled(Li)`
  margin-bottom: 5px;
  padding-left: 18px;
  display: flex;
  align-items: center;
  > span {
    padding-left: 5px;
    display: inline-block;
    word-break: break-word;
  }
`;

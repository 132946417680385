import { Modal, ModalContents } from '@controlrooms/components';

export const ConfirmDeleteModal = ({ onConfirm }: { onConfirm: (closeState: boolean) => void }) => {
  return (
    <Modal open={true}>
      <ModalContents
        title={'Confirm to delete'}
        styles={{ content: { minWidth: '450px' } }}
        confirmButtonText="Delete"
        closeButtonText="Cancel"
        closeButtonCallback={() => {
          onConfirm(false);
        }}
        shouldCloseOnEsc={false}
        confirmButtonCallback={async () => {
          onConfirm(true);
        }}
        dataTestIdCancel="modal-close-icon-delete-alert"
      >
        <div style={{ padding: '10px', fontSize: '13px' }}>
          <p>Are you sure you want to delete?</p>
        </div>
      </ModalContents>
    </Modal>
  );
};

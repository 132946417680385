import { jwtDecode, JwtPayload } from 'jwt-decode';

export interface UserProps {
  env_id: number;
  roles: Array<{ role_id: number; role_desc: string }>;
  tenant: number;
  user_id: number;
  access: boolean;
}

export const CR_USER_PROP = 'https://controlrooms.ai/user';

export interface CRJwtPayload extends JwtPayload {
  permissions: never[];
  [CR_USER_PROP]: UserProps;
}

export class TokenUtil {
  public static decode = (token: string) => jwtDecode<CRJwtPayload>(token);

  public static generateTokenHash = (token: CRJwtPayload): string =>
    `${token.sub}_${token.iat}_${token.exp}_${token.iss}_${token[CR_USER_PROP].tenant}`;

  public static extractUserProps = (token: string): UserProps | null => {
    if (token.length === 0) return null;
    const decoded = this.decode(token);
    return {
      ...decoded[CR_USER_PROP],
      tenant: decoded[CR_USER_PROP].tenant,
    };
  };

  public static parseJwt = (token: string) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      throw new Error('Invalid token');
    }
  };

  public static isTokenExpired = (token: string) => {
    const decodedJwt = this.parseJwt(token);
    return decodedJwt.exp * 1000 < Date.now();
  };
}

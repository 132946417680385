export const ViewTooltipComponent = (props: {
  name: string;
  description: string;
  createdBy: string;
}) => {
  const { name, description, createdBy } = props;
  return (
    <div
      style={{
        display: 'flex',
        maxWidth: '400px',
        minWidth: '200px',
        padding: '10px',
        flexDirection: 'column',
        borderRadius: '3px',
        gap: '15px',
      }}
    >
      {name && (
        <div
          className="row horizontal"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <span
            className="name"
            style={{
              fontFamily: 'Open Sans',
              fontSize: '13px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '19px',
              flexGrow: 1,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {name}
          </span>
          <span
            className="owner"
            style={{
              fontSize: '9px',
              flexGrow: 1,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {createdBy && createdBy !== '' ? `Created By: ${createdBy}` : ''}
          </span>
        </div>
      )}
      {description && (
        <div
          className="row"
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            marginTop: '10px',
            marginLeft: '5px',
          }}
        >
          <span className="description" style={{ fontSize: '11px' }}>
            {description}
          </span>
        </div>
      )}
    </div>
  );
};

import { createGlobalStyle } from 'styled-components';

import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/700.css';
import '@fontsource/open-sans/800.css';
/*eslint-disable */
import '@fontsource/space-grotesk/300.css';
import '@fontsource/space-grotesk/400.css';
import '@fontsource/space-grotesk/500.css';
import '@fontsource/space-grotesk/600.css';
import '@fontsource/space-grotesk/700.css';
/*eslint-enable */
const GlobalStyle = createGlobalStyle`
  html,body {
    margin:0;
    padding:0;
    font-family: 'Space Grotesk', 'Open Sans', sans-serif;
  }

  button {
    font-family: 'Space Grotesk', 'Open Sans', sans-serif;
  }

  a {
    color: ${({ theme }) => theme.linkColor};
  }
`;

export default GlobalStyle;

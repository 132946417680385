import { TextInputTheme } from 'styled-components';

import { colors } from '@controlrooms/design-tokens';

export const textInputDarkTheme: TextInputTheme = {
  color: colors.k[90],
  borderColor: colors.k[12],
  background: colors.k[5],
  boxShadow: colors.effects['field-inner-shadow'],
  errorBorderColor: colors.datavis['pink-1'],
  focus: {
    background: 'linear-gradient(180deg, #131a1b 0%, #0d0d0d 100%)',
    borderColor: colors.focus.dark[4],
  },
  hover: {
    borderColor: colors.focus.dark[2],
    boxShadow: colors.effects['hover-glow'],
  },
};

export const textInputLightTheme: TextInputTheme = {
  color: colors.k[10],
  borderColor: colors.k[65],
  background: colors.container['b-g-1'],
  boxShadow: colors.effects['field-inner-shadow'],
  errorBorderColor: colors.datavis['pink-1'],
  focus: {
    background: 'linear-gradient(180deg, #DEFDFF 0%, #F3F3F2 100%);',
    borderColor: colors.focus.dark[2],
  },
  hover: {
    borderColor: colors.focus.dark[2],
    boxShadow: colors.effects['hover-glow'],
  },
};

import { SwitchTheme } from 'styled-components';

import { colors } from '@controlrooms/design-tokens';

export const switchDarkTheme: SwitchTheme = {
  switchTrack: {
    background: colors.content['neutral-6'],
    borderColor: colors.k[3],
    boxShadow: 'inset 0px 1px 1px rgba(0, 0, 0, 0.3)',
    selected: {
      background: colors.focus.dark[5],
      borderColor: colors.k[3],
    },
    hover: {
      boxShadow: '0px 0px 2px #FFFFFF, 0px 0px 8px #80F7FF',
      background: colors.focus.dark[5],
      borderColor: '#93BBC3',
    },
  },
  switchToggle: {
    background: colors.content['neutral-5'],
    boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.6)',
    borderColor: colors.focus.light[2],
    selected: {
      background: colors.focus.dark[5],
      borderColor: colors.focus.dark[1],
      boxShadow: '0px 1px 12px #579EA3, inset 0px 0px 6px #80F7FF',
    },
    hover: {
      background: colors.focus.dark[1],
      borderColor: colors.focus.light[2],
    },
  },
};

export const switchLightTheme: SwitchTheme = {
  switchTrack: {
    background: colors.container['b-g-2'],
    borderColor: colors.container['b-g-6'],
    // boxShadow: 'inset 0px 1px 1px rgba(0, 0, 0, 0.3)',
    boxShadow: '1px 2px 2px 0px rgba(0, 0, 0, 0.50) inset',
    selected: {
      background: colors.focus.light[5],
      borderColor: colors.focus.light[2],
    },
    hover: {
      boxShadow: '0px 0px 2px #FFFFFF, 0px 0px 8px #80F7FF',
      background: colors.focus.light[5],
      borderColor: colors.focus.light[2],
    },
  },
  switchToggle: {
    background: colors.focus.light[2],
    boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.6)',
    borderColor: colors.content['neutral-3'],
    selected: {
      background: colors.content['neutral-4'],
      borderColor: colors.focus.light[2],
      boxShadow: colors.effects['hover-glow'],
    },
    hover: {
      background: colors.focus.light[1],
      borderColor: colors.focus.light[2],
    },
  },
};

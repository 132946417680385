import { useAuth0 } from '@auth0/auth0-react';
import React, { ReactElement, Fragment } from 'react';

import { Role } from '../../constants/auth';
import { useRoles } from '../../context/authorization-context';

interface Props {
  role?: Role;
  roles?: Role | Role[];
  fallback?: ReactElement;
}

export const AuthRender: React.FC<Props> = ({ role, roles, children, fallback }) => {
  const { userRoles } = useRoles();
  const { user } = useAuth0();

  // Combine role and roles into a single array, filtering out undefined values
  const effectiveRoles = [
    ...(Array.isArray(roles) ? roles : roles ? [roles] : []),
    ...(role ? [role] : []),
  ];

  // Check if any of the effective roles are included in userRoles
  const hasRequiredRole = effectiveRoles.some((r) => userRoles.includes(r));

  if (user?.email_verified && hasRequiredRole) {
    return <Fragment>{children}</Fragment>;
  }
  return <Fragment>{fallback}</Fragment>;
};

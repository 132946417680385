import { colors } from '@controlrooms/design-tokens';

export type ButtonTheme = {
  primaryBackgroundColor: string;
  primaryTextColor: string;
  secondaryBackgroundColor: string;
  secondaryTextColor: string;
  disabledBackgroundColor: string;
  disabledTextColor: string;
  selectedBackgroundColor: string;
  selectedTextColor: string;
  borderColorPrimary: string;
  borderColorSecondary: string;
  boxShadow: string;
  textButton: {
    textColor: string;
    hoverColor: string;
  };
};

export const buttonDarkTheme: ButtonTheme = {
  primaryBackgroundColor: colors.button.dark['ui-button-1'],
  primaryTextColor: colors.content['neutral-1'],
  secondaryBackgroundColor: colors.button.dark['ui-button-2'],
  secondaryTextColor: colors.content['neutral-1'],
  disabledBackgroundColor: colors.k[20],
  disabledTextColor: colors.content['neutral-5'],
  selectedBackgroundColor: colors.k[5],
  selectedTextColor: colors.content['neutral-1'],
  borderColorPrimary: colors.focus.dark[5],
  borderColorSecondary: colors.content['neutral-6'],
  boxShadow: colors.effects['hover-glow'],
  textButton: {
    textColor: colors.content['neutral-3'],
    hoverColor: colors.k[80],
  },
};

export const buttonLightTheme: ButtonTheme = {
  primaryBackgroundColor: colors.button.light['ui-button-1'],
  primaryTextColor: colors.content['neutral-1'],
  secondaryBackgroundColor: colors.button.light['ui-button-2'],
  secondaryTextColor: colors.content['neutral-5'],
  disabledBackgroundColor: colors.k[80],
  disabledTextColor: colors.content['neutral-5'],
  selectedBackgroundColor: colors.k[80],
  selectedTextColor: colors.k[80],
  borderColorPrimary: colors.k[80],
  borderColorSecondary: colors.container['b-g-5'],
  boxShadow: colors.effects['hover-glow'],
  textButton: {
    textColor: colors.content['neutral-3'],
    hoverColor: colors.focus.light[1],
  },
};

import { colors } from '@controlrooms/design-tokens';

export const investigateDarkTheme = {
  backgroundColor: colors.k[5],
  itemGroupBorder: colors.content['neutral-6'],
  addToInvestigate: colors.content['neutral-3'],
  addedToInvestigate: colors.focus.dark[2],
  tag: {
    folder: {
      label: colors.k[55],
    },
    chip: {
      background: colors.k[8],
      desc: colors.k[80],
    },
  },
  controls: {
    content: colors.content['neutral-1'],
  },
  chart: {
    yAxisLabel: colors.content['neutral-2'],
    anomalyLine: colors.anomalies.dark['3'],
    trendLine: colors.k[100],
    range: colors.content['neutral-6'],
    hoverline: colors.k[80],
  },
};

export const investigateLightTheme = {
  backgroundColor: colors.k[95],
  itemGroupBorder: colors.content['neutral-2'],
  addToInvestigate: colors.content['neutral-6'],
  addedToInvestigate: colors.focus.light[2],
  tag: {
    folder: {
      label: colors.k[30],
    },
    chip: {
      background: colors.k[90],
      desc: colors.k[12],
    },
  },
  controls: {
    content: colors.content['neutral-6'],
  },
  chart: {
    yAxisLabel: colors.content['neutral-5'],
    anomalyLine: colors.anomalies.dark['2'],
    trendLine: colors.content['neutral-4'],
    range: colors.k[80],
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CallBack = (...args: any[]) => void;

export class FunctionUtils {
  /**
   * Call all function callbacks.
   * @param fns functions to call
   */
  public static callAll =
    (...fns: Array<CallBack | undefined>) =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (...args: any[]) =>
      fns.forEach((fn) => typeof fn === 'function' && fn(...args));
}

import styled from 'styled-components';

import useScript from './useScript'; // Import the custom hook

import { Icon } from '@controlrooms/components';

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10;
  img {
    display: none;
  }
`;
const TeamsShareButton = ({ link }: { link: string }) => {
  const { isLoaded: isScriptLoaded, isError: isScriptError } = useScript(
    'https://teams.microsoft.com/share/launcher.js',
  );

  const handleClick = () => {
    if (!isScriptLoaded) {
      console.error('Teams launcher script not loaded yet.');
    }
    if (isScriptError) {
      console.error('Failed to load Teams launcher script.');
    } else {
      console.error('Teams launcher script not loaded yet.');
    }
  };

  return (
    <StyledButton
      type="button"
      onClick={handleClick}
      data-testid="ms-teams-share-popup"
      className={`teams-share-button`}
      data-icon-px-size="124"
      data-href={link}
      role="button"
      aria-label="Share via Microsoft Teams"
    >
      <Icon name="teams" />
      Share via Teams
    </StyledButton>
  );
};

export default TeamsShareButton;

import classNames from 'classnames';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { generatePath, NavLink } from 'react-router-dom';
import styled from 'styled-components';

import Logo from '../../../assets/cr_logo.svg';
import { Paths } from '../../constants/paths';
import { AppContext } from '../../context/app-context';
import { useTenant } from '../../context/tenant-context';
import { breakpoints } from '../../global-styles';
import { useTenants, useSwitchTenants, useTenantEnvironment } from '../../hooks/tenants';
import { Analyze } from '../../pages/routes';
import { Loading } from '../loading/loading';

import { StyledEllipse, StyledHeader } from './styles';

import { ButtonGroup, Icon, Li, OptionsGroup, Ul } from '@controlrooms/components';
import { useClickOutside, usePrevious } from '@controlrooms/hooks';

const DeskView = styled.div`
  display: none;
  ${breakpoints.desk} {
    display: block;
  }
`;

export const DemoHeader: React.FC = () => {
  // const { loginWithRedirect } = useAuth0();

  const { tenant } = useTenant();
  const { setCurrentEnvironment } = useContext(AppContext);
  const { tenants } = useTenants();
  const [switchTenant, isTenantSwitching] = useSwitchTenants();

  const [selectedTenant, setSelectedTenant] = useState<number | undefined>(
    tenants?.find((t) => t.selected)?.id,
  );
  const previousSelectedTenant = usePrevious(selectedTenant);
  const { data: env } = useTenantEnvironment(selectedTenant);

  const [isPlantSelectOpen, setIsPlantSelectOpen] = useState(false);

  const plantUlRef = useRef(null);

  useClickOutside(plantUlRef, () => setIsPlantSelectOpen(false));

  useEffect(() => {
    // Initialize from path
    setSelectedTenant(tenants?.find((t) => t.selected)?.id);
  }, [tenants]);

  // Set the current environment (contains streaming / batch info if needed outside this context)
  useEffect(() => {
    env && setCurrentEnvironment(env);
  }, [env, setCurrentEnvironment]);

  useEffect(() => {
    if (previousSelectedTenant && selectedTenant && previousSelectedTenant !== selectedTenant) {
      switchTenant(selectedTenant);
    }
  }, [selectedTenant, previousSelectedTenant, switchTenant]);

  const renderTenantSelector = () => {
    const enabled = (tenants?.length ?? 0) > 1;
    return (
      <div className="plant-name">
        <OptionsGroup>
          <button
            data-testid="plant-button"
            type="button"
            aria-haspopup="listbox"
            aria-expanded={isPlantSelectOpen}
            className={classNames({ disabled: !enabled })}
            onClick={(evt) => {
              evt.stopPropagation();
              enabled && setIsPlantSelectOpen(!isPlantSelectOpen);
            }}
          >
            <ButtonGroup>
              {selectedTenant && (
                <>
                  {tenants?.find((t) => t.id === selectedTenant)?.name}
                  {enabled && <Icon name="chevron" style={{ display: 'flex' }} />}{' '}
                </>
              )}
            </ButtonGroup>
          </button>
          <Ul isOpen={isPlantSelectOpen} ref={plantUlRef}>
            {tenants?.map((t, index) => (
              <Li
                data-testid={`plant-button-${index}`}
                onClick={(e) => {
                  setSelectedTenant(tenants?.find((t) => t.id === e.currentTarget.value)?.id);
                  setIsPlantSelectOpen(false);
                }}
                key={t.id}
                value={t.id}
              >
                {t.name}
              </Li>
            ))}
          </Ul>
        </OptionsGroup>
      </div>
    );
  };

  return (
    <StyledHeader>
      {isTenantSwitching && <Loading overlay />}
      <div className="header-left">
        <img
          className="logo"
          src={Logo}
          alt="Control Rooms"
          height={25}
          width={30}
          loading={'lazy'}
        />
        <nav className="navigation">
          {renderTenantSelector()}
          <NavLink
            data-testid="monitor"
            to={generatePath(`${Paths.DEMO_MONITOR}`, { tenantId: tenant.toString() })}
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            Monitor
          </NavLink>
          <NavLink
            data-testid="analyze"
            onMouseOver={() => Analyze.preload()}
            to={generatePath(`${Paths.DEMO_ANALYZE}`, { tenantId: tenant.toString() })}
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            Analyze
          </NavLink>
        </nav>
      </div>
      <div className="header-right">
        <StyledEllipse>
          <div className="ellipse" />
        </StyledEllipse>
        <DeskView>
          <NavLink
            data-testid="analyze"
            onMouseOver={() => Analyze.preload()}
            to={generatePath('/app/redirect')}
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            User Sign In
          </NavLink>
        </DeskView>
      </div>
    </StyledHeader>
  );
};

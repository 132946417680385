import React, { useRef, useEffect } from 'react';

import { Icon } from '../icon';

import { CheckboxContainer, HiddenCheckbox, StyledCheckbox, StyledLabel } from './styles';

import { ICONS } from '@controlrooms/constants';

interface IProps {
  className?: string;
  id?: string;
  checked: boolean;
  indeterminate?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  labelWrap?: boolean;
  dataTestId?: string;
  disabled?: boolean;
}

export enum CheckboxStatus {
  UNCHECKED = 'unchecked',
  CHECKED = 'checked',
  INDETERMINATE = 'indeterminate',
}

export const Checkbox = React.forwardRef<HTMLInputElement, IProps>(
  (
    {
      className,
      id,
      checked,
      indeterminate = false,
      labelWrap = true,
      dataTestId,
      disabled,
      onChange,
      ...props
    },
    ref,
  ) => {
    // Create a local ref to store the indeterminate state
    const localRef = useRef<HTMLInputElement>(null);

    // Ensure we're keeping the forwarded ref intact
    useEffect(() => {
      if (ref) {
        if (typeof ref === 'function') {
          ref(localRef.current);
        } else {
          ref.current = localRef.current;
        }
      }
    }, [ref]);

    // Apply the indeterminate attribute to the checkbox input
    useEffect(() => {
      if (localRef.current) {
        localRef.current.indeterminate = indeterminate;
      }
    }, [indeterminate]);

    const content = (
      <CheckboxContainer className={className} id={id}>
        <HiddenCheckbox
          onChange={onChange}
          defaultChecked={checked}
          disabled={disabled}
          {...props}
          ref={localRef}
        />
        <StyledCheckbox data-testid={dataTestId} isDisabled={disabled}>
          {checked && <Icon name={ICONS.Checkmark} width="8.6" height="6.79" />}
          {indeterminate && !checked && (
            <Icon name={ICONS.Indeterminate} width="8.6" height="6.79" />
          )}
        </StyledCheckbox>
      </CheckboxContainer>
    );

    return labelWrap ? <StyledLabel>{content}</StyledLabel> : <>{content}</>;
  },
);

import { MsChannel, MsTeam, RegisteredChannel, sortedTeamsChannel } from '@controlrooms/models';

const MS_GRAPH_API_ROOT = 'https://graph.microsoft.com/v1.0';
/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */

export const msalConfig = {
  auth: {
    clientId: '3a023637-5b52-4429-b6e1-4d79d5e378ce',
    authority: 'https://login.microsoftonline.com/03448718-b6db-4bfb-8a8f-cb3c8672d585',
    redirectUri: window?.location?.origin || '',
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const loginScopes = {
  scopes: ['User.Read', 'User.ReadBasic.All', 'Team.ReadBasic.All', 'Channel.ReadBasic.All'],
};

export const channelScopes = {
  scopes: ['Team.ReadBasic.All', 'Channel.ReadBasic.All'],
};

export const usersScopes = {
  scopes: ['User.ReadBasic.All'],
};

const constructReqOptions = (accessToken: string) => {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);

  return {
    method: 'GET',
    headers: headers,
  };
};

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the teams
 * @param accessToken
 */
export const getTeamsList = async (accessToken: string) => {
  return fetch(`${MS_GRAPH_API_ROOT}/me/joinedTeams`, await constructReqOptions(accessToken))
    .then((response) => response.json())
    .then((data) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return data?.value?.map((team: any) => new MsTeam(team));
    })
    .catch((error) => console.log(error));
};

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the teams & channel
 * @param accessToken
 */
export const getTeamsDetails = async (accessToken: string, team: sortedTeamsChannel) => {
  return fetch(`${MS_GRAPH_API_ROOT}/teams/${team.team_id}`, await constructReqOptions(accessToken))
    .then((response) => response.json())
    .then(async (data) => {
      const teamDetails = new MsTeam(data);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const channelPromiseArr = team.channels?.map((channel: any) =>
        getChannelDetails(accessToken, channel),
      );
      const channels = await Promise.all(channelPromiseArr);
      return { ...teamDetails, channels };
    })
    .catch((error) => console.log(error));
};

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the channels of a team
 * @param accessToken
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getChannelList = async (accessToken: string, teamData: MsTeam) => {
  return fetch(
    `${MS_GRAPH_API_ROOT}/teams/${teamData.team_id}/channels`,
    await constructReqOptions(accessToken),
  )
    .then((response) => response.json())
    .then((data) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return { ...teamData, channels: data?.value?.map((channel: any) => new MsChannel(channel)) };
    })
    .catch((error) => console.log(error));
};

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the teams individual channel
 * @param accessToken
 */
export const getChannelDetails = async (accessToken: string, channel: RegisteredChannel) => {
  return fetch(
    `${MS_GRAPH_API_ROOT}/teams/${channel.team_id}/channels/${channel.channel_id}`,
    await constructReqOptions(accessToken),
  )
    .then((response) => response.json())
    .then((data) => new MsChannel(data, channel.email))
    .catch((error) => console.log(error));
};

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the team
 * @param accessToken
 */
export const getTeamsChannelList = async (
  accessToken: string,
  teamId: string,
  channelId: string,
) => {
  return fetch(
    `${MS_GRAPH_API_ROOT}/teams/${teamId}/channels/${channelId}`,
    await constructReqOptions(accessToken),
  )
    .then((response) => response.json())
    .then((data) => {
      return data?.value;
    })
    .catch((error) => console.log(error));
};

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getUserList = async (accessToken: string) => {
  return fetch(`${MS_GRAPH_API_ROOT}/users`, await constructReqOptions(accessToken))
    .then((response) => response.json())
    .then((data) => {
      return { users: data?.value };
    })
    .catch((error) => console.log(error));
};

import styled from 'styled-components';

interface ContainerProps {
  isPage: boolean;
}

export const StyledContainer = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${({ isPage }) => (isPage ? '100vh' : 'auto')};
  background: ${({ theme }) => theme.backgroundColor};
  color: ${({ theme }) => theme.textColor};
  z-index: 1;
`;

import { colors } from '@controlrooms/design-tokens';

export type UploadInstructionsTheme = {
  body: {
    titleColor: string;
    bodyColor: string;
  };
  section: {
    titleColor: string;
  };
  footer: {
    textColor: string;
  };
  column: {
    headerTextColor: string;
    bodyTextColor: string;
    widgetTextColor: string;
  };
};

export const uploadInstructionsDarkTheme: UploadInstructionsTheme = {
  body: {
    titleColor: colors.k[100],
    bodyColor: colors.content['neutral-2'],
  },
  section: {
    titleColor: colors.content['neutral-1'],
  },
  footer: {
    textColor: colors.content['neutral-1'],
  },
  column: {
    headerTextColor: colors.k[100],
    bodyTextColor: colors.content['neutral-2'],
    widgetTextColor: colors.content['neutral-1'],
  },
};

export const uploadInstructionsLightTheme: UploadInstructionsTheme = {
  body: {
    titleColor: colors.k[5],
    bodyColor: colors.k[5],
  },
  section: {
    titleColor: colors.content['neutral-6'],
  },
  footer: {
    textColor: colors.content['neutral-6'],
  },
  column: {
    headerTextColor: colors.content['neutral-6'],
    bodyTextColor: colors.content['neutral-5'],
    widgetTextColor: colors.content['neutral-5'],
  },
};

import React, { createContext, Dispatch, SetStateAction, useMemo, useState } from 'react';

import { AnalyzeSavedView, InvestigateSavedView, MonitorSavedView } from '@controlrooms/models';

interface ContextProps {
  currentView: MonitorSavedView | AnalyzeSavedView | InvestigateSavedView | null;
  setCurrentView: Dispatch<
    SetStateAction<MonitorSavedView | AnalyzeSavedView | InvestigateSavedView | null>
  >;
}

const defaultState = {
  currentView: null,
  setCurrentView: () => null,
};

export const SavedViewContext = createContext<ContextProps>(defaultState);

const SavedViewContextProvider: React.FC = ({ children }) => {
  const [currentView, setCurrentView] = useState<
    MonitorSavedView | AnalyzeSavedView | InvestigateSavedView | null
  >(defaultState.currentView);

  const analyzeState = useMemo(
    () => ({
      currentView,
      setCurrentView,
    }),
    [currentView],
  );

  return <SavedViewContext.Provider value={analyzeState}>{children}</SavedViewContext.Provider>;
};

export default SavedViewContextProvider;

import Request from './request';

import { ApiResult, CRNewTenant } from '@controlrooms/models';

export interface BatchDeleteParams {
  startTime: string;
  endTime: string;
}

const SERVICE_ROOT = '/batch-manager'; // FIXME: Batch Manager was sunset on 2024-05-01

export const deleteData = (deleteParams?: BatchDeleteParams): Promise<void> => {
  let url = `${SERVICE_ROOT}/batch/data`;
  let params = {};
  if (deleteParams) {
    params = {
      start_time: deleteParams.startTime,
      end_time: deleteParams.endTime,
    };
  } else {
    url += '/all';
  }

  return Request.delete(url, {
    params,
  }).then(({ result }) => result);
};

export const createTenant = (): Promise<ApiResult<CRNewTenant>> => {
  return Request.post(`${SERVICE_ROOT}/batch/tenant`, {}).then(({ result, ...rest }) => ({
    ...rest,
    // Change to a number because it's coming back as a string...
    result: { ...result, tenant: Number(result.tenant) },
  }));
};

export enum Size {
  HEADER_47 = '47-header',
  HEADER_31 = '31-header',
  HEADER_19 = '19-header',
  HEADER_13 = '13-header',
  HEADER_11 = '11-header',
  HEADER_9 = '9-header',

  LABEL_47 = '47-label',
  LABEL_31 = '31-label',
  LABEL_19 = '19-label',
  LABEL_13 = '13-label',
  LABEL_11 = '11-label',
  LABEL_9 = '9-label',

  DATA_47 = '47-data',
  DATA_31 = '31-data',
  DATA_19 = '19-data',
  DATA_17 = '17-data',
  DATA_13 = '13-data',
  DATA_11 = '11-data',
  DATA_9 = '9-data',

  PROMPT_13 = '13-prompt',
  PROMPT_11 = '11-prompt',
  PROMPT_9 = '9-prompt',

  BOLD_11 = '11-bold',
}

export const text = {
  size: {
    [Size.HEADER_47]: '47px',
    [Size.DATA_47]: '47px',
    [Size.LABEL_47]: '47px',
    [Size.HEADER_31]: '31px',
    [Size.DATA_31]: '31px',
    [Size.LABEL_31]: '31px',
    [Size.HEADER_19]: '19px',
    [Size.DATA_19]: '19px',
    [Size.LABEL_19]: '19px',
    [Size.DATA_17]: '17px',
    [Size.HEADER_13]: '13px',
    [Size.DATA_13]: '13px',
    [Size.LABEL_13]: '13px',
    [Size.PROMPT_13]: '13px',
    [Size.BOLD_11]: '11px',
    [Size.HEADER_11]: '11px',
    [Size.DATA_11]: '11px',
    [Size.LABEL_11]: '11px',
    [Size.PROMPT_11]: '11px',
    [Size.HEADER_9]: '9px',
    [Size.DATA_9]: '9px',
    [Size.LABEL_9]: '9px',
    [Size.PROMPT_9]: '9px',
  } as Record<Size, string>,
};

import styled from 'styled-components';

import { Li, OptionsGroup } from '@controlrooms/components';
import { colors } from '@controlrooms/design-tokens';
import { Size, text } from '@controlrooms/design-tokens';

export const ModalBodyContainer = styled.div`
  width: 500px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-bottom: 40px;
  padding: 20px 30px;
  text-align: left;
`;

export const Header = styled.h3`
  color: ${({ theme }) => theme.exportModal.headerColor};
  font-size: ${text.size[Size.HEADER_13]};
  line-height: 13px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 30px;
`;

export const MenuHeaderContainer = styled.div`
  font-size: 11px;
  font-family: 'Open Sans', sans-serif;
  text-transform: uppercase;
  line-height: 4px;
  color: ${({ theme }) => theme.exportModal.headerColor};
  border-bottom: 1px solid ${({ theme }) => theme.exportModal.borderColor};
  padding: 0;
  weight: 700;
`;

export const MenuHeader = styled(OptionsGroup)`
  margin-bottom: 0;
`;

export const TimeInputContainer = styled.div`
  margin-top: 8px;
`;

export const StyledOptionsGroup = styled(OptionsGroup)`
  padding-left: 0;
`;

export const StyledLi = styled(Li)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 8px;

  span {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: ${colors.content['neutral-3']};
  }
`;

import styled from 'styled-components';

import { colors } from '@controlrooms/design-tokens';

export const BrowserFilterWrapper = styled.div`
  display: flex;
  .filter-select {
    margin: 0;
    padding: 0 0 0 17px;
    .filter-title {
      padding: 0;
    }
    li {
      display: flex;
      padding: 4px 8px;
      align-items: center;
      gap: 6px;
      &.unselected {
        padding-left: 28px;
      }
    }
    .ensemble-family {
      text-overflow: ellipsis;
      text-wrap: nowrap;
      overflow: hidden;
    }
  }
`;

export const HeaderContainer = styled.span`
  color: ${colors.content['neutral-3']} !important;
  cursor: default;
  display: flex;
  font-size: 9px;
  font-style: normal;
  font-weight: 700;
  line-height: 13px;
  margin-bottom: 5px;
  padding: 4px 0 4px 17px;
  text-transform: uppercase;
  border-bottom: 1px solid ${colors.content['neutral-3']};
`;

import React from 'react';
import { InputHTMLAttributes } from 'react';

import { RadioButtonContainer, RadioButtonLabel, RadioButtonStyle } from './styles';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
}

export const RadioButton: React.FC<Props> = ({ id, children, ...rest }) => {
  return (
    <RadioButtonContainer checked={!!rest.checked}>
      <RadioButtonStyle type="radio" id={id} {...rest} />
      {children && (
        <RadioButtonLabel checked={!!rest.checked} htmlFor={id}>
          {children}
        </RadioButtonLabel>
      )}
    </RadioButtonContainer>
  );
};

import ReactTooltip, { Place } from 'react-tooltip';
import styled from 'styled-components';

interface StyledTooltipProps {
  delayShow?: number | string;
  place?: Place;
  _theme?: string;
  type?: 'primary' | 'secondary';
}

export const StyledTooltip = styled.span.attrs<StyledTooltipProps>((props) => ({
  'data-effect': 'solid',
  'data-background-color':
    props.type === 'secondary'
      ? props._theme === 'light'
        ? props.theme.tooltip.backgroundColorAltDark
        : props.theme.tooltip.backgroundColorAlt
      : props._theme === 'light'
      ? props.theme.tooltip.backgroundColorDark
      : props.theme.tooltip.backgroundColor,
  'data-text-color':
    props.type === 'secondary'
      ? props._theme === 'light'
        ? props.theme.tooltip.textColorAltDark
        : props.theme.tooltip.textColorAlt
      : props._theme === 'light'
      ? props.theme.tooltip.textColorDark
      : props.theme.tooltip.textColor,
  'data-delay-show': props.delayShow || '500',
  'data-place': props.place,
  'data-padding': '4px 8px',
}))<StyledTooltipProps>`
  flex-grow: 1;
`;

export interface CommonTooltipProps {
  id: string;
  place?: Place;
  theme?: 'primary' | 'secondary';
  delayShow?: number;
}
export const StyledCommonTooltip = styled(ReactTooltip).attrs<CommonTooltipProps>((props) => ({
  effect: 'solid',
  backgroundColor:
    props.theme === 'secondary'
      ? props.theme.tooltip.backgroundColorAlt
      : props.theme.tooltip.backgroundColor,
  textColor:
    props.theme === 'secondary' ? props.theme.tooltip.textColorAlt : props.theme.tooltip.textColor,
  delayShow: props.delayShow || 500,
  place: props.place || 'top',
  padding: '4px 8px',
  id: props.id,
}))<StyledTooltipProps>`
  flex-grow: 1;
`;

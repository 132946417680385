import { ScrollBarTheme } from 'styled-components';

import { colors } from '@controlrooms/design-tokens';

export const scrollBarDarkTheme: ScrollBarTheme = {
  primary: {
    track: {
      backgroundImage: `repeating-linear-gradient(
        ${colors.k[8]} 0,
        ${colors.content['neutral-6']} 2px,
        ${colors.content['neutral-6']} 444px,
        ${colors.k[8]} 6px
      )`,
      hoverBackgroundImage:
        'repeating-linear-gradient(#0a1415 0, #1f3133 2px, #1f3133 444px, #0a1415 6px)',
    },
  },
  secondary: {
    thumb: {
      background:
        'linear-gradient(180deg,rgba(122, 247, 255, 0.3) 0%,rgba(7, 53, 57, 0.24) 68.23%,rgba(0, 39, 43, 0) 100%)',
      borderColor: '#579ea3',
      hoverBorder: '#80f7ff',
      hoverFilter: `drop-shadow(0px 0px 2px ${colors.k[100]}) drop-shadow(0px 0px 8px #80f7ff)`,
      activeBorderColor: colors.k[100],
    },
    track: {
      backgroundImage: `repeating-linear-gradient(
        ${colors.k[8]} 0,
        ${colors.content['neutral-6']} 2px,
        ${colors.content['neutral-6']} 4px,
        ${colors.k[8]} 6px
      )`,
      borderRightColor: colors.k[8],
      borderLeftColor: colors.k[8],
      hoverBackgroundImage:
        'repeating-linear-gradient(#0a1415 0, #1f3133 2px, #1f3133 4px, #0a1415 6px)',
      hoverBorderRightColor: colors.k[8],
      hoverBorderLeftColor: colors.k[8],
    },
  },
};
export const scrollBarLightTheme: ScrollBarTheme = {
  primary: {
    track: {
      backgroundImage: `repeating-linear-gradient(
        ${colors.k[90]} 0,
        ${colors.container['b-g-5']} 2px,
        ${colors.container['b-g-5']} 444px,
        ${colors.k[90]} 6px
      )`,
      hoverBackgroundImage: `repeating-linear-gradient(${colors.focus.light[5]} 0, ${colors.focus.light[4]} 2px, ${colors.focus.light[4]} 444px, ${colors.focus.light[5]} 6px)`,
    },
  },
  secondary: {
    thumb: {
      background:
        'linear-gradient(180deg, rgba(122, 247, 255, 0.3) 0%, rgba(7, 53, 57, 0.24) 68.23%, rgba(0, 39, 43, 0) 100%)',
      borderColor: colors.focus.light[3],
      hoverBorder: colors.focus.light[1],
      hoverFilter: `drop-shadow(0px 0px 2px ${colors.k[100]}) drop-shadow(0px 0px 8px #80f7ff)`,
      activeBorderColor: colors.focus.light[1],
    },
    track: {
      backgroundImage: `repeating-linear-gradient(
        ${colors.k[90]} 0,
        ${colors.container['b-g-5']} 2px,
        ${colors.container['b-g-5']} 4px,
        ${colors.k[90]} 6px
      )`,
      borderRightColor: colors.k[90],
      borderLeftColor: colors.k[90],
      hoverBackgroundImage: `repeating-linear-gradient(${colors.focus.light[5]} 0, ${colors.focus.light[4]} 2px, ${colors.focus.light[4]} 4px, ${colors.focus.light[5]} 6px)`,
      hoverBorderRightColor: colors.focus.light[5],
      hoverBorderLeftColor: colors.focus.light[5],
    },
  },
};

import { AxiosRequestConfig, AxiosResponse } from 'axios';

import Api from './api';

const _request = (config: AxiosRequestConfig) => {
  const onSuccess = (response: AxiosResponse) => {
    let request = response.config.data;
    if (request) {
      try {
        request = JSON.parse(response.config.data);
        // eslint-disable-next-line no-empty
      } catch {}
    }
    return {
      headers: response.headers,
      result: response.data,
      request,
    };
  };
  const onError = (error: Error) => Promise.reject(error);

  return Api(config).then(onSuccess).catch(onError);
};

const get = (url: string, options = {}) => _request({ method: 'get', url, ...options });
const post = (url: string, data: object, options = {}) =>
  _request({ method: 'post', url, data, ...options });
const put = (url: string, data: object, options = {}) =>
  _request({ method: 'put', url, data, ...options });
const patch = (url: string, data: object, options = {}) =>
  _request({ method: 'patch', url, data, ...options });
const _delete = (url: string, options = {}, data: object | undefined = undefined) =>
  _request({ method: 'delete', url, data, ...options });

export default {
  delete: _delete,
  get,
  patch,
  post,
  put,
};

import styled from 'styled-components';

import { GRADIENTS } from '@controlrooms/constants';
import { colors } from '@controlrooms/design-tokens';

export const StyledChart = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  svg,
  .empty {
    width: 100%;
    height: 100%;
    box-shadow: 5px 0 9px -9px ${colors.k[0]} inset;
  }
  .header-bar {
    fill: ${({ theme }) => theme.heatmap.headers.fill};
    filter: ${({ theme }) => `url(#${theme.heatmap.headers.filter})`};
  }
  .folder-row {
    &.selected {
      > rect:first-of-type {
        fill: url(#selected-row);
      }
    }
    &.hover {
      > rect:first-of-type {
        fill: url(#hover-row);
      }
    }
  }
  .cell {
    fill: transparent;
  }
  .${GRADIENTS.g3} {
    fill: url(#${GRADIENTS.g3});
  }

  .${GRADIENTS.g2} {
    fill: url(#${GRADIENTS.g2});
  }

  .${GRADIENTS.g1} {
    fill: url(#${GRADIENTS.g1});
  }

  .no-data {
    stroke: ${({ theme }) => theme.heatmap.noDataColor};
    stroke-width: 2;
    stroke-linecap: round;
  }

  .error {
    fill: ${({ theme }) => theme.heatmap.errorColor};
  }

  .serie_label {
    fill: #2b2929;
    font-family: Georgia;
    font-size: 80%;
  }

  .tooltip-content {
    background-color: ${({ theme }) => theme.chart.tooltip.backgroundColor};
    color: ${({ theme }) => theme.chart.tooltip.textColor};
    position: absolute;
    padding: 4px;
    display: flex;
    opacity: 0;
    pointer-events: none;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    border-radius: 3px;
    z-index: 99;
    // max-width: 100px;
    flex-direction: column;
    &:after {
      content: '▼';
      font-size: 8px;
      position: absolute;
      bottom: -8px;
      left: 50%;
      transform: translateX(-50%);
      color: #fcfcfa;
    }
  }
`;

export const StyledMiniChart = styled.div`
  @media (max-width: 480px) {
    margin-right: 20px;
  }
  margin-right: 39px;
  padding-left: 40px;
  padding-bottom: 5px;
  border-right: ${({ theme }) => theme.tagHeader.borderRight};

  > svg {
    background: ${({ theme }) => theme.chart.chartBackgroundColor};
    border-radius: 6px;
    display: flex;
  }

  .cell {
    rx: 2;
  }

  .chart-wrapper {
    padding: 2px;
  }

  .${GRADIENTS.g3} {
    fill: url(#${GRADIENTS.g3});
  }

  .${GRADIENTS.g2} {
    fill: url(#${GRADIENTS.g2});
  }

  .${GRADIENTS.g1} {
    fill: url(#${GRADIENTS.g1});
  }

  .no-data {
    stroke: ${({ theme }) => theme.heatmap.noDataColor};
    stroke-width: 2;
    stroke-linecap: round;
  }

  .error {
    fill: ${({ theme }) => theme.heatmap.errorColor};
    fill-opacity: 0.3;
    stroke: ${({ theme }) => theme.heatmap.errorColor};
    stroke-width: 1;
    stroke-linecap: round;
  }
`;

import React from 'react';

import { ModalBodyContainer } from './styles';

export const VerifyEmailModal: React.FC = () => {
  return (
    <ModalBodyContainer>
      You have not verified the email used when creating your account. Please respond to the
      verification email you received, or click Resend.
    </ModalBodyContainer>
  );
};

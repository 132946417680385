import { useIntercom } from 'react-use-intercom';

import { useAnalytics } from '../../../../analytics';

import { StyledSandboxBanner } from './styles';

import { Button } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';

export const SandboxWithData: React.FC = () => {
  const { show: showIntercom } = useIntercom();
  const { track } = useAnalytics();
  return (
    <StyledSandboxBanner>
      <div className="content">
        Go realtime on your data
        <Button
          buttonType="link"
          iconName={ICONS.Union}
          buttonSize="small"
          onClick={() => {
            track('Sandbox With Data - Banner', {
              chatWithEngineer: 'clicked',
              intercom: 'visible',
            });
            showIntercom();
          }}
        >
          Chat with an engineer now
        </Button>
      </div>
    </StyledSandboxBanner>
  );
};

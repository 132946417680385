import { AlertTheme } from 'styled-components';

import { colors } from '@controlrooms/design-tokens';

export const alertDarkTheme: AlertTheme = {
  backgroundColor: colors.k[5],
  alertBackgroundColor: colors.container['b-g-8'],
  alertSecondaryBgColor: colors.container['b-g-8'],
  buttonTextColor: colors.k[100],
  search: {
    inputBorder: colors.k[12],
    inputBackground: colors.k[5],
    boxShadow: 'rgba(0, 0, 0, 0.35) inset',
    searchIcon: colors.focus.dark[4],
  },
  select: {
    borderColor: colors.content['neutral-5'],
    backgroundColor: colors.button.dark['ui-button-2'],
    dropdownTextColor: colors.content['neutral-2'],
  },
  table: {
    targetNameBg: colors.k[12],
    targetNameText: colors.k[65],
  },
  content: {
    text: colors.content['neutral-1'],
    tableHeader: colors.content['neutral-2'],
    inactive: colors.content['neutral-2'],
  },
  modal: {
    textColor: colors.content['neutral-1'],
    secondaryTextColor: colors.content['neutral-2'],
    subTextColor: colors.content['neutral-3'],
    titleText: colors.content['neutral-6'],
    titleCardBG: colors.container['b-g-1'],
    durationTitleColor: colors.content['neutral-2'],
    tooltipBackground: '#F0EEEB',
    tooltipText: colors.content['neutral-6'],
    sectionBorderColor: colors.content['neutral-5'],
    buttonBorderColor: colors.content['neutral-5'],
    actionButtonBgColor: colors.button.dark['ui-button-2'],
    inputInfo: colors.k[40],
    buttonTextColor: colors.content['neutral-2'],
    tagNameColor: colors.k[20],
    infoBorder: colors.datavis['orange-1'],
    infoBackground: colors.container['b-g-9'],
    infoIcon: colors.k[100],
    errorBorder: colors.datavis['pink-2'],
    errorBackground: colors.error[1],
    errorIcon: colors.k[100],
    tabText: colors.content['neutral-2'],
    tabActiveText: colors.content['neutral-1'],
    tabBorder: colors.content['neutral-3'],
    tabActiveBorder: colors.focus.dark[2],
    recipientSelectionBorder: colors.k[65],
    recipientSelectionBg: 'transparent',
    ensembleFilterColor: colors.content['neutral-1'],
  },
  channelPicker: {
    suggestedColor: colors.content['neutral-3'],
    textColor: colors.content['neutral-1'],
    menuBackground: colors.container['b-g-8'],
    selectedChannelBg: 'linear-gradient(180deg, #222320 0%, #191A18 100%)',
  },
};

export const alertLightTheme: AlertTheme = {
  backgroundColor: colors.container['b-g-3'],
  alertBackgroundColor: colors.container['b-g-2'],
  alertSecondaryBgColor: '#f2f1ee',
  buttonTextColor: colors.k[100],
  search: {
    inputBorder: colors.k[65],
    inputBackground: colors.container['b-g-1'],
    boxShadow: 'rgba(0, 0, 0, 0.15) inset',
    searchIcon: colors.focus.dark[2],
  },
  select: {
    borderColor: colors.container['b-g-5'],
    backgroundColor: colors.container['b-g-1'],
    dropdownTextColor: colors.content['neutral-5'],
  },
  table: {
    targetNameBg: colors.container['b-g-3'],
    targetNameText: colors.k[20],
  },
  content: {
    text: colors.content['neutral-6'],
    tableHeader: colors.content['neutral-4'],
    inactive: colors.content['neutral-4'],
  },
  modal: {
    textColor: colors.content['neutral-6'],
    secondaryTextColor: colors.content['neutral-4'],
    subTextColor: colors.k[5],
    titleText: colors.content['neutral-6'],
    titleCardBG: colors.container['b-g-1'],
    durationTitleColor: colors.content['neutral-4'],
    tooltipBackground: '#F0EEEB',
    tooltipText: colors.content['neutral-6'],
    sectionBorderColor: colors.container['b-g-3'],
    buttonBorderColor: colors.container['b-g-5'],
    actionButtonBgColor: colors.container['b-g-1'],
    inputInfo: colors.k[40],
    buttonTextColor: colors.content['neutral-5'],
    tagNameColor: colors.k[20],
    infoBorder: colors.datavis['orange-1'],
    infoBackground: colors.container['b-g-10'],
    infoIcon: colors.error[1],
    errorBorder: colors.datavis['pink-2'],
    errorBackground: '#FFE5E5',
    errorIcon: colors.error[1],
    tabText: colors.content['neutral-5'],
    tabActiveText: colors.content['neutral-6'],
    tabBorder: colors.content['neutral-4'],
    tabActiveBorder: colors.focus.light[2],
    recipientSelectionBorder: colors.k[65],
    recipientSelectionBg: colors.container['b-g-1'],
    ensembleFilterColor: colors.content['neutral-5'],
  },
  channelPicker: {
    suggestedColor: colors.k[40],
    textColor: colors.content['neutral-5'],
    menuBackground: '#F0EEEB',
    selectedChannelBg:
      'linear-gradient(180deg, #f9f8f4 0%, #e3edef 75%, #e2ebed 93.75%, #b7d4da 100%)',
  },
};

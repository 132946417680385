import styled from 'styled-components';

import { Icon } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { colors, Size, text } from '@controlrooms/design-tokens';

// TODO: Add colors to theme
export const StyledSearch = styled.div`
  .search-wrapper {
    margin: 8px 22px 0;
    width: calc(100% - 44px);
  }

  .search-wrapper input::placeholder {
    color: ${colors.k[40]};
  }

  .search-options {
    display: flex;
    align-items: center;
    padding-right: 10px;
    color: ${({ theme }) => theme.search.content.textColor};
    .match-select {
      margin: 0;
    }
    .match-title {
      color: ${({ theme }) => theme.search.content.textColor};
    }
    .current-selections {
      display: flex;
      font-size: 11px;
      font-family: 'Open Sans', sans-serif;
      line-height: 13px;
      flex-grow: 1;
      align-items: center;
      .current-checkbox {
        margin-right: 5px;
        border: 1px solid ${({ theme }) => theme.search.checkboxBorder};
        border-radius: 3px;
        div {
          background: ${({ theme }) => theme.textInput.background};
          border: none;
          box-shadow: none;
        }
      }
    }

    .match-dropdown {
      padding: 10px 0;
      width: 103px;
    }
    .search-count {
      color: ${({ theme }) => theme.systemBrowser.header.search.searchCountColor};
      font-size: ${text.size[Size.DATA_11]};
      font-family: 'Open Sans', sans-serif;
    }
  }
`;

// could look at setting left/right padding in here so children don't have to handle
export const BrowserHeaderSection = styled.div`
  background-color: ${({ theme }) => theme.systemBrowser.header.backgroundColor};
  border-bottom: 1px solid ${({ theme }) => theme.systemBrowser.header.borderBottomColor};
  width: 100%;

  .back-button {
    color: ${({ theme }) => theme.systemBrowser.header.backButtonColor};
  }

  .browser-header-back {
    display: inline-block;
    vertical-align: text-top;
  }
`;

export const HeatMapIndicator = styled.div`
  width: 4px;
  height: 16px;
  display: block;
  margin: 0 2px;
  background: ${({ theme }) => theme.sidebar.folders.chipColor};
  mix-blend-mode: normal;
  filter: blur(0.5px);
  border-radius: 2px;
`;

export const BackButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  background: none;
  border: none;
  color: ${colors.content['neutral-1']};
  cursor: pointer;
  font-size: 11px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  margin-left: 3px;
  padding: 8px;
  width: 100%;
  border-top: 1px solid rgb(70, 71, 64);
  text-align: left;

  .back-icon {
    font-size: 9px;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: -2px;
    margin-right: 3px;
    vertical-align: text-top;
  }
`;

export const Badge = styled.div`
  background-color: ${colors.content['neutral-4']};
  border-radius: 4px;
  color: ${colors.content['neutral-1']};
  font-size: 9px;
  font-weight: 700;
  padding: 4px 3px;
  text-align: center;
`;

export const NoResults = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
  margin-top: -25px;
`;

// TODO: make more of the styling associated with this reusable
export const ExpandIcon: React.FC<{ height?: string; width?: string }> = ({
  height = '7',
  width = '8',
}) => (
  <div className="arrow-icon">
    <Icon height={height} name={ICONS.Arrow} width={width} />
  </div>
);

import styled from 'styled-components';

import logo from '../../../assets/controlrooms_ai_logo.png';
import { breakpoints } from '../../global-styles';

const Wrapper = styled.section`
  background: #fff;
`;

const ContentArea = styled.section`
  margin: 0 auto;
  width: 80%;
  display: flex;
  flex-direction: column;
  padding-top: 70px;
  padding-bottom: 70px;
  ${breakpoints.desk} {
    justify-content: center;
    width: 60%;
  }
`;

const Otc = styled.ol`
  counter-reset: item;
  padding-left: 15px;
  li {
    display: block;
  }
  > li {
    margin-bottom: 20px;
    margin-top: 5px;
  }
  li:before {
    content: counters(item, '.') '. ';
    counter-increment: item;
    padding-right: 5px;
  }
`;

export const TermsAndConditions = () => {
  return (
    <Wrapper data-custom-class="body">
      <ContentArea>
        <div style={{ textAlign: 'center' }}>
          <img src={logo} alt="ControlRooms.ai" width={300} />
          <h1 style={{ fontSize: '26px', textAlign: 'center' }}>
            <strong>
              <span data-custom-class="title">Terms Of Service Agreement</span>
            </strong>
          </h1>
        </div>
        <div style={{ lineHeight: '1.5' }}>
          <span style={{ fontSize: '15px' }}>
            <p>
              By accepting this Agreement, either by accessing or using the Service, or authorizing
              or permitting any Agent or End-User to access or use the Service, You agree to be
              bound by this Agreement as of the date of such access or use of the Service (the
              &ldquo;<strong>Effective Date</strong>&rdquo;) as defined in the ControlRooms Order
              Form (&ldquo;<strong>Order Form</strong>&rdquo;). If You are entering into this
              Agreement on behalf of a company, organization, or another legal entity (an
              &ldquo;Entity&rdquo;), You are agreeing to this Agreement for that Entity and
              representing to ControlRooms that You have the authority to bind such Entity and its
              Affiliates to this Agreement.
            </p>
            <Otc>
              <li>
                <strong>
                  <strong>DEFINITIONS</strong>. Capitalized terms will have the meanings set forth
                  in this section or in the section where they are first used.
                </strong>
                <Otc>
                  <li>
                    &ldquo;<strong>Access Protocols</strong>&rdquo; means the passwords, access
                    codes, technical specifications, connectivity standards or protocols, or other
                    relevant procedures as may be necessary to allow Customer or any Authorized
                    Users to access the ControlRooms Solution.
                  </li>
                  <li>
                    &ldquo;<strong>Authorized User</strong>&rdquo;means each of Customer&rsquo;s
                    employees, agents, and independent contractors who are authorized to access the
                    ControlRooms Solution pursuant to Customer&rsquo;s rights under this Agreement.
                  </li>
                  <li>
                    &ldquo;<strong>ControlRooms Solution</strong>&rdquo; means the ControlRooms
                    software-as-a-service critical infrastructure monitoring application further
                    described in any Order Form and all incorporated software that allows Authorized
                    Users to access certain features and functions through a web interface.
                  </li>
                  <li>
                    &ldquo;<strong>Customer Content</strong>&rdquo; means any content and
                    information provided or submitted by, or on behalf of, Customer or its
                    Authorized Users for use with the Services.
                  </li>
                  <li>
                    &ldquo;<strong>Documentation</strong>&rdquo; means the technical materials
                    provided by ControlRooms to the Customer in hard copy or electronic form
                    describing the use and operation of the ControlRooms Solution.
                  </li>
                  <li>
                    <strong>
                      &ldquo;<strong>Effective Date</strong>&rdquo; means the date both parties sign
                      the Order Form that references this Agreement or the Date the Customer starts
                      using the Service.
                    </strong>
                  </li>
                  <li>
                    &ldquo;<strong>Error</strong>&rdquo; means a reproducible failure of the
                    ControlRooms Solution to substantially conform to the Documentation.
                  </li>
                  <li>
                    &ldquo;<strong>Intellectual Property Rights</strong>&rdquo; means any and all
                    now known or hereafter existing (a) rights associated with works of authorship,
                    including copyrights, mask work rights, and moral rights; (b) trademark or
                    service mark rights; (c) trade secret rights; (d) patents, patent rights, and
                    industrial property rights; (e) layout design rights, design rights, and other
                    proprietary rights of every kind and nature other than trademarks, service
                    marks, trade dress, and similar rights; and (f) all registrations, applications,
                    renewals, extensions, or reissues of the foregoing, in each case in any
                    jurisdiction throughout the world.
                  </li>
                  <li>
                    &ldquo;<strong>Output</strong>&rdquo; means results, reports, materials and
                    documentation made available by ControlRooms to Customer as part of the
                    Services.
                  </li>
                  <li>
                    &ldquo;<strong>Order Form</strong>&rdquo; refers to this Agreement and describes
                    the ControlRooms business terms and services subscription.
                  </li>
                  <li>
                    &ldquo;<strong>Professional Services</strong>&rdquo; means professional services
                    provided by ControlRooms to Customer as described in any Order Form (as may be
                    further elaborated in any SOW).
                  </li>
                  <li>
                    &ldquo;<strong>Services</strong>&rdquo; means any services provided by
                    ControlRooms to Customer under this Agreement as set forth in an Order Form,
                    including, but not limited to, provision of the ControlRooms Solution [and
                    Professional Services].
                  </li>
                </Otc>
              </li>
              <li>
                <strong>
                  <strong>PROVISION OF SERVICES</strong>
                </strong>
                <Otc>
                  <li>
                    <strong>Access</strong>. Subject to Customer&rsquo;s payment of the fees and
                    payment terms set forth in the Order Form (&ldquo;<strong>Fees</strong>&rdquo;),
                    ControlRooms will provide Customer with access to and use of the ControlRooms
                    Solution. On or as soon as reasonably practicable after the Effective Date,
                    ControlRooms will provide to Customer the necessary passwords, security
                    protocols and policies, and network links or connections and Access Protocols to
                    allow Customer and its Authorized Users to access and use the ControlRooms
                    Solution in accordance with the Access Protocols. Customer will use commercially
                    reasonable efforts to prevent unauthorized access to or use of the ControlRooms
                    Solution and notify ControlRooms promptly of any such unauthorized use known to
                    Customer.
                  </li>
                  <li>
                    <strong>Support Services</strong>. Subject to the terms and conditions of this
                    Agreement, ControlRooms will use commercially reasonable efforts to (a) provide
                    support for the use of the ControlRooms Solution to Customer, and (b) keep the
                    ControlRooms Solution operational and available to Customer, in each case in
                    accordance with its standard policies and procedures.
                  </li>
                  <li>
                    <strong>Hosting</strong>.ControlRooms will provide hosting for the Solution.
                    Nothing herein will be construed to require ControlRooms to provide or bear any
                    responsibility with respect to any telecommunications or computer network
                    hardware required by Customer or any Authorized User to access and use the
                    ControlRooms Solution from the Internet.
                  </li>
                </Otc>
              </li>
              <li>
                <strong>
                  <strong>INTELLECTUAL PROPERTY</strong>
                </strong>
                <Otc>
                  <li>
                    <strong>License Grant</strong>. Subject to the terms and conditions of this
                    Agreement, ControlRooms grants to Customer a non-exclusive, non-transferable
                    (except as permitted under Section 13.5 (No Assignment)) license during the Term
                    (as defined below), solely for Customer&rsquo;s internal business purposes and
                    in accordance with the limitations (if any) set forth in the Order Form, (a) to
                    access and use the ControlRooms Solution and in accordance with the
                    Documentation; and (b) to use and reproduce a reasonable number of copies of the
                    Documentation solely to support Customer&rsquo;s use of the ControlRooms
                    Solution. Customer may permit any Authorized Users to access and use the
                    features and functions of the ControlRooms Solution as contemplated by this
                    Agreement.
                  </li>
                  <li>
                    <strong>Restrictions</strong>. Customer will not, and will not permit any
                    Authorized User or other party to: (a) allow any third party to access the
                    ControlRooms Solution, Output or Documentation, except as expressly allowed
                    herein; (b) modify, adapt, alter or translate the ControlRooms Solution, Output
                    or Documentation; (c) sublicense, lease, sell, resell, rent, loan, distribute,
                    transfer or otherwise allow the use of the ControlRooms Solution or
                    Documentation for the benefit of any unauthorized third party; (d) reverse
                    engineer, decompile, disassemble, or otherwise derive or determine or attempt to
                    derive or determine the source code (or the underlying ideas, algorithms,
                    structure or organization) of the ControlRooms Solution, except as permitted by
                    law; (e) interfere in any manner with the operation of the ControlRooms Solution
                    or the hardware and network used to operate the ControlRooms Solution; (f)
                    modify, copy, or make derivative works based on any part of the ControlRooms
                    Solution or Documentation; (g) access or use the ControlRooms Solution to build
                    a similar or competitive product or service; (h) attempt to access the
                    ControlRooms Solution through any unapproved interface; or (i) otherwise use the
                    ControlRooms Solution, Output, or Documentation in any manner that exceeds the
                    scope of use permitted under Section 3.1 (License Grant) or in a manner
                    inconsistent with applicable law, the Documentation, or this Agreement. Customer
                    acknowledges and agrees that the ControlRooms Solution will not be used, and is
                    not licensed for use, to enable any of Customer&rsquo;s time-critical or
                    mission-critical functions. Customer will not remove, alter, or obscure any
                    proprietary notices (including copyright and trademark notices) of ControlRooms
                    or its licensors on the Output or any copies thereof.
                  </li>
                  <li>
                    <strong>Ownership</strong>. The ControlRooms Solution, Output and Documentation,
                    and all worldwide Intellectual Property Rights in each of the foregoing are the
                    exclusive property of ControlRooms and its suppliers. All rights in and to the
                    ControlRooms Solution and Documentation not expressly granted to Customer in
                    this Agreement are reserved by ControlRooms and its suppliers. Except as
                    expressly set forth herein, no express or implied license or right of any kind
                    is granted to Customer regarding the ControlRooms Solution, Documentation, or
                    any part thereof.
                  </li>
                  <li>
                    <strong>License to Output</strong>. Subject to the terms and conditions of this
                    Agreement, ControlRooms grants Customer a perpetual, royalty-free, fully-paid,
                    nonexclusive, non-transferable (except as permitted under Section 13.5 (No
                    Assignment)), non-sublicensable license to use the Output solely for
                    Customer&rsquo;s internal business purposes.
                  </li>
                  <li>
                    <strong>Open Source Software</strong>.Certain items of software may be provided
                    to Customer with the ControlRooms Solution and are subject to &ldquo;open
                    source&rdquo; or &ldquo;free software&rdquo; licenses (&ldquo;
                    <strong>Open Source Software</strong>&rdquo;). Some of the Open Source Software
                    is owned by third parties. The Open Source Software is not subject to the terms
                    and conditions of Sections 3.1 (Ownership) or 11 (Indemnification). Instead,
                    each item of Open Source Software is licensed under the terms of the end-user
                    license that accompanies such Open Source Software. Nothing in this Agreement
                    limits Customer&rsquo;s rights under or grants Customer rights that supersede
                    the terms and conditions of any applicable end user license for the Open Source
                    Software. If required by any license for particular Open Source Software,
                    ControlRooms makes such Open Source Software, and ControlRooms&rsquo;
                    modifications to that Open Source Software, available by written request at the
                    notice address specified below.&nbsp;
                  </li>
                  <li>
                    <strong>Feedback</strong>. Customer hereby grants to ControlRooms a
                    royalty-free, worldwide, transferable, sublicensable, irrevocable, perpetual
                    license to use or incorporate into the Services any suggestions, enhancement
                    requests, recommendations or other feedback provided by Customer, including
                    Authorized Users relating to the Services. ControlRooms will not identify
                    Customer as the source of any such feedback without Customer&rsquo;s express
                    prior written consent.
                  </li>
                </Otc>
              </li>
              <li>
                <strong>FEES AND EXPENSES; PAYMENTS</strong>
                <Otc>
                  <li>
                    <strong>Fees</strong>.In consideration of the access rights granted to Customer
                    and the Services performed by ControlRooms under this Agreement, Customer will
                    pay ControlRooms the Fees. Except as otherwise provided in the Order Form, all
                    Fees are billed at the beginning of the month and are due and payable within
                    thirty (30) days of the date of the invoice. ControlRooms reserves the right to
                    modify the Fees payable hereunder upon written notice to Customer at least
                    fifteen (15) days before the end of the then-current term. ControlRooms will be
                    reimbursed only for expenses expressly provided for in an Order Form or SOW (as
                    defined below) or that have been approved in advance in writing by Customer,
                    provided ControlRooms has furnished such documentation for authorized expenses
                    as Customer may reasonably request. ControlRooms reserves the right (in addition
                    to any other rights or remedies ControlRooms may have) to discontinue the
                    ControlRooms Solution and suspend all Authorized Users&rsquo; and
                    Customer&rsquo;s access to the Services if any Fees are more than thirty (30)
                    days overdue until such amounts are paid in full. Customer will maintain
                    complete, accurate and up-to-date Customer billing and contact information at
                    all times.
                  </li>
                  <li>
                    <strong>Taxes</strong>. The Fees exclude all applicable sales, use, value-added
                    and other taxes. All applicable duties, tariffs, assessments, export and import
                    fees, or other similar charges, and Customer will be responsible for payment of
                    all such taxes (other than taxes based on ControlRooms&rsquo; income), fees,
                    duties, and charges and any related penalties and interest, arising from the
                    payment of the fees, the provision of the Services, or the license of the
                    ControlRooms Solution to Customer. Customer will make all payments of Fees to
                    ControlRooms free and clear of, and without reduction for, any withholding
                    taxes; any such taxes imposed on payments of Fees to ControlRooms will be
                    Customer&rsquo;s sole responsibility, and Customer will provide ControlRooms
                    with official receipts issued by the appropriate taxing authority, or such other
                    evidence as the ControlRooms may reasonably request, to establish that such
                    taxes have been paid.&nbsp;
                  </li>
                  <li>
                    <strong>Interest</strong>. Any amounts not paid when due will bear interest at
                    the rate of one and one-half percent (1.5%) per month, or the maximum legal rate
                    if less, from the due date until paid.
                  </li>
                </Otc>
              </li>
              <li>
                <strong>CUSTOMER CONTENT AND RESPONSIBILITIES</strong>
                <Otc>
                  <li>
                    <strong>License; Ownership</strong>. Customer is solely responsible for any and
                    all obligations with respect to the accuracy, quality and legality of Customer
                    Content. The customer will obtain all third-party licenses, consents and
                    permissions needed for ControlRooms to use the Customer Content to provide the
                    Services. Without limiting the foregoing, Customer will be solely responsible
                    for obtaining from third parties all necessary rights for ControlRooms to use
                    the Customer Content submitted by or on behalf of Customer for the purposes set
                    forth in this Agreement. Customer grants ControlRooms a non-exclusive,
                    worldwide, royalty-free and fully-paid license during the Term (a) to use the
                    Customer Content as necessary for purposes of providing and improving the
                    Services, (b) to use the Customer trademarks, service marks, and logos as
                    required to provide the Services, and (c) use the Customer Content in an
                    aggregated and anonymized form to: (i) improve the Services and
                    ControlRooms&rsquo; related products and services; (ii) provide analytics and
                    benchmarking services; and (iii) generate and disclose statistics regarding use
                    of the Services, provided, however, that no Customer-only statistics will be
                    disclosed to third parties without Customer&rsquo;s consent. The Customer
                    Content, and all worldwide Intellectual Property Rights in it, is the Customer's
                    exclusive property. All rights in and to the Customer Content not expressly
                    granted to ControlRooms in this Agreement are reserved by Customer.
                  </li>
                  <li>
                    <strong>Customer Warranty</strong>. Customer represents and warrants that any
                    Customer Content will not (a) infringe any copyright, trademark, or patent; (b)
                    misappropriate any trade secret; (c) be deceptive, defamatory, obscene,
                    pornographic or unlawful; (d) contain any viruses, worms or other malicious
                    computer programming codes intended to damage ControlRooms&rsquo; system or
                    data; and (e) otherwise violate the rights of a third party. ControlRooms is not
                    obligated to back up any Customer Content; the Customer is solely responsible
                    for creating backup copies of any Customer Content at the Customer&rsquo;s sole
                    cost and expense before submitting such Customer Content to the ControlRooms
                    Solution. Customer agrees that any use of the ControlRooms Solution contrary to
                    or in violation of the representations and warranties of Customer in this
                    Section 5.2 (Customer Warranty) constitutes unauthorized and improper use of the
                    ControlRooms Solution.
                  </li>
                  <li>
                    <strong>Customer Responsibility for Data and Security</strong>.Customer and its
                    Authorized Users will have access to the Customer Content and will be
                    responsible for all changes to and/or deletions of Customer Content and the
                    security of all passwords and other Access Protocols required to access the
                    ControlRooms Solution. Customer is encouraged to make its own back-ups of the
                    Customer Content. Customer will be solely responsible for the accuracy, quality,
                    integrity, legality, reliability, and appropriateness of all Customer Content.
                    Before any use of the ControlRooms Solution, Customer shall ensure its equipment
                    and systems comply with the minimum system and equipment requirements set forth
                    in the Documentation.
                  </li>
                  <li>
                    <strong>Customer Responsibility for Operational Decisions</strong>. Customer is
                    solely responsible for any decisions or actions based on the Output or the
                    ControlRooms Solution. Customer acknowledges that the ControlRooms Solution is
                    not a safety protocol system or equipment control system, and Customer agrees it
                    will not rely on the Output or ControlRooms Solution for any operational
                    decisions related to Customer&rsquo;s equipment, facilities, or business. Under
                    no circumstances can the ControlRooms Solution replace professional judgment
                    rendered by Customer and its employees and/or contractors.{' '}
                  </li>
                </Otc>
              </li>
              <li>
                <strong>PROFESSIONAL SERVICES</strong>.Where the parties have agreed to
                ControlRooms&rsquo; provision of Professional Services, the details of such
                Professional Services will be set out in an Order Form or a mutually executed
                statement of work that is signed by both parties, references this Agreement, or is
                defined in a Statement of Work (&ldquo;<strong>SOW</strong>&rdquo;). The Order Form
                or SOW, as applicable, will include: (a)&nbsp;a description of the Professional
                Services, (b)&nbsp;the schedule for the performance of the Professional Services,
                and (c)&nbsp;the Fees applicable for the performance of the Professional Services.
                Each Order Form or SOW, as applicable, will incorporate the terms and conditions of
                this Agreement. To the extent that a conflict arises between the terms and
                conditions of an Order Form or SOW and the terms of this Agreement, the terms and
                conditions of this Agreement will govern, except to the extent that the Order Form
                or SOW, as applicable, expressly states that it supersedes specific language in the
                Agreement.&nbsp;
              </li>
              <li>
                <strong>WARRANTIES AND DISCLAIMERS</strong>
                <Otc>
                  <li>
                    <strong>Limited Warranty</strong>. ControlRooms represents and warrants that it
                    will provide the Services and perform its other obligations under this Agreement
                    in a professional and workmanlike manner substantially consistent with general
                    industry standards. Provided that Customer notifies ControlRooms in writing of
                    the breach within thirty (30) days following the performance of the defective
                    Services, specifying the breach in reasonable detail, ControlRooms will, as
                    Customer&rsquo;s sole and exclusive remedy, for any breach of the foregoing,
                    re-perform the Services which gave rise to the breach or, at ControlRooms&rsquo;
                    option, refund the fees paid by Customer for the Services which gave rise to the
                    breach. ControlRooms further warrants to Customer that the ControlRooms Solution
                    will operate free from Errors during the Term, provided that such warranty will
                    not apply to failures to conform to the Documentation to the extent such
                    failures arise, in whole or in part, from (a) any use of the ControlRooms
                    Solution not in accordance with this Agreement or as specified in the
                    Documentation; (b) any use of the ControlRooms Solution in combination with
                    other products, equipment, software or data not supplied by ControlRooms; or (c)
                    any modification of the ControlRooms Solution by any person other than
                    ControlRooms or its authorized agents. Provided that Customer notifies
                    ControlRooms in writing of any breach of the foregoing warranty during the Term,
                    ControlRooms will, as Customer&rsquo;s sole and exclusive remedy, provide the
                    support described in Section 2.2 (Support Services).&nbsp;
                  </li>
                </Otc>
              </li>
              <li>
                <strong>Disclaimer</strong>. THE LIMITED WARRANTY SET FORTH IN SECTION 7.1 (LIMITED
                WARRANTY) IS MADE FOR THE BENEFIT OF CUSTOMER ONLY. EXCEPT AS EXPRESSLY PROVIDED IN
                THIS SECTION 7 (WARRANTIES AND DISCLAIMERS), AND TO THE MAXIMUM EXTENT PERMITTED BY
                APPLICABLE LAW, THE SERVICES, OUTPUT AND DOCUMENTATION ARE PROVIDED &ldquo;AS
                IS,&rdquo; AND CONTROLROOMS MAKES NO (AND HEREBY DISCLAIMS ALL) OTHER WARRANTIES,
                REPRESENTATIONS, OR CONDITIONS, WHETHER WRITTEN, ORAL, EXPRESS, IMPLIED OR
                STATUTORY, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF SATISFACTORY
                QUALITY, COURSE OF DEALING, TRADE USAGE OR PRACTICE, SYSTEM INTEGRATION, DATA
                ACCURACY, MERCHANTABILITY, TITLE, NONINFRINGEMENT, OR FITNESS FOR A PARTICULAR
                PURPOSE. CONTROLROOMS DOES NOT WARRANT THAT ALL ERRORS CAN BE CORRECTED OR THAT
                OPERATION OF THE CONTROLROOMS SOLUTION WILL BE UNINTERRUPTED OR ERROR-FREE.
                CONTROLROOMS MAKES NO WARRANTY OR GUARANTEES REGARDING RESULTS, INCLUDING BUT NOT
                LIMITED TO THE ACCURACY OF ANY OUTPUT. WITHOUT LIMITING THE FOREGOING, CONTROLROOMS
                TAKES NO RESPONSIBILITY FOR AND DOES NOT GUARANTEE AGAINST CUSTOMER SYSTEM FAILURES,
                POWER SURGES, CUSTOMER SYSTEM OR EQUIPMENT DOWNTIME, THIRD-PARTY HARDWARE, CUSTOMER
                HARDWARE, ACCIDENTS, FIRE, EXPLOSIONS, EQUIPMENT FAILURE OR ANY OTHER PROBLEMS WITH
                CUSTOMER&rsquo;S SYSTEMS AND EQUIPMENT AND SHALL HAVE NO LIABILITY WITH RESPECT TO
                ANY OF THE FOREGOING OR ANY SIMILAR SUCH EVENTS. NO ADVICE OR INFORMATION, WHETHER
                ORAL OR WRITTEN, OBTAINED BY CUSTOMER FROM THE CONTROLROOMS SOLUTION, DOCUMENTATION,
                OUTPUT, OR CONTROLROOMS WILL CREATE ANY WARRANTY THAT IS NOT EXPRESSLY STATED IN
                THIS AGREEMENT.
              </li>
              <li>
                <strong>LIMITATION OF LIABILITY</strong>
                <Otc>
                  <li>
                    <strong>Types of Damages</strong>. EXCEPT FOR A PARTY&rsquo;S BREACH OF ITS
                    OBLIGATIONS SET FORTH IN SECTION 10 (Confidentiality) AND SECTION 11
                    (Indemnification), IN NO EVENT WILL EITHER PARTY BE LIABLE TO THE OTHER PARTY
                    FOR ANY INCIDENTAL, INDIRECT, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES,
                    REGARDLESS OF THE NATURE OF THE CLAIM, INCLUDING, WITHOUT LIMITATION, LOST
                    PROFITS, COSTS OF DELAY, ANY FAILURE OF DELIVERY, BUSINESS INTERRUPTION, COSTS
                    OF LOST OR DAMAGED DATA OR DOCUMENTATION, OR LIABILITIES TO THIRD PARTIES
                    ARISING FROM ANY SOURCE, EVEN IF A PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF
                    SUCH DAMAGES. THIS LIMITATION UPON DAMAGES AND CLAIMS IS INTENDED TO APPLY
                    WITHOUT REGARD TO WHETHER OTHER PROVISIONS OF THIS AGREEMENT HAVE BEEN BREACHED
                    OR HAVE PROVEN INEFFECTIVE.&nbsp;
                  </li>
                  <li>
                    <strong>Amount of Damages</strong>. EXCEPT FOR A PARTY&rsquo;S BREACH OF ITS
                    OBLIGATIONS SET FORTH IN SECTION 10 (Confidentiality) AND SECTION 11
                    (Indemnification), THE MAXIMUM LIABILITY OF EITHER PARTY ARISING OUT OF OR IN
                    ANY WAY CONNECTED TO THIS AGREEMENT WILL NOT EXCEED THE SUM OF FEES PAID AND
                    PAYABLE BY CUSTOMER TO CONTROLROOMS DURING THE TWELVE (12) MONTHS PRECEDING THE
                    ACT, OMISSION OR OCCURRENCE GIVING RISE TO SUCH LIABILITY. IN NO EVENT WILL
                    CONTROLROOMS&rsquo; SUPPLIERS HAVE ANY LIABILITY ARISING OUT OF OR IN ANY WAY
                    CONNECTED TO THIS AGREEMENT. NOTHING IN THIS AGREEMENT WILL LIMIT OR EXCLUDE
                    EITHER PARTY&rsquo;S LIABILITY FOR GROSS NEGLIGENCE OR INTENTIONAL MISCONDUCT OF
                    A PARTY OR ITS EMPLOYEES OR AGENTS OR FOR DEATH OR PERSONAL INJURY.
                  </li>
                  <li>
                    <strong>Basis of the Bargain</strong>. The parties agree that the limitations of
                    liability set forth in this Section 8 (Limitation of Liability) will survive and
                    continue in full force and effect despite any failure of consideration or of an
                    exclusive remedy. The parties acknowledge that the prices have been set and the
                    Agreement entered into in reliance upon these limitations of liability and that
                    all such limitations form an essential basis of the bargain between the
                    parties.&nbsp;
                  </li>
                </Otc>
              </li>
              <li>
                <strong>
                  <strong>CONFIDENTIALITY</strong>
                </strong>
                <Otc>
                  <li>
                    <strong>Confidential Information</strong>. &ldquo;
                    <strong>Confidential Information</strong>&rdquo; means any nonpublic information
                    of a party (the &ldquo;<strong>Disclosing Party</strong>&rdquo;), whether
                    disclosed orally or in written or digital media, that is identified as
                    &ldquo;confidential&rdquo; or with a similar legend at the time of such
                    disclosure or that the receiving party (the &ldquo;
                    <strong>Receiving Party</strong>&rdquo;) knows or should have known is the
                    confidential or proprietary information of the Disclosing Party based on the
                    nature of the information and circumstances of disclosure. The Services,
                    Documentation, and all enhancements and improvements thereto will be considered
                    Confidential Information of ControlRooms.
                  </li>
                  <li>
                    <strong>Protection of Confidential Information</strong>. The Receiving Party
                    agrees that it will not use or disclose to any third party any Confidential
                    Information of the Disclosing Party except as expressly permitted under this
                    Agreement. The Receiving Party will limit access to the Confidential Information
                    to Authorized Users (with respect to Customer) or to those employees who need to
                    know, who have confidentiality obligations no less restrictive than those set
                    forth herein, and who have been informed of the confidential nature of such
                    information (with respect to ControlRooms). In addition, the Receiving Party
                    will protect the Disclosing Party&rsquo;s Confidential Information from
                    unauthorized use, access, or disclosure in the same manner that it protects its
                    own proprietary information of a similar nature, but in no event with less than
                    reasonable care. At the Disclosing Party&rsquo;s request or upon termination or
                    expiration of this Agreement, the Receiving Party will return to the Disclosing
                    Party or destroy (or permanently erase in the case of electronic files) all
                    copies of the Confidential Information that the Receiving Party does not have a
                    continuing right to use under this Agreement, and the Receiving Party will, upon
                    request, certify to the Disclosing Party its compliance with this
                    sentence.&nbsp;
                  </li>
                  <li>
                    <strong>Exceptions</strong>. The confidentiality obligations set forth in
                    Section 10.2 (Protection of Confidential Information) will not apply to any
                    information that (a) is at the time of disclosure or becomes generally available
                    to the public through no fault of the Receiving Party; (b) is lawfully provided
                    to the Receiving Party by a third party free of any confidentiality duties or
                    obligations; (c) was already known to the Receiving Party at the time of
                    disclosure free of any confidentiality duties or obligations; or (d) the
                    Receiving Party can demonstrate, by clear and convincing evidence, was
                    independently developed by employees and contractors of the Receiving Party who
                    had no access to the Confidential Information. In addition, the Receiving Party
                    may disclose Confidential Information to the extent that such disclosure is
                    necessary for the Receiving Party to enforce its rights under this Agreement or
                    is required by law or by order of a court or similar judicial or administrative
                    body, provided that (to the extent legally permissible) the Receiving Party
                    promptly notifies the Disclosing Party in writing of such required disclosure
                    and cooperates with the Disclosing Party if the Disclosing Party seeks an
                    appropriate protective order.
                  </li>
                </Otc>
              </li>
              <li>
                <strong>
                  <strong>INDEMNIFICATION</strong>
                </strong>
                <Otc>
                  <li>
                    <strong>By ControlRooms</strong>. ControlRooms will defend at its expense any
                    suit brought against Customer and will pay any settlement ControlRooms makes or
                    approves, or any damages finally awarded in such suit, insofar as such suit is
                    based on a claim by any third party alleging that the ControlRooms Solution
                    infringes such third party&rsquo;s patents, copyrights or trade secret rights
                    under applicable laws of any jurisdiction within the United States of America.
                    If any portion of the ControlRooms Solution becomes, or in ControlRooms&rsquo;
                    opinion is likely to become, the subject of a claim of infringement,
                    ControlRooms may, at ControlRooms&rsquo; option: (a) procure for Customer the
                    right to continue using the ControlRooms Solution; (b) replace the ControlRooms
                    Solution with non-infringing software or services which do not materially impair
                    the functionality of the ControlRooms Solution; (c) modify the ControlRooms
                    Solution so that it becomes non-infringing, or (d) terminate this Agreement and
                    refund any unused prepaid Fees for the remainder of the term then in effect, and
                    upon such termination, Customer will immediately cease all use of the
                    ControlRooms Solution and Documentation. Notwithstanding the foregoing,
                    ControlRooms will have no obligation under this section or otherwise with
                    respect to any infringement claim based upon (i) any use of the ControlRooms
                    Solution not in accordance with this Agreement or as specified in the
                    Documentation; (ii) any use of the ControlRooms Solution in combination with
                    other products, equipment, software or data not supplied by ControlRooms; or
                    (iii) any modification of the ControlRooms Solution by any person other than
                    ControlRooms or its authorized agents (collectively, the &ldquo;
                    <strong>Exclusions</strong>
                    &rdquo; and each, an &ldquo;<strong>Exclusion</strong>&rdquo;). This section
                    states the sole and exclusive remedy of Customer and the entire liability of
                    ControlRooms, or any of the officers, directors, employees, shareholders,
                    contractors or representatives of the foregoing, for infringement claims and
                    actions.
                  </li>
                  <li>
                    <strong>By Customer</strong>. Customer will defend at its expense any suit
                    brought against ControlRooms and will pay any settlement Customer makes or
                    approves, or any damages finally awarded in such suit, insofar as such suit is
                    based on a claim arising out of or relating to (a) an Exclusion, or (b)
                    Customer&rsquo;s breach or alleged breach of Sections 5.2 (Customer Warranty).
                    This section states the sole and exclusive remedy of ControlRooms and the entire
                    liability of Customer, or any of the officers, directors, employees,
                    shareholders, contractors or representatives of the foregoing, for the claims
                    and actions described herein.
                  </li>
                  <li>
                    <strong>Procedure</strong>. The indemnifying party&rsquo;s obligations as set
                    forth aboveare expressly conditioned upon each of the foregoing: (a) the
                    indemnified party will promptly notify the indemnifying party in writing of any
                    threatened or actual claim or suit; (b) the indemnifying party will have sole
                    control of the defense or settlement of any claim or suit; and (c) the
                    indemnified party will cooperate with the indemnifying party to facilitate the
                    settlement or defense of any claim or suit.
                  </li>
                </Otc>
              </li>
              <li>
                <strong>TERM AND TERMINATION</strong>
                <Otc>
                  <li>
                    <strong>Term</strong>. This Agreement will begin on the Effective Date and
                    continue in full force and effect as long as any Order Form remains in effect
                    unless earlier terminated in accordance with the Agreement (the &ldquo;
                    <strong>Term</strong>&rdquo;). Unless otherwise stated in the applicable Order
                    Form, the term of an Order Form will begin on the effective date of the Order
                    Form and continue in full force and effect for one (1) year, unless earlier
                    terminated in accordance with the Agreement. Thereafter, the Order Form will
                    automatically renew for additional terms of one (1) year unless either party
                    gives written notice of non-renewal to the other party at least sixty (60) days
                    before the expiration of the then-current term.
                  </li>
                  <li>
                    <strong>Termination for Breach</strong>.Either party may terminate this
                    Agreement immediately upon notice to the other party if the other party
                    materially breaches this Agreement, and such breach remains uncured more than
                    thirty (30) days after receipt of written notice of such breach.&nbsp;
                  </li>
                  <li>
                    <strong>Effect of Termination</strong>.Upon termination or expiration of this
                    Agreement for any reason: (a) all licenses granted hereunder will immediately
                    terminate; (b) promptly after the effective date of termination or expiration,
                    each party will comply with the obligations to return all Confidential
                    Information of the other party, as set forth in Section 10 (Confidentiality);and
                    (c) any amounts owed to ControlRooms under this Agreement will become
                    immediately due and payable. Sections 1 (Definitions), 3.2 (Restrictions), 3.3
                    (Ownership), 3.5 (Open Source Software), 4 (Fees and Expenses; Payments), 8
                    (Disclaimer), 8 (Limitation of Liability), 10 (Confidentiality), 11
                    (Indemnification), 12.2 (Termination for Breach), 12.3 (Effect of Termination),
                    and 13 (Miscellaneous) will survive expiration or termination of this Agreement
                    for any reason.
                  </li>
                </Otc>
              </li>
              <li>
                <strong>MISCELLANEOUS</strong>
                <Otc>
                  <li>
                    <strong>Governing Law and Venue</strong>. This Agreement and any action related
                    thereto will be governed and interpreted by and under the laws of the State of
                    California, without giving effect to any conflicts of laws principles that
                    require the application of the law of a different jurisdiction. Customer hereby
                    expressly consents to the personal jurisdiction and venue in the state and
                    federal courts for Santa Clara County, California, for any lawsuit filed there
                    against Customer by ControlRooms arising from or related to this Agreement. The
                    United Nations Convention on Contracts for the International Sale of Goods does
                    not apply to this Agreement.&nbsp;
                  </li>
                  <li>
                    <strong>Export</strong>. Customer agrees not to export, re-export, or transfer,
                    directly or indirectly, any U.S. technical data acquired from ControlRooms or
                    any products utilizing such data in violation of the United States export laws
                    or regulations.
                  </li>
                  <li>
                    <strong>Severability</strong>.If any provision of this Agreement is, for any
                    reason, held to be invalid or unenforceable, the other provisions of this
                    Agreement will remain enforceable, and the invalid or unenforceable provision
                    will be deemed modified so that it is valid and enforceable to the maximum
                    extent permitted by law.&nbsp;
                  </li>
                  <li>
                    <strong>Waiver</strong>. Any waiver or failure to enforce any provision of this
                    Agreement on one occasion will not be deemed a waiver of any other provision or
                    such provision on any other occasion.
                  </li>
                  <li>
                    <strong>No Assignment</strong>. Neither party will assign, subcontract,
                    delegate, or otherwise transfer this Agreement, or its rights and obligations
                    herein, without obtaining the prior written consent of the other party, and any
                    attempted assignment, subcontract, delegation, or transfer in violation of the
                    foregoing will be null and void; provided, however, that either party may assign
                    this Agreement in connection with a merger, acquisition, reorganization or sale
                    of all or substantially all of its assets, or other operation of law, without
                    any consent of the other party. The terms of this Agreement will be binding upon
                    the parties and their respective successors and permitted assigns.&nbsp;
                  </li>
                  <li>
                    <strong>Compliance with Law</strong>. Customer shall comply with all
                    international and domestic laws, ordinances, regulations, and statutes that
                    apply to its purchase and use of the Services, Output and Documentation.
                  </li>
                  <li>
                    <strong>Force Majeure</strong>. Any delay in the performance of any duties or
                    obligations of either party will not be considered a breach of this Agreement if
                    such delay is caused by a labor dispute, shortage of materials, fire,
                    earthquake, flood, or any other event beyond the control of such party, provided
                    that such party uses reasonable efforts, under the circumstances, to notify the
                    other party of the cause of such delay and to resume performance as soon as
                    possible.
                  </li>
                  <li>
                    <strong>Independent Contractors</strong>. Customer&rsquo;s relationship with
                    ControlRooms is that of an independent contractor, and neither party is an agent
                    or partner of the other. Customer will not have, and will not represent to any
                    third party that it has, any authority to act on behalf of ControlRooms.
                  </li>
                  <li>
                    <strong>Notices</strong>. All notices required or permitted under this agreement
                    must be delivered in writing to the other party at the address listed on the
                    Cover Page by courier, by certified or registered mail (postage prepaid and
                    return receipt requested), or by a nationally recognized express mail service.
                    Each party may change its email address and/or address for receipt of notice by
                    giving notice of such change to the other party.
                  </li>
                  <li>
                    <strong>Counterparts</strong>. This Agreement may be executed in one or more
                    counterparts, each of which will be deemed an original and all of which will be
                    taken together and deemed to be one instrument.
                  </li>
                  <li>
                    <strong>Entire Agreement</strong>. This Agreement is the final, complete and
                    exclusive agreement of the parties with respect to the subject matters hereof
                    and supersedes and merges all prior discussions between the parties with respect
                    to such subject matters. No modification of or amendment to this Agreement, or
                    any waiver of any rights under this Agreement, will be effective unless in
                    writing and signed by an authorized signatory of Customer and the ControlRooms.
                  </li>
                </Otc>
              </li>
            </Otc>
          </span>
        </div>
        <h5 style={{ textAlign: 'justify', marginBottom: '5px' }}>
          <strong>
            <span style={{ color: '#333', fontSize: '16px' }}>Contacting Us</span>
          </strong>
        </h5>
        <span style={{ color: '#333', fontSize: '14px' }}>
          For additional information, please contact
        </span>
        <div style={{ color: '#333', fontSize: '14px', marginTop: '15px' }}>
          <strong>Controlrooms.ai</strong>
        </div>
        <div style={{ color: '#333', fontSize: '14px' }}>600 Congress Street</div>
        <div style={{ color: '#333', fontSize: '14px' }}>Austin, Texas 78701</div>
        <div style={{ fontSize: '14px' }}>
          <a href="mailto:info@controlrooms.ai">info@controlrooms.ai</a>
        </div>
        <div style={{ fontSize: '14px' }}>
          <a href="https://controlrooms.ai">controlrooms.ai</a>
        </div>
      </ContentArea>
    </Wrapper>
  );
};

/**
 * @reference https://www.bennadel.com/blog/1504-ask-ben-parsing-csv-strings-with-javascript-exec-regular-expression-command.htm
 */
export const csvToArray = (data: string, delimiter = ',') => {
  // Create a regular expression to parse the CSV values.
  const objPattern = new RegExp(
    // Delimiters.
    '(\\' +
      delimiter +
      '|\\r?\\n|\\r|^)' +
      // Quoted fields.
      '(?:"([^"]*(?:""[^"]*)*)"|' +
      // Standard fields.
      '([^"\\' +
      delimiter +
      '\\r\\n]*))',
    'gi',
  );

  // Create an array to hold our data. Give the array
  // a default empty first row.
  const arrData: Array<string[]> = [[]];

  // Create an array to hold our individual pattern
  // matching groups.
  let arrMatches = null;

  // Keep looping over the regular expression matches
  // until we can no longer find a match.
  while ((arrMatches = objPattern.exec(data))) {
    // Get the delimiter that was found.
    const strMatchedDelimiter = arrMatches[1];

    // Check to see if the given delimiter has a length
    // (is not the start of string) and if it matches
    // field delimiter. If id does not, then we know
    // that this delimiter is a row delimiter.
    if (strMatchedDelimiter.length && strMatchedDelimiter !== delimiter) {
      // Since we have reached a new row of data,
      // add an empty row to our data array.
      arrData.push([]);
    }

    let strMatchedValue;

    // Now that we have our delimiter out of the way,
    // let's check to see which kind of value we
    // captured (quoted or unquoted).
    if (arrMatches[2]) {
      // We found a quoted value. When we capture
      // this value, unescape any double quotes.
      strMatchedValue = arrMatches[2].replace(new RegExp('""', 'g'), '"');
    } else {
      // We found a non-quoted value.
      strMatchedValue = arrMatches[3];
    }

    // Now that we have our value string, let's add
    // it to the data array.
    arrData[arrData.length - 1].push(strMatchedValue);
  }
  return arrData;
};

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units (powers of 1000). False to use binary (IEC) (powers of 1024).
 * @param decimals Number of decimal places to display.
 *
 * @return Formatted string.
 */
export const humanFileSize = (bytes: number, si = true, decimals = 1) => {
  const threshold = si ? 1000 : 1024;

  if (Math.abs(bytes) < threshold) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let unitIndex = -1;
  const r = 10 ** decimals;

  do {
    bytes /= threshold;
    ++unitIndex;
  } while (Math.round(Math.abs(bytes) * r) / r >= threshold && unitIndex < units.length - 1);

  return bytes.toFixed(decimals) + ' ' + units[unitIndex];
};

/**
 * Truncate string and apply ellipsis.
 *
 * @param str String to truncate
 * @param n Characters allowed
 *
 * @return Formatted string.
 */
export const truncate = (str: string, n: number) => {
  return str.length > n ? `${str.slice(0, n - 1)}\u2026` : str;
};

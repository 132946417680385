import dayjs from 'dayjs';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { useAnalytics } from '../../../app/analytics';
import { Paths } from '../../../app/constants/paths';
import { windowWidthBreakpoints } from '../../../app/global-styles';
import { AnalyzeChartContext } from '../../context/analyze-chart-context';
import { AppContext } from '../../context/app-context';
import { useLayoutContext } from '../../context/layout-context';
import { MonitorContext } from '../../context/monitor-context';
import { useViewContext } from '../../context/view-context';

import { StyledActions } from './styles';

import { Icon, Tooltip } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { useKeyPress } from '@controlrooms/hooks';
import { ViewType } from '@controlrooms/models';

export const ANALYZE_MIN_ZOOM = dayjs.duration(1, 's').as('seconds');
export const MONITOR_MIN_ZOOM = dayjs.duration(1, 'h').as('seconds');

export const TimelineActions: React.FC = () => {
  const { pathname } = useLocation();
  const { viewState, handleUndoTimeSelection } = useViewContext();
  const { activeModes, activeView } = useLayoutContext();

  const selectedMode = activeModes[activeView];

  const [zoomInDisabled, setZoomInDisabled] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // TODO: disabled for ux testable - needs more ticket detail
  // const [panNextDisabled, setPanNextDisabled] = useState(false);

  //TODO: Need to work on timeselection history
  const { timeSelection, timeSelectionHistory } = viewState;
  useContext(AppContext);
  const {
    zoomIn: analyzeZoomIn,
    zoomOut: analyzeZoomOut,
    panNext: analyzePanNext,
    panPrevious: analyzePanPrevious,
    jumpToNow: analyzeJumpToNow,
  } = useContext(AnalyzeChartContext);
  const {
    zoomIn: monitorZoomIn,
    zoomOut: monitorZoomOut,
    panNext: monitorPanNext,
    panPrevious: monitorPanPrevious,
    jumpToNow: monitorJumpToNow,
  } = useContext(MonitorContext);

  const isAnalyze = selectedMode === ViewType.ANALYZE;
  const { track } = useAnalytics();

  const handleZoomIn = isAnalyze ? analyzeZoomIn : monitorZoomIn;
  const handleZoomOut = isAnalyze ? analyzeZoomOut : monitorZoomOut;
  const handlePanNext = isAnalyze ? analyzePanNext : monitorPanNext;
  const handlePanPrevious = isAnalyze ? analyzePanPrevious : monitorPanPrevious;
  const handleJumpToNow = isAnalyze ? analyzeJumpToNow : monitorJumpToNow;

  const { startTime, endTime } = timeSelection;

  const getRangeDiff = useCallback(
    () => dayjs(endTime).diff(dayjs(startTime), 'seconds'),
    [endTime, startTime],
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // set zoom to disabled on monitor and analyze when reaching max zoom durations
    const rangeDiff: number = getRangeDiff();
    const isAnalyzeZoom =
      !!matchPath(Paths.ANALYZE, pathname) || !!matchPath(Paths.DEMO_ANALYZE, pathname);
    setZoomInDisabled(
      isAnalyzeZoom ? rangeDiff <= ANALYZE_MIN_ZOOM : rangeDiff <= MONITOR_MIN_ZOOM,
    );

    // TODO: disabled for ux testable - needs more ticket detail
    // const newEndTime = dayjs(endTime).add(panStep, 's');
    // const isEndTimeInFuture: boolean = dayjs().diff(newEndTime, 'seconds') < 0;
    // setPanNextDisabled(isEndTimeInFuture || streamingTimeInSeconds !== undefined);
  }, [startTime, endTime, getRangeDiff, pathname]);

  useKeyPress(['=', '+'], handleZoomIn);
  useKeyPress(['-', '_'], handleZoomOut);
  useKeyPress(['u', 'U'], handleUndoTimeSelection);
  useKeyPress([','], handlePanPrevious);
  useKeyPress(['.'], handlePanNext);

  return (
    <StyledActions>
      <div className="group">
        <button
          data-testid="zoom-out"
          id="zoomOut"
          type="button"
          onClick={() => {
            track('Chart Actions - Zoom Out', {
              zoomOut: 'clicked',
              currentPage: isAnalyze ? 'Analyze' : 'Monitor',
            });
            handleZoomOut();
          }}
        >
          <Tooltip
            label={
              windowWidth > windowWidthBreakpoints.mobile ? (
                <ShortcutTooltip label="Zoom Out" shortcut={'Press the "-" key.'} />
              ) : (
                <ShortcutTooltip label="Zoom Out" />
              )
            }
          >
            <Icon name={ICONS.ZoomOut} width="16" height="16" />
          </Tooltip>
        </button>
        <button
          data-testid="zoom-in"
          id="zoomIn"
          type="button"
          onClick={() => {
            track('Chart Actions - Zoom In', {
              zoomIn: 'clicked',
              currentPage: isAnalyze ? 'Analyze' : 'Monitor',
            });
            handleZoomIn();
          }}
          disabled={zoomInDisabled}
        >
          <Tooltip
            label={
              windowWidth > windowWidthBreakpoints.mobile ? (
                <ShortcutTooltip
                  label="Zoom In"
                  shortcut={
                    isAnalyze
                      ? 'Double click, click & drag on a trend or press the "+" key.'
                      : 'Double click on the timeline, press the "+" key.'
                  }
                />
              ) : (
                <ShortcutTooltip label="Zoom In" />
              )
            }
          >
            <Icon name={ICONS.ZoomIn} width="16" height="16" />
          </Tooltip>
        </button>
        <button
          data-testid="pan-left"
          id="panLeft"
          type="button"
          onClick={() => {
            track('Chart Actions - Pan Previous', {
              panPrevious: 'clicked',
              currentPage: isAnalyze ? 'Analyze' : 'Monitor',
            });
            handlePanPrevious();
          }}
        >
          {isAnalyze ? (
            <Tooltip label="Pan to earlier time">
              <Icon name={ICONS.PanLeft} width="12" height="12" />
            </Tooltip>
          ) : (
            <Tooltip label="Pan to earlier time">
              <Icon name={ICONS.PanLeft} width="12" height="12" />
            </Tooltip>
          )}
        </button>
        <button
          // TODO: disabled for ux testable - needs more ticket detail
          // disabled={panNextDisabled}
          id="panRight"
          type="button"
          data-testid="pan-right"
          onClick={() => {
            track('Chart Actions - Pan Next', {
              panNext: 'clicked',
              currentPage: isAnalyze ? 'Analyze' : 'Monitor',
            });
            handlePanNext();
          }}
          disabled={!!timeSelection.streamingTimeInSeconds}
        >
          {isAnalyze ? (
            <Tooltip label="Pan to later time">
              <Icon name={ICONS.PanRight} width="12" height="12" />
            </Tooltip>
          ) : (
            <Tooltip label="Pan to later time">
              <Icon name={ICONS.PanRight} width="12" height="12" />
            </Tooltip>
          )}
        </button>
        {/* {isStreaming} */}
        <button
          data-testid="undo"
          type="button"
          onClick={() => {
            track('Chart Actions - Undo Timeshift', {
              undoTimeshift: 'clicked',
              currentPage: isAnalyze ? 'Analyze' : 'Monitor',
            });
            handleUndoTimeSelection();
          }}
          disabled={!timeSelectionHistory.length}
        >
          <Tooltip
            label={
              timeSelectionHistory.length && windowWidth > windowWidthBreakpoints.mobile ? (
                <ShortcutTooltip label="Undo Timeshift" shortcut={'Press the "u" key.'} />
              ) : (
                <ShortcutTooltip label="Undo Timeshift" />
              )
            }
          >
            <Icon name={ICONS.Undo} width="16" height="16" />
          </Tooltip>
        </button>
        <button
          id="now"
          data-testid="jump-to-now"
          type="button"
          onClick={() => {
            track('Chart Actions - Jump to Now', {
              jumpToNow: 'clicked',
              currentPage: isAnalyze ? 'Analyze' : 'Monitor',
            });
            handleJumpToNow();
          }}
        >
          {/* <Tooltip label={`Jump to ${currentEnvironment?.isBatch ? `latest` : `now`}`}>
              <Icon name={ICONS.Now} width="16" height="16" />
            </Tooltip> */}
          <Tooltip label={`Jump to now`}>
            <Icon name={ICONS.Now} width="16" height="16" />
          </Tooltip>
        </button>
      </div>
    </StyledActions>
  );
};

const ShortcutTooltip: React.FC<{ label: string; shortcut?: string }> = ({ label, shortcut }) => {
  return (
    <>
      {`${label}.`}
      <br />
      {shortcut && <i>{`Shortcut: ${shortcut}`}</i>}
    </>
  );
};

import React from 'react';
import styled from 'styled-components';

import loading from '../../../assets/loading.svg';

const LoadingContainer = styled.div<LoadingProps>`
  position: absolute;
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.backgroundColor};
  opacity: ${({ overlay }) => (overlay ? 0.75 : 1)};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  img {
    max-width: 30%;
  }
`;

interface LoadingProps {
  overlay?: boolean;
  small?: boolean;
}

export const Loading: React.FC<LoadingProps> = ({ overlay, small }) => (
  <LoadingContainer id="spinner" overlay={overlay}>
    <img src={loading} alt="Loading" width={small ? '50px' : 'auto'} />
  </LoadingContainer>
);

import React, { createContext, useCallback, useMemo, useState } from 'react';

import { Channel } from '@controlrooms/models';

interface ContextProps {
  userChannel: Channel | null;
  tenantChannel: Channel | null;
  setChannels: (channels: Channel[]) => void;
}

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <AblyChannelProvider>.');
};

const defaultState = {
  userChannel: null,
  tenantChannel: null,
  setChannels: stub,
};

export const AblyChannelContext = createContext<ContextProps>(defaultState);

const AblyChannelProvider: React.FC = ({ children }) => {
  const [userChannel, setUserChannel] = useState<Channel | null>(defaultState.userChannel);
  const [tenantChannel, setTenantChannel] = useState<Channel | null>(defaultState.tenantChannel);

  const setChannels = useCallback((channels: Channel[]) => {
    // this is a hack, but it's what we have to do for now...
    setUserChannel(channels.find((c) => c.description.toLowerCase().includes('user')) ?? null);
    setTenantChannel(channels.find((c) => c.description.toLowerCase().includes('tenant')) ?? null);
  }, []);

  const channelState = useMemo(
    () => ({
      userChannel,
      tenantChannel,
      setChannels,
    }),
    [setChannels, tenantChannel, userChannel],
  );

  return <AblyChannelContext.Provider value={channelState}>{children}</AblyChannelContext.Provider>;
};

export default AblyChannelProvider;

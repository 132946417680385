import styled from 'styled-components';

import { breakpoints } from '../../global-styles';

interface Props {
  open: boolean;
}

export const StyledMenu = styled.nav<Props>`
  display: flex;
  flex-direction: column;
  background: #3e4b4d;
  position: fixed;
  width: 30%;
  top: 0;
  right: 0;
  bottom: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? 'translateY(0)' : 'translateX(200%)')};
  overflow-y: auto;
  padding-top: 10%;
  padding-right: 18%;
  font-family: 'Space Grotesk';
  @media (max-width: 480px) {
    padding-top: 20%;
  }
  .mobile-view-icon {
    padding-top: 15%;
  }
  .mobile-view-alert {
    padding-top: 15%;
    padding-left: 30%;
  }
  .mobile-view-user {
    padding-top: 15%;
    padding-left: 31%;
  }
  .mobile-view-chat {
    position: fixed;
    bottom: 0;
    margin-bottom: 5%;
    padding-left: 10%;
    button {
      display: flex;
      flex-direction: row;
      span {
        margin-left: 5px;
        font-size: 11px;
        font-weight: 400;
      }
    }
  }
  ${breakpoints.sm} {
    width: 20%;
  }

  li {
    button {
      color: inherit;
    }
  }

  span {
    font-size: 15px;
    font-weight: 400;
    font-family: 'Space Grotesk';
  }

  a {
    align-items: left;
    padding-left: 30%;
    padding-top: 15%;
    text-decoration: none;
    color: #fff;
    font-size: 15px;
    font-weigth: 200;
    font-family: 'Space Grotesk';
    &.active {
      color: ${({ theme }) => theme.navigation.active.textColor};
      font-weight: 700;
    }
  }
`;

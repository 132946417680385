import dayjs from 'dayjs';
import React from 'react';

import { humanFileSize } from '../../../utils/text';

import { FilePreviewContainer } from './styles';

interface Props {
  file: File;
  rows: Array<string[]>;
}

export const FilePreview: React.FC<Props> = ({ file, rows }) => {
  return (
    <FilePreviewContainer>
      <div className="file-name">{file.name}</div>
      <div className="file-meta">
        {dayjs(file.lastModified).format('M/D/YYYY h:mma')}
        ,&nbsp;
        {humanFileSize(file.size)}
      </div>
      <div className="preview-table">
        <table>
          {rows.map((row) => (
            <tr>
              {row.map((d) => (
                <td>{d}</td>
              ))}
            </tr>
          ))}
        </table>
      </div>
    </FilePreviewContainer>
  );
};

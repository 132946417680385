import fileDownload from 'js-file-download';
import { useMutation } from 'react-query';

import { downloadTemplate } from '../services/template';

export const useDownloadTemplate = () => {
  return useMutation(downloadTemplate, {
    onSuccess: ({ blob, filename }) => {
      fileDownload(blob, filename);
    },
  });
};

import { DatePickerTheme } from 'styled-components';

import { colors } from '@controlrooms/design-tokens';

export const datePickerDarkTheme: DatePickerTheme = {
  chevronColor: colors.content['neutral-2'],
  container: {
    boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.24)',
  },
  selector: {
    bottomBorder: `1px solid ${colors.k[65]}`,
  },
  cell: {
    backgroundColor: colors.content['neutral-2'],
    color: colors.k[0],
    hover: {
      backgroundColor: '#e9e9e9',
    },
    selected: {
      backgroundColor: '#0036cc',
      color: '#ffffff',
    },
  },
};

// TODO: light theming colors
export const datePickerLightTheme: DatePickerTheme = {
  chevronColor: colors.k[0],
  container: {
    boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.24)',
  },
  selector: {
    bottomBorder: `1px solid ${colors.k[65]}`,
  },
  cell: {
    backgroundColor: colors.content['neutral-2'],
    color: colors.k[0],
    hover: {
      backgroundColor: '#e9e9e9',
    },
    selected: {
      backgroundColor: '#0036cc',
      color: '#ffffff',
    },
  },
};

import React, { useEffect, useState } from 'react';

import { windowWidthBreakpoints } from '../../../app/global-styles';
import { useLayoutContext } from '../../context/layout-context';
import { useViewContext } from '../../context/view-context';
import { SystemBrowser } from '../analyze-system-browser';
import { TimeDisplay } from '../time-display/time-display';
import { TimelineActions } from '../timeline-actions/timeline-actions';
import { Timeline } from '../timeline/timeline';

import {
  ActionsWrapper,
  DeskWrapper,
  MobileWrapper,
  Overlay,
  PopupContent,
  PopupHeader,
  PopupWrapper,
  StyledHeader,
  StyledHeaderActions,
  SystemsMenu,
  TimeWrapper,
  TimelineActionsWrapper,
} from './styles';

import { Button } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { ViewType } from '@controlrooms/models';

interface Props {
  children?: React.ReactNode;
}

export const ChartHeader: React.FC<Props> = ({ children }) => {
  const { activeModes, activeView } = useLayoutContext();
  const [show, setShow] = useState(false);
  const { viewState, handleShowLimits } = useViewContext();

  const { view, isTrendSearch } = viewState;
  const selectedMode = activeModes[activeView];

  const isAnalyze = viewState.selectedMode === ViewType.ANALYZE;
  //TODO: Add Viewtype for Timesearch or move it to viewState
  const { showLimits } = view[selectedMode];
  const [isMenuVisible, setMenuVisibility] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleMenu = () => {
    setMenuVisibility(!isMenuVisible);
    showLimits ? handleShowLimits(showLimits) : handleShowLimits(false);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    showPopup();
  };

  const showPopup = () => {
    return (
      <>
        <Overlay show={show} onClick={handleClose} />
        <PopupWrapper show={show}>
          <PopupHeader>
            <h4>Select systems</h4>
            <Button
              data-testid="close-icon-systems-mobile"
              iconName={ICONS.Close}
              buttonType="icon"
              buttonSize="small"
              onClick={handleClose}
              style={{ background: 'transparent', border: 'transparent' }}
            />
          </PopupHeader>
          <PopupContent>
            <SystemBrowser />
          </PopupContent>
        </PopupWrapper>
      </>
    );
  };

  return (
    <StyledHeader>
      {windowWidth > windowWidthBreakpoints.desk ? (
        <DeskWrapper>
          {!isTrendSearch && (
            <StyledHeaderActions>
              <div className="desk-view-action-wrap">
                <ActionsWrapper>
                  <div style={{ display: 'flex' }}>{children}</div>
                </ActionsWrapper>
              </div>
              <div className="desk-view-timeline-actions">
                <TimelineActionsWrapper>
                  <TimelineActions />
                </TimelineActionsWrapper>
              </div>
            </StyledHeaderActions>
          )}
          <Timeline />
        </DeskWrapper>
      ) : (
        <MobileWrapper>
          <div className="mobile-view">
            <div className="select-systems">
              {isAnalyze && (
                <SystemsMenu data-id="select-systems" onClick={handleShow}>
                  <Button
                    data-testid="search-icon-systems-mobile"
                    iconName={ICONS.Search}
                    buttonType="icon"
                    buttonSize="large"
                    style={{ background: 'transparent', border: 'transparent' }}
                  />
                </SystemsMenu>
              )}
              {isAnalyze && show && <div>{showPopup()}</div>}
            </div>
            <TimeWrapper>
              <TimeDisplay />
            </TimeWrapper>
            <div className="mobile-view-filter">
              <Button
                data-testid="filter-icon-mobile"
                iconName={ICONS.Filter}
                className={`${isMenuVisible ? 'filter-clicked' : ''}`}
                buttonType="icon"
                buttonSize="large"
                style={{ background: 'transparent', border: 'transparent' }}
                onClick={toggleMenu}
              />
            </div>
          </div>
          {isMenuVisible && (
            <div className="mobile-view-action-wrap">
              <ActionsWrapper>
                <div style={{ display: 'flex' }}>{children}</div>
              </ActionsWrapper>
            </div>
          )}
          {isMenuVisible && (
            <div className="mobile-view-timeline-actions">
              <TimelineActionsWrapper>
                <TimelineActions />
              </TimelineActionsWrapper>
            </div>
          )}
          <Timeline />
        </MobileWrapper>
      )}
    </StyledHeader>
  );
};

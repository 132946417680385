import { css } from 'styled-components';

export const windowWidthBreakpoints = {
  desk: 1024,
  md: 992,
  sm: 768,
  mobile: 480,
};

export const breakpoints = {
  low: '@media only screen and (max-width: 992px)',
  xs: '@media only screen and (min-width: 480px)',
  sm: '@media only screen and (min-width: 768px)',
  md: '@media only screen and (min-width: 992px)',
  desk: '@media only screen and (min-width: 1024px)',
  lg: '@media only screen and (min-width: 1250px)',
  xl: '@media only screen and (min-width: 2560px)',
};

export const customScrollSecondary = css`
  &::-webkit-scrollbar {
    width: 15px; /* Mostly for vertical scrollbars */
  }
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.scrollBar.secondary.thumb.background};
    mix-blend-mode: color-burn;
    /* Focus/Dark/Focus 2 */

    border: 1px solid ${({ theme }) => theme.scrollBar.secondary.thumb.borderColor};
    border-radius: 1px;

    &:hover {
      /* Focus/Dark/Focus 1 */

      border: 1px solid ${({ theme }) => theme.scrollBar.secondary.thumb.hoverBorder};
      /* UI/Hover Glow */

      filter: ${({ theme }) => theme.scrollBar.secondary.thumb.hoverFilter};
    }

    &:active {
      border: 1px solid ${({ theme }) => theme.scrollBar.secondary.thumb.activeBorderColor};
    }
  }
  &::-webkit-scrollbar-track {
    /* Background */
    background-image: ${({ theme }) => theme.scrollBar.secondary.track.backgroundImage};
    margin: 0 2px;
    border-left: 6px solid ${({ theme }) => theme.scrollBar.secondary.track.borderLeftColor};
    border-right: 6px solid ${({ theme }) => theme.scrollBar.secondary.track.borderRightColor};
    filter: blur(0.5px);
    mix-blend-mode: hard-light;

    &:hover {
      /* Background */
      background-image: ${({ theme }) => theme.scrollBar.secondary.track.hoverBackgroundImage};
      border-left: 6px solid ${({ theme }) => theme.scrollBar.secondary.track.hoverBorderLeftColor};
      border-right: 6px solid
        ${({ theme }) => theme.scrollBar.secondary.track.hoverBorderRightColor};
    }
  }
`;

export const customScrollPrimary = css`
  ${customScrollSecondary}

  &::-webkit-scrollbar-track {
    /* Background */
    background-image: ${({ theme }) => theme.scrollBar.primary.track.backgroundImage};
    mix-blend-mode: color-burn;

    &:hover {
      /* Background */
      background-image: ${({ theme }) => theme.scrollBar.primary.track.hoverBackgroundImage};
    }
  }
`;

import dayjs from 'dayjs';
import React, { createContext, Dispatch, SetStateAction, useMemo, useState } from 'react';

import { TimePresetMap } from '../../app/constants/time-selection-form';

import {
  AnomalyRange,
  CRTagData,
  Mode,
  Tag,
  TimePresetKeys,
  TimeRanges,
  TimeSelection,
  TimeSeries,
} from '@controlrooms/models';

export type IselectedTag = {
  tag: Tag;
  color: string;
};

export type TagDataPoint = {
  [tag: string]: number | string;
};

export type tagChartData = { [tagName: string]: CRTagData<TimeSeries, Mode, AnomalyRange> };
export interface InvestigateContextProps {
  selectedTags: { [tagName: string]: IselectedTag };
  setSelectedTags: Dispatch<SetStateAction<{ [tagName: string]: IselectedTag }>>;
  tagChartData: tagChartData;
  setTagChartData: Dispatch<SetStateAction<tagChartData>>;
  investigateTimeSelection: TimeSelection;
  setInvestigateTimeSelection: Dispatch<SetStateAction<TimeSelection>>;
  hoveredTagDatapoints: TagDataPoint;
  setHoveredTagDatapoints: Dispatch<SetStateAction<TagDataPoint>>;
  highlightedTag: string | null;
  setHighlightedTag: Dispatch<SetStateAction<string | null>>;
  lockedTag: string | null;
  setLockedTag: Dispatch<SetStateAction<string | null>>;
}

const defaultTimeSelections: TimeSelection = {
  startTime: dayjs().subtract(12, 'hour'),
  endTime: dayjs(),
  timezone: dayjs.tz.guess(), // Default to user timezone
  timeRange: TimeRanges.PRESET,
  streamingTimeInSeconds: TimePresetMap.get(TimePresetKeys.LAST_TWELVE_HOURS),
  nowSelected: false,
};

const defaultState = {
  selectedTags: {},
  setSelectedTags: () => null,
  tagChartData: {},
  setTagChartData: () => null,
  hoveredTagDatapoints: {},
  setHoveredTagDatapoints: () => null,
  investigateTimeSelection: defaultTimeSelections,
  setInvestigateTimeSelection: () => null,
  highlightedTag: null,
  setHighlightedTag: () => null,
  lockedTag: null,
  setLockedTag: () => null,
};

export const InvestigateContext = createContext<InvestigateContextProps>(defaultState);

const InvestigateContextProvider: React.FC = ({ children }) => {
  const [highlightedTag, setHighlightedTag] = useState<string | null>(null);
  const [lockedTag, setLockedTag] = useState<string | null>(null);

  const [selectedTags, setSelectedTags] = useState<{ [tagName: string]: IselectedTag }>(
    defaultState.selectedTags,
  );
  const [tagChartData, setTagChartData] = useState<{
    [tagName: string]: CRTagData<TimeSeries, Mode, AnomalyRange>;
  }>({});

  const [investigateTimeSelection, setInvestigateTimeSelection] = useState<TimeSelection>(
    defaultState.investigateTimeSelection,
  );

  const [hoveredTagDatapoints, setHoveredTagDatapoints] = useState<TagDataPoint>({});

  const investigateState = useMemo(
    () => ({
      selectedTags,
      setSelectedTags,
      tagChartData,
      setTagChartData,
      investigateTimeSelection,
      setInvestigateTimeSelection,
      hoveredTagDatapoints,
      setHoveredTagDatapoints,
      highlightedTag,
      setHighlightedTag,
      lockedTag,
      setLockedTag,
    }),
    [
      selectedTags,
      tagChartData,
      investigateTimeSelection,
      hoveredTagDatapoints,
      highlightedTag,
      lockedTag,
    ],
  );

  return (
    <InvestigateContext.Provider value={investigateState}>{children}</InvestigateContext.Provider>
  );
};

export default InvestigateContextProvider;

import { Composition } from 'atomic-layout';
import React, { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';

import Logo from '../../../assets/cr_logo.svg';
import { MonitorContext } from '../../context/monitor-context';
import SavedViewContextProvider from '../../context/saved-view-context';
import { useGetViews } from '../../hooks/accounts';
import { useTenants } from '../../hooks/tenants';
import { isDemoApp } from '../../utils/app';
import BrowserFilter from '../browser-filter/browser-filter';
import { ChartHeader } from '../chart-header/chart-header';
import { ReportChartHeader } from '../chart-header/report-chart-header';
import { CustomViewManager } from '../custom-view/custom-view';
import { Timeline } from '../timeline/timeline';

import { Filtering } from './filtering';
import SeverityFilter from './severity-filter';
import LimitsToggle from './severity-limit-exceeded-toggle';
import { StyledFilterContainer } from './styles';

import { ViewType } from '@controlrooms/models';

const areasMobile = `
sidebar content
`;

export const MonitorHeader: React.FC = () => {
  const { data: savedCustomViews } = useGetViews(ViewType.MONITOR);
  const { tenants } = useTenants();
  const { selectedViewTitle, setSelectedViewTitle } = useContext(MonitorContext);
  const [searchParams] = useSearchParams();
  const isReport = searchParams.get('report');
  const isDemo = isDemoApp(location.pathname);

  const customStyle = {
    logoHeader: {
      display: 'flex',
      alignItems: 'center',
      margin: '10px',
    },
    logoDiv: {
      width: '50px',
      marginLeft: '14px',
      marginTop: '30px',
    },
    companyDiv: {
      width: '100%',
      fontSize: '18px',
      color: '#666666',
      fontWeight: 600,
      marginLeft: '26px',
    },
  };

  if (isReport) {
    return (
      <>
        <div style={customStyle.logoHeader}>
          <img
            className="logo"
            src={Logo}
            alt="Control Rooms"
            height={40}
            width={40}
            loading={'lazy'}
          />
          <div id="tenant-name" style={customStyle.companyDiv}>
            {tenants
              ?.filter((t) => t.selected)
              .map((t, index) => (
                <div key={index}>{t.name}</div>
              ))}
          </div>
          <div>
            <ReportChartHeader>
              <SeverityFilter label="Anomaly Severity" />
            </ReportChartHeader>
          </div>
        </div>
        <Composition
          areas={areasMobile}
          gap={0}
          templateCols="220px 1fr"
          templateColsLg="320px 1fr"
        >
          {(Areas) => (
            <>
              <Areas.Content flexGrow="1" flexDirection="column">
                <Timeline />
              </Areas.Content>
            </>
          )}
        </Composition>
      </>
    );
  }

  return (
    <Composition areas={areasMobile} gap={0} templateCols="220px 1fr" templateColsLg="320px 1fr">
      {(Areas) => (
        <>
          <Areas.Sidebar>
            <StyledFilterContainer>
              {savedCustomViews && (
                <SavedViewContextProvider>
                  <CustomViewManager
                    title={selectedViewTitle}
                    views={isDemo ? [] : savedCustomViews}
                    viewType={ViewType.MONITOR}
                    onTitleChange={setSelectedViewTitle}
                  />
                </SavedViewContextProvider>
              )}
              <BrowserFilter />
              <div className="sort">By System</div>
            </StyledFilterContainer>
          </Areas.Sidebar>
          <Areas.Content flexGrow="1" flexDirection="column">
            <ChartHeader>
              <SeverityFilter showSlider={true} />
              <Filtering />
              <LimitsToggle />
            </ChartHeader>
          </Areas.Content>
        </>
      )}
    </Composition>
  );
};

import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { TimeThreshold } from '@controlrooms/models';
import { ObjectUtils } from '@controlrooms/utils';

dayjs.extend(duration);

export class IntervalUtil {
  static DEFAULT_INTERVAL = 900;
  static MIN_REFETCH_MS = 15000;

  public static fromThresholds(start: Dayjs, end: Dayjs, thresholds: Array<TimeThreshold>): number {
    ObjectUtils.requireNonNull({ start, end }, ['start', 'end']);

    const difference = end.diff(start, 'minute');

    return (
      thresholds?.find((t) => t.rangeMinutes <= difference)?.intervalSeconds ??
      IntervalUtil.DEFAULT_INTERVAL
    );
  }

  public static refetchMsFromInterval(intervalSec: number): number {
    return Math.max(Math.round((intervalSec / 10) * 1000), IntervalUtil.MIN_REFETCH_MS);
  }
}

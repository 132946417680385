import React, { createContext, Dispatch, SetStateAction, useMemo, useState } from 'react';

import { MatchType } from '@controlrooms/models';

interface ContextProps {
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  searchTermOnNavigate: string;
  setSearchTermOnNavigate: Dispatch<SetStateAction<string>>;
  isSearchWithin: boolean;
  setIsSearchWithin: Dispatch<SetStateAction<boolean>>;
  searchInputValue: string;
  setSearchInputValue: Dispatch<SetStateAction<string>>;
  isSearchDebounce: boolean;
  setIsSearchDebounce: Dispatch<SetStateAction<boolean>>;
  searchCount: number;
  setSearchCount: Dispatch<SetStateAction<number>>;
  matchType: string;
  setMatchType: Dispatch<SetStateAction<string>>;
}

const defaultState = {
  searchTerm: '',
  setSearchTerm: () => null,
  searchTermOnNavigate: '',
  setSearchTermOnNavigate: () => null,
  isSearchWithin: false,
  setIsSearchWithin: () => null,
  searchInputValue: '',
  setSearchInputValue: () => null,
  isSearchDebounce: true,
  setIsSearchDebounce: () => null,
  searchCount: 0,
  setSearchCount: () => null,
  matchType: MatchType.ANY,
  setMatchType: () => null,
};

export const SearchContext = createContext<ContextProps>(defaultState);

const SearchContextProvider: React.FC = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState<string>(defaultState.searchTerm);
  const [searchTermOnNavigate, setSearchTermOnNavigate] = useState<string>(defaultState.searchTerm);
  const [isSearchWithin, setIsSearchWithin] = useState<boolean>(defaultState.isSearchWithin);
  const [searchInputValue, setSearchInputValue] = useState<string>(defaultState.searchInputValue);
  const [isSearchDebounce, setIsSearchDebounce] = useState<boolean>(defaultState.isSearchDebounce);
  const [searchCount, setSearchCount] = useState<number>(defaultState.searchCount);
  const [matchType, setMatchType] = useState<string>(defaultState.matchType);

  const analyzeState = useMemo(
    () => ({
      searchTerm,
      setSearchTerm,
      searchTermOnNavigate,
      setSearchTermOnNavigate,
      isSearchWithin,
      setIsSearchWithin,
      searchInputValue,
      setSearchInputValue,
      isSearchDebounce,
      setIsSearchDebounce,
      searchCount,
      setSearchCount,
      matchType,
      setMatchType,
    }),
    [
      isSearchDebounce,
      isSearchWithin,
      searchInputValue,
      searchTerm,
      searchTermOnNavigate,
      searchCount,
      matchType,
    ],
  );

  return <SearchContext.Provider value={analyzeState}>{children}</SearchContext.Provider>;
};

export default SearchContextProvider;

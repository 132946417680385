import { DateTimeRangePickerTheme } from '@controlrooms/components/src/date-time-range-picker/theme';
import { colors } from '@controlrooms/design-tokens';

export const dateTimeRangePickerDarkTheme: DateTimeRangePickerTheme = {
  backgroundColor: colors.container['b-g-8'],
  boxShadow: `0px 12px 18px ${colors.k[0]}`,
  textColor: colors.content['neutral-1'],
  header: {
    monthColor: colors.k[100],
  },
  day: {
    border: `1px solid ${colors.k[12]}`,
    hover: {
      background: colors.select.dark['row-hover-grid'],
      border: `1px solid ${colors.focus.dark[1]}`,
      boxShadow: colors.effects['hover-glow'],
    },
    inRange: {
      background: colors.select.dark['row-select'],
      border: `1px solid ${colors.focus.dark[2]}`,
    },
    selected: {
      background: 'linear-gradient(180deg, #304e53 0%, #2e4347 100%)',
      border: `1px solid ${colors.focus.dark[2]}`,
    },
    outsideMonth: {
      color: colors.content['neutral-4'],
    },
  },
  divider: {
    border: `1px solid ${colors.content['neutral-5']}`,
  },
  error: {
    color: colors.datavis['pink-1'],
  },
  timeInput: {
    color: colors.content['neutral-1'],
    background: colors.k[5],
    border: `1px solid ${colors.k[12]}`,
    boxShadow: colors.field.dark['inner-shadow'],
    labelColor: colors.content['neutral-3'],
  },
};

export const dateTimeRangePickerLightTheme: DateTimeRangePickerTheme = {
  backgroundColor: colors.container['b-g-2'],
  boxShadow: '0px 8px 12px rgba(0, 0, 0, 0.6)',
  textColor: colors.content['neutral-6'],
  header: {
    monthColor: colors.k[0],
  },
  day: {
    border: `1px solid ${colors.k[80]}`,
    hover: {
      background: colors.select.light['row-hover-grid'],
      border: `1px solid ${colors.focus.light[1]}`,
      boxShadow: colors.effects['hover-glow'],
    },
    inRange: {
      background: colors.select.light['row-select'],
      border: `1px solid ${colors.focus.light[2]}`,
    },
    selected: {
      background: colors.select.light['row-select'],
      border: `1px solid ${colors.focus.light[2]}`,
    },
    outsideMonth: {
      color: colors.content['neutral-4'],
    },
  },
  divider: {
    border: `1px solid ${colors.container['b-g-4']}`,
  },
  error: {
    color: colors.datavis['pink-1'],
  },
  timeInput: {
    color: colors.content['neutral-6'],
    background: colors.container['b-g-1'],
    border: `1px solid ${colors.k[65]}`,
    boxShadow: colors.field.light['inner-shadow'],
    labelColor: colors.content['neutral-6'],
  },
};

import { colors } from '@controlrooms/design-tokens';

export const timeSearchDarkTheme = {
  sidebar: {
    background: 'linear-gradient(180deg, #191224 0%, #0d0d0d 100%)',
    dateColor: '#8C8C8C',
    referenceTag: {
      tagBg: '#464740',
      tagName: '#D9D9D9',
      tagDesc: '#CCC',
      background: '#0a1315',
      borderColor: '#4D696F',
      dateTimeColor: '#CCC',
      dateTimeBg: '#1f1f1f',
      systemColor: '#8C8C8C',
    },
    eventCard: {
      dateTimeColor: colors.k[80],
      dateTimeBg: colors.k[12],
      background: colors.k[8],
      selected: {
        dateTimeBg: colors.focus.dark[5],
        background:
          'linear-gradient(180deg, #151717 0%, #1b1e1f 71.61%, #1b1e1f 93.75%, #3c555a 100%)',
      },
    },
    navHeader: {
      background: 'linear-gradient(90deg, #253D3F 0%, #0A1315 100%)',
      boxShadow: '0px 8px 12px 0px rgba(0, 0, 0, 0.2)',
      goBack: '#A0A38D',
      title: '##FAFFDA',
    },
    spinnerColor: colors.focus.dark[1],
  },
  viewer: {
    background: '#080808',
    chart: '#000',
    tab: {
      borderColor: colors.content['neutral-3'],
      selectedBorderColor: colors.focus.dark[2],
      color: colors.content['neutral-2'],
      selectedTextColor: colors.content['neutral-1'],
    },
  },
};

export const timeSearchLightTheme = {
  sidebar: {
    background: 'linear-gradient(180deg, #EFEBF2 0%, #E5E3E1 100%)',
    dateColor: '#666666',
    referenceTag: {
      tagBg: '#7199A1',
      tagName: '#FFFFFF',
      tagDesc: '#242522',
      background: 'linear-gradient(180deg, #EFEBF2 0%, #E5E3E1 100%)',
      borderColor: '#BFA8D0',
      dateTimeColor: '#CCC',
      dateTimeBg: '#1f1f1f',
      systemColor: '#666666',
    },

    eventCard: {
      dateTimeColor: colors.k[80],
      dateTimeBg: colors.focus.dark[4],
      background: colors.k[90],
      selected: {
        dateTimeBg: colors.focus.dark[4],
        background:
          'linear-gradient(180deg, #F9F8F4 0%, #E3EDEF 75%, #E2EBED 93.75%, #B7D4DA 100%)',
      },
    },
    navHeader: {
      background: 'linear-gradient(90deg, #DDECE9 0%, #E5E3E1 100%)',
      boxShadow: '0px 8px 12px 0px rgba(0, 0, 0, 0.2)',
      goBack: '#8C8C8C',
      title: '#1F1F1F',
    },
    spinnerColor: colors.focus.light[1],
  },
  viewer: {
    background: '#F9F8F4',
    chart: '#fff',
    tab: {
      borderColor: colors.content['neutral-4'],
      selectedBorderColor: colors.focus.light[2],
      color: colors.content['neutral-5'],
      selectedTextColor: colors.content['neutral-6'],
    },
  },
};

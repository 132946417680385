import { Styles } from 'react-modal';
import styled, { css } from 'styled-components';

import { ConfirmationBannerType } from './modal';

import { colors, Size, text } from '@controlrooms/design-tokens';

export const HeadingContainer = styled.div`
  position: relative;
  padding: 8px;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  box-shadow: ${colors.elevation.dark[2]};
  border-radius: 4px;
  overflow: hidden;
`;

export const FooterInfoContainer = styled.div`
  margin-right: 10px;
  color: ${({ theme }) => theme.fileUploadModal.footer.footerInfoColor};
  font-size: 11px;
  line-height: 13px;
  margin-left: 0.5rem;
  flex: 1;
`;

export const ModalHeader = styled(HeadingContainer)`
  background: ${({ theme }) => theme.modal.contentBackgroundColor};
  border-bottom: 1px solid ${({ theme }) => theme.modal.header.borderColor};
  border-radius: 4px 4px 0 0;
  padding: 11px 0;

  .modal-flex {
    display: flex;
    justify-content: space-between;
  }
`;

export const ModalHeaderText = styled.div`
  margin-left: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 19px;
  color: ${({ theme }) => theme.modal.header.headerTextColor};
`;

export const ModalSubHeaderText = styled.div`
  margin-top: 5px;
  margin-left: 8px;
  top: 50px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: ${({ theme }) => theme.modal.header.subHeaderColor};
`;

export const ModalConfirmationBanner = styled.div<{ type: ConfirmationBannerType }>`
  text-align: center;
  width: calc(100% - 12px);
  margin: 4px;
  font-weight: 600;
  font-size: 13px;
  line-height: 25px;
  justify-content: center;
  border-radius: 4px;

  ${(props) =>
    props.type === ConfirmationBannerType.SUCCESS &&
    css`
      color: ${({ theme }) => theme.modal.contentBackgroundColor};
      background-color: ${colors.datavis['green-1']};
      border: 1px solid ${colors.datavis['green-4']};

      svg > path {
        fill: ${({ theme }) => theme.modal.contentBackgroundColor};
      }
    `}

  ${(props) =>
    props.type === ConfirmationBannerType.ERROR &&
    css`
      color: ${colors.k[100]};
      background-color: ${colors.error[1]};
      border: 1px solid ${colors.datavis['pink-1']};

      svg > path {
        fill: ${colors.k[100]};
      }
    `}
`;

export const ModalFooter = styled(HeadingContainer)<{
  secondary?: boolean;
  customConfirm?: boolean;
}>`
  align-items: center;
  background-color: ${({ theme }) => theme.modal.contentBackgroundColor};
  border-top: 1px solid ${({ theme }) => theme.modal.footer.borderColor};
  min-height: 30px;
  display: flex;
  justify-content: space-between;

  ${(props) =>
    props.customConfirm === true &&
    css`
      justify-content: center;
    `}

  button:only-child {
    margin-left: auto;
  }
  padding: 4px 5px;
  ${(props) =>
    props.secondary === true &&
    css`
      background-color: ${({ theme }) => theme.modal.footer.secondaryBackground};
    `}
  .okConfirmation {
    gap: 10px;
    padding: 0 20px;
    margin-left: unset !important;

    &:disabled {
      cursor: not-allowed;
    }
  }
  .confirm {
    align-self: right;

    &:disabled {
      cursor: not-allowed;
    }
  }
`;

export const HeaderInfoContainer = styled.div`
  color: ${({ theme }) => theme.modal.header.headerInfoColor};
  font-size: ${text.size[Size.HEADER_11]};
  margin-right: 15px;
  position: absolute;
  right: 10px;
  top: 8px;
`;

export const ModalCloseButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.modal.footer.closeButton.color};
  cursor: pointer;
  margin: 2px 5px 0 0;
  padding: 3px;
  right: 0;
  top: 0;
  font-size: 11px;
  font-weight: 100;
  right: 5px;
  top: 10px;
  &:hover {
    color: ${({ theme }) => theme.timeDisplay.primaryTextHover};
    cursor: pointer;
  }
`;
export const ModalBody = styled.div<{ secondary?: boolean }>`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  color: ${colors.content['neutral-3']};
  background-color: ${({ theme }) => theme.modal.contentBackgroundColor};
  background: ${({ theme }) => theme.modal.contentBackgroundColor};
  &:label {
    color: ${({ theme }) => theme.modal.panel.color};
  }
  ${(props) =>
    props.secondary === true &&
    css`
      background-color: ${({ theme }) => theme.modal.secondaryBackground};
    `}
`;

export const ModalStyles: Styles = {
  overlay: {
    position: 'fixed',
    zIndex: 100,
    border: '0px',
    background: 'rgba(0, 0, 0, 0.6)',
    width: '100%',
    height: '100%',
    overflowY: 'auto',
  },
  content: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
    outline: 'none',
    padding: '0px',
    borderRadius: '4px',
    border: 'none',
    overflow: 'visible',
    display: 'table',
    maxWidth: '760px',
    margin: '50px auto',
  },
};

export const ModalPanel = styled.div<{ width?: number }>`
  display: flex;
  flex-direction: column;
  padding: 9px 21px 25px 22px;
  position: relative;
  width: ${({ width }) => width ?? 50}%;

  &:first-child:after {
    content: '';
    height: 75%;
    width: 1px;

    position: absolute;
    right: 0;
    top: 12.5%;

    background-color: ${({ theme }) => theme.modal.panel.firstChildBackgroundColor};
  }
`;

export const ModalPanelHeader = styled.div`
  color: ${({ theme }) => theme.modal.panel.headerColor};
  font-size: 13px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 12px;
`;

import dayjs from 'dayjs';
import React, { useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { NavLink, generatePath, matchPath, useLocation, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { useAnalytics } from '../../../analytics';
import { Role } from '../../../constants/auth';
import { QueryParams, Paths } from '../../../constants/paths';
import { useTenant } from '../../../context/tenant-context';
import { breakpoints, windowWidthBreakpoints } from '../../../global-styles';
import { useExportMultiView, useShiftReport } from '../../../hooks/report';
import { useGetTenantConfig, useTenants } from '../../../hooks/tenants';
import { useBuildSharedView, useSaveView } from '../../../hooks/view';
import { InviteModal } from '../../account/invite-modal';
import { ExportModal } from '../../export-modal';
import { ShiftReportModal, ShiftReportProvider } from '../../shift-report-modal';

import {
  Button,
  Li,
  Modal,
  ModalContents,
  ModalOpenButton,
  OptionsGroup,
  Tooltip,
  Ul,
} from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { useClickOutside } from '@controlrooms/hooks';
import { Fields, TimePresetKeys } from '@controlrooms/models';
import { PersistView } from '@controlrooms/models';
import { TimeUtils } from '@controlrooms/utils';

const StyledUl = styled(Ul)`
  width: 191px;
`;

const HeaderContainer = styled.div`
  font-size: 11px;
  font-family: 'Open Sans', sans-serif;
  text-transform: uppercase;
  line-height: 4px;
  color: ${({ theme }) => theme.exportModal.headerColor};
  border-bottom: 1px solid ${({ theme }) => theme.exportModal.borderColor};
  padding: 10px 0;
  font-weight: 700;
`;

const HeaderText = styled.p`
  margin: 0;
  margin-left: 10px;
  padding: 0;
`;
const ListFooter = styled.div`
  margin: 0;
  border-top: 1px solid ${({ theme }) => theme.exportModal.borderColor};
  span {
    margin-left: -10px;
  }
`;
const ResponsiveIconWarpper = styled.div`
  display: none;
  ${breakpoints.desk} {
    display: block;
  }
`;

const ResponsiveNavWarpper = styled.div`
  display: block;
  ${breakpoints.desk} {
    display: none;
  }
`;

export const ExportSelect: React.FC = () => {
  const [isExportSelectOpen, setIsExportSelectOpen] = useState(false);
  const userUlRef = useRef(null);
  useClickOutside(userUlRef, () => setIsExportSelectOpen(false));
  const { data: tenantConfig } = useGetTenantConfig();
  const { pathname } = useLocation();

  const showShare = !!matchPath(Paths.MONITOR, pathname) || !!matchPath(Paths.ANALYZE, pathname);

  const [viewLink, setViewLink] = useState<string>();
  const [sharedView, setView] = useState<PersistView | undefined>();
  const [searchParams, setSearchParams] = useSearchParams();

  const { generateReport, resetError, isLoading, isError } = useExportMultiView();
  const { mutateAsync: createView } = useSaveView();
  const buildView = useBuildSharedView();
  const windowSize = window.innerWidth;
  const defaultTooltipLabel = `Share screens, create a shift report`;
  const { track } = useAnalytics();
  const { tenant } = useTenant();

  return (
    <OptionsGroup className="export-select">
      {showShare && (
        <Tooltip
          label={
            tenantConfig?.showInvite && windowSize > windowWidthBreakpoints.desk
              ? `${defaultTooltipLabel}, or invite colleagues`
              : tenantConfig?.showInvite && windowSize < windowWidthBreakpoints.desk
              ? 'Share and Invite'
              : defaultTooltipLabel
          }
          offset="{'left': 8}"
          place="bottom,left"
        >
          <ResponsiveIconWarpper>
            <Button
              data-testid="export-button"
              buttonSize="large"
              buttonType="icon"
              iconName={ICONS.Export}
              aria-haspopup="listbox"
              aria-expanded={isExportSelectOpen}
              onClick={(evt) => {
                evt.stopPropagation();
                setIsExportSelectOpen(!isExportSelectOpen);
                track('Export Icon', {
                  shareExport: 'clicked',
                });
              }}
            />
          </ResponsiveIconWarpper>
          <ResponsiveNavWarpper>
            <NavLink
              data-testid="export-mobile"
              onClick={(evt) => {
                evt.stopPropagation();
                setIsExportSelectOpen(!isExportSelectOpen);
                track('Export Icon', {
                  shareExport: 'clicked',
                });
              }}
              to={''}
              className={({ isActive }) => (!isActive ? 'active' : '')}
            >
              Share
            </NavLink>
          </ResponsiveNavWarpper>
        </Tooltip>
      )}
      <StyledUl isOpen={isExportSelectOpen} ref={userUlRef} className="user-dropdown">
        {showShare && (
          <HeaderContainer>
            <HeaderText>Share</HeaderText>
          </HeaderContainer>
        )}
        {showShare && (
          <>
            <Modal unmountOnExit>
              <ModalOpenButton>
                <Li
                  data-testid="export-screen"
                  onClick={async () => {
                    track('Share Screen', {
                      shareScreen: 'clicked',
                    });
                    const view = buildView();
                    const hash = await createView(view);
                    setView(view);
                    setViewLink(
                      `${window.location.origin}${generatePath(Paths.SHARE, {
                        tenantId: tenant.toString(),
                        hash,
                      })}`,
                    );
                  }}
                  className="user-prefs-menu-item"
                >
                  <button>Share this Screen</button>
                </Li>
              </ModalOpenButton>
              <ModalContents
                title="Share this Screen"
                styles={{ content: { width: '35%', minWidth: '400px' } }}
                closeButtonText="Cancel"
                confirmButtonText={isLoading ? 'Downloading...' : 'Download PDF'}
                confirmButtonDisabled={isLoading}
                confirmButtonCallback={() => {
                  track('Share Screen', {
                    downloadPDF: 'clicked',
                  });
                  generateReport();
                  return false;
                }}
                closeButtonCallback={() => {
                  resetError();
                }}
              >
                <ExportModal view={sharedView?.view} link={viewLink} isError={isError} />
              </ModalContents>
            </Modal>
            <Li data-testid="shift-report">
              <ShiftReportProvider>
                <ShiftReportModalConsumer />
              </ShiftReportProvider>
            </Li>
          </>
        )}
        {tenantConfig?.showInvite && (
          <ListFooter>
            <Li data-testid="invite-colleague">
              <Modal open={Boolean(searchParams.get(QueryParams.INVITE))}>
                <ModalOpenButton>
                  <button
                    onClick={() => {
                      track('Invite', {
                        inviteColleague: 'clicked',
                      });
                      const params = new URLSearchParams();
                      params.append(QueryParams.INVITE, 'true');
                      params.append(QueryParams.ROLE, Role.OBSERVER);
                      return setSearchParams(params);
                    }}
                  >
                    Invite a Colleague
                  </button>
                </ModalOpenButton>
                <ModalContents
                  closeButtonCallback={() => {
                    const _params = new URLSearchParams(searchParams);
                    _params.delete(QueryParams.INVITE);
                    _params.delete(QueryParams.ROLE);
                    setSearchParams(_params);
                  }}
                  title="Invite"
                >
                  <InviteModal
                    sandbox={false}
                    onSubmit={() => {
                      const _params = new URLSearchParams(searchParams);
                      _params.delete(QueryParams.INVITE);
                      _params.delete(QueryParams.ROLE);
                      setSearchParams(_params);
                    }}
                  />
                </ModalContents>
              </Modal>
            </Li>
          </ListFooter>
        )}
      </StyledUl>
    </OptionsGroup>
  );
};

const ShiftReportModalConsumer: React.FC = () => {
  const { getValues } = useFormContext();
  const { generateReport, resetError, isLoading, isError, isGenerating } = useShiftReport();
  const { currentTenant } = useTenants();

  const plantTimezone = currentTenant?.timezone;

  return (
    <Modal>
      <ModalOpenButton>
        <button>Create Shift Report</button>
      </ModalOpenButton>
      <ModalContents
        title="Shift Report"
        styles={{ content: { width: '361px' } }}
        confirmButtonText={isLoading || isGenerating ? 'Downloading...' : 'Download PDF'}
        confirmButtonDisabled={isLoading || isGenerating}
        confirmButtonCallback={() => {
          const { preset, type } = getValues();
          if (type === 'preset') {
            const startOfDay = dayjs().tz(plantTimezone).startOf('day');
            const startTime =
              preset === TimePresetKeys.MORNING_REPORT
                ? startOfDay.subtract(6, 'hours')
                : startOfDay.add(6, 'hours');
            const endTime =
              preset === TimePresetKeys.MORNING_REPORT
                ? startOfDay.add(6, 'hours')
                : startOfDay.add(18, 'hours');
            generateReport({ startTime, endTime, timezone: plantTimezone });
          } else {
            const _timeframe = getValues(Fields.CUSTOM_TIMEFRAME as 'customTimeframe');
            const [start, end] = _timeframe.split(' - ');
            const startTime = TimeUtils.toTimezone(
              dayjs(start, 'MM/DD/YYYY HH:mm:ss'),
              plantTimezone,
              true,
            );
            const endTime = TimeUtils.toTimezone(
              dayjs(end, 'MM/DD/YYYY HH:mm:ss'),
              plantTimezone,
              true,
            );
            const isValid = dayjs(startTime).isBefore(dayjs(endTime));
            if (isValid) generateReport({ startTime, endTime, timezone: plantTimezone });
          }
          return false;
        }}
        closeButtonCallback={() => {
          resetError();
        }}
        closeButtonText="Cancel"
      >
        <ShiftReportModal isError={isError} />
      </ModalContents>
    </Modal>
  );
};

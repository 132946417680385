import { TooltipTheme } from 'styled-components';

import { colors } from '@controlrooms/design-tokens';

export const tooltipDarkTheme: TooltipTheme = {
  backgroundColor: colors.container['b-g-4'],
  textColor: colors.content['neutral-6'],
  backgroundColorAlt: colors.container['b-g-8'],
  textColorAlt: colors.content['neutral-1'],
  backgroundColorDark: colors.content['neutral-6'],
  backgroundColorAltDark: colors.container['b-g-4'],
  textColorDark: colors.content['neutral-1'],
  textColorAltDark: colors.container['b-g-8'],
};

export const tooltipLightTheme: TooltipTheme = {
  backgroundColor: colors.container['b-g-4'],
  textColor: colors.content['neutral-6'],
  backgroundColorAlt: colors.container['b-g-2'],
  textColorAlt: colors.content['neutral-6'],
  backgroundColorDark: colors.content['neutral-6'],
  backgroundColorAltDark: colors.container['b-g-4'],
  textColorDark: colors.content['neutral-1'],
  textColorAltDark: colors.container['b-g-8'],
};

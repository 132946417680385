import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { FileUploadContext } from '../../app/components/file-upload-modal/file-upload-context';
import { GlobalContext } from '../../app/context/global-context';
import { useTenant } from '../../app/context/tenant-context';
import { getTenantConfig, getTenantEnvironment, getTenants } from '../../app/services/tenants';
import { CR_USER_PROP, TokenUtil } from '../../app/utils/token';
import { AppContext } from '../context/app-context';

import { Tenant } from '@controlrooms/models';

export const useTenants = () => {
  const { user } = useAuth0();
  const { tenant: currentTenantId } = useTenant();
  const { demoAccessToken } = useContext(GlobalContext);
  let userId: number | undefined;

  userId = user?.[CR_USER_PROP]?.user_id;

  if (demoAccessToken) {
    const extracted = TokenUtil.extractUserProps(demoAccessToken);
    userId = extracted?.user_id;
  }

  const key = ['my-tenants'];
  const { data: tenants, ...rest } = useQuery(key, () => getTenants(), {
    enabled: Boolean(userId),
    select: ({ result }) =>
      result.map((t) => ({ ...t, selected: t.id === currentTenantId })).sort((a, b) => a.id - b.id),
    cacheTime: 8 * 60 * 60 * 1000, // 8 hours,
    staleTime: 60 * 60 * 1000, // 1 hour,
  });

  const currentTenant = useMemo(() => {
    const currentTenant = tenants?.find((t) => t.id === currentTenantId);
    if (tenants?.length && currentTenantId && !currentTenant)
      throw new Error('You may not have the access to view this tenant!');
    return currentTenant;
  }, [tenants, currentTenantId]) as Tenant;

  return {
    currentTenant,
    tenants,
    ...rest,
  };
};

export const useTenantEnvironment = (tenantId: number | undefined) => {
  const { tenants } = useTenants();
  const environment = tenants?.find((t) => t.id === tenantId)?.environmentId;
  const key = [
    'tenant-environment',
    {
      tenant: tenantId,
      environment,
    },
  ];

  if (tenantId && tenants && !environment) throw new Error('Invalid tenant environment');

  return useQuery(key, () => getTenantEnvironment(), {
    enabled: environment !== undefined,
    select: ({ result }) => result,
    cacheTime: 8 * 60 * 60 * 1000, // 8 hours,
    staleTime: 60 * 60 * 1000, // 1 hour,
  });
};

export const useSwitchTenants = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { tenant, updateTenant } = useTenant();
  // const queryClient = useQueryClient();
  const [, setSearchParams] = useSearchParams();
  const { resetFilesAndProgress } = useContext(FileUploadContext);
  const { setTenantSwitching } = useContext(AppContext);

  const switchTenant = useCallback(
    async (tenantId: number, params?: URLSearchParams) => {
      if (tenant === tenantId) {
        if (!params) return;
        setSearchParams(params);
        return;
      }

      resetFilesAndProgress();
      setTenantSwitching(true);

      try {
        // Force a token update from Auth0
        sessionStorage.clear();
        await getAccessTokenSilently({ ignoreCache: true, tenant: tenantId });

        updateTenant(tenantId, params);
        setTenantSwitching(false);
      } catch {
        setTenantSwitching(false);
      }
    },
    [
      tenant,
      resetFilesAndProgress,
      setTenantSwitching,
      setSearchParams,
      getAccessTokenSilently,
      updateTenant,
    ],
  );
  return { switchTenant };
};

export const useGetTenantConfig = () => {
  const key = ['tenant_config'];
  return useQuery(key, () => getTenantConfig(), {
    cacheTime: 8 * 60 * 60 * 1000, // 8 hours,
    staleTime: 8 * 60 * 60 * 1000, // 8 hours,
  });
};

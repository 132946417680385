import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext, useEffect } from 'react';
import { generatePath, matchPath, useNavigate } from 'react-router-dom';

import { useVersion } from '../../../app-v2/components/version';
import { Loading } from '../../components/loading/loading';
import { Paths } from '../../constants/paths';
import { GlobalContext } from '../../context/global-context';
import { isDemoApp } from '../../utils/app';
import { CR_USER_PROP, TokenUtil } from '../../utils/token';

const Redirect: React.FC = () => {
  const navigate = useNavigate();
  const { version } = useVersion();
  const { demoAccessToken } = useContext(GlobalContext);

  const { user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const isDemo = isDemoApp(location.pathname);

    if (isDemo && demoAccessToken) {
      const tokenExtracts = TokenUtil.extractUserProps(demoAccessToken);
      if (tokenExtracts) {
        navigate(generatePath(Paths.DEMO_MONITOR, { tenantId: String(tokenExtracts.tenant) }));
      }
      return;
    }
  }, [demoAccessToken, navigate]);

  useEffect(() => {
    const isDemo = isDemoApp(location.pathname);
    const redirectToShareView = async () => {
      if (!isDemo && user) {
        // After successful authentication, retrieve the requested route
        const storedRouteJSON = localStorage.getItem('requestedRoute');
        const currentQueryParams = new URLSearchParams(location.search);

        let storedRoute, storedQueryParams;

        if (storedRouteJSON) {
          const parsedRoute = JSON.parse(storedRouteJSON);
          storedRoute = parsedRoute.pathname;
          storedQueryParams = new URLSearchParams(parsedRoute.search);

          const currToken = await getAccessTokenSilently();
          const tokenExtracts = TokenUtil.extractUserProps(currToken);

          const sharePath = matchPath(Paths.SHARE, storedRoute);
          if (sharePath && tokenExtracts) {
            const tenantId = sharePath?.params.tenantId;
            if (tokenExtracts.tenant !== Number(tenantId)) {
              await getAccessTokenSilently({
                ignoreCache: true,
                tenant: Number(tenantId),
              });
            }
          }

          // Merge current and stored query parameters
          for (const [key, value] of storedQueryParams.entries()) {
            if (!currentQueryParams.has(key)) {
              currentQueryParams.set(key, value);
            }
          }

          // Clear the stored route and query parameters from session storage
          localStorage.removeItem('requestedRoute');
        }

        let navRoute;

        if (storedRoute && storedRoute === '/app/redirect') {
          navRoute = version === 2 ? Paths.VIEWS : Paths.MONITOR;
        } else if (storedRoute) {
          navRoute = version === 2 ? Paths.VIEWS : storedRoute;
        } else {
          navRoute = version === 2 ? Paths.VIEWS : Paths.MONITOR;
        }

        // Generate path
        const path = generatePath(navRoute, {
          tenantId: user[CR_USER_PROP].tenant,
        });

        // Append query parameters to the path
        const pathWithQuery = `${path}?${currentQueryParams.toString()}`;

        // Navigate to the default tenant or stored route
        navigate(pathWithQuery);
      }
    };
    redirectToShareView();
  }, [getAccessTokenSilently, navigate, user, version]);

  useEffect(() => {
    const isDemo = isDemoApp(location.pathname);
    if (!isDemo && !user) return;
  }, [user]);

  return <Loading />;
};

export default Redirect;

import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';

interface AppContextProps {
  appContext: { [key: string]: string };
  setAppContext: Dispatch<SetStateAction<{ [key: string]: string }>>;
  isTenantSwitching: boolean;
  setTenantSwitching: Dispatch<SetStateAction<boolean>>;
}

const defaultState = {
  appContext: {},
  setAppContext: () => null,
  isTenantSwitching: false,
  setTenantSwitching: () => null,
};

export const AppContext = createContext<AppContextProps>(defaultState);

export const AppContextProvider: React.FC = ({ children }) => {
  const [appContext, setAppContext] = useState<{ [key: string]: string }>({});
  const [isTenantSwitching, setTenantSwitching] = useState<boolean>(false);

  const appState = useMemo(
    () => ({
      appContext,
      setAppContext,
      isTenantSwitching,
      setTenantSwitching,
    }),
    [appContext, isTenantSwitching],
  );

  return <AppContext.Provider value={appState}>{children}</AppContext.Provider>;
};

export const useAppContext = (): AppContextProps => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within a AppContextProvider');
  }
  return context;
};

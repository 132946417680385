import { SystemBrowserTheme } from 'styled-components';

import { colors } from '@controlrooms/design-tokens';

export const systemBrowserDarkTheme: SystemBrowserTheme = {
  header: {
    backgroundColor: '#1A1A1A',
    titleColor: colors.content['neutral-1'],
    borderBottomColor: colors.content['neutral-5'],
    backButtonColor: colors.content['neutral-1'],
    search: {
      subTextColor: colors.content['neutral-3'],
      iconColor: '#C4C4C4',
      searchCountColor: colors.k[65],
      textHighlightColor: colors.focus.dark[1],
    },
  },
  systemBrowser: {
    textColor: colors.content['neutral-5'],
  },
};

export const systemBrowserLightTheme: SystemBrowserTheme = {
  header: {
    backgroundColor: colors.container['b-g-2'],
    titleColor: colors.content['neutral-6'],
    borderBottomColor: colors.container['b-g-2'],
    backButtonColor: colors.content['neutral-5'],
    search: {
      subTextColor: colors.content['neutral-5'],
      iconColor: colors.content['neutral-5'],
      searchCountColor: colors.content['neutral-5'],
      textHighlightColor: colors.focus.light[3],
    },
  },
  systemBrowser: {
    textColor: colors.content['neutral-5'],
  },
};

import { TabsThemeV2 } from 'styled-components';

import { colors } from '@controlrooms/design-tokens';

export const tabDarkTheme: TabsThemeV2 = {
  tab: {
    backgroundColor: colors.k[5],
    textColor: colors.content['neutral-3'],
    tabContainerBG: colors.k[8],
  },
};

export const tabLightTheme: TabsThemeV2 = {
  tab: {
    backgroundColor: colors.k[95],
    textColor: colors.content['neutral-4'],
    tabContainerBG: colors.k[85],
  },
};

import dayjs from 'dayjs';

import { TimeSelection, ZoomTo } from '@controlrooms/models';

enum PanDirection {
  RIGHT = 1,
  LEFT = -1,
}

export class ChartInteractionUtil {
  public static PAN_MULTIPLIER = 4;

  private static _getPanStep(timeSelection: TimeSelection) {
    return (
      dayjs(timeSelection.endTime).diff(dayjs(timeSelection.startTime), 's') / this.PAN_MULTIPLIER
    );
  }

  /**
   * Calculate the new start and end bounds on zoom-in.
   * @param prevTimeSelection the previous time selection
   * @param minZoomSec the minimum allowed zoom (in seconds)
   * @param params the ZoomTo params (optionally right & left OR center)
   */
  public static inCalculateBounds(
    prevTimeSelection: TimeSelection,
    minZoomSec: number,
    params?: Partial<ZoomTo>,
  ) {
    const panStep = this._getPanStep(prevTimeSelection);

    let newStartTime = dayjs(prevTimeSelection.startTime).add(panStep, 's');
    let newEndTime = dayjs(prevTimeSelection.endTime).subtract(panStep, 's');

    if (params?.center) {
      // Zoom on center
      const zoomRange = newEndTime.diff(newStartTime, 's');
      newStartTime = params?.center.subtract(zoomRange / 2, 's');
      newEndTime = params?.center.add(zoomRange / 2, 's');
    } else if (params?.left && params?.right) {
      // Zoom to values
      newStartTime = params?.left;
      newEndTime = params?.right;
    }

    const duration = newEndTime.diff(newStartTime, 's');

    if (duration < minZoomSec) {
      const shift = Math.ceil((duration - minZoomSec) / 2);
      newStartTime = newStartTime.add(shift, 's');
      newEndTime = newEndTime.subtract(shift, 's');
    }
    return [newStartTime, newEndTime];
  }

  /**
   * Calculate the new start and end bounds on zoom-out.
   * @param prevTimeSelection the previous time selection
   */
  public static outCalculateBounds(prevTimeSelection: TimeSelection) {
    const panStep = this._getPanStep(prevTimeSelection);
    const newStartTime = dayjs(prevTimeSelection.startTime).subtract(panStep, 's');
    const newEndTime = dayjs(prevTimeSelection.endTime).add(panStep, 's');

    return [newStartTime, newEndTime];
  }

  /**
   * Calculate the new start and end bounds on pan left (past).
   * @param prevTimeSelection the previous time selection
   */
  public static panLeftCalculateBounds(prevTimeSelection: TimeSelection) {
    return this._pan(prevTimeSelection, PanDirection.LEFT);
  }

  /**
   * Calculate the new start and end bounds on pan right (future).
   * @param prevTimeSelection
   */
  public static panRightCalculateBounds(prevTimeSelection: TimeSelection) {
    return this._pan(prevTimeSelection, PanDirection.RIGHT);
  }

  public static _pan(prevTimeSelection: TimeSelection, direction: PanDirection) {
    const panStep = this._getPanStep(prevTimeSelection) * direction;

    const newStartTime = dayjs(prevTimeSelection.startTime).add(panStep, 's');
    const newEndTime = dayjs(prevTimeSelection.endTime).add(panStep, 's');

    return [newStartTime, newEndTime];
  }
}

import Request from './request';

const TEMPLATE_FILENAME = 'sample_csv_data.csv';

export const downloadTemplate = (): Promise<{ blob: Blob; filename: string }> => {
  const url = `${window.origin}/${TEMPLATE_FILENAME}`;
  return Request.get(url, {
    responseType: 'blob',
  }).then(({ result }) => ({ blob: result, filename: TEMPLATE_FILENAME }));
};

import styled, { css } from 'styled-components';

import { breakpoints, customScrollPrimary } from '../../../../app/global-styles';
import { SidebarFooter } from '../../../../app/pages/monitor/styles';

import { navIconCss, rowSharedCss, subTitleCss } from './shared-styles';

import { colors, Size, text } from '@controlrooms/design-tokens';

export const SystemViewContainer = styled.div`
  width: 100%;
  align-items: stretch;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  overflow-y: auto;
  height: calc(100vh - 225px);
  background: ${({ theme }) => theme.sidebar.backgroundColor};

  ${customScrollPrimary}
`;

export const SystemSectionContainer = styled.div<{
  showHiddenTag?: boolean;
}>`
  .search-container {
    ${customScrollPrimary}
  }
  align-items: stretch;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;

  .plant-name {
    padding-left: 33px;
  }

  .section-header-label {
    .name {
      &.hiddenFolderName {
        padding-left: 25px;
      }
    }
  }
  header.section-header {
    padding-left: 25px;
    &:hover {
      background: ${({ theme }) => theme.sidebar.folders.headerBackgroundColor};
    }
  }

  .section-header {
    align-items: center;
    background-color: ${({ theme }) => theme.sidebar.folders.headerBackgroundColor};
    border-bottom: 1px solid ${({ theme }) => theme.sidebar.folders.borderColor};
    box-sizing: border-box;
    color: ${({ theme }) => theme.sidebar.folders.headerTextColor};
    display: flex;
    flex-flow: row nowrap;
    font-size: 11px;
    font-weight: 700;
    height: 28px;
    cursor: pointer;

    ${rowSharedCss}

    &:hover {
      background: ${({ theme }) => theme.tagHeader.backgroundGradientHover};
    }

    .description {
      flex-grow: 1;
      text-align: left;
      font-weight: 400;
      margin-right: 1.6rem;
    }

    .icon-wrapper {
      text-align: right;
      padding-right: 0.5rem;

      .arrow-icon {
        display: inline-block;
        transform: rotate(0deg);
        transition: transform 0.2s linear;

        svg {
          fill: none;
          stroke: ${({ theme }) => theme.sidebar.folders.accordionArrowExpanded};
        }
      }
    }

    .pinned-count {
      font-size: 9px;
      height: 16px;
      background: ${colors.content['neutral-4']};
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 4px;
      margin-right: 2px;
    }
  }

  .section-content {
    display: none;
  }

  &.expanded {
    .section-header {
      .arrow-icon {
        transition: transform 0.2s linear;
        transform: rotate(90deg);

        svg {
          fill: ${({ theme }) => theme.sidebar.folders.accordionArrowExpanded};
          stroke: none;
        }
      }
    }

    .section-content {
      display: block;
    }
  }

  .no-results {
    margin: 0;
    padding: 50px 0;
    display: block;
    text-align: CENTER;
  }
`;

export const SystemBrowserContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  height: 100%;
  ${breakpoints.low} {
    overflow-y: scroll;
  }

  ${SidebarFooter} {
    width: 320px;
    ${breakpoints.low} {
      width: 150px;
    }

    .gta-wrapper {
      justify-content: end;
    }
  }

  .browser-wrapper {
    overflow: hidden;
    height: calc(100vh - 48px); // This is ugly, longing for flexbox layout
  }

  .system-view-title {
    color: ${({ theme }) => theme.systemBrowser.header.titleColor};
    font-size: 17px;
    font-weight: 400;
    line-height: 19px;
    padding: 12px 22px 0;
    margin: 0;
  }

  .sub-title {
    ${subTitleCss}
    display: flex;
    justify-content: space-between;

    button.un-hidden-tags {
      background: none;
      border: none;
      cursor: pointer;
      font-size: 11px;
      font-family: 'Open Sans', sans-serif;
      color: ${({ theme }) => theme.systemHeader.hiddenTagsCountColor};
      padding: 0px 5px;
      display: flex;
      position: relative;
      &.active {
        border-radius: 4px;
        border: 1px solid var(--content-neutral-4, #737566);
      }
      &:hover {
        text-shadow: 0px 0px 8px #80f7ff, 0px 0px 2px #fff;
        color: #80f7ff;
        svg {
          stroke: #80f7ff;
        }
      }
    }

    .closed-eye-icon {
      margin-left: 2px;
      stroke: ${({ theme }) => theme.systemHeader.closedEyeFill};
    }
  }
`;

export const SubSystemContainer = styled.div<{ depth?: number }>`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  height: 28px;
  justify-content: flex-start;
  width: 100%;
  position: relative;

  .sub-system-check {
    flex: 0 0 12px;
    visibility: hidden;
    width: 12px;
    margin-right: 7px;
    margin-left: 5px;
  }

  ${rowSharedCss}

  &.checked .sub-system-check,
  &:hover .sub-system-check {
    visibility: visible;
  }

  .tooltip {
    width: 100%;
  }

  &.no-tags {
    // height: 18px;
    .description {
      font-size: 9px;
    }

    .name {
      color: ${({ theme }) => theme.sidebar.folders.nameColor};
    }

    .description {
      align-self: baseline;
      color: ${colors.k[55]};
    }
  }

  .expand-icon {
    padding-right: 10px;
  }

  .name {
    color: ${({ theme }) => theme.sidebar.folders.textColor};
    padding-left: ${({ depth = 0 }) => (depth - 1) * 15 + 9.5}px;
    font-size: ${text.size[Size.HEADER_11]};
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    height: 100%;
    align-items: center;
    display: flex;
    flex-grow: 0;
  }

  .truncate {
    flex: 9;
    min-width: 0;
    span {
      // padding: 7px 0;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .description {
    color: ${({ theme }) => theme.sidebar.folders.textColor};
    font-size: ${text.size[Size.HEADER_9]};
    font-weight: 400;
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 20px;
    align-self: baseline;
    margin-top: 1px;
  }
`;

export const SubSystemViewContainer = styled.div<{
  depth?: number;
  expanded?: boolean;
}>`
  align-items: stretch;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;

  &.has-tags .description {
    margin-right: 0;
  }

  .header {
    align-items: center;
    background-color: ${({ theme }) => theme.sidebar.folders.backgroundColor};
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    ${({ depth, theme }) => {
      return (
        depth &&
        css`
          background-color: ${{
            1: theme.sidebar.folders.backgroundColor,
            2: theme.sidebar.folders.evenBackgroundColor,
            3: theme.sidebar.folders.backgroundColor,
            4: theme.sidebar.folders.evenBackgroundColor,
          }[depth]};
        `
      );
    }}
  }

  &:nth-child(even) .header {
    background-color: ${({ theme }) => theme.sidebar.folders.evenBackgroundColor};
  }

  &.has-tags.is-child:not(.has-subfolders) .name {
    padding-left: ${({ depth = 1 }) => (depth - 1) * 15 + 20}px;
  }

  &:not(.search-result) .header {
    ${rowSharedCss}
  }

  .search-result .sub-system-tag-header {
    ${rowSharedCss}
  }

  // bump specificity of nested folders to avoid styling collisions
  ${({ depth = 1 }) => '&'.repeat(depth)} {
    .header {
      .expand-icon {
        padding-left: 10.5px;
        height: 100%;
        display: flex;
        align-items: center;
        .arrow-icon {
          transition: transform 0.2s linear;
          transform: rotate(0deg);

          svg {
            stroke: ${colors.content['neutral-4']};
            fill: none;
          }
        }
      }

      ${navIconCss}
    }

    // TODO: add transition to expand/collapse
    .content {
      display: none;
    }

    &.expanded {
      .header {
        .arrow-icon {
          transition: transform 0.2s linear;
          transform: rotate(90deg);

          svg {
            fill: ${({ theme }) => theme.sidebar.folders.accordionClosedFill};
            stroke: none;
          }
        }
      }

      &:hover {
        .arrow-icon svg {
          fill: ${({ theme }) => theme.sidebar.folders.accordionClosedBorder};
        }
      }

      .content {
        display: block;
      }
    }
  }

  .menu-select {
    margin: 0;
    padding: 0;
    visibility: hidden;
    button {
      padding: 0;
    }
    > ul {
      right: 0;
    }
  }
  &:hover .menu-select {
    visibility: visible;
  }
`;

import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Ensemble } from '@controlrooms/models';

interface ContextProps {
  selectedEnsemble: Ensemble | null;
  setSelectedEnsemble: Dispatch<SetStateAction<Ensemble | null>>;
  showEnsembleErrorBanner: boolean;
  setShowEnsembleErrorBanner: Dispatch<SetStateAction<boolean>>;
}

const defaultState = {
  selectedEnsemble: null,
  setSelectedEnsemble: () => null,
  showEnsembleErrorBanner: false,
  setShowEnsembleErrorBanner: () => null,
};

export const EnsembleContext = createContext<ContextProps>(defaultState);

const EnsembleContextProvider: React.FC = ({ children }) => {
  const [selectedEnsemble, setSelectedEnsemble] = useState<Ensemble | null>(null);
  const [showEnsembleErrorBanner, setShowEnsembleErrorBanner] = useState<boolean>(false);

  /**
   * Update the ensemble session storage when the ensemble is updated
   */
  useEffect(() => {
    if (selectedEnsemble) {
      sessionStorage.setItem('ensemble', JSON.stringify(selectedEnsemble));
    }
  }, [selectedEnsemble]);

  const appState = useMemo(
    () => ({
      selectedEnsemble,
      setSelectedEnsemble,
      showEnsembleErrorBanner,
      setShowEnsembleErrorBanner,
    }),
    // eslint-disable-next-line
    [selectedEnsemble, showEnsembleErrorBanner],
  );

  return <EnsembleContext.Provider value={appState}>{children}</EnsembleContext.Provider>;
};

export default EnsembleContextProvider;

import styled, { css } from 'styled-components';

import { StyledIcon } from '../icon';

import { colors, text } from '@controlrooms/design-tokens';

const sharedCss = css`
  display: block;
  color: ${({ theme }) => theme.textInput.color};
  width: 100%;
  background: transparent;
  flex-grow: 1;
  height: 30px;
  border: none;
  margin: 0 0.5rem;
  padding: 1px 0;
  font-weight: 400;
  font-size: ${text.size['13-data']};
  line-height: 13px;
  caret-color: ${({ theme }) => theme.systemBrowser.header.search.textHighlightColor};

  &:focus-visible {
    outline: none;
  }

  &::placeholder {
    font-style: italic;
  }
`;

export const StyledTextarea = styled.textarea`
  ${sharedCss}
  height: 100px;
  font-family: inherit;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 19px;
`;

export const StyledInput = styled.input`
  ${sharedCss}
`;

export const StyledLabel = styled.label`
  display: block;
  font-size: 0.75em;
  color: ${({ theme }) => theme.textColor};
`;

export const StyledInputWrapper = styled.div`
  .text-input {
    display: flex;
    width: 100%;
    display: inline-flex;
    width: 100%;
    border: 1px solid ${({ theme }) => theme.textInput.borderColor};
    background: ${({ theme }) => theme.textInput.background};

    box-shadow: ${colors.effects['field-inner-shadow']};
    border-radius: 3px;
    &:focus-within {
      background: ${({ theme }) => theme.textInput.focus.background};
      border: 1px solid ${({ theme }) => theme.textInput.focus.borderColor};
    }

    &:hover:not(.disabled) {
      box-shadow: ${({ theme }) => theme.textInput.hover.boxShadow};
      border: 1px solid ${({ theme }) => theme.textInput.hover.borderColor};
    }
  }

  .text-input.disabled {
    background: initial;
    color: ${({ theme }) => theme.textColor};
  }

  button {
    background: transparent;
    border: none;
    cursor: pointer;

    svg {
      fill: #4d696e;

      &:hover {
        fill: ${({ theme }) => theme.systemBrowser.header.search.textHighlightColor};
      }
    }
  }

  &.error .text-input {
    border-color: ${colors.datavis['pink-1']};

    ${StyledIcon} {
      display: flex;
      align-items: center;
    }

    svg {
      fill: ${colors.datavis['pink-1']};
      width: 14px;
      height: 14px;
    }
  }

  .error-message,
  .info-message {
    font-size: ${text.size['9-data']};
    margin-top: 0.1rem;
    display: block;
    color: ${({ theme }) => theme.textColor};
  }

  .error-message {
    color: ${colors.datavis['pink-1']};
  }
`;

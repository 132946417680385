/*eslint-disable */
import dayjs from 'dayjs';
import html2canvas from 'html2canvas';
/*eslint-enable */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useAnalytics } from '../../../app/analytics';
import { useUserId } from '../../../app/hooks/accounts';
import { FolderSort, useFlatFolders, usePlantFolders } from '../../../app/hooks/folders';
import { useUpdateView } from '../../../app/hooks/view';
import { useLayoutContext } from '../../context/layout-context';
import { constructPersistedView } from '../../context/view-context';
import { ErrorText } from '../error-text/error-text';
import { preparePrimaryLabel } from '../layout/TabbedContainer';

import TeamsShareButton from './TeamsShareButton';
import {
  CanvasContainer,
  CopyLinkIcon,
  ExportHeader,
  ExportSocials,
  ModalBodyContainer,
  ScreenshotContainer,
  SharableLink,
  SharableLinkContainer,
  SharableLinkHeader,
  ShareLabel,
  ShareText,
} from './styles';

import { RadioButton, Tooltip } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { DateFormats, TimeRanges, ViewShareType, ViewState, ViewType } from '@controlrooms/models';
import { TimeUtils } from '@controlrooms/utils';

interface Props {
  viewHash: string;
  link?: string;
  isError?: boolean;
  isLoading?: boolean;
  view: ViewState;
}
interface CollectionProps {
  hash: string;
  link?: string;
  isError?: boolean;
  isLoading?: boolean;
}

const CopyToClipboardButton = ({ link }: { link: string }) => {
  const [copied, setCopied] = useState(false);
  const { track } = useAnalytics();

  const onCopy = () => {
    setCopied(true);
    navigator.clipboard.writeText(link);

    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  useEffect(() => {
    if (copied) {
      track('Share Screen', {
        copyLink: 'clicked',
      });
    }
    // eslint-disable-next-line
  }, [copied]);

  return (
    <div data-testid="copy-export-link">
      {copied ? (
        <Tooltip label="Copied to clipboard!">
          <CopyLinkIcon name={ICONS.Copy} width="14" height="14" onClick={onCopy} />
        </Tooltip>
      ) : (
        <CopyLinkIcon name={ICONS.Copy} width="14" height="14" onClick={onCopy} />
      )}
    </div>
  );
};

const ScreenshotView = ({ viewState }: { viewState: ViewState }) => {
  const { viewId } = viewState;
  const { activeModes } = useLayoutContext();
  const view = viewState.view[activeModes[viewId]];
  const { data: plant } = usePlantFolders();
  const { data: flatFolders } = useFlatFolders(FolderSort.DEFAULT);
  const [loading, setLoading] = useState(false);

  const canvasRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (activeModes[viewId] === ViewType.ANALYZE) {
      generateScreenshot(`#analyze-chart-container-${viewId}`);
    } else if (activeModes[viewId] === ViewType.MONITOR) {
      generateScreenshot(`#folder-heatmap-system-${viewId}`);
    } else if (activeModes[viewId] === ViewType.INVESTIGATE) {
      generateScreenshot(`#investigate-chart-container-${viewId}`);
    }
  }, [activeModes, viewId]);

  const generateScreenshot = (screenshotId: string) => {
    console.log('generateScreenshot', screenshotId);
    const screenshotNode = document.querySelector(screenshotId) as HTMLElement;
    const canvasNode = canvasRef.current;
    if (screenshotNode && canvasNode) {
      try {
        canvasNode.innerHTML = 'Generating Preview....';
        setTimeout(() => {
          html2canvas(screenshotNode, {}).then((canvas) => {
            canvasNode.innerHTML = ''; // clear previous content
            const imageDataUrl = canvas.toDataURL();
            const img = new Image();
            img.src = imageDataUrl;
            img.width = 450;
            img.height = 150;
            canvasNode.appendChild(img);
          });
        }, 1000);
      } catch (error) {
        console.error(`Failed to generate screenshot for "${screenshotId}":`, error);
      } finally {
        setLoading(false);
      }
    }
  };

  const getSystemNames = () => {
    return plant?.subfolders.map((sub) => sub.name).join(', ');
  };

  const getSelectedSubsystems = () => {
    const getSelectedSubsystemsInfo = () => {
      return flatFolders
        ?.filter((item) => view?.selectedFolders.includes(item.folder))
        .map((subFolder) => ({ name: subFolder.name, tags: subFolder?.tags?.length }));
    };

    return getSelectedSubsystemsInfo()
      ?.map((subsystem) => `${subsystem.name} (${subsystem.tags})`)
      .join(', ');
  };

  return (
    <ScreenshotContainer>
      <CanvasContainer id="canvas-container" ref={canvasRef}></CanvasContainer>
      <div data-testid="info-area" className="info-area">
        {loading
          ? 'Loading...'
          : view && (
              <>
                <span data-testid="view-name" className="view-name">
                  {getSystemNames()}{' '}
                </span>
                <span data-testid="share-screen-time">
                  {TimeUtils.toTimezone(
                    viewState.timeSelection?.startTime,
                    viewState.timeSelection?.timezone,
                  ).format(DateFormats.TIME)}{' '}
                  -{' '}
                  {TimeUtils.toTimezone(
                    viewState.timeSelection?.endTime,
                    viewState.timeSelection?.timezone,
                  ).format(DateFormats.TIME)}
                  {', '}
                </span>
                <span data-testid="share-screen-date">
                  {TimeUtils.toTimezone(
                    viewState.timeSelection?.startTime,
                    viewState.timeSelection?.timezone,
                  ).format(DateFormats.DATE)}
                </span>
                {view?.selectedFolders && view.selectedFolders?.length > 0 && (
                  <div
                    data-testid="subsystem-display-selected-folders"
                    className="subsystem-display"
                  >
                    <span>{getSelectedSubsystems()}</span>
                  </div>
                )}
                {/* {view?.pinnedTags && view?.pinnedTags?.length > 0 && (
                  <div data-testid="subsystem-display-pinned-tags" className="subsystem-display">
                    Pinned tags: {view.pinnedTags?.length}
                  </div>
                )} */}
              </>
            )}
      </div>
    </ScreenshotContainer>
  );
};

export const ExportModal: React.FC<Props> = ({ link, isError, view }) => {
  const { track } = useAnalytics();
  const [isScriptLoaded, setScriptLoaded] = React.useState(false);

  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://teams.microsoft.com/share/launcher.js';
    script.async = true;
    script.defer = true;
    script.onload = () => setScriptLoaded(true);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [link, view]);
  return (
    <ModalBodyContainer>
      <ShareText data-testid="share-text">
        Captures a snapshot of these systems and tags within this timeframe.
      </ShareText>
      <ShareLabel data-testid="share-label">Share Preview</ShareLabel>
      <ScreenshotView viewState={view} />
      <ExportHeader>Share via:</ExportHeader>
      <ExportSocials>
        <div
          onClick={() => {
            if (isScriptLoaded) {
              track('Share Screen', {
                shareViaTeams: 'clicked',
              });
            } else {
              console.error('Teams launcher script not loaded yet.');
            }
          }}
          data-testid="ms-teams-share-popup"
          data-icon-px-size="64"
          className={`teams-share-button`}
          data-href={link}
        ></div>
      </ExportSocials>
      <SharableLinkHeader>or copy this link:</SharableLinkHeader>
      <SharableLinkContainer>
        {link ? <SharableLink>{link}</SharableLink> : 'Loading...'}
        {link && <CopyToClipboardButton link={link} />}
      </SharableLinkContainer>
      {isError && <ErrorText text="An error occurred while generating the report. Please retry." />}
    </ModalBodyContainer>
  );
};

export const ExportModalV2: React.FC<Props> = ({ link, view, isError, isLoading, viewHash }) => {
  const [, setScriptLoaded] = React.useState(false);
  const [isLive, setIsLive] = useState(view.timeSelection.timeRange === TimeRanges.PRESET);
  const { activeModes } = useLayoutContext();
  const { mutateAsync: updatePersistedView } = useUpdateView();
  const currentUserId = useUserId();

  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://teams.microsoft.com/share/launcher.js';
    script.async = true;
    script.defer = true;
    script.onload = () => setScriptLoaded(true);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [link, view]);

  const handleTimeModeChange = useCallback(
    (isLiveTime: boolean) => {
      setIsLive(isLiveTime);
      const persistView = constructPersistedView({
        viewState: view,
        viewName: view.name || '',
        activeModes,
        currentUserId,
        viewDescription: '',
        shareType: ViewShareType.ANONYMOUS,
        shared: false,
      });
      // While switching timemode in anonymous mode, 'view' is just a snapshot so create view has no sideeffect
      // So apply same logic as create view for anonymous view update
      if (view.view_id === '') {
        persistView.view.name = `Anonymous_${dayjs().valueOf()}`;
        persistView.view_name = `Anonymous_${dayjs().valueOf()}`;
        persistView.view.description = '';
        persistView.default_view = false;
        persistView.share_type = ViewShareType.ANONYMOUS;
      }
      if (isLiveTime) {
        persistView.view.timeSelection.timeRange = TimeRanges.PRESET;
      } else {
        persistView.view.timeSelection.timeRange = TimeRanges.CUSTOM;
      }
      updatePersistedView({
        view: persistView,
        view_id: viewHash,
      });
    },
    [activeModes, currentUserId, updatePersistedView, view, viewHash],
  );

  const renderExportOptions = useMemo(() => {
    if (view.timeSelection.timeRange === TimeRanges.PRESET) {
      return (
        <div>
          <p style={{ textAlign: 'left', marginTop: 0 }}>
            This is a live view displaying realtime streaming data. How do you want to share this?
          </p>
          <div>
            <RadioButton
              id="live-view"
              data-testid="alert-email-overwrite"
              className="tz-radio"
              onClick={() => handleTimeModeChange(true)}
              checked={isLive}
              value="overwrite"
            >
              <p style={{ fontWeight: 'bold', textAlign: 'left', marginTop: 0 }}>
                Share as a Live View: {preparePrimaryLabel(view.timeSelection)}
              </p>
              {/* <p style={{ textAlign: 'left', marginTop: 0 }}>
                Recipient will see this view relative to their live time.
              </p> */}
            </RadioButton>
            <RadioButton
              id="snapshot-view"
              onClick={() => handleTimeModeChange(false)}
              checked={!isLive}
            >
              <p style={{ fontWeight: 'bold', textAlign: 'left', marginTop: 0 }}>
                Share as a Snapshot View: {preparePrimaryLabel(view.timeSelection, true)}
              </p>
              {/* <p style={{ textAlign: 'left', marginTop: 0 }}>
                Recipient will see this view and a snapshot of current timeframe.
              </p> */}
            </RadioButton>
          </div>
        </div>
      );
    }
    return null;
  }, [handleTimeModeChange, isLive, view.timeSelection]);
  if (isLoading) {
    return <>{isLoading && <div>Loading...</div>}</>;
  }
  return (
    <ModalBodyContainer style={{ width: '500px' }}>
      <ShareText data-testid="share-text">
        Share a snapshot of the systems and tags within this timeframe
      </ShareText>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <ScreenshotView viewState={view} />
      </div>
      <div style={{ marginTop: '15px', marginBottom: '10px', textAlign: 'left' }}>
        {preparePrimaryLabel(view.timeSelection)}
      </div>
      <div>{renderExportOptions}</div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '10px',
        }}
      >
        <ExportHeader>Share to your team:</ExportHeader>
        <ExportSocials>
          <TeamsShareButton link={link || ''} />
        </ExportSocials>
      </div>

      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <SharableLinkHeader style={{ marginRight: '10px' }}>Copy link:</SharableLinkHeader>
        <SharableLinkContainer style={{ flexGrow: 1 }}>
          {link ? <SharableLink>{link}</SharableLink> : 'Loading...'}
          {link && <CopyToClipboardButton link={link} />}
        </SharableLinkContainer>
      </div>
      {isError && <ErrorText text="An error occurred while generating the report. Please retry." />}
    </ModalBodyContainer>
  );
};

export const ExportCollectionModal: React.FC<CollectionProps> = ({ link, isError, isLoading }) => {
  const [, setScriptLoaded] = React.useState(false);

  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://teams.microsoft.com/share/launcher.js';
    script.async = true;
    script.defer = true;
    script.onload = () => setScriptLoaded(true);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  if (isLoading) {
    return <>{isLoading && <div>Loading...</div>}</>;
  }
  return (
    <ModalBodyContainer style={{ width: '500px' }}>
      <ShareText data-testid="share-text">Share a snapshot of this Collection,</ShareText>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '10px',
        }}
      >
        <ExportHeader>Share to your team:</ExportHeader>
        <ExportSocials>
          <TeamsShareButton link={link || ''} />
        </ExportSocials>
      </div>

      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <SharableLinkHeader style={{ marginRight: '10px' }}>Copy link:</SharableLinkHeader>
        <SharableLinkContainer style={{ flexGrow: 1 }}>
          {link ? <SharableLink>{link}</SharableLink> : 'Loading...'}
          {link && <CopyToClipboardButton link={link} />}
        </SharableLinkContainer>
      </div>
      {isError && <ErrorText text="An error occurred while generating the report. Please retry." />}
    </ModalBodyContainer>
  );
};

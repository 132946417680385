import styled from 'styled-components';

export const ModalBodyContainer = styled.div`
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-bottom: 40px;
  padding: 20px 30px;
  text-align: left;
`;

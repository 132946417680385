import styled from 'styled-components';

import { Icon } from '../icon';

import { ICONS } from '@controlrooms/constants';

export const DatePickerContainer = styled.div`
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.datePicker.container.boxShadow};
  padding: 8px;
  width: 320px;
`;

export const DatePickerSelectorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 42px;
  border-bottom: ${({ theme }) => theme.datePicker.selector.bottomBorder};
  margin-bottom: 8px;
`;

export const DatePickerSelectorDate = styled.div`
  font-size: 13px;
  font-weight: 400;
`;

export const Chevron = styled(Icon).attrs((props) => ({
  color: props.theme.datePicker.chevronColor,
  name: ICONS.Chevron,
}))`
  cursor: pointer;
`;

export const LeftChevron = styled.div`
  transform: rotate(90deg);
`;

export const RightChevron = styled.div`
  transform: rotate(-90deg);
`;

export const DatePickerCalendarRow = styled.div`
  display: flex;
`;

export const DatePickerCalenderHeader = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

export const DatePickerCalenderCell = styled.div`
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 38px;
  justify-content: center;
  margin: 2px;
  padding: 4px;
  width: 38px;
`;

export const DatePickerDayCell = styled(DatePickerCalenderCell)<{ selected: boolean }>`
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

  background-color: ${({ theme }) => theme.datePicker.cell.backgroundColor};
  color: ${({ theme }) => theme.datePicker.cell.color};

  &:hover {
    background-color: ${({ theme }) => theme.datePicker.cell.hover.backgroundColor};
  }

  ${(props) => {
    if (props.selected) {
      return `
      background-color: ${props.theme.datePicker.cell.selected.backgroundColor};
      background-color: ${props.theme.datePicker.cell.selected.color};
          `;
    }
  }}
`;

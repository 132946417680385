import { useAuth0 } from '@auth0/auth0-react';
import { GetTokenSilentlyOptions } from '@auth0/auth0-spa-js';
import { useCallback, useEffect } from 'react';

import { useRoles } from '../context/authorization-context';
import { useTenant } from '../context/tenant-context';
import { CR_USER_PROP, TokenUtil } from '../utils/token';

export const useAuthorization = () => {
  const { tenant } = useTenant();
  const { getAccessTokenSilently, ...rest } = useAuth0();
  const { setUserRoles, tokenHash, setTokenHash } = useRoles();

  const getTokenAndUpdatePermissions = useCallback(
    (options: GetTokenSilentlyOptions) => {
      return getAccessTokenSilently(options).then((token) => {
        const decoded = TokenUtil.decode(token);
        const currentHash = TokenUtil.generateTokenHash(decoded);
        if (tokenHash != currentHash) {
          // token updated, reset permissions
          setTokenHash(currentHash);
          setUserRoles(decoded[CR_USER_PROP].roles.map(({ role_desc }) => role_desc));
        }
        return token;
      });
    },
    [getAccessTokenSilently, tokenHash, setTokenHash, setUserRoles],
  );

  // Update the permissions when the tenant changes
  useEffect(() => {
    !isNaN(tenant) && getTokenAndUpdatePermissions({ tenant });
  }, [getTokenAndUpdatePermissions, tenant]);

  return {
    ...rest,
    getAccessTokenSilently: getTokenAndUpdatePermissions,
  };
};

export const useBetaUser = () => {
  const { user } = useAuth0();
  return !!user?.[CR_USER_PROP].is_beta;
};

import * as d3 from 'd3';

import { SVGDefsSelection } from '@controlrooms/models';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const appendFilters = (svgDefs: d3.Selection<SVGDefsElement, unknown, HTMLElement, any>) => {
  // create filter with id #drop-shadow
  // height=130% so that the shadow is not clipped
  const filter = svgDefs.append('filter').attr('id', 'inset-shadow').attr('height', '130%');

  filter
    .append('feComponentTransfer')
    .attr('in', 'SourceAlpha')
    .append('feFuncA')
    .attr('type', 'table')
    .attr('tableValues', '1 0');

  filter.append('feGaussianBlur').attr('stdDeviation', '3');
  filter.append('feOffset').attr('dx', '3').attr('dy', '3').attr('result', 'offsetblur');
  filter
    .append('feFlood')
    .attr('flood-color', 'rgb(0, 0, 0)')
    .attr('flood-opacity', 0.5)
    .attr('result', 'color');

  filter.append('feComposite').attr('in2', 'offsetblur').attr('operator', 'in');
  filter.append('feComposite').attr('in2', 'SourceAlpha').attr('operator', 'in');

  const feMerge = filter.append('feMerge');
  feMerge.append('feMergeNode').attr('in', 'SourceGraphic');
  feMerge.append('feMergeNode');
};

export const setCellsVisibilityByClassName = (
  chart: SVGDefsSelection,
  className: string,
  visibility: string,
) => {
  chart.selectAll(`rect.cell.${className}`).style('display', visibility);
};

import { DropzoneRootProps } from 'react-dropzone';
import styled, { css } from 'styled-components';

import { customScrollPrimary } from '../../../global-styles';

import { colors, Size, text } from '@controlrooms/design-tokens';

export const UploaderContainer = styled.div`
  margin-top: 2px;
  &:first-child {
    margin-top: 20px;
  }
`;

export const UploadProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 12px 16px 24px;

  background: ${({ theme }) => theme.fileUploadModal.uploader.file.background};
  border: ${({ theme }) => theme.fileUploadModal.uploader.file.border};
  box-shadow: ${({ theme }) => theme.fileUploadModal.uploader.file.boxShadow};
  border-radius: 6px;

  .upload-heading {
    font-weight: 400;
    font-size: ${text.size[Size.DATA_13]};
    font-family: 'Open Sans', sans-serif;
    line-height: 19px;

    display: flex;

    color: ${({ theme }) => theme.fileUploadModal.uploader.file.heading.color};
    width: 100%;
    justify-content: space-between;
  }

  .upload-details {
    font-weight: 400;
    margin-top: 2px;
    margin-bottom: 12px;
    font-size: 11px;
    font-family: 'Open Sans', sans-serif;
    display: flex;
    justify-content: space-between;
    width: 100%;

    .file-bounds {
      color: ${({ theme }) => theme.fileUploadModal.uploader.file.bounds.color};
    }
    .file-meta {
      color: ${({ theme }) => theme.fileUploadModal.uploader.file.meta.color};
    }
  }

  .progress {
    display: flex;
    width: 100%;
  }
`;

export const FileUploadProgress = styled.progress`
  margin-left: 2px;
  margin-bottom: 10px;
  width: 100%;
  border-radius: 1px;
  height: 1px;
  color: ${({ theme }) => theme.fileUploadModal.progress.backgroundColor};
  transition: width 5s ease;
  margin-top: 0.75rem;
  &::-webkit-progress-value {
    border-radius: 5px;
    background-color: ${({ theme }) => theme.fileUploadModal.progress.progressValueColor};
    box-shadow: 0px 0px 2px #ffffff, 0px 0px 8px #80f7ff;
    transition: width 5s ease;
  }
`;

export const StyledFileList = styled.div`
  max-height: 560px;
  overflow: auto;
  margin-top: 20px;
  ${customScrollPrimary}
`;

export const Container = styled.div<DropzoneRootProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-width: ${({ isDragAccept }) => {
    return isDragAccept ? '4px' : '1px';
  }};
  filter: ${({ isDragAccept }) => {
    if (isDragAccept) {
      return 'drop-shadow(0px 0px 2px #FFFFFF) drop-shadow(0px 0px 8px #80F7FF);';
    }
    return '';
  }};
  border-color: ${({ theme }) => theme.fileUploadModal.uploader.borderColor};
  border-style: dashed;
  border-radius: 6px;
  outline: none;
  transition: border 0.24s ease-in-out;
  text-align: center;
  ${({ isDragAccept }) => {
    if (isDragAccept) {
      return css`
        filter: drop-shadow(0px 0px 4px #ffffff) drop-shadow(0px 0px 8px #80f7ff);
        border-style: solid;
        background-color: ${({ theme }) => theme.fileUploadModal.uploader.backgroundAcceptColor};
        box-shadow: inset 0px 1px 0px #000000, inset 0px 0px 2px #000000,
          inset 0px 4px 8px rgba(0, 0, 0, 0.8);
      `;
    }
  }};
  cursor: pointer;
`;

export const HeadingContainer = styled.div<DropzoneRootProps>`
  ${({ isDragAccept }) => {
    if (isDragAccept) {
      return css`
        opacity: 0.2;
      `;
    }
  }}
`;

export const UploaderHeader = styled.p`
  color: ${({ theme }) => theme.fileUploadModal.uploader.headerText};
  font-size: 13px;
  font-family: 'Open Sans', sans-serif;
  font-style: italic;
  margin: 10px 0 5px 0;
  filter: none;
`;

export const UploaderSubheading = styled.p`
  color: ${({ theme }) => theme.fileUploadModal.uploader.subHeaderText};
  font-size: 11px;
  font-family: 'Open Sans', sans-serif;
  margin: 0px 0 10px 0;
  filter: none;
`;

export const FilePreviewContainer = styled.div`
  display: flex;
  height: 100%;
  max-height: 40vh;
  width: calc(100% - 40px);
  flex-direction: column;
  margin: 20px 20px 8px 20px;

  .file-name {
    font-size: ${text.size[Size.DATA_19]};
    color: ${({ theme }) => theme.fileUploadModal.preview.filenameColor};
  }

  .file-meta {
    margin-top: 4px;
    margin-bottom: 12px;
    font-size: ${text.size[Size.DATA_11]};
    font-family: 'Open Sans', sans-serif;
    line-height: 13px;
    color: ${({ theme }) => theme.fileUploadModal.preview.fileMetaColor};
  }

  .preview-table {
    overflow: auto;
    font-size: ${text.size[Size.DATA_11]};
    font-family: 'Open Sans', sans-serif;

    /* Scrollbar */
    ::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: ${colors.k[3]};
      border: 1px solid ${colors.k[12]};
    }

    /* Slider */
    ::-webkit-scrollbar-thumb {
      background: #1f3133;
      border: 1px solid #427275;
      border-radius: 4px;
    }

    /* Corner */
    ::-webkit-scrollbar-corner {
      background: ${colors.k[3]};
    }
  }

  table,
  tr,
  td {
    border: ${({ theme }) => theme.fileUploadModal.preview.border};
    border-collapse: collapse;
  }

  td {
    padding: 10px;
    white-space: nowrap;
  }
`;

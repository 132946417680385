import { DeleteDataModalTheme } from 'styled-components';

import { colors } from '@controlrooms/design-tokens';

export const deleteDataModalDarkTheme: DeleteDataModalTheme = {
  body: {
    color: colors.content['neutral-1'],
  },
};

export const deleteDataModalLightTheme: DeleteDataModalTheme = {
  body: {
    color: colors.k[5],
  },
};

import styled from 'styled-components';

import { RadioHeader, StyledIcon } from '@controlrooms/components';
import { colors, text } from '@controlrooms/design-tokens';

export const StyledUserPrefs = styled.div`
  width: 760px;
  height: 100%;
  display: flex;
  flex-direction: row;
  text-align: left;
  padding-bottom: 50px;
  padding: 14.5px 22px;
  font-size: ${text.size['13-data']};
  font-family: 'Open Sans', sans-serif;

  .prefs-nav {
    width: 150px;
    border-right: 1px solid ${colors.content['neutral-5']};

    ul,
    li {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li {
      margin-bottom: 10px;
    }

    button {
      background: none;
      border: none;
      font-size: ${text.size['11-data']};
      font-family: 'Open Sans', sans-serif;
      color: ${({ theme }) => theme.modal.navigation.color};
      cursor: pointer;
      width: 100%;
      text-align: left;

      display: flex;
      align-items: center;
      justify-content: space-between;

      ${StyledIcon} {
        display: none;
        margin-right: 1rem;
        svg {
          fill: ${({ theme }) => theme.modal.navigation.activeColor};
        }
      }

      &.active {
        color: ${({ theme }) => theme.modal.navigation.activeColor};
        ${StyledIcon} {
          display: block;
        }
      }
    }
  }

  .prefs-pages {
    padding: 0 20px;
  }

  ${RadioHeader} {
    margin-top: 1rem;
  }
`;

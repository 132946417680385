import React from 'react';
import styled from 'styled-components';

import { icons } from './index';

import { ICONS } from '@controlrooms/constants';

interface Props extends React.ButtonHTMLAttributes<HTMLDivElement> {
  className?: string;
  color?: string;
  width?: string;
  height?: string;
  rotate?: number;
}

export const StyledIcon = styled.div<Props>`
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  height: ${({ height }) => (height ? `${height}px` : 'auto')};
  display: flex;
  align-items: center;
  ${({ rotate }) => {
    if (rotate) {
      return `
        transform: rotate(${rotate}deg);
      `;
    }
  }}
  & svg {
    width: ${({ width }) => (width ? `${width}px` : 'auto')};
    height: ${({ height }) => (height ? `${height}px` : 'auto')};
    path {
      ${({ color }) => color && `fill: ${color}`};
    }
  }
`;

interface Props {
  className?: string;
  color?: string;
  name: typeof ICONS[keyof typeof ICONS];
  width?: string;
  height?: string;
  rotate?: number;
}

export const Icon: React.FC<Props> = ({
  name,
  width,
  height,
  color,
  rotate,
  className,
  ...props
}) => {
  const IconComp = icons[name];

  if (!IconComp) return null;

  return (
    <StyledIcon
      name={name}
      color={color}
      className={className}
      width={width}
      height={height}
      rotate={rotate}
      {...props}
    >
      <IconComp className={`${name}-icon`} />
    </StyledIcon>
  );
};

import { createRef, useContext, useEffect, useRef, useState } from 'react';

import { useAnalytics } from '../../analytics';
import { SearchContext } from '../../context/search-context';

import { StyledSearch } from './styles/styles';

import {
  Icon,
  TextInput,
  OptionsGroup,
  Button,
  Li,
  Ul,
  Checkbox,
  Tooltip,
} from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { useClickOutside, useDebounce } from '@controlrooms/hooks';
import { MatchType } from '@controlrooms/models';

const SEARCH_DEBOUNCE_INTERVAL = 900;
const SEARCH_MINIMUM_CHARS = 1;

export interface SearchProps {
  disabled?: boolean;
}

const matchTypes = [
  {
    value: MatchType.ANY,
    label: 'Match any',
    dataTestId: 'match-any-list',
    tooltipText: 'Tags where ANY entered keyword matches on that tag',
  },
  {
    value: MatchType.ALL,
    label: 'Match all',
    dataTestId: 'match-all-list',
    tooltipText: 'Tags where ALL entered keywords MUST match on that tag',
  },
];

export const Search: React.FC<SearchProps> = ({ disabled = false }) => {
  const {
    searchInputValue,
    setSearchInputValue,
    isSearchWithin,
    setIsSearchWithin,
    setSearchTerm,
    isSearchDebounce,
    setIsSearchDebounce,
    setSearchTermOnNavigate,
    searchCount,
    searchTerm,
    setMatchType,
  } = useContext(SearchContext);
  const [isMatchTypeSelectOpen, setIsMatchTypeSelectOpen] = useState(false);
  const [selectedMatchType, setSelectedMatchType] = useState(matchTypes[0]);
  const { track } = useAnalytics();

  const ulRef = useRef(null);
  const debouncedValue = useDebounce<string>(searchInputValue, SEARCH_DEBOUNCE_INTERVAL);

  useClickOutside(ulRef, () => setIsMatchTypeSelectOpen(false));

  const handleMatchTypeClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    setIsMatchTypeSelectOpen(!isMatchTypeSelectOpen);
  };

  const handleChange = (search: string): void => {
    setSearchInputValue(search);
    track('Analyze - Search', {
      searchInput: search,
    });
  };

  const isSearchWithinClick = () => {
    track('Analyze - Search', {
      isSearchWithinSelected: isSearchWithin,
    });
    setIsSearchWithin(!isSearchWithin);
  };

  const searchRef = createRef<HTMLInputElement>();

  useEffect(() => {
    // Bypass debounce when empty (eg. for close button, we don't want a delay)
    if (searchInputValue === '' || !isSearchDebounce) {
      setSearchTerm(searchInputValue);
      return setIsSearchDebounce(true);
    }

    if (debouncedValue.length >= SEARCH_MINIMUM_CHARS) {
      return setSearchTerm(debouncedValue);
    }

    setSearchTerm('');

    // Force focus on search result input, to counteract wonkieness from switching from sys. view input
    // TODO: Might cause trouble down the road, look for better alternative
    searchRef?.current?.focus();
  }, [
    searchInputValue,
    searchRef,
    setSearchTerm,
    debouncedValue,
    isSearchDebounce,
    setIsSearchDebounce,
  ]);

  return (
    <StyledSearch>
      <div className="search-wrapper">
        <TextInput
          data-testid="search-systems-input"
          disabled={disabled}
          placeholder="Search"
          id="tag-search-input"
          onChange={({ currentTarget }) => handleChange(currentTarget?.value)}
          onKeyDown={(e: React.KeyboardEvent) => {
            e.stopPropagation();
          }}
          value={searchInputValue}
          ref={searchRef}
        >
          <button
            data-testid="clear-search-button"
            className="clear-search"
            onClick={() => {
              track('Analyze - Search', {
                clearSearch: 'clicked',
              });
              setSearchInputValue('');
              setSearchTermOnNavigate('');
            }}
          >
            <Icon name={ICONS.CloseX} width="14" height="14" />
          </button>
        </TextInput>
      </div>
      <div className="search-options">
        <OptionsGroup className="match-select">
          <Tooltip label={selectedMatchType.tooltipText || ''} place="top">
            <Button
              data-testid="match-type"
              buttonSize="small"
              buttonType={'menu-text'}
              className="match-title"
              onClick={(e) => {
                track('Analyze - Search', {
                  matchTypeSelectionBox: 'clicked',
                });
                handleMatchTypeClick(e);
              }}
              aria-haspopup="listbox"
              aria-expanded={isMatchTypeSelectOpen}
            >
              {selectedMatchType?.label}
            </Button>
          </Tooltip>
          <Ul isOpen={isMatchTypeSelectOpen} className="match-dropdown" ref={ulRef}>
            {matchTypes?.map((matchType) => (
              <Tooltip label={matchType.tooltipText || ''} place="top" key={`${matchType.value}`}>
                <Li
                  className="match-menu-item"
                  data-testid={matchType.dataTestId}
                  onClick={(e) => {
                    handleMatchTypeClick(e);
                    setSelectedMatchType(matchType);
                    setMatchType(matchType.value);
                    track('Analyze - Search', {
                      matchTypeSelected: matchType.value,
                    });
                  }}
                >
                  {matchType.label}
                </Li>
              </Tooltip>
            ))}
          </Ul>
        </OptionsGroup>
        <div className="current-selections">
          <Checkbox
            className="current-checkbox"
            id="within-current-selection"
            checked={isSearchWithin}
            dataTestId="within-current-selection"
            onChange={isSearchWithinClick}
          />
          <span>within current selection</span>
        </div>
        {searchTerm && (
          <div data-testid="search-count" className="search-count">
            {searchCount} Results
          </div>
        )}
      </div>
    </StyledSearch>
  );
};

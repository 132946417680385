import styled from 'styled-components';

import { breakpoints } from '../../global-styles';

import { StyledInputWrapper } from '@controlrooms/components';
import { StyledButton } from '@controlrooms/components';

export const StyledCustomViewInput = styled.div`
  display: flex;
  align-items: center;

  & > ${StyledButton} {
    background: none;
    border: none;

    svg path {
      fill: ${({ theme }) => theme.sidebar.filtersColor};
    }
  }

  .edit-view-mode {
    display: flex;
    margin: 0.5rem 1rem 0 16px;
    flex-flow: row wrap;
  }

  ${StyledButton}.view-title {
    padding: 0;
    margin-top: 10px;
    justify-content: left;
    height: auto;

    ${breakpoints.xl} {
      margin-top: 20px;
    }

    span {
      font-size: 17px;
      font-weight: 300;
      line-height: 19px;
      color: ${({ theme }) => theme.sidebar.filtersColor};
      padding-left: 0;

      ${breakpoints.xl} {
        font-size: 31px;
        line-height: 37px;
      }
    }
  }

  .view-select {
    flex-grow: 1;
  }

  .time-range-options-group {
    padding: 0;
    padding-left: 5px;
    margin-top: 5px;

    button {
      margin-top: 0;
    }
  }

  ${StyledInputWrapper} {
    /* max-width: 186px; */
    flex-grow: 1;
  }

  .cv-buttons {
    display: flex;
    align-items: center;
    button {
      padding-left: 16px;
    }
  }

  svg {
    fill: ${({ theme }) => theme.sidebar.filtersColor};
  }

  .menu-select {
    margin: 0 10px 0 0;
    padding: 0;
  }
`;

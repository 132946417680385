import { colors } from '@controlrooms/design-tokens';

export type SelectTheme = {
  subLabelColor: string;
};

export const selectDarkTheme: SelectTheme = {
  subLabelColor: colors.k[65],
};

export const selectLightTheme: SelectTheme = {
  subLabelColor: colors.k[65],
};

import styled from 'styled-components';

import { StyledAccountShared } from '../shared-styles';

export const StyledConfirmEmail = styled(StyledAccountShared)`
  height: 442px;

  .banner-background {
    mix-blend-mode: screen;
    margin-top: 36px;
  }

  .banner-foreground {
    position: relative;
    top: -160px;
  }
`;

export const StyledCheckboxRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .terms-checkbox {
    margin-right: 10px;

    & > div {
      border-color: ${({ theme }) => theme.confirmEmail.checkboxBorderColor};
      background: transparent;
      box-shadow: none;
    }
  }
`;

export const StyledModalLink = styled.span`
  color: ${({ theme }) => theme.linkColor};
  text-decoration: underline;
  cursor: pointer;
`;

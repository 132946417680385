import styled from 'styled-components';

import { breakpoints } from '../../global-styles';

import { colors } from '@controlrooms/design-tokens';

export const StyledTimeline = styled.div<{ selectedFolders: number[] }>`
  margin-top: auto;
  position: relative;
  box-shadow: 5px 0 9px -9px #000000 inset;
  background: transparent;
  height: 30px;
  ${breakpoints.xs} {
    margin-right: ${({ selectedFolders }) => selectedFolders.length > 1 && '17px'};
  }
  .domain {
    stroke: ${({ theme }) => theme.timeLine.horizontalLine};
    stroke-width: 3;
  }
  .tooltip-timeline {
    position: absolute;
    padding: 5px;
    background-color: ${({ theme }) => theme.chart.tooltip.backgroundColor};
    display: flex;
    opacity: 0;
    pointer-events: none;
    color: ${({ theme }) => theme.chart.tooltip.textColor};
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    border-radius: 4px;
    z-index: 99;
    // width: 60px;
    flex-direction: column;
    &:after {
      content: '▼';
      position: absolute;
      bottom: -13px;
      left: 50%;
      transform: translateX(-50%);
      color: ${({ theme }) => theme.chart.tooltip.backgroundColor};
    }
    span {
      width: 100%;
      text-align: center;
    }
  }
  .tick {
    text {
      font-weight: 700;
      font-size: 9px;
      line-height: 13px;
      fill: ${({ theme }) => theme.timeLine.timeLabel};
    }
    line {
      stroke: ${({ theme }) => theme.timeLine.majorTickColor};
    }
  }
  .grey {
    text {
      fill: ${({ theme }) => theme.timeLine.minorTickColor};
    }
    line {
      stroke: ${({ theme }) => theme.timeLine.minorTickColor};
    }
  }
  .streaming-tick {
    font-family: 'Open Sans', sans-serif;
    text-shadow: 0 0 2px ${colors.k[100]}, 0px 0px 8px #80f7ff;
    fill: ${({ theme }) => theme.chart.streamingTickColor};
    stroke: ${({ theme }) => theme.chart.streamingTickColor};
  }

  ${breakpoints.xl} {
    .timeline-scroll {
      font-size: 13px;
    }
  }
`;

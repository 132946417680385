import { HeaderThemeTag } from 'styled-components';

import { GRADIENTS } from '@controlrooms/constants';
import { colors } from '@controlrooms/design-tokens';

export const headerDarkThemeTag: HeaderThemeTag = {
  backgroundHover: 'transparent',
  backgroundGradientHover: `linear-gradient(
    180deg, 
    ${colors.tags.dark[GRADIENTS.tagHeaderHover].color1} 0%, 
    ${colors.tags.dark[GRADIENTS.tagHeaderHover].color2} 92.71%,
    ${colors.tags.dark[GRADIENTS.tagHeaderHover].color3} 100%
  )`,
  closeIconFillHover: colors.focus.dark[2],
  closedEyeIconFill: colors.content['neutral-4'],
  closedEyeIconFillHover: colors.focus.dark[2],
  tagTitleBackgroundHover: colors.focus.dark[2],
  tagTitleBoxShadowHover: colors.effects['hover-glow'],
  tagTitleBackgroundHoverPinned: colors.focus.dark[2],
  tagTitleColor: colors.k[55],
  tagTitleColorHover: colors.k[5],
  tagTitleColorHoverPinned: colors.k[5],
  tagDescColor: colors.k[80],
  tagDescColorHover: colors.focus.dark[1],
  tagDescColorHoverPinned: colors.focus.dark[1],
  leftBorderColorHover: '#427275',
  closeIconFill: colors.content['neutral-5'],
  borderRight: colors.focus.dark[2],
  tagTitleBackground: colors.k[12],
  tagTitleBackgroundPinned: colors.focus.dark[4],
  tagTitleColorPinned: colors.k[100],
  tagDescColorPinned: colors.k[100],
  tagUOMColor: colors.k[55],
  tagUomColorHover: colors.k[90],
  pinIconFill: colors.focus.dark[2],
  pinIconFillHover: colors.focus.dark[6],
  pinIconStrokeHover: colors.effects['hover-glow'],
  pinIconRowHoverStroke: colors.content['neutral-3'],
  pinIconRowHoverFill: 'transparent',
  leftBorderColor: '#1f3133',
};

export const headerLightThemeTag: HeaderThemeTag = {
  backgroundHover: `transparent`,
  closeIconFillHover: '#427275',
  backgroundGradientHover: `linear-gradient(
    180deg, 
    ${colors.tags.dark[GRADIENTS.tagHeaderHover].color1} 0%, 
    ${colors.tags.dark[GRADIENTS.tagHeaderHover].color2} 92.71%,
    ${colors.tags.dark[GRADIENTS.tagHeaderHover].color3} 100%
  )`,
  closedEyeIconFill: colors.content['neutral-5'],
  closedEyeIconFillHover: colors.focus.light[2],
  tagTitleBackgroundHover: colors.focus.light[2],
  tagTitleBoxShadowHover: colors.effects['hover-glow'],
  tagTitleBackgroundHoverPinned: '#8FB8C0',
  tagTitleColor: colors.k[20],
  tagTitleColorHover: colors.k[95],
  tagTitleColorHoverPinned: colors.k[20],
  tagDescColor: colors.k[40],
  tagDescColorHover: colors.k[10],
  tagDescColorHoverPinned: colors.k[10],
  leftBorderColorHover: '#579EA3',
  closeIconFill: colors.content['neutral-5'],
  borderRight: colors.focus.dark[2],
  tagTitleBackground: colors.k[80],
  tagTitleBackgroundPinned: colors.focus.light[4],
  tagTitleColorPinned: colors.k[20],
  tagDescColorPinned: colors.k[10],
  tagUOMColor: colors.k[55],
  tagUomColorHover: colors.k[55],
  pinIconFill: colors.focus.light[3],
  pinIconFillHover: colors.focus.light[2],
  pinIconStrokeHover: colors.content['neutral-3'],
  pinIconRowHoverStroke: colors.content['neutral-3'],
  pinIconRowHoverFill: colors.focus.light[2],
  leftBorderColor: '#579EA3',
};

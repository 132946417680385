import { AxiosError } from 'axios';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { InfoCard } from '../alert-modal/styles';

import { FieldLabel, preparePrimaryLabel } from './TabbedContainer';

import { Modal, ModalContents, TextInput, Select, Icon } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { ViewState, ViewShareType } from '@controlrooms/models';

export const SaveViewModal = ({
  onClose,
  viewState,
  onSave,
  apiError,
  isUpdateModal = false,
}: {
  onClose: (closeState: boolean) => void;
  viewState: ViewState;
  onSave: ({
    name,
    description,
    shareType,
  }: {
    name: string;
    description: string;
    shareType: ViewShareType;
  }) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  apiError: AxiosError<any> | null;
  isUpdateModal?: boolean;
}) => {
  const shareTypeOptions = useMemo(() => {
    return [
      {
        value: ViewShareType.PRIVATE,
        label: ViewShareType.PRIVATE,
      },
      {
        value: ViewShareType.EDITABLE,
        label: ViewShareType.EDITABLE,
      },
      {
        value: ViewShareType.VIEWABLE,
        label: ViewShareType.VIEWABLE,
      },
    ];
  }, []);

  const {
    register,
    watch,
    setValue,
    formState: { errors },
    trigger,
    setError,
    clearErrors,
  } = useForm<{
    name: string;
    description: string;
    shareType: ViewShareType;
    apiError: string;
  }>({
    mode: 'onChange',
    defaultValues: isUpdateModal
      ? {
          name: viewState.name || '',
          description: viewState.description || '',
          shareType: viewState.shareType || ViewShareType.PRIVATE,
          apiError: '',
        }
      : {
          name: '',
          description: '',
          shareType: ViewShareType.PRIVATE,
          apiError: '',
        },
  });

  useEffect(() => {
    if (apiError?.response?.data?.detail) {
      setError('apiError', { type: 'custom', message: apiError?.response?.data.detail });
    }
  }, [apiError, setError]);

  return (
    <Modal open={true}>
      <ModalContents
        title={'Save View'}
        styles={{ content: { maxWidth: '500px' } }}
        confirmButtonText="Save View"
        closeButtonText="Cancel"
        closeButtonCallback={() => {
          onClose(false);
        }}
        shouldCloseOnEsc={false}
        confirmButtonCallback={() => {
          clearErrors('apiError');
          const viewName = watch(`name`);
          if (!viewName) {
            trigger();
            return false;
          } else {
            onSave({
              name: watch(`name`),
              description: watch(`description`),
              shareType: watch(`shareType`),
            });
          }
        }}
        dataTestIdCancel="modal-close-icon-delete-alert"
      >
        <div style={{ padding: '16px', fontSize: '13px' }}>
          {errors?.apiError?.message && (
            <InfoCard type="error">
              <Icon className="icon" name={ICONS.SystemDown} />
              <span data-testid="alert-error">{errors['apiError']?.message}</span>
            </InfoCard>
          )}
          <p>Save the time range, tags, and charting options you are currently using as a View.</p>
          <div>
            <FieldLabel>Name</FieldLabel>
            <TextInput
              type="string"
              value={watch(`name`)}
              className="view-name"
              label=""
              onKeyDown={(e) => {
                clearErrors('apiError');
                e.stopPropagation();
              }}
              placeholder=""
              errorId={'view-name-required'}
              disabled={false}
              {...register(`name`, {
                setValueAs: (v) => {
                  if (!v) return '';
                  return v;
                },
                validate: (value) => {
                  if (value.length <= 1) {
                    return 'Provide a valid view name';
                  }
                  return true;
                },
              })}
              errorMessage={errors.name?.message || ''}
            />
          </div>
          <div style={{ marginTop: '10px' }}>
            <FieldLabel>Timeframe</FieldLabel>
            <div>{preparePrimaryLabel(viewState.timeSelection)}</div>
          </div>
          <div style={{ marginTop: '10px' }}>
            <FieldLabel>Description</FieldLabel>
            <TextInput
              type="string"
              value={watch(`description`)}
              className="view-description"
              label=""
              onKeyDown={(e) => {
                clearErrors('apiError');
                e.stopPropagation();
              }}
              placeholder=""
              disabled={false}
              {...register(`description`, {
                setValueAs: (v) => {
                  if (!v) return '';
                  return v;
                },
              })}
              errorMessage={errors.description?.message || ''}
            />
          </div>
          <div style={{ marginTop: '10px' }}>
            <FieldLabel>Sharing</FieldLabel>
            <Select
              style={{ width: '300px' }}
              options={shareTypeOptions}
              markSelectedOption
              defaultOption={{
                label: watch(`shareType`),
                value: watch(`shareType`),
              }}
              onChange={(e) => {
                clearErrors('apiError');
                setValue('shareType', e.value as ViewShareType);
              }}
            />
            {watch(`shareType`) === ViewShareType.PRIVATE && (
              <>
                <p>This view is primarily for your own use.</p>
                <p>
                  You can still use the Share action to copy a link. This takes a snapshot of the
                  current state that others can view, minus the name and description you have
                  supplied. Any changes you make after sharing the link will not be reflected to the
                  link recipients.
                </p>
              </>
            )}
            {watch(`shareType`) === ViewShareType.VIEWABLE && (
              <>
                <p>
                  Your colleagues will see this view in their list of Shared Views, but any changes
                  they make to it will be visible only to them. Changes you make can be published to
                  all and applied by each viewer using the Update action.
                </p>
              </>
            )}
            {watch(`shareType`) === ViewShareType.EDITABLE && (
              <>
                <p>
                  You and your colleagues can edit the View and publish those edits using the
                  Publish action. To apply someone else’s edits to you will need to use the Update
                  action.
                </p>
              </>
            )}
          </div>
        </div>
      </ModalContents>
    </Modal>
  );
};

import React from 'react';

import { StyledContainer } from './styles';

interface Props {
  className?: string;
  id?: string;
  isPage?: boolean;
}

export const Container: React.FC<Props> = ({ className, children, id, isPage = false }) => {
  return (
    <StyledContainer data-testid="styled-container" className={className} id={id} isPage={isPage}>
      {children}
    </StyledContainer>
  );
};

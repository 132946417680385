import Request from './request';

import {
  EnvironmentResult,
  TenantConfig,
  TenantResult,
  PersistView,
  PersistCollection,
  ViewCollectionUpdate,
  PatchCollection,
} from '@controlrooms/models';

const SERVICE_ROOT = '/accounts-v2';
const ACCOUNTS_CUSTOMIZE = '/user-customize/accounts-customize';

export const getTenants = (): Promise<TenantResult> =>
  Request.get(`${SERVICE_ROOT}/users/me/tenants`).then((r) => new TenantResult(r));

export const getTenantEnvironment = (): Promise<EnvironmentResult> =>
  Request.get(`${SERVICE_ROOT}/environments/me`).then((r) => new EnvironmentResult(r));

export const getCollection = (collectionId: string): Promise<PersistCollection> =>
  Request.get(`${ACCOUNTS_CUSTOMIZE}/view-collections/${collectionId}`).then(
    ({ result }) => result,
  );

export const getCollections = (): Promise<PersistCollection[]> =>
  Request.get(`${ACCOUNTS_CUSTOMIZE}/view-collections`).then(({ result }) => result);

export const getSharedCollections = (): Promise<PersistCollection[]> =>
  Request.get(`${ACCOUNTS_CUSTOMIZE}/view-collections/shared`).then(({ result }) => result);

export const addToSharedCollections = (
  collectionId: string,
  payload: { user_ids: number[] },
): Promise<string> =>
  Request.post(`${ACCOUNTS_CUSTOMIZE}/views/${collectionId}/share`, payload).then(
    ({ result }) => result,
  );

export const removeViewFromSharedCollection = (collectionId: string): Promise<string> =>
  Request.delete(`${ACCOUNTS_CUSTOMIZE}/view-collections/shared/${collectionId}`).then(
    ({ result }) => result,
  );

export const saveCollection = (collection: PersistCollection): Promise<PersistCollection> =>
  Request.post(`${ACCOUNTS_CUSTOMIZE}/view-collections`, collection).then(({ result }) => result);

export const updateCollection = (
  collectionId: string,
  collection: PersistCollection,
): Promise<string> =>
  Request.put(`${ACCOUNTS_CUSTOMIZE}/view-collections/${collectionId}`, collection).then(
    ({ result }) => result,
  );

export const patchCollection = (
  collectionId: string,
  collection: PatchCollection,
): Promise<string> =>
  Request.patch(`${ACCOUNTS_CUSTOMIZE}/view-collections/${collectionId}`, collection).then(
    ({ result }) => result,
  );

export const deleteCollection = (collectionId: string): Promise<string> =>
  Request.delete(`${ACCOUNTS_CUSTOMIZE}/view-collections/${collectionId}`).then(
    ({ result }) => result,
  );

export const getView = (viewId: string): Promise<PersistView> =>
  Request.get(`${ACCOUNTS_CUSTOMIZE}/views/${viewId}`).then(({ result }) => result);

export const getViews = (): Promise<PersistView[]> =>
  Request.get(`${ACCOUNTS_CUSTOMIZE}/views`).then(({ result }) => result);

export const getSharedViews = (): Promise<PersistView[]> =>
  Request.get(`${ACCOUNTS_CUSTOMIZE}/views/shared`).then(({ result }) => result);

export const addToSharedViews = (
  viewId: string,
  payload: { user_ids: number[] },
): Promise<string> =>
  Request.post(`${ACCOUNTS_CUSTOMIZE}/views/${viewId}/share`, payload).then(({ result }) => result);

export const removeViewFromSharedViews = (viewId: string): Promise<string> =>
  Request.delete(`${ACCOUNTS_CUSTOMIZE}/views/shared/${viewId}`).then(({ result }) => result);

export const saveView = (view: PersistView): Promise<string> =>
  Request.post(`${ACCOUNTS_CUSTOMIZE}/views`, view).then(({ result }) => result);

export const deleteView = (viewId: string): Promise<string> =>
  Request.delete(`${ACCOUNTS_CUSTOMIZE}/views/${viewId}`).then(({ result }) => result);

export const updateView = (viewId: string, view: PersistView): Promise<string> =>
  Request.put(`${ACCOUNTS_CUSTOMIZE}/views/${viewId}`, view).then(({ result }) => result);

export const getTenantConfig = (): Promise<TenantConfig> =>
  Request.get(`${SERVICE_ROOT}/tenants/config`).then(({ result }) => new TenantConfig(result));

export const updateViewInCollection = (
  collectionId: string,
  payload: ViewCollectionUpdate,
): Promise<string> =>
  Request.put(`${ACCOUNTS_CUSTOMIZE}/view-collections/${collectionId}`, payload).then(
    ({ result }) => result,
  );

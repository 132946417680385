import * as d3 from 'd3';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { useAnalytics } from '../../../app/analytics';
import {
  analyzeConfig,
  monitorConfig,
  trendSearchConfig,
} from '../../../app/constants/page-configs';
import { Paths } from '../../../app/constants/paths';
import { windowWidthBreakpoints } from '../../../app/global-styles';
import { AnalyzeChartContext } from '../../context/analyze-chart-context';
import { AnalyzeContext } from '../../context/analyze-context';
import { useLayoutContext } from '../../context/layout-context';
import { MonitorChartContext } from '../../context/monitor-chart-context';
import { TimeSearchContext } from '../../context/time-search-context';
import { useViewContext } from '../../context/view-context';
import { useTenants } from '../../hooks/tenants';
import {
  handleTooltipMouseMove,
  handleTooltipMouseOut,
  handleTooltipMouseOver,
} from '../analyze-charts/utils/tooltips';

import { StyledTimeline } from './styles';
import { getTickFormat, getTickInterval, getTickPattern } from './tick-utils';

import { colors } from '@controlrooms/design-tokens';
import { SVGDefsSelection, TimeThreshold, ViewType } from '@controlrooms/models';
import { TimeUtils } from '@controlrooms/utils';
import { IntervalUtil } from '@controlrooms/utils/src/chart-utils/interval';

export const Timeline: React.FC = () => {
  const timelineRef = useRef(null);
  const theme = useTheme();
  const { activeModes, activeView } = useLayoutContext();

  const { viewState, viewId } = useViewContext();
  const selectedMode = activeModes[activeView];
  const { timeSelection, isTrendSearch } = viewState;
  const { selectedFolders } = useContext(AnalyzeContext);
  const { timeSearchTimeSelection } = useContext(TimeSearchContext);
  const { zoomIn: analyzeZoomIn } = useContext(AnalyzeChartContext);
  const { zoomIn: monitorZoomIn } = useContext(MonitorChartContext);

  const { currentTenant } = useTenants();
  const tenantConfig = currentTenant?.preferences;

  const { pathname } = useLocation();
  const { track } = useAnalytics();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // For now, there are two separate timezone paradigms in the app.  If that changes,
  // revisit this logic
  //TODO: Handle for time search module
  const { endTime, startTime, timezone, streamingTimeInSeconds } =
    isTrendSearch && selectedMode === ViewType.ANALYZE ? timeSearchTimeSelection : timeSelection;

  const interval = IntervalUtil.fromThresholds(
    startTime,
    endTime,
    tenantConfig?.monitorTimeThresholds as Array<TimeThreshold>,
  );

  const isAnalyze = selectedMode === ViewType.ANALYZE;

  const { timelineHeight, timelineMargin } = isAnalyze
    ? analyzeConfig
    : isTrendSearch
    ? trendSearchConfig
    : monitorConfig;

  const duration = endTime.diff(startTime, 's');

  const [streamingDisplay, setStreamingDisplay] = useState<string>(
    TimeUtils.toTimezone(dayjs(), timezone).format(getTickFormat(duration)),
  );

  useEffect(() => {
    if (streamingTimeInSeconds) {
      const tickFormat = getTickFormat(duration);
      let interval = 15000;
      if (tickFormat.includes('ss')) {
        interval = 1000;
      } else if (tickFormat.includes('mm')) {
        interval = 2500;
      }
      const intervalId = setInterval(() => {
        setStreamingDisplay(TimeUtils.toTimezone(dayjs(), timezone).format(tickFormat));
      }, interval);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [duration, endTime, streamingTimeInSeconds, timezone]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // select parent component
    const TimelineWrapper = d3.select(`#chart-timeline-${viewId}`);
    // get the width of the svg parent div for responsive
    const width = (TimelineWrapper.node() as HTMLElement)?.getBoundingClientRect().width;

    const breakpointWidth = windowWidth > windowWidthBreakpoints.mobile ? 10 : 28;

    const ctrWidth = width - timelineMargin.left - timelineMargin.right + breakpointWidth;

    const svgTimelineEl = d3
      .select(timelineRef.current)
      .attr('width', '100%')
      .attr('height', timelineHeight)
      .attr('preserveAspectRatio', 'none')
      .attr('viewBox', `0 0 ${width} 30`)
      .classed('timeline', true);

    // Clear svg content before adding new elements
    svgTimelineEl.selectAll('*').remove();

    // clear old tooltips to prevent duplicates
    TimelineWrapper.selectAll(`.tooltip-timeline-${viewId}`).remove();

    // add a tooltip for timeline
    const tooltipTimeline = TimelineWrapper.append('div').attr(
      'class',
      `tooltip-timeline tooltip-timeline-${viewId}`,
    );

    // add span for time value on timeline
    tooltipTimeline.append('span').classed(`time-value time-value-${viewId}`, true);

    // append a container for margin spacing
    const ctr = svgTimelineEl
      .append('g')
      .attr('transform', `translate(${timelineMargin.left}, 30)`)
      .attr('width', ctrWidth)
      .attr('height', timelineHeight)
      .classed('ctr-timeline', true);

    const xScale = d3
      .scaleTime()
      .domain([startTime.valueOf(), endTime.valueOf()])
      .range([0, ctrWidth]);

    // add x axis
    const xAxis = d3
      .axisTop(xScale)
      .ticks(
        windowWidth > windowWidthBreakpoints.desk
          ? getTickInterval(duration, false)
          : getTickInterval(duration, true),
      )
      .tickFormat((d) => {
        return TimeUtils.toTimezone(dayjs(d.toString()), timezone).format(getTickFormat(duration));
      });

    //  move x-axis to bottom of chart
    ctr.append('g').classed('timeline-scroll', true).call(xAxis);

    // remove values to show only on every 4th tick
    const ticks = ctr.selectAll('.tick');

    ticks.each(function (d: unknown, i: number) {
      // keep the text on last tick representing relative / NOW
      // if (i === ticks.nodes().length - 1) return;
      getTickPattern(duration, d3.select(this) as unknown as SVGDefsSelection, d as Date, i);
    });

    // Style tick line with different heights for major and minor ticks
    svgTimelineEl.selectAll('.tick > line').attr('y2', '-8');
    svgTimelineEl.selectAll('.tick.grey > line').attr('y2', '-6');

    // Style timeline if in relative mode
    if (streamingTimeInSeconds) {
      // remove last tick to replace width custom
      svgTimelineEl.selectAll('.tick:last-of-type').remove();

      const streamingTick = ctr.append('g');

      streamingTick
        .append('text')
        .attr('fill', colors.k[90])
        .attr('font-size', 13)
        .attr('class', 'streaming-tick')
        .html(streamingDisplay);

      const tickWidth = (streamingTick.node() as SVGGElement).getBoundingClientRect().width / 2;

      streamingTick.attr('transform', `translate(${ctrWidth - tickWidth}, -10)`);

      streamingTick
        .append('line')
        .attr('y1', 4)
        .attr('y2', 11)
        .attr('x1', tickWidth)
        .attr('x2', tickWidth)
        .style('stroke', colors.k[100])
        .style('stroke-width', '2px')
        .classed('tooltipLine', true);
    }

    // Monitor interactions
    matchPath(Paths.MONITOR, pathname) &&
      svgTimelineEl
        .append('rect')
        .attr('width', ctrWidth)
        .attr('height', timelineHeight)
        .attr('transform', `translate(31,0)`)
        .style('opacity', 0)
        .style('cursor', 'pointer')
        .on('dblclick', (e: MouseEvent) => {
          const center = dayjs(xScale.invert(e.offsetX - timelineMargin.left));
          monitorZoomIn({ center });
          track('Monitor Chart - Double Click on Timeline');
        })
        .on('mouseout', () =>
          // on mouse out hide line, circles and text
          handleTooltipMouseOut('monitor', viewId),
        )
        .on('mouseover', () =>
          // on mouse in show line, circles and text
          {
            handleTooltipMouseOver('monitor');
            track('Monitor Chart - Mouse Over on Timeline');
          },
        )
        .on('touchmouse mousemove', function (e) {
          handleTooltipMouseMove('monitor', e, xScale, timezone, theme, { interval });
        });

    // Analyze interactions
    const isAnalyze =
      !!matchPath(Paths.ANALYZE, pathname) || !!matchPath(Paths.DEMO_ANALYZE, pathname);
    isAnalyze &&
      svgTimelineEl
        .append('rect')
        .attr('width', ctrWidth)
        .attr('height', timelineHeight)
        .attr('transform', `translate(31,0)`)
        .style('opacity', 0)
        .style('cursor', 'pointer')
        .on('dblclick', (e: MouseEvent) => {
          const center = dayjs(xScale.invert(e.offsetX - timelineMargin.left));
          analyzeZoomIn({ center });
          track('Analyze Chart - Double Click on Timeline');
        })
        .on('mouseout', () =>
          // on mouse out hide line, circles and text
          handleTooltipMouseOut('analyze', viewId),
        )
        .on('mouseover', () =>
          // on mouse in show line, circles and text
          {
            handleTooltipMouseOver('analyze');
          },
        )
        .on('touchmouse mousemove', function (e) {
          handleTooltipMouseMove('analyze', e, xScale, timezone, theme, { interval });
        });
  }, [
    analyzeZoomIn,
    duration,
    endTime,
    interval,
    monitorZoomIn,
    pathname,
    startTime,
    streamingDisplay,
    streamingTimeInSeconds,
    theme,
    timelineHeight,
    timelineMargin.left,
    timelineMargin.right,
    timezone,
    track,
    viewId,
    windowWidth,
  ]);

  return (
    <StyledTimeline id={`chart-timeline-${viewId}`} selectedFolders={selectedFolders}>
      <svg ref={timelineRef} />
    </StyledTimeline>
  );
};

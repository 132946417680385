import dayjs from 'dayjs';

import { HiddenTag } from '../tag';
import { TimePresetKeyType, TimeRangesType } from '../time-selection';

import { ParentTag } from './folder';

import { Ensemble, Tag, TimeSelection } from '@controlrooms/models';
import { ChartMode } from '@controlrooms/web/src/app-v2/context/investigate-chart-context';

export enum ViewType {
  MONITOR = 'monitor',
  ANALYZE = 'analyze',
  INVESTIGATE = 'investigate',
}

export interface ViewCollectionUpdate {
  collection_name: string;
  add_views: string[];
  remove_views: string[];
}

export interface PersistView {
  user_id?: number;
  view_id?: string;
  view: View;
  shared?: boolean;
  ensemble_family_id?: string;
  view_name?: string;
  default_view?: boolean;
  share_type?: ViewShareType;
  description?: string;
  created_by?: string;
  view_history?: PersistView[];
}
export interface PersistCollection {
  collection_id?: string;
  collection_name: string;
  description?: string;
  view_ids: string[];
  views?: PersistView[];
  shareType?: ViewShareType;
}
export interface PatchCollection {
  collection_name: string;
  description: string;
  add_views: string[];
  remove_views: string[];
}

export enum ViewShareType {
  PRIVATE = 'Private',
  VIEWABLE = 'Viewable',
  EDITABLE = 'Editable',
  ANONYMOUS = 'Anonymous',
}

export interface ViewState {
  viewId: string;
  userId: number | undefined;
  isDefault: boolean;
  selectedMode: ViewType;
  name: string;
  description: string;
  created_by?: string;
  isHidden?: boolean;
  isDirty?: boolean;
  isDeleted?: boolean;
  tenant_id: number;
  view: TabbedView;
  timeSelection: TimeSelection;
  timeSelectionHistory: TimeSelection[];
  view_id: string;
  selectedEnsemble: Ensemble | null;
  isTrendSearch?: boolean;
  shareType?: ViewShareType;
  shared: boolean;
  // isNewDefault?: boolean;
}

export class PersistTimeSelection {
  startTime: string;
  endTime: string;
  timezone: string;
  streamingTimeInSeconds: number | undefined;
  timeRange?: TimeRangesType;
  timePreset?: TimePresetKeyType;

  constructor(
    startTime: string,
    endTime: string,
    timezone: string,
    streamingTimeInSeconds: number | undefined,
    timeRange: TimeRangesType | undefined,
    timePreset: TimePresetKeyType | undefined,
  ) {
    this.startTime = startTime;
    this.endTime = endTime;
    this.timezone = timezone;
    this.streamingTimeInSeconds = streamingTimeInSeconds;
    this.timeRange = timeRange;
    this.timePreset = timePreset;
  }

  public static ofTimeSelection(init: TimeSelection) {
    return new PersistTimeSelection(
      init.startTime.toISOString(),
      init.endTime.toISOString(),
      init.timezone,
      init.streamingTimeInSeconds,
      init.timeRange,
      init.timePreset,
    );
  }

  public static toTimeSelection(init: PersistTimeSelection) {
    return new TimeSelection(
      dayjs(init.startTime),
      dayjs(init.endTime),
      init.timezone,
      init.streamingTimeInSeconds,
      init.timeRange,
      init.timePreset,
    );
  }
}

export interface View {
  pinnedTags?: ParentTag[];
  hiddenTags?: HiddenTag[];
  type: ViewType;
  timeSelection: PersistTimeSelection;
  selectedFolders: number[];
  showLimits?: boolean;
  ensemble_family_id?: string;
  showAnalyzeLimits?: boolean;
  showMonitorLimits?: boolean;
  showInvestigateLimits?: boolean;
  severityFilter?: number;
  selectedTags?: string[] | { [folder: number]: Tag[] };
  showAnomalies?: boolean;
  chartMode?: ChartMode;
  groupByUom?: boolean;
  name: string;
  description?: string;
}

export interface MonitorView extends View {
  type: ViewType.MONITOR;
  severityFilter?: number;
  showMonitorLimits?: boolean;
}

export interface AnalyzeView extends View {
  type: ViewType.ANALYZE;
  pinnedTags: ParentTag[];
  hiddenTags?: HiddenTag[];
  showAnalyzeLimits?: boolean;
}
export interface InvestigateView extends View {
  type: ViewType.INVESTIGATE;
  selectedTags: string[];
}

interface SavedView {
  name: string;
  id?: string;
}

export interface DeleteView {
  view_id: string;
  user_id: number;
}

export interface TrendSearchData {
  id?: number;
  startTime: string;
  endTime: string;
  folder: number;
  tag: Tag;
  tagName: string;
  timeRange: string;
}

export type InitialStateType = Record<string, boolean>;

export interface MonitorModeView {
  selectedFolders: number[];
  ensemble_family_id?: string;
  selectedTags: string[];
  severityFilter?: number;
  showLimits?: boolean;
  showMonitorLimits?: boolean;
  selectedLabelTypes?: InitialStateType;
}

interface AnalyzeModeView {
  selectedFolders: number[];
  ensemble_family_id?: string;
  selectedTags: string[];
  severityFilter?: number;
  showLimits?: boolean;
  showAnalyzeLimits?: boolean;
  pinnedTags?: ParentTag[];
  hiddenTags?: HiddenTag[];
  trendSearchData?: TrendSearchData;
}

interface InvestigateModeView {
  ensemble_family_id?: string;
  selectedFolders: number[];
  selectedTags: { [folder: number]: Tag[] };
  showLimits?: boolean;
  showInvestigateLimits?: boolean;
  showAnomalies?: boolean;
  organize?: ChartMode;
  severityFilter?: number;
  groupByUom: boolean;
}

/**
Monitor:
type: ViewType.MONITOR;
severityFilter?: number;
showMonitorLimits?: boolean;
pinnedTags?: ParentTag[];
hiddenTags?: HiddenTag[];
type: ViewType;
timeSelection: PersistTimeSelection;
selectedFolders: number[];
ensemble_family_id?: string;
name: string;
id: string;

ANALYZE:
type: ViewType.ANALYZE;
  showAnalyzeLimits?: boolean;
  pinnedTags?: ParentTag[];
  hiddenTags?: HiddenTag[];
  timeSelection: PersistTimeSelection;
  selectedFolders: number[];
  ensemble_family_id?: string;
name: string;
id: string;
**/

export type TabbedView = {
  [ViewType.MONITOR]: MonitorModeView;
  [ViewType.ANALYZE]: AnalyzeModeView;
  [ViewType.INVESTIGATE]: InvestigateModeView;
};

export interface MonitorSavedView extends MonitorView, SavedView {}
export interface AnalyzeSavedView extends AnalyzeView, SavedView {}
export interface InvestigateSavedView extends InvestigateView, SavedView {}

import { Dispatch, SetStateAction, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { matchPath, useLocation } from 'react-router-dom';

import { useAnalytics } from '../../../../app/analytics';
import {
  GroupOptions,
  PresetOptions,
  getTimePresetMapKey,
} from '../../../../app/constants/time-selection-form';
import { useViewContext } from '../../../context/view-context';
import { Chevron } from '../../time-display/styles';

import { RangeInputMask } from './range-input-mask';
import { Button, ButtonGroup, ChevronContainer, ListContainer } from './styles';

import { Li, OptionsGroup, RadioButton, Ul } from '@controlrooms/components';
import {
  DateFormats,
  Fields,
  TimePresetKeyType,
  TimePresetKeys,
  TimeRanges,
  TimeRangesType,
} from '@controlrooms/models';
import { CallBack, TimeUtils } from '@controlrooms/utils';

interface Props {
  isSelectOpen: boolean;
  setIsSelectOpen: Dispatch<SetStateAction<boolean>>;
}

interface TimeRangeProps extends Props {
  disabled?: boolean;
  value: string;
  onSelect: CallBack;
  dataTestId?: string;
}

export const TimeRangeDropdown: React.FC<TimeRangeProps> = ({
  setIsSelectOpen,
  isSelectOpen,
  disabled,
  value,
  onSelect,
  dataTestId,
}) => {
  const { pathname } = useLocation();

  const triggerCallback = (value: TimePresetKeyType) => {
    onSelect && onSelect(value);
    setIsSelectOpen(false);
  };
  const selectMapper = () =>
    GroupOptions.map((groupOption) => (
      <div key={groupOption.label}>
        <OptionsGroup>{groupOption.label.toUpperCase()}</OptionsGroup>
        <ListContainer>
          {groupOption.options
            .filter(({ paths }) => paths.some((path) => matchPath(path, pathname)))
            .map(
              ({ value, label }) =>
                label.length > 0 && (
                  <Li
                    data-testid={`${
                      dataTestId ? `preset-${label}-${dataTestId}` : `preset-${label}`
                    }`}
                    key={label}
                    onClick={() => triggerCallback(value)}
                  >
                    {label}
                  </Li>
                ),
            )}
        </ListContainer>
      </div>
    ));
  return (
    <OptionsGroup isInactive={disabled} className="time-range-options-group">
      <Button
        data-testid="timeframe-preset-list"
        type="button"
        aria-haspopup="listbox"
        aria-expanded={isSelectOpen}
        disabled={disabled}
        onClick={(evt) => {
          evt.stopPropagation();
          setIsSelectOpen(!isSelectOpen);
        }}
      >
        <ButtonGroup>
          <span>
            {value ? PresetOptions.find((opt) => opt.value === value)?.label : 'Select a Time'}
          </span>
          <ChevronContainer>
            <Chevron />
          </ChevronContainer>
        </ButtonGroup>
      </Button>
      <Ul isOpen={isSelectOpen}>{selectMapper()}</Ul>
    </OptionsGroup>
  );
};

export const TimeRangeSelector: React.FC<Props> = ({ setIsSelectOpen, isSelectOpen }) => {
  const { pathname } = useLocation();
  const { track } = useAnalytics();
  const { viewState, updateTimeSelection } = useViewContext();
  const { timeSelection } = viewState;

  const { startTime, endTime, streamingTimeInSeconds, timeRange, timezone } = timeSelection;

  const { control, clearErrors, setValue } = useFormContext();

  const setPreset = (value: TimePresetKeyType) => {
    setValue(Fields.PRESET, value);
    track('Timeframe Changes - Preset', {
      presetSelected: value,
      currentPage: pathname,
    });

    updateTimeSelection({ ...timeSelection, nowSelected: false }, false);
  };

  useEffect(() => {
    if (timeRange === TimeRanges.PRESET) {
      setValue(Fields.TIMERANGE, timeRange);
      setValue(
        Fields.PRESET,
        getTimePresetMapKey(streamingTimeInSeconds) ?? TimePresetKeys.LAST_TWELVE_HOURS,
      );
    }
    if (timeRange === TimeRanges.CUSTOM) {
      setValue(
        Fields.CUSTOM_TIMEFRAME,
        `${TimeUtils.toTimezone(startTime, timezone).format(
          DateFormats.DATETIME_ALT,
        )} - ${TimeUtils.toTimezone(endTime, timezone).format(DateFormats.DATETIME_ALT)}`,
      );
      setValue(Fields.TIMERANGE, [
        TimeUtils.toTimezone(startTime, timezone),
        TimeUtils.toTimezone(endTime, timezone),
      ]);
    }
    // Only update on mount (each time it is opened), so that streaming updates don't affect the time selector
    // eslint-disable-next-line
  }, [setValue]);

  const handleRadioButtonChange = (value: TimeRangesType) => {
    setValue(Fields.TIMERANGE, value);
    track('Timeframe Changes', {
      selectedOption: value,
      currentPage: pathname,
    });
    if (value !== TimeRanges.CUSTOM) {
      clearErrors(Fields.CUSTOM_TIMEFRAME);
    }
    if (value !== TimeRanges.PRESET) {
      clearErrors(Fields.TIMERANGE);
    }
  };

  return (
    <Controller
      control={control}
      defaultValue={TimeRanges.PRESET}
      name={Fields.TIMERANGE}
      render={({ field: { value: timerangeValue } }) => (
        <>
          <RadioButton
            data-testid="preset-radio-button"
            id={TimeRanges.PRESET}
            value={TimeRanges.PRESET}
            checked={timerangeValue === TimeRanges.PRESET}
            onChange={(e) => {
              const id: TimeRangesType = e.target.id as TimeRangesType;
              handleRadioButtonChange(id);
            }}
          >
            Preset
            <div
              onClick={() => {
                handleRadioButtonChange(TimeRanges.PRESET);
              }}
            >
              <Controller
                control={control}
                name={Fields.PRESET}
                render={({ field: { value: presetValue } }) => (
                  <TimeRangeDropdown
                    isSelectOpen={isSelectOpen}
                    disabled={timerangeValue !== TimeRanges.PRESET}
                    value={presetValue}
                    setIsSelectOpen={setIsSelectOpen}
                    onSelect={setPreset}
                  />
                )}
              />
            </div>
          </RadioButton>

          <RadioButton
            data-testid="custom-timeframe-radio-button"
            id={TimeRanges.CUSTOM}
            value={TimeRanges.CUSTOM}
            checked={timerangeValue === TimeRanges.CUSTOM || Array.isArray(timerangeValue)}
            onChange={(e) => {
              const id: TimeRangesType = e.target.id as TimeRangesType;
              handleRadioButtonChange(id);
            }}
          >
            Custom Timeframe
            <RangeInputMask disabled={timerangeValue !== TimeRanges.CUSTOM} />
          </RadioButton>
        </>
      )}
    />
  );
};

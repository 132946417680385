import { ChartTheme } from 'styled-components';

import { GRADIENTS } from '@controlrooms/constants';
import { colors } from '@controlrooms/design-tokens';

export const chartDarkTheme: ChartTheme = {
  chartSelectedBgColor: colors.focus.dark[6],
  chartSelectedBorderColor: colors.focus.dark[4],
  streamingTickColor: colors.k[100],
  actionBorderColor: colors.content['neutral-6'],
  backgroundColor: colors.k[5],
  boxShadow: colors.elevation.dark[3],
  chartBackgroundColor: colors.k[0],
  chartStrokeColor: colors.k[10],
  lineHighlight: colors.k[100],
  lineColor: colors.content['neutral-2'],
  labelColor: colors.k[100],
  areaFillColor: colors.content['neutral-6'],
  highlightColor: '#80F7FF',
  frequentLineColor: colors.datavis['purple-2'],
  limitLineColor: colors.k[40],
  limitIndicator: colors.datavis['pink-2'],
  limitIndicatorDefault: colors.k[30],
  anomalyLines: colors.anomalies.dark[3],
  anomalyLimitLines: colors.datavis['pink-2'],
  anomalyLightlyShadedArea: 'rgba(255, 113, 113, 0.30)',
  anomalyDarkerShadedArea: 'rgba(255, 113, 113, 0.50)',
  groupTitleColor: colors.k[100],
  gradients: {
    [GRADIENTS.tooltipLine]: {
      color1: '#579EA3',
      color2: '#2E4547',
    },
  },
  yAxisFill: colors.k[40],
  selection: {
    backgroundColor: 'rgba(66, 114, 117, 0.55)', //Opacity doesn't match figma value. Bumped up to match visually.
    borderColor: colors.focus.dark[2],
  },
  tooltip: {
    backgroundColor: colors.container['b-g-8'],
    backgroundColorActive: colors.container['b-g-2'],
    textColor: colors.content['neutral-1'],
    textColorActive: colors.content['neutral-6'],
    minMaxColor: colors.content['neutral-2'],
    minMaxColorActive: colors.content['neutral-5'],
    minMaxIconColor: colors.content['neutral-4'],
    minMaxIconColorActive: colors.content['neutral-4'],
    uomColor: colors.content['neutral-2'],
    uomColorActive: colors.content['neutral-4'],
    frequentColor: colors.datavis['purple-3'],
    warningColor: colors.content['warning-1'],
    limitColor: colors.datavis['pink-2'],
  },
  headerBackgroundColor: colors.k[0],
  headerBackgroundGradient: {
    color1: colors.k[0],
    color2: colors.k[0],
  },
  timelineBackgroundGradient: {
    color1: colors.k[0],
    color2: colors.k[0],
  },
  noDataScreenGradient: {
    color1: '#0a1415',
    color2: '#080808',
  },
  tagTitle: {
    backgroundColor: colors.k[10],
    textColor: colors.k[100],
  },
  loadingTextColor: colors.k[100],
  stringChartBackground: {
    color1: 'rgba(115, 117, 102, 1)',
    color2: 'rgba(70, 71, 64, 1)',
  },
};

export const chartLightTheme: ChartTheme = {
  chartSelectedBgColor: colors.focus.light[5],
  chartSelectedBorderColor: colors.focus.light[4],
  streamingTickColor: colors.k[20],
  actionBorderColor: colors.container['b-g-5'],
  backgroundColor: colors.container['b-g-3'],
  boxShadow: colors.elevation.light[3],
  chartBackgroundColor: colors.k[100],
  chartStrokeColor: colors.container['b-g-5'],
  lineHighlight: colors.k[0],
  lineColor: colors.content['neutral-4'],
  labelColor: colors.k[0],
  areaFillColor: colors.k[90],
  highlightColor: '#80F7FF',
  frequentLineColor: colors.datavis['purple-1'],
  limitLineColor: colors.k[65],
  limitIndicator: colors.datavis['pink-2'],
  limitIndicatorDefault: colors.k[65],
  anomalyLines: colors.anomalies.light[4],
  anomalyLimitLines: colors.datavis['pink-2'],
  anomalyLightlyShadedArea: 'rgba(255, 113, 113, 0.30)',
  anomalyDarkerShadedArea: 'rgba(255, 113, 113, 0.50)',
  groupTitleColor: colors.k[0],
  gradients: {
    [GRADIENTS.tooltipLine]: {
      color1: '#579EA3',
      color2: '#2E4547',
    },
  },
  yAxisFill: colors.k[40],
  selection: {
    backgroundColor: '#427275',
    borderColor: colors.focus.dark[4],
  },
  tooltip: {
    backgroundColor: colors.container['b-g-2'],
    backgroundColorActive: colors.container['b-g-8'],
    textColor: colors.content['neutral-6'],
    textColorActive: colors.content['neutral-1'],
    minMaxColor: colors.content['neutral-5'],
    minMaxColorActive: colors.content['neutral-2'],
    minMaxIconColor: colors.content['neutral-4'],
    minMaxIconColorActive: colors.content['neutral-4'],
    uomColor: colors.content['neutral-4'],
    uomColorActive: colors.content['neutral-2'],
    frequentColor: colors.datavis['purple-3'],
    warningColor: colors.content['warning-1'],
    limitColor: colors.datavis['pink-2'],
  },
  headerBackgroundColor: '#E6E3E1',
  noDataScreenGradient: {
    color1: '#E6E3E1',
    color2: '#DEE7E9',
  },
  headerBackgroundGradient: {
    color1: colors.container['b-g-3'],
    color2: '#D1D9D8',
  },
  timelineBackgroundGradient: {
    color1: '#E6E3E1',
    color2: '#D1D9D8',
  },
  tagTitle: {
    backgroundColor: colors.k[10],
    textColor: colors.k[20],
  },
  loadingTextColor: colors.k[0],
  stringChartBackground: {
    color1: 'rgba(115, 117, 102, 1)',
    color2: 'rgba(70, 71, 64, 1)',
  },
};

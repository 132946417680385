import classNames from 'classnames';
import React, { useState, useRef, useEffect } from 'react';

import { StyledCollapsible, CollapsibleBar, CollapsibleContent } from './styles';

import { Checkbox, Icon } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';

interface Props {
  open: boolean;
  description: string | undefined;
  name: string | undefined;
  className: string;
  isChecked: boolean;
  handleCheckboxCheck: () => void;
  onClick: () => void;
  hasChildren: boolean;
}

export const Collapsible: React.FC<Props> = ({
  open,
  children,
  description,
  name,
  className,
  isChecked,
  handleCheckboxCheck,
  hasChildren,
  onClick,
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const [height, setHeight] = useState<number | undefined>();
  const ref = useRef<HTMLDivElement>(null);

  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    if (isOpen) {
      setHeight(ref?.current?.getBoundingClientRect().height);
    } else {
      setHeight(0);
    }
  }, [isOpen]);

  // Resize collapsible on window resize
  useEffect(() => {
    window.addEventListener('resize', () =>
      setHeight(ref?.current?.getBoundingClientRect().height),
    );

    return window.removeEventListener('resize', () =>
      setHeight(ref?.current?.getBoundingClientRect().height),
    );
  }, []);

  return (
    <StyledCollapsible className={className} onClick={onClick}>
      <CollapsibleBar
        data-testid="collapsible-bar"
        open={isOpen}
        type="button"
        className={classNames({
          selected: isChecked,
        })}
      >
        <div className="sidebar-collapse">
          <Icon className="drag-handle" name={ICONS.RowDragHandle} width="6" height="10" />
          <Checkbox
            className="header-checkbox"
            checked={isChecked}
            onChange={handleCheckboxCheck}
          />

          <div className="colapsbtn" onClick={handleFilterOpening}>
            {hasChildren && (
              <div className="arrow-icon">
                <Icon name={ICONS.Arrow} width="8" height="7" />
              </div>
            )}
          </div>
          <span className="title" onClick={handleFilterOpening}>
            {name}
          </span>
          {description && name && <span>:&nbsp;</span>}
          <span className="description" onClick={handleFilterOpening}>
            {description}
          </span>
        </div>
        <div className="heatmap-collapse" onClick={handleFilterOpening}></div>
      </CollapsibleBar>
      <CollapsibleContent data-testid="collapsible-content" open={isOpen} style={{ height }}>
        <div ref={ref}>{children}</div>
      </CollapsibleContent>
    </StyledCollapsible>
  );
};

export default Collapsible;

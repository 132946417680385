import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import { useAnalytics } from '../../analytics';
import { AppContext } from '../../context/app-context';
import { truncate } from '../../utils/text';
import {
  NotificationData,
  NotificationSource,
} from '../header/banners/notifications/notification-banner';
import {
  UploadInstructions as UploadInstructionsContent,
  UploadInstructionsFooter,
} from '../upload-instructions-modal';

import { Dropzone } from './components/dropzone';
import { FileUploadContext } from './file-upload-context';
import {
  ModalBodyContainer,
  ModalWrapper,
  StyledProgress,
  UploaderContainer,
  UploadInstructionsContainer,
  UploadModalHeader,
} from './file-upload-styles';
import { MyUploads } from './my-uploads';

import {
  Button,
  Icon,
  Modal,
  ModalConfirmationBanner,
  ModalContents,
  ModalOpenButton,
} from '@controlrooms/components';
import { ConfirmationBannerType } from '@controlrooms/components/src/modal/modal';
import { ICONS } from '@controlrooms/constants';

export const BannerDismissButton = styled.button`
  background: none;
  border: none;

  .close-icon {
    margin-right: auto;
  }
  cursor: pointer;
  font-size: 11px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 100;
  float: right;
  position: relative;
  top: 5px;
  &:hover {
    cursor: pointer;
  }
`;

export const UploadModal: React.FC = () => <UploadModalContents />;

const UploadModalContents: React.FC = () => {
  const { files, averageProgress, tenantName } = useContext(FileUploadContext);
  const { notification } = useContext(AppContext);

  const [confirmationType, setConfirmationBannerType] = useState<null | ConfirmationBannerType>(
    null,
  );

  const getHeaderText = () => {
    const notificationData = notification as NotificationData;
    if (files.size === 0) {
      return 'Get started by adding some files';
    }
    // TODO handle error state
    if (Array.from(files.values()).find((v) => v?.progress !== null && v?.progress < 100)) {
      return `Data upload for ${truncate(tenantName, 25)} will be ready in a few minutes`;
    }
    if (notificationData?.source === NotificationSource.FILE_UPLOAD.toUpperCase()) {
      return notificationData?.payload?.message;
    }
    return `Data upload for ${truncate(tenantName, 25)} is complete`;
  };

  return (
    <ModalWrapper>
      {confirmationType && (
        <ModalConfirmationBanner data-testid="confirmation-banner" type={confirmationType}>
          {confirmationType === ConfirmationBannerType.SUCCESS
            ? 'Data successfully deleted'
            : ' Something bad happened'}
          <BannerDismissButton onClick={() => setConfirmationBannerType(null)}>
            <Icon name={ICONS.Close} width="10" height="10" />
          </BannerDismissButton>
        </ModalConfirmationBanner>
      )}
      <ModalBodyContainer>
        <UploadModalHeader data-testid="plant-upload-complete">{getHeaderText()}</UploadModalHeader>
        {files.size > 0 && <StyledProgress value={averageProgress} max={100} />}
        <UploaderContainer>
          <Dropzone />
          <MyUploads onDeleteStatusChange={setConfirmationBannerType} />
        </UploaderContainer>
      </ModalBodyContainer>
    </ModalWrapper>
  );
};

export const UploadInstructions: React.FC = () => {
  const { track } = useAnalytics();
  return (
    <UploadInstructionsContainer>
      <Modal>
        <ModalOpenButton>
          <Button
            data-testid="upload-instructions"
            buttonType="text"
            buttonSize="small"
            iconName="help"
            onClick={() =>
              track('Upload Instructions', {
                uploadInstructionsHelp: 'clicked',
              })
            }
          >
            Upload Instructions
          </Button>
        </ModalOpenButton>
        <ModalContents
          title="Upload Instructions"
          styles={{ content: { maxWidth: '900px' } }}
          footerPrompt={<UploadInstructionsFooter />}
          secondary
        >
          <UploadInstructionsContent />
        </ModalContents>
      </Modal>
    </UploadInstructionsContainer>
  );
};

export const FooterInfo: React.FC = () => {
  return <div>You will be notified by email when your data is ready to view</div>;
};

import { useCallback, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';

import { useUserId } from '../../../app/hooks/accounts';
import { useDeleteCollection, useGetCollection, usePatchCollection } from '../../../app/hooks/view';
import { useLayoutContext } from '../../context/layout-context';

import { SaveCollectionModal } from './SaveCollectionModal';
import { ShareCollectionLinkModal } from './ShareCollectionLinkModal';
import { Menu, Tab } from './TabbedContainer';
import { ViewTooltipComponent } from './ViewTooltipComponent';

import { Icon, Tooltip } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { colors } from '@controlrooms/design-tokens';
import { useClickOutside } from '@controlrooms/hooks';

export const CollectionTab = () => {
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);

  const {
    viewIds,
    setViewIds,
    savedViewIds,
    setSavedViewIds,
    setActiveView,
    viewCollection,
    setViewCollection,
  } = useLayoutContext();
  const subMenuRef = useRef<HTMLDivElement | null>(null);
  useClickOutside(subMenuRef, () => setOpenSubMenu(false));
  const currentUserId = useUserId();
  const queryClient = useQueryClient();

  const [showManageCollectionModal, setShowManageCollectionModal] = useState(false);

  const { mutateAsync: patchCollection } = usePatchCollection();
  const { mutateAsync: deletePersistedView } = useDeleteCollection();
  const { data: currentCollection } = useGetCollection(viewCollection.collectionId as string);

  const handleCloseAll = useCallback(() => {
    const currentCollectionViewIds =
      currentCollection?.views?.map((v) => v.view_id as string) || [];
    currentCollectionViewIds?.forEach((id) => {
      if (id !== 'default') sessionStorage.removeItem(id);
    });
    const _viewIds = viewIds.filter((id) => !currentCollectionViewIds.includes(id));
    const _savedViewIds = savedViewIds.filter((id) => !currentCollectionViewIds.includes(id));
    setViewIds(_viewIds);
    setSavedViewIds(_savedViewIds);
    setViewCollection({
      name: '',
      viewsIds: [],
      views: [],
    });
    setActiveView('default');
  }, [
    currentCollection?.views,
    setActiveView,
    setViewCollection,
    setViewIds,
    viewIds,
    savedViewIds,
    setSavedViewIds,
  ]);

  const handlePatch = useCallback(async () => {
    const collection_id = viewCollection.collectionId;
    const nonDefaultViewIds = viewIds.filter((id) => id !== 'default');
    const currentCollectionViewIds =
      currentCollection?.views?.map((v) => v.view_id as string) || [];

    const newViewIds = nonDefaultViewIds.filter((id) => !currentCollectionViewIds.includes(id));
    const deletedViewIds = currentCollectionViewIds.filter((id) => !nonDefaultViewIds.includes(id));
    try {
      if (collection_id) {
        try {
          await patchCollection({
            collection_id: collection_id,
            collection: {
              collection_name: viewCollection.name || '',
              description: viewCollection.description || '',
              add_views: newViewIds,
              remove_views: deletedViewIds,
            },
          });
          queryClient.refetchQueries(['user-collections', currentUserId]);
          queryClient.refetchQueries(['collection', collection_id]);
          setViewCollection((prev) => ({ ...prev, isDirty: false }));
        } catch (error) {
          console.error(error);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setOpenSubMenu(false);
    }
  }, [
    currentCollection?.views,
    currentUserId,
    patchCollection,
    queryClient,
    setViewCollection,
    viewCollection.collectionId,
    viewCollection.description,
    viewCollection.name,
    viewIds,
  ]);

  const handleDelete = useCallback(async () => {
    const collection_id = viewCollection.collectionId;
    if (collection_id) await deletePersistedView(collection_id);
    handleCloseAll();
  }, [deletePersistedView, handleCloseAll, viewCollection.collectionId]);

  if (!viewCollection.name || viewCollection.name === '') {
    return null;
  }

  return (
    <Tab isActive={false}>
      <div className="tab-content" style={{ paddingLeft: '10px' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <Icon name={ICONS.Folder} width="15" height="20" />
          <Tooltip
            label={
              <ViewTooltipComponent
                name={viewCollection.name}
                description={viewCollection.description || ''}
                createdBy={viewCollection.createdBy || ''}
              />
            }
            theme={'dark'}
          >
            <span className="label">
              {viewCollection.isDirty && (
                <span style={{ color: colors.datavis['pink-2'], marginRight: '5px' }}>*</span>
              )}
              {viewCollection.name}
            </span>
          </Tooltip>
        </div>
        <div ref={subMenuRef}>
          <Icon
            className="subMenu-link"
            name={ICONS.Chevron}
            width="10"
            color="#A0A38D"
            onClick={(e) => {
              e.stopPropagation(); // Prevent tab selection when clicking submenu link
              setOpenSubMenu((prev) => !prev);
            }}
          />
          {openSubMenu && (
            <Menu>
              <ul>
                <li
                  className={`option ${
                    !viewCollection.isDirty || viewCollection.viewsIds.length === 0
                      ? 'disabled'
                      : ''
                  }`}
                  onClick={() => {
                    if (!viewCollection.isDirty || viewCollection.viewsIds.length === 0) return;
                    handlePatch();
                  }}
                >
                  Save Changes
                </li>
                <li
                  className={`option`}
                  onClick={() => {
                    setShowManageCollectionModal(true);
                  }}
                >
                  Manage
                </li>
                <li
                  className="option"
                  onClick={() => {
                    handleDelete();
                    setOpenSubMenu(false);
                  }}
                >
                  Delete
                </li>
                <li className="separator"></li>
                <li
                  className="option"
                  onClick={() => {
                    handleCloseAll();
                    setOpenSubMenu(false);
                    return;
                  }}
                >
                  Close
                </li>
                {!!viewCollection?.viewsIds.length && (
                  <li
                    className="option"
                    onClick={() => {
                      setShowShareModal(true);
                      setOpenSubMenu(false);
                      return;
                    }}
                  >
                    Share
                  </li>
                )}
              </ul>
            </Menu>
          )}
        </div>
      </div>
      {showShareModal && !!viewCollection?.viewsIds.length && (
        <ShareCollectionLinkModal
          onClose={() => {
            setShowShareModal(false);
          }}
        />
      )}
      {showManageCollectionModal ? (
        <SaveCollectionModal
          onClose={() => setShowManageCollectionModal(false)}
          isUpdateCollection={true}
        />
      ) : null}
    </Tab>
  );
};

import styled from 'styled-components';

import { ButtonGroup } from '@controlrooms/components';
import { colors } from '@controlrooms/design-tokens';

export const DemographicContainer = styled.div`
  margin: 16px 50px;

  .field {
    width: 40%;
    margin-right: 12px;
    margin-bottom: 8px;
    display: inline-block;
  }
  .field.email {
    width: calc(60% + 12px);
  }

  label {
    color: ${({ theme }) => theme.userPreferences.textColor};
    font-size: 13px;
    font-family: 'Open Sans', sans-serif;
    line-height: 19px;
    font-weight: 300;
  }

  div > input {
    color: ${({ theme }) => theme.userPreferences.textColor};
  }

  .name-form {
    display: flex;
  }

  .password-form {
    display: flex;
  }
`;

export const ProfileButtonGroup = styled(ButtonGroup)`
  align-items: center;
  margin-bottom: 16px;

  .update-success {
    svg {
      width: 14px;
      height: 11px;
      path {
        fill: ${colors.datavis['green-1']};
      }
    }
  }
`;

import { Dayjs } from 'dayjs';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { InvestigateContext } from '../../app-v2/context/investigate-context';
import { useViewContext } from '../../app-v2/context/view-context';
import { EnsembleContext } from '../context/ensemble-context';
import { getEnsembleIdByReference } from '../services/alerts';
import {
  fetchAllHiddenTags,
  hideTag,
  resampledTagsMinMax,
  resampledTagsMinMaxString,
  // searchTagsMinMax,
  timeSearch,
  unHideTag,
  getEnsembleModels,
  resampledTagsMinMaxDiscrete,
} from '../services/tag';

import { ANOMALIES_KEY } from './anomalies';
import { ANOMALOUS_FOLDERS_KEY, FOLDER_KEY } from './folders';
import { useTenants } from './tenants';

import { usePrevious } from '@controlrooms/hooks';
import {
  AnomalyRange,
  CRTagData,
  HiddenTag,
  Mode,
  ResampleTagsMinMaxResult,
  TimeSeries,
  TimeThreshold,
  TagResampleMinMaxPayload,
  Ensemble,
} from '@controlrooms/models';
import { ObjectUtils } from '@controlrooms/utils';
import { IntervalUtil } from '@controlrooms/utils/src/chart-utils/interval';

export const HIDDEN_TAG_KEY = 'HIDDEN_TAGS';

interface RangeParams {
  startTime: Dayjs | undefined;
  endTime: Dayjs | undefined;
}

interface FolderTag {
  tag_name: string;
  folder: number;
}

interface TagParams extends RangeParams {
  streamingTimeInSeconds?: number | undefined;
  folder?: number;
}

interface TagMinMaxParams extends TagParams {
  tag: string;
}
interface FolderTagMinMaxParams extends RangeParams {
  streamingTimeInSeconds?: number | undefined;
  folderTags: FolderTag[];
}

interface TagMinMaxStringParams extends TagParams {
  tag: string[];
}

interface MultiTagMinMaxParams extends TagParams {
  tag: string | string[];
}

interface MultiTagMinMaxStringParams extends TagParams {
  tag: string | string[];
}

// interface TagSearchParams extends TagParams {
//   searchKeys: string[] | undefined;
// }

interface TagResampleParams extends TagParams {
  tags: string[] | undefined;
}

export const TAG_DATA_KEY = 'tag_data';
export const FOLDER_DATA_KEY = 'folderData';
export const FOLDER_STRING_DATA_KEY = 'folderStringData';
export const FOLDER_DISCRETE_DATA_KEY = 'folderDiscreteData';
export const NUMERIC_TAGS_DATA_KEY = 'numeric-tags';
export const STRING_TAGS_DATA_KEY = 'string-tags';
export const DISCRETE_TAGS_DATA_KEY = 'discrete-tags';

export const useFolderTagMinMax = (
  { startTime, endTime, streamingTimeInSeconds, folderTags }: FolderTagMinMaxParams,
  enabled = true,
) => {
  const { currentTenant } = useTenants();
  const {
    viewState: { selectedEnsemble: viewEnsemble },
    getQueryKeyWithViewId,
  } = useViewContext();
  const { selectedEnsemble: appEnsemble } = useContext(EnsembleContext);
  const selectedEnsemble = viewEnsemble ?? appEnsemble;
  const tenantConfig = currentTenant?.preferences;
  const isStreaming = Boolean(streamingTimeInSeconds);
  const formattedStartTime = startTime?.toISOString() ?? undefined;
  const formattedEndTime = endTime?.toISOString() ?? undefined;
  const { setTagChartData } = useContext(InvestigateContext);

  let interval = IntervalUtil.DEFAULT_INTERVAL;
  if (startTime && endTime) {
    interval = IntervalUtil.fromThresholds(
      startTime,
      endTime,
      tenantConfig?.analyzeTimeThresholds as Array<TimeThreshold>,
    );
  }

  const refetchInterval: number | false = isStreaming
    ? IntervalUtil.refetchMsFromInterval(interval)
    : false;

  const queries = folderTags.map(({ folder, tag_name }) => {
    const payload = {
      folder: Number(folder),
      tag_names: [tag_name],
      interval: interval,
    } as unknown as TagResampleMinMaxPayload;

    if (isStreaming) {
      payload.preset = streamingTimeInSeconds;
    } else {
      payload.start_time = formattedStartTime;
      payload.end_time = formattedEndTime;
      payload.start_time = formattedStartTime;
    }
    payload.ensemble_family_id = selectedEnsemble?.family_id || '';
    return {
      queryKey: [
        getQueryKeyWithViewId(FOLDER_DATA_KEY),
        getQueryKeyWithViewId(NUMERIC_TAGS_DATA_KEY),
        folder,
        tag_name,
        selectedEnsemble?.family_id,
      ],
      select: (body: ResampleTagsMinMaxResult) => body.result,
      queryFn: () => resampledTagsMinMax(payload, selectedEnsemble?.family_id as string),
      refetchInterval: refetchInterval, // 60 seconds
      retry: isStreaming ? 1 : undefined,
      cacheTime: isStreaming ? interval * 4000 : 4 * 60 * 60 * 1000, // 4 hour,
      staleTime: isStreaming ? interval * 1000 : 60 * 60 * 1000, // 1 hour,
      enabled:
        enabled &&
        ObjectUtils.areNonNull(payload as unknown as Record<string, unknown>, Object.keys(payload)),
      onSuccess: (newData: CRTagData<TimeSeries, Mode, AnomalyRange>[]) => {
        newData.map((tagData) => {
          setTagChartData((prevState) => {
            return {
              ...prevState,
              ...{
                [tagData.tag]: tagData,
              },
            };
          });
        });
      },
    };
  });

  return useQueries(queries);
};
export const useFolderStringTagMinMax = (
  { startTime, endTime, streamingTimeInSeconds, folderTags }: FolderTagMinMaxParams,
  enabled = true,
) => {
  const { currentTenant } = useTenants();
  const {
    viewState: { selectedEnsemble: viewEnsemble },
    getQueryKeyWithViewId,
  } = useViewContext();
  const { selectedEnsemble: appEnsemble } = useContext(EnsembleContext);
  const selectedEnsemble = viewEnsemble ?? appEnsemble;
  const tenantConfig = currentTenant?.preferences;
  const isStreaming = Boolean(streamingTimeInSeconds);
  const formattedStartTime = startTime?.toISOString() ?? undefined;
  const formattedEndTime = endTime?.toISOString() ?? undefined;
  const { setTagChartData } = useContext(InvestigateContext);

  let interval = IntervalUtil.DEFAULT_INTERVAL;
  if (startTime && endTime) {
    interval = IntervalUtil.fromThresholds(
      startTime,
      endTime,
      tenantConfig?.analyzeTimeThresholds as Array<TimeThreshold>,
    );
  }

  const refetchInterval: number | false = isStreaming
    ? IntervalUtil.refetchMsFromInterval(interval)
    : false;

  const queries = folderTags.map(({ folder, tag_name }) => {
    const payload = {
      folder: Number(folder),
      tag_names: [tag_name],
      interval: interval,
    } as unknown as TagResampleMinMaxPayload;

    if (isStreaming) {
      payload.preset = streamingTimeInSeconds;
    } else {
      payload.start_time = formattedStartTime;
      payload.end_time = formattedEndTime;
      payload.start_time = formattedStartTime;
    }
    payload.ensemble_family_id = selectedEnsemble?.family_id || '';
    return {
      queryKey: [
        getQueryKeyWithViewId(FOLDER_STRING_DATA_KEY),
        getQueryKeyWithViewId(STRING_TAGS_DATA_KEY),
        folder,
        tag_name,
        selectedEnsemble?.family_id,
      ],
      select: (body: ResampleTagsMinMaxResult) => body.result,
      queryFn: () => resampledTagsMinMaxString(payload, selectedEnsemble?.family_id as string),
      refetchInterval: refetchInterval, // 60 seconds
      retry: isStreaming ? 1 : undefined,
      cacheTime: isStreaming ? interval * 4000 : 4 * 60 * 60 * 1000, // 4 hour,
      staleTime: isStreaming ? interval * 1000 : 60 * 60 * 1000, // 1 hour,
      enabled:
        enabled &&
        ObjectUtils.areNonNull(payload as unknown as Record<string, unknown>, Object.keys(payload)),
      onSuccess: (newData: CRTagData<TimeSeries, Mode, AnomalyRange>[]) => {
        newData.map((tagData) => {
          setTagChartData((prevState) => {
            return {
              ...prevState,
              ...{
                [tagData.tag]: tagData,
              },
            };
          });
        });
      },
    };
  });

  return useQueries(queries);
};
export const useFolderDiscreteTagMinMax = (
  { startTime, endTime, streamingTimeInSeconds, folderTags }: FolderTagMinMaxParams,
  enabled = true,
) => {
  const { currentTenant } = useTenants();
  const {
    viewState: { selectedEnsemble: viewEnsemble },
    getQueryKeyWithViewId,
  } = useViewContext();
  const { selectedEnsemble: appEnsemble } = useContext(EnsembleContext);
  const selectedEnsemble = viewEnsemble ?? appEnsemble;
  const tenantConfig = currentTenant?.preferences;
  const isStreaming = Boolean(streamingTimeInSeconds);
  const formattedStartTime = startTime?.toISOString() ?? undefined;
  const formattedEndTime = endTime?.toISOString() ?? undefined;
  const { setTagChartData } = useContext(InvestigateContext);

  let interval = IntervalUtil.DEFAULT_INTERVAL;
  if (startTime && endTime) {
    interval = IntervalUtil.fromThresholds(
      startTime,
      endTime,
      tenantConfig?.analyzeTimeThresholds as Array<TimeThreshold>,
    );
  }

  const refetchInterval: number | false = isStreaming
    ? IntervalUtil.refetchMsFromInterval(interval)
    : false;

  const queries = folderTags.map(({ folder, tag_name }) => {
    const payload = {
      folder: Number(folder),
      tag_names: [tag_name],
      interval: interval,
    } as unknown as TagResampleMinMaxPayload;

    if (isStreaming) {
      payload.preset = streamingTimeInSeconds;
    } else {
      payload.start_time = formattedStartTime;
      payload.end_time = formattedEndTime;
      payload.start_time = formattedStartTime;
    }
    payload.ensemble_family_id = selectedEnsemble?.family_id || '';
    return {
      queryKey: [
        getQueryKeyWithViewId(FOLDER_DISCRETE_DATA_KEY),
        getQueryKeyWithViewId(DISCRETE_TAGS_DATA_KEY),
        folder,
        tag_name,
        selectedEnsemble?.family_id,
      ],
      select: (body: ResampleTagsMinMaxResult) => body.result,
      queryFn: () => resampledTagsMinMaxDiscrete(payload, selectedEnsemble?.family_id as string),
      refetchInterval: refetchInterval, // 60 seconds
      retry: isStreaming ? 1 : undefined,
      cacheTime: isStreaming ? interval * 4000 : 4 * 60 * 60 * 1000, // 4 hour,
      staleTime: isStreaming ? interval * 1000 : 60 * 60 * 1000, // 1 hour,
      enabled:
        enabled &&
        ObjectUtils.areNonNull(payload as unknown as Record<string, unknown>, Object.keys(payload)),
      onSuccess: (newData: CRTagData<TimeSeries, Mode, AnomalyRange>[]) => {
        newData.map((tagData) => {
          setTagChartData((prevState) => {
            return {
              ...prevState,
              ...{
                [tagData.tag]: tagData,
              },
            };
          });
        });
      },
    };
  });

  return useQueries(queries);
};

export const useTagMinMax = (
  { startTime, endTime, streamingTimeInSeconds, folder, tag }: TagMinMaxParams,
  enabled = true,
) => {
  const { data, ...rest } = useTagResampleMinMax(
    { startTime, endTime, streamingTimeInSeconds, folder, tags: [tag] },
    enabled,
  );

  const prevData = usePrevious(data);
  const [tagData, setTagData] = useState<CRTagData<TimeSeries, Mode, AnomalyRange> | undefined>(
    data?.[0],
  );

  useEffect(() => {
    if (!prevData && data?.length) {
      setTagData(data[0]);
    } else if (data?.length && JSON.stringify(prevData?.[0] ?? {}) !== JSON.stringify(data[0])) {
      setTagData(data[0]);
    }
  }, [data, prevData]);

  return { ...rest, data: tagData, isLoading: rest.isLoading || (!tagData && !rest.isError) };
};

export const useTagMinMaxString = (
  { startTime, endTime, streamingTimeInSeconds, folder, tag }: TagMinMaxStringParams,
  enabled = true,
) => {
  const tags = Array.isArray(tag) ? tag : [tag];
  const { data, ...rest } = useTagResampleMinMaxString(
    { startTime, endTime, streamingTimeInSeconds, folder, tags: tags },
    enabled,
  );

  const prevData = usePrevious(data);
  const [tagData, setTagData] = useState<CRTagData<TimeSeries, Mode, AnomalyRange> | undefined>(
    data?.[0],
  );

  useEffect(() => {
    if (!prevData && data?.length) {
      setTagData(data[0]);
    } else if (data?.length && JSON.stringify(prevData?.[0] ?? {}) !== JSON.stringify(data[0])) {
      setTagData(data[0]);
    }
  }, [data, prevData]);

  return { ...rest, data: tagData, isLoading: rest.isLoading || (!tagData && !rest.isError) };
};

export const useMultiTagMinMax = (
  { startTime, endTime, streamingTimeInSeconds, folder, tag }: MultiTagMinMaxParams,
  enabled = true,
) => {
  const tags = Array.isArray(tag) ? tag : [tag];
  const { data, ...rest } = useTagResampleMinMax(
    { startTime, endTime, streamingTimeInSeconds, folder, tags: tags },
    enabled,
  );
  const prevData = usePrevious(data);
  const [tagData, setTagData] = useState<CRTagData<TimeSeries, Mode, AnomalyRange>[] | undefined>(
    data,
  );

  useEffect(() => {
    if (!prevData && data?.length) {
      setTagData(data);
    } else if (data?.length && JSON.stringify(prevData ?? []) !== JSON.stringify(data)) {
      setTagData(data);
    }
  }, [data, prevData]);

  return { ...rest, data: tagData, isLoading: rest.isLoading || (!tagData && !rest.isError) };
};

export const useMultiTagMinMaxString = (
  { startTime, endTime, streamingTimeInSeconds, folder, tag }: MultiTagMinMaxStringParams,
  enabled = true,
) => {
  const tags = Array.isArray(tag) ? tag : [tag];
  const { data, ...rest } = useTagResampleMinMaxString(
    { startTime, endTime, streamingTimeInSeconds, folder, tags: tags },
    enabled,
  );
  const prevData = usePrevious(data);
  const [tagData, setTagData] = useState<CRTagData<TimeSeries, Mode, AnomalyRange>[] | undefined>(
    data,
  );

  useEffect(() => {
    if (!prevData && data?.length) {
      setTagData(data);
    } else if (data?.length && JSON.stringify(prevData ?? []) !== JSON.stringify(data)) {
      setTagData(data);
    }
  }, [data, prevData]);

  return { ...rest, data: tagData, isLoading: rest.isLoading || (!tagData && !rest.isError) };
};

// export const useTagSearchMinMax = (
//   { startTime, endTime, streamingTimeInSeconds, folder = 0, searchKeys }: TagSearchParams,
//   enabled = true,
// ) => {
//   const { currentTenant } = useTenants();
//   const { getQueryKeyWithViewId } = useViewContext();
//   const tenantConfig = currentTenant?.preferences;
//   const isStreaming = Boolean(streamingTimeInSeconds);

//   const formattedStartTime = startTime?.toISOString() ?? undefined;
//   const formattedEndTime = endTime?.toISOString() ?? undefined;
//   const searchKey = searchKeys?.join(',') ?? undefined;

//   let interval = IntervalUtil.DEFAULT_INTERVAL;
//   if (startTime && endTime) {
//     interval = IntervalUtil.fromThresholds(
//       startTime,
//       endTime,
//       tenantConfig?.analyzeTimeThresholds as Array<TimeThreshold>,
//     );
//   }

//   const payload = {
//     start_time: formattedStartTime,
//     end_time: formattedEndTime,
//     streamingTimeInSeconds,
//     folder,
//     search_key: searchKey,
//     interval,
//   };
//   const key = [getQueryKeyWithViewId(TAG_DATA_KEY), 'tag_search', 'min_max', payload];
//   return useQuery(key, () => searchTagsMinMax(payload), {
//     enabled:
//       enabled &&
//       (ObjectUtils.areNonNull(payload, ['start_time', 'end_time', 'folder', 'search_key']) ||
//         ObjectUtils.areNonNull(payload, ['preset', 'folder', 'search_key'])),
//     retry: isStreaming ? 1 : undefined,
//     refetchInterval: isStreaming ? IntervalUtil.refetchMsFromInterval(interval) : false,
//     select: (body) => body.result,
//     cacheTime: 4 * 60 * 60 * 1000, // 4 hour,
//     staleTime: 60 * 60 * 1000, // 1 hour,
//   });
// };

export const useUnHideTag = () => {
  const queryClient = useQueryClient();
  const { getQueryKeyWithViewId } = useViewContext();

  return useMutation((tagData: HiddenTag) => unHideTag(tagData), {
    onSuccess: async () => {
      queryClient.invalidateQueries(getQueryKeyWithViewId(HIDDEN_TAG_KEY));
      queryClient.invalidateQueries(getQueryKeyWithViewId(ANOMALOUS_FOLDERS_KEY));
      queryClient.invalidateQueries(getQueryKeyWithViewId(FOLDER_KEY));
      queryClient.invalidateQueries(getQueryKeyWithViewId(ANOMALIES_KEY));
      queryClient.invalidateQueries(getQueryKeyWithViewId(TAG_DATA_KEY));
    },
    onError: (e) => {
      console.warn('Failed to unhide', e);
    },
  });
};

export const useHideTag = () => {
  const queryClient = useQueryClient();
  const { getQueryKeyWithViewId } = useViewContext();

  return useMutation((folderData: HiddenTag) => hideTag(folderData), {
    onSuccess: async () => {
      queryClient.invalidateQueries(getQueryKeyWithViewId(HIDDEN_TAG_KEY));
      queryClient.invalidateQueries(getQueryKeyWithViewId(ANOMALOUS_FOLDERS_KEY));
      queryClient.invalidateQueries(getQueryKeyWithViewId(FOLDER_KEY));
      queryClient.invalidateQueries(getQueryKeyWithViewId(ANOMALIES_KEY));
      queryClient.invalidateQueries(getQueryKeyWithViewId(TAG_DATA_KEY));
    },
    onError: (e) => {
      console.warn('Failed to hide tag', e);
    },
  });
};

export const useHiddenTags = () => {
  const { getQueryKeyWithViewId } = useViewContext();
  return useQuery([getQueryKeyWithViewId(HIDDEN_TAG_KEY)], () => fetchAllHiddenTags());
};

export const useTagResampleMinMax = (
  { startTime, endTime, streamingTimeInSeconds, folder = 0, tags }: TagResampleParams,
  enabled = true,
) => {
  const { currentTenant } = useTenants();
  const {
    viewState: { selectedEnsemble: viewEnsemble },
    getQueryKeyWithViewId,
  } = useViewContext();
  const { selectedEnsemble: appEnsemble } = useContext(EnsembleContext);
  const selectedEnsemble = viewEnsemble ?? appEnsemble;
  const tenantConfig = currentTenant?.preferences;
  const isStreaming = Boolean(streamingTimeInSeconds);
  const formattedStartTime = startTime?.toISOString() ?? undefined;
  const formattedEndTime = endTime?.toISOString() ?? undefined;

  let interval = IntervalUtil.DEFAULT_INTERVAL;
  if (startTime && endTime) {
    interval = IntervalUtil.fromThresholds(
      startTime,
      endTime,
      tenantConfig?.analyzeTimeThresholds as Array<TimeThreshold>,
    );
  }

  const payload = {
    folder: folder,
    tag_names: tags,
    interval: interval,
  } as TagResampleMinMaxPayload;

  if (isStreaming) {
    payload.preset = streamingTimeInSeconds;
  } else {
    payload.start_time = formattedStartTime;
    payload.end_time = formattedEndTime;
  }

  if (selectedEnsemble?.family_id) {
    payload.ensemble_family_id = selectedEnsemble?.family_id;
  }

  const key = [
    getQueryKeyWithViewId(TAG_DATA_KEY),
    'tag_resample',
    'min_max',
    payload,
    selectedEnsemble?.family_id,
  ];
  return useQuery(key, () => resampledTagsMinMax(payload, selectedEnsemble?.family_id as string), {
    enabled:
      enabled &&
      ObjectUtils.areNonNull(payload as unknown as Record<string, unknown>, Object.keys(payload)),
    select: (body) => body.result,
    retry: isStreaming ? 1 : undefined,
    refetchInterval: isStreaming ? IntervalUtil.refetchMsFromInterval(interval) : false,
    cacheTime: isStreaming ? interval * 4000 : 4 * 60 * 60 * 1000, // 4 hour,
    staleTime: isStreaming ? interval * 1000 : 60 * 60 * 1000, // 1 hour,
  });
};

export const useSearchEvents = (
  startTime: string,
  endTime: string,
  tag_name: string,
  searchStartTime: string,
  searchEndTime: string,
) => {
  const { getQueryKeyWithViewId } = useViewContext();
  return useQuery(
    [getQueryKeyWithViewId('TIMESEARCH')],
    () => timeSearch({ startTime, endTime, tag_name, searchStartTime, searchEndTime }),
    {
      cacheTime: 4 * 60 * 60 * 1000, // 4 hour,
      staleTime: 60 * 60 * 1000, // 1 hour,
    },
  );
};

export const useTagResampleMinMaxString = (
  { startTime, endTime, streamingTimeInSeconds, folder = 0, tags }: TagResampleParams,
  enabled = true,
) => {
  const { currentTenant } = useTenants();
  const {
    viewState: { selectedEnsemble: viewEnsemble },
    getQueryKeyWithViewId,
  } = useViewContext();
  const { selectedEnsemble: appEnsemble } = useContext(EnsembleContext);
  const selectedEnsemble = viewEnsemble ?? appEnsemble;
  const tenantConfig = currentTenant?.preferences;
  const isStreaming = Boolean(streamingTimeInSeconds);
  const formattedStartTime = startTime?.toISOString() ?? undefined;
  const formattedEndTime = endTime?.toISOString() ?? undefined;

  let interval = IntervalUtil.DEFAULT_INTERVAL;
  if (startTime && endTime) {
    interval = IntervalUtil.fromThresholds(
      startTime,
      endTime,
      tenantConfig?.analyzeTimeThresholds as Array<TimeThreshold>,
    );
  }

  const payload = {
    folder: folder,
    tag_names: tags,
    interval: interval,
  } as TagResampleMinMaxPayload;

  if (isStreaming) {
    payload.preset = streamingTimeInSeconds;
  } else {
    payload.start_time = formattedStartTime;
    payload.end_time = formattedEndTime;
  }

  const key = [
    getQueryKeyWithViewId(TAG_DATA_KEY),
    'tag_resample_string',
    'min_max_string',
    payload,
  ];
  return useQuery(
    key,
    () => resampledTagsMinMaxString(payload, selectedEnsemble?.family_id as string),
    {
      enabled:
        enabled &&
        ObjectUtils.areNonNull(payload as unknown as Record<string, unknown>, Object.keys(payload)),
      select: (body) => body.result,
      retry: isStreaming ? 1 : undefined,
      refetchInterval: isStreaming ? IntervalUtil.refetchMsFromInterval(interval) : false,
      cacheTime: isStreaming ? interval * 4000 : 4 * 60 * 60 * 1000, // 4 hour,
      staleTime: isStreaming ? interval * 1000 : 60 * 60 * 1000, // 1 hour,
    },
  );
};

export const useEnsembleModelsQuery = () => {
  const { data: ensembleFamilies, ...rest } = useQuery('ensemble', getEnsembleModels, {
    cacheTime: 8 * 60 * 60 * 1000, // 8 hours,
    staleTime: 60 * 60 * 1000, // 1 hour,
  });

  return {
    ensembleFamilies: ensembleFamilies?.result ? ensembleFamilies?.result?.family_list : [],
    ...rest,
  };
};

export const useDefaultEnsembleModels = () => {
  const [search] = useSearchParams();
  const ensembleReference = search.get('reference');
  const startTime = search.get('startTime');
  const endTime = search.get('endTime');

  const { setSelectedEnsemble, setShowEnsembleErrorBanner } = useContext(EnsembleContext);
  const { ensembleFamilies } = useEnsembleModelsQuery();

  // Todo: Show error page if response has "is_anomaly_visible": false
  const { data: ensembleByReference } = useQuery('ensemble_by_reference', () => {
    if (ensembleReference) {
      return getEnsembleIdByReference({ reference: ensembleReference, startTime, endTime });
    }
  });

  const defaultEnsembleFamily = useMemo(() => {
    let ensembleFamily = {} as Ensemble;
    if (ensembleByReference?.result?.family_id) {
      ensembleFamily = ensembleFamilies?.find(
        (ef: Ensemble) => ef.family_id === ensembleByReference?.result?.family_id,
      ) as Ensemble;
      if (!ensembleFamily) {
        setShowEnsembleErrorBanner(true);
      }
    } else if (ensembleFamilies?.length) {
      if (JSON.parse(sessionStorage.getItem('ensemble') as string)?.family_id) {
        ensembleFamily = JSON.parse(sessionStorage.getItem('ensemble') || '{}') as Ensemble;
      } else {
        ensembleFamily = ensembleFamilies?.find((ef: Ensemble) => ef.main_family) as Ensemble;
      }
    }
    return ensembleFamily;
  }, [ensembleByReference, ensembleFamilies, setShowEnsembleErrorBanner]) as Ensemble;

  useEffect(() => {
    if (defaultEnsembleFamily?.family_id) {
      setSelectedEnsemble(defaultEnsembleFamily);
    }
  }, [ensembleFamilies, defaultEnsembleFamily, setSelectedEnsemble]);

  return {
    defaultEnsembleFamily,
  };
};

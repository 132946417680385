import styled from 'styled-components';

import { colors } from '@controlrooms/design-tokens';

export const StyledTimeRangeFilter = styled.div`
  border-bottom: 1px solid ${colors.content['neutral-3']};
  @media print {
    display: none;
  }
`;

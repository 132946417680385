import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';

interface VersionContextProps {
  version: number;
  setVersion: Dispatch<SetStateAction<number>>; // To dynamically update the version
}

const VersionContext = createContext<VersionContextProps | undefined>(undefined);

const useVersion = (): VersionContextProps => {
  const context = useContext(VersionContext);
  if (!context) {
    throw new Error('useVersion must be used within a Version component');
  }
  return context;
};

const Version: React.FC<{ children: ReactNode }> & {
  One: React.FC<{ children: ReactNode }>;
  Two: React.FC<{ children: ReactNode }>;
  Fallback: React.FC<{ children: ReactNode }>;
} = ({ children }) => {
  const [version, setVersion] = useState<number>(1);

  return (
    <VersionContext.Provider value={{ version, setVersion }}>
      <div>{children}</div>
    </VersionContext.Provider>
  );
};

const VersionOne: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { version } = useVersion();

  if (version === 1) {
    return <div>{children}</div>;
  }
  return null;
};

const VersionTwo: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { version } = useVersion();

  if (version === 2) {
    return <div>{children}</div>;
  }
  return null;
};
const VersionFallback: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { version } = useVersion();

  if (version === 0) {
    return <div>{children}</div>;
  }
  return null;
};

Version.One = VersionOne;
Version.Two = VersionTwo;
Version.Fallback = VersionFallback;

export default Version;
export { useVersion };

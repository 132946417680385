import React from 'react';

import { ErrorPage, Error } from '../../pages/not-found/error-page';

interface Props {
  error: Error;
  resetErrorBoundary: () => void;
}

export const ErrorFallback: React.FC<Props> = ({ error }) => {
  return (
    <ErrorPage>
      <Error message={error.message} />
    </ErrorPage>
  );
};

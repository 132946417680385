/*eslint-disable */
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
/*eslint-enable */
import React, { useState, useEffect } from 'react';

import { Tooltip } from '../tooltip';

import { StyledSlider } from './styles';

interface SliderProps<ValueType = number | number[]> {
  value: number;
  onChange: (key: ValueType) => void;
  className?: string;
  min?: number;
  max?: number;
  stepCount?: number | null;
  tooltip?: string | null;
  testid?: string | null;
}

const HandlePosition: { [key: number]: string } = {
  1: '10%',
  2: '45%',
  3: '80%',
};

const RailPosition: { [key: number]: string } = {
  1: '0%',
  2: '50%',
  3: '100%',
};

export const CustomSlider: React.FC<SliderProps> = ({
  value,
  onChange,
  min = 1,
  max = 3,
  stepCount = 1,
  tooltip,
  testid,
}: SliderProps) => {
  const [leftPosition, setLeftPosition] = useState<string>(HandlePosition[value]);
  const [railPosition, setRailPosition] = useState<string>(RailPosition[value]);

  useEffect(() => {
    setLeftPosition(HandlePosition[value]);
    setRailPosition(HandlePosition[value]);
  }, [value]);

  return (
    <Tooltip testid={testid ?? ''} label={tooltip ?? ''}>
      <StyledSlider
        data-testid="rc-slider-handle"
        leftPosition={leftPosition}
        railPosition={railPosition}
      >
        <Slider min={min} max={max} step={stepCount} value={value} onChange={onChange} />
      </StyledSlider>
    </Tooltip>
  );
};

// import { withAuthenticationRequired, useContext, useState } from '@auth0/auth0-react';
import React, { ComponentType, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useUser } from '../../hooks/accounts';
import { useTenants } from '../../hooks/tenants';
import { Loading } from '../loading/loading';

interface Props {
  component: ComponentType;
}

const DemoRoute: React.FC<Props> = ({ component }) => {
  const location = useLocation();
  const navigate = useNavigate();
  if (location.pathname === '/demo/' || location.pathname === '/demo') {
    navigate('/demo/redirect');
  }
  // Memoize to prevent HOC from rendering a "new" component when something at the top of the tree changes.
  const Component = useMemo(() => component, [component]);

  // bootstrap (blocking) tenants
  const { tenants, isError: tenantError } = useTenants();
  const { data: user, isError: userError } = useUser();

  if (tenantError || userError) throw new Error('Unable to bootstrap user / tenant data.');

  if (!tenants || !user) return <Loading />;

  return <Component />;
};

export default DemoRoute;

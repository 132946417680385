import dayjs from 'dayjs';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { useLocation } from 'react-router-dom';

import { useAnalytics } from '../../../../app/analytics';
import { useViewContext } from '../../../context/view-context';

import { Calendar, CalendarButton } from './styles';

import { DateTimeRangePicker, TextInput, TextInputProps } from '@controlrooms/components';
import { DateFormats, Fields, TimeRanges } from '@controlrooms/models';
import { TimeUtils } from '@controlrooms/utils';

const getSubstringAsInt = (value: string, length: number) =>
  parseInt(value.substring(value.length - length, value.length), 10);

/**
 * Check if the entered time value is allowed.
 * @param value the string value
 * @param floatValue the float value
 */
const isTimeAllowed = ({ value }: NumberFormatValues): boolean => {
  switch (value.length) {
    case 0:
      return true;
    // Month
    case 1:
    case 15:
      return getSubstringAsInt(value, 1) <= 1;
    case 2:
    case 16:
      return getSubstringAsInt(value, 2) <= 12;
    // Day
    case 3:
    case 17:
      return getSubstringAsInt(value, 1) <= 3;
    case 4:
    case 18:
      return getSubstringAsInt(value, 2) <= 31;
    // Year
    case 5:
    case 6:
    case 7:
    case 8:
    case 19:
    case 20:
    case 21:
    case 22:
      return true;
    // Hour
    case 9:
    case 23:
      return getSubstringAsInt(value, 1) <= 2;
    case 10:
    case 24:
      return getSubstringAsInt(value, 2) <= 23;
    // Minute
    case 11:
    case 13:
    case 25:
    case 27:
      return getSubstringAsInt(value, 1) <= 5;
    // Second
    case 12:
    case 14:
    case 26:
    case 28:
      return getSubstringAsInt(value, 2) <= 59;
    default:
      return false;
  }
};

interface RangeInputMaskProps {
  disabled?: boolean;
}

const MASK = [
  'm',
  'm',
  'd',
  'd',
  'y',
  'y',
  'y',
  'y',
  'h',
  'h',
  'm',
  'm',
  's',
  's',
  'm',
  'm',
  'd',
  'd',
  'y',
  'y',
  'y',
  'y',
  'h',
  'h',
  'm',
  'm',
  's',
  's',
];

const CustomInput = (props: TextInputProps) => {
  const {
    formState: { errors },
    setValue,
  } = useFormContext();
  const { viewState } = useViewContext();
  const { timeSelection } = viewState;

  const { startTime, endTime, timezone } = timeSelection;

  return (
    <TextInput
      {...props}
      className="date-time-range-input"
      errorMessage={errors?.[Fields.CUSTOM_TIMEFRAME]?.message}
    >
      <DateTimeRangePicker
        minimumDurationSeconds={1}
        initialValues={{
          start: TimeUtils.toTimezone(startTime, timezone),
          end: TimeUtils.toTimezone(endTime, timezone),
        }}
        onApply={([start, end]) => {
          setValue(Fields.TIMEFRAME, [start.toDate(), end.toDate()]);
          setValue(
            Fields.CUSTOM_TIMEFRAME,
            `${start.format(DateFormats.DATETIME_ALT)} - ${end.format(DateFormats.DATETIME_ALT)}`,
          );
        }}
      >
        <CalendarButton
          data-testid="calendar"
          type="button"
          aria-haspopup="listbox"
          onClick={() => setValue(Fields.TIMERANGE, TimeRanges.CUSTOM)}
        >
          <Calendar width="18" height="18" />
        </CalendarButton>
      </DateTimeRangePicker>
    </TextInput>
  );
};

export const RangeInputMask: React.FC<RangeInputMaskProps> = ({ disabled = false }) => {
  const { control, getValues } = useFormContext();
  const { pathname } = useLocation();
  const { track } = useAnalytics();

  return (
    <Controller
      render={({ field }) => (
        <NumberFormat
          data-testid="custom-timeframe-input"
          id={Fields.CUSTOM_TIMEFRAME}
          customInput={CustomInput}
          format="##/##/#### ##:##:## - ##/##/#### ##:##:##"
          placeholder="mm/dd/yyyy hh:mm:ss - mm/dd/yyyy hh:mm:ss"
          mask={MASK}
          disabled={disabled}
          isAllowed={isTimeAllowed}
          onValueChange={({ formattedValue }) => {
            track('Timeframe Changes - Custom Timeframe', {
              customValue: formattedValue,
              currentPage: pathname,
            });
            field.onChange(formattedValue);
          }}
          {...field}
        />
      )}
      name={Fields.CUSTOM_TIMEFRAME}
      control={control}
      rules={{
        required: getValues(Fields.TIMERANGE).value === TimeRanges.CUSTOM,
        validate: {
          requiredValue: (value: string) => {
            if (!value || value.length === 0) return 'Select a start and end time';
          },
          incompleteTimeRange: (value: string) => {
            return value.match(/^[^a-zA-Z]+$/) ? true : 'Incomplete time range';
          },
          invalidDate: (value: string) => {
            const [start, end] = value.split(' - ');
            const startTime = dayjs(start, 'MM/DD/YYYY HH:mm:ss');
            const endTime = dayjs(end, 'MM/DD/YYYY HH:mm:ss');
            return endTime.diff(startTime) > 0 ? true : 'Enter valid time range';
          },
        },
      }}
    />
  );
};

import { useAuth0 } from '@auth0/auth0-react';
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { Role } from '../constants/auth';
import { CR_USER_PROP } from '../utils/token';

interface ContextProps {
  userRoles: Array<string>;
  setUserRoles: Dispatch<SetStateAction<string[]>>;
  tokenHash: string;
  setTokenHash: Dispatch<SetStateAction<string>>;
  canUserReadAlert: boolean;
  canUserEditAlert: (alertUserId: string | number) => boolean;
  hasRole: (requiredRole: string) => boolean;
  userHasGlobalAlertEditRole: boolean;
}

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <AuthorizationContextProvider>.');
};

const defaultState = {
  userRoles: [],
  setUserRoles: stub,
  tokenHash: '',
  setTokenHash: stub,
  canUserReadAlert: false,
  canUserEditAlert: stub,
  hasRole: stub,
  userHasGlobalAlertEditRole: false,
};

export const AuthorizationContext = createContext<ContextProps>(defaultState);

export const useRoles = () => useContext(AuthorizationContext);

const AuthorizationContextProvider: React.FC = ({ children }) => {
  const [userRoles, setUserRoles] = useState<string[]>([]);
  const [tokenHash, setTokenHash] = useState('');
  const { user } = useAuth0();
  const userId = user?.[CR_USER_PROP]?.user_id;

  const canUserReadAlert = useMemo(() => {
    return userRoles.some(
      (role) =>
        role === Role.ALERT ||
        role === Role.GLOBAL_ALERT_VIEWER ||
        role === Role.GLOBAL_ALERT_EDITOR,
    );
  }, [userRoles]) as boolean;

  const canUserEditAlert = useCallback(
    (alertUserId) => {
      return userId == alertUserId || userRoles?.some((role) => role === Role.GLOBAL_ALERT_EDITOR);
    },
    [userId, userRoles],
  );

  const userHasGlobalAlertEditRole = useMemo(() => {
    return userRoles?.some((role) => role === Role.GLOBAL_ALERT_EDITOR);
  }, [userRoles]) as boolean;

  const hasRole = useCallback(
    (requiredRole: string) => {
      return userRoles?.some((role) => role === requiredRole);
    },
    [userRoles],
  );

  const value = useMemo(
    () => ({
      userRoles,
      setUserRoles,
      tokenHash,
      setTokenHash,
      canUserReadAlert,
      canUserEditAlert,
      userHasGlobalAlertEditRole,
      hasRole,
    }),
    [userRoles, tokenHash, canUserReadAlert, canUserEditAlert, userHasGlobalAlertEditRole, hasRole],
  );

  return <AuthorizationContext.Provider value={value}>{children}</AuthorizationContext.Provider>;
};

export default AuthorizationContextProvider;

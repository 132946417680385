import styled, { css } from 'styled-components';

import { ModalContents, ModalHeader, ModalBody, ModalFooter } from '@controlrooms/components';
import { colors, text, Size } from '@controlrooms/design-tokens';

export const LegalModalContents = styled(ModalContents)`
  ${ModalBody}, ${ModalHeader}, ${ModalFooter} {
    background: ${colors.container['b-g-1']};
  }
`;

export const TextStyleCss = css`
  font-weight: 400;
  font-size: ${text.size[Size.DATA_13]};
  font-family: 'Open Sans', sans-serif;
  line-height: 19px;
  margin-top: 0;
  color: ${colors.content['neutral-5']};
  text-align: left;
`;

export const ModalBodyContainer = styled.div`
  padding: 35px 48px;
  text-align: center;
  height: 550px;
  overflow: auto;
  width: 100%;
`;

export const ModalBodyPrivacyPolicy = styled.div`
  padding: 35px 48px;
  text-align: center;
  height: 550px;
  overflow: auto;
  width: 100%;

  // Notion provided styles.
  [data-custom-class='body'],
  [data-custom-class='body'] * {
    background: transparent !important;
    text-align: left;
  }
  [data-custom-class='title'],
  [data-custom-class='title'] * {
    font-family: Arial !important;
    font-size: 26px !important;
    color: #000000 !important;
  }
  [data-custom-class='subtitle'],
  [data-custom-class='subtitle'] * {
    font-family: Arial !important;
    color: #595959 !important;
    font-size: 14px !important;
  }
  [data-custom-class='heading_1'],
  [data-custom-class='heading_1'] * {
    font-family: Arial !important;
    font-size: 19px !important;
    color: #000000 !important;
  }
  [data-custom-class='heading_2'],
  [data-custom-class='heading_2'] * {
    font-family: Arial !important;
    font-size: 17px !important;
    color: #000000 !important;
  }
  [data-custom-class='body_text'],
  [data-custom-class='body_text'] * {
    color: #595959 !important;
    font-size: 14px !important;
    font-family: Arial !important;
  }
  [data-custom-class='link'],
  [data-custom-class='link'] * {
    color: #3030f1 !important;
    font-size: 14px !important;
    font-family: Arial !important;
    word-break: break-word !important;
  }

  em {
    margin: 0;
  }

  ul {
    list-style-type: square;
  }
  ul > li > ul {
    list-style-type: circle;
  }
  ul > li > ul > li > ul {
    list-style-type: square;
  }
  ol li {
    font-family: Arial;
  }
`;

export const LegalHeader = styled.h2`
  color: ${colors.k['0']};
  font-size: ${text.size[Size.HEADER_31]};
  line-height: 37px;
  font-weight: 300;
  margin: 0;
`;

export const LegalSubHeader = styled.h3`
  color: ${colors.k['0']};
  font-size: ${text.size[Size.HEADER_19]};
  line-height: 37px;
  font-weight: 300;
  margin: 0 0 24px;
`;

export const LegalContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

export const Section = styled.section`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
  margin-bottom: 15px;
  width: 100%;
`;

export const SectionTitle = styled.h3`
  font-style: normal;
  font-weight: 400;
  font-size: ${text.size[Size.DATA_19]};
  line-height: 23px;
  margin: 0 0 10px;
  color: ${colors.k['0']};
`;

export const SectionText = styled.p`
  ${TextStyleCss};
`;

export const SubSectionLabel = styled.span`
  padding-left: 20px;
  font-weight: 700;
  margin-right: 5px;
`;

export const StyledUl = styled.ul`
  padding-left: 20px;
`;

export const StyledLi = styled.li`
  ${TextStyleCss};
  margin-bottom: 0;
`;

import { memo, useMemo } from 'react';
import { generatePath } from 'react-router-dom';

import { Paths } from '../../../app/constants/paths';
import { useTenant } from '../../../app/context/tenant-context';
import { useLayoutContext } from '../../context/layout-context';
import { ExportCollectionModal } from '../export-modal/export-modal';

import { Modal, ModalContents } from '@controlrooms/components';

export const ShareCollectionLinkModal = memo(({ onClose }: { onClose: () => void }) => {
  const { viewCollection } = useLayoutContext();
  const { tenant } = useTenant();

  const viewLink = useMemo(
    () =>
      `${window.location.origin}${generatePath(Paths.COLLECTION_SHARE, {
        tenantId: tenant.toString(),
        hash: viewCollection.collectionId,
      })}`,
    [tenant, viewCollection.collectionId],
  );

  return (
    <Modal open={true}>
      <ModalContents
        title="Share Collection"
        styles={{ content: { minWidth: '450px' } }}
        confirmButtonText="Close"
        closeButtonCallback={() => onClose()}
        shouldCloseOnEsc={false}
        confirmButtonCallback={() => onClose()}
        dataTestIdCancel="modal-close-icon-delete-alert"
      >
        <ExportCollectionModal link={viewLink} hash={viewCollection.collectionId as string} />
      </ModalContents>
    </Modal>
  );
});

import * as d3 from 'd3';
import dayjs from 'dayjs';

import { SVGDefsSelection } from '@controlrooms/models';

export enum TimeDurations {
  MIN5 = dayjs.duration(5, 'minute').as('seconds'),
  MIN30 = dayjs.duration(30, 'minute').as('seconds'),
  HOUR1 = dayjs.duration(1, 'hour').as('seconds'),
  HOUR4 = dayjs.duration(4, 'hours').as('seconds'),
  HOUR12 = dayjs.duration(12, 'hours').as('seconds'),
  HOUR24 = dayjs.duration(24, 'hours').as('seconds'),
  DAY2 = dayjs.duration(2, 'day').as('seconds'),
  DAY4 = dayjs.duration(4, 'day').as('seconds'),
  WEEK1 = dayjs.duration(1, 'week').as('seconds'),
  MONTH1 = dayjs.duration(1, 'month').as('seconds'),
  MONTH3 = dayjs.duration(3, 'month').as('seconds'),
  YEAR1 = dayjs.duration(1, 'year').as('seconds'),
  YEAR5 = dayjs.duration(5, 'year').as('seconds'),
}

export const getTickFormat = (duration: number) => {
  // less than 5m show seconds
  if (duration <= dayjs.duration(5, 'm').asSeconds()) {
    return 'HH:mm:ss';
  }

  // show day of onth at a week
  if (duration <= dayjs.duration(4, 'd').asSeconds()) {
    return 'HH:mm';
  }

  if (duration <= TimeDurations.MONTH3) {
    return 'D MMM';
  }

  // add show years
  return 'D MMM YYYY';
};

export const getLimitTickInterval = (duration: number, isMobile: boolean) => {
  switch (true) {
    case duration <= TimeDurations.HOUR4:
      return d3.timeMinute.every(isMobile ? 15 : 30);
    case duration <= TimeDurations.HOUR12:
      return isMobile ? d3.timeHour.every(2) : d3.timeHour.every(1);
    case duration <= TimeDurations.HOUR24:
      return isMobile ? d3.timeHour.every(3) : d3.timeHour.every(3);
    case duration <= TimeDurations.WEEK1:
      return d3.timeDay.every(isMobile ? 6 : 1);
    case duration <= TimeDurations.MONTH1:
      return d3.timeDay.every(isMobile ? 4 : 3);
    case duration <= TimeDurations.MONTH3:
      return d3.timeDay.every(isMobile ? 4 : 16);
    default:
      return d3.timeMonth.every(isMobile ? 18 : 12);
  }
};

export const getTickInterval = (duration: number, isMobile: boolean) => {
  switch (true) {
    case duration <= TimeDurations.MIN5:
      return d3.timeSecond.every(10);
    case duration <= TimeDurations.HOUR1:
      return d3.timeMinute.every(isMobile ? 10 : 1);
    case duration <= TimeDurations.HOUR4:
      return d3.timeMinute.every(isMobile ? 15 : 5);
    case duration <= TimeDurations.HOUR12:
      return isMobile ? d3.timeHour.every(2) : d3.timeMinute.every(15);
    case duration <= TimeDurations.HOUR24:
      return isMobile ? d3.timeHour.every(3) : d3.timeMinute.every(30);
    case duration <= TimeDurations.DAY2:
      return d3.timeHour.every(2);
    case duration <= TimeDurations.DAY4:
      return d3.timeHour.every(6);
    case duration <= TimeDurations.WEEK1:
      return d3.timeHour.every(isMobile ? 6 : 2);
    case duration <= TimeDurations.MONTH3:
      return d3.timeDay.every(isMobile ? 4 : 1);
    case duration <= TimeDurations.YEAR1:
      return d3.timeMonth.every(isMobile ? 3 : 1);
    case duration <= TimeDurations.YEAR5:
      return d3.timeMonth.every(isMobile ? 6 : 3);
    default:
      return d3.timeMonth.every(isMobile ? 18 : 12);
  }
};

export const getTickPattern = (duration: number, tick: SVGDefsSelection, d: Date, i: number) => {
  const hourValue = dayjs(d).hour();
  const minuteValue = dayjs(d).minute();
  const secondsValue = dayjs(d).second();

  switch (true) {
    case duration <= TimeDurations.MIN30:
      tick.classed('grey', secondsValue !== 0);
      return;

    case duration <= TimeDurations.HOUR1:
      tick.classed('grey', minuteValue % 5 !== 0);
      if (minuteValue % 5 === 0) return;
      return tick.select('text').remove();

    case duration <= TimeDurations.HOUR4:
      tick.classed('grey', minuteValue % 10 !== 0);
      if (minuteValue % 10 === 0) return;
      return tick.select('text').remove();

    case duration <= TimeDurations.HOUR12:
      tick.classed('grey', minuteValue !== 0);
      if (minuteValue === 0) return;
      return tick.select('text').remove();

    case duration <= TimeDurations.HOUR24:
      tick.classed('grey', minuteValue !== 0 || (hourValue % 2 !== 0 && minuteValue === 0));
      if (minuteValue > 0 || hourValue % 2 !== 0) {
        tick.select('text').remove();
      }
      return;

    case duration <= TimeDurations.WEEK1:
      tick.classed('grey', i % 5 !== 0);
      if (i % 5 === 0) return;
      return tick.select('text').remove();

    case duration <= TimeDurations.MONTH1:
      tick.classed('grey', i % 2 !== 0);
      if (i % 2 === 0) return;
      return tick.select('text').remove();

    case duration <= TimeDurations.MONTH3:
      tick.classed('grey', i % 5 !== 0);
      if (i % 5 === 0) return;
      return tick.select('text').remove();

    default:
      return;
  }
};

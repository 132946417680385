import dayjs from 'dayjs';
import { memo, useMemo, useState, useEffect } from 'react';
import { generatePath } from 'react-router-dom';

import { Paths } from '../../../app/constants/paths';
import { useTenant } from '../../../app/context/tenant-context';
import { useUserId } from '../../../app/hooks/accounts';
import { useSaveView } from '../../../app/hooks/view';
import { useLayoutContext } from '../../context/layout-context';
import { constructPersistedView } from '../../context/view-context';
import { ExportModalV2 } from '../export-modal/export-modal';

import { Modal, ModalContents } from '@controlrooms/components';
import { ViewState, ViewShareType } from '@controlrooms/models';

export const ShareViewLinkModal = memo(
  ({ viewSnapshot, onClose }: { viewSnapshot: ViewState; onClose: () => void }) => {
    const { activeModes } = useLayoutContext();
    const currentUserId = useUserId();
    const { mutateAsync: createView, isLoading } = useSaveView();
    const { tenant } = useTenant();

    const isNewView =
      viewSnapshot.view_id === '' ||
      viewSnapshot.viewId === 'default' ||
      viewSnapshot.shareType === ViewShareType.PRIVATE;

    const persistView = useMemo(
      () =>
        constructPersistedView({
          viewState: viewSnapshot,
          viewName: '',
          activeModes,
          currentUserId,
          viewDescription: '',
          shareType: ViewShareType.PRIVATE,
          shared: false,
        }),
      [viewSnapshot, activeModes, currentUserId],
    );

    const [viewHash, setViewHash] = useState(isNewView ? '' : viewSnapshot.view_id);
    const [viewhashError, setViewhashError] = useState('');

    useEffect(() => {
      const initializeViewHash = async () => {
        if (isNewView) {
          try {
            persistView.view.name = `Anonymous_${dayjs().valueOf()}`;
            persistView.view_name = `Anonymous_${dayjs().valueOf()}`;
            persistView.view.description = '';
            persistView.default_view = false;
            persistView.share_type = ViewShareType.ANONYMOUS;
            const hash = await createView(persistView);
            setViewHash(hash);
          } catch (error) {
            setViewhashError('Failed to create a view hash.');
          }
        } else {
          setViewHash(viewSnapshot.view_id);
        }
      };
      initializeViewHash();
    }, [isNewView, createView, persistView, viewSnapshot.view_id]);

    const viewLink = useMemo(
      () =>
        `${window.location.origin}${generatePath(Paths.SHARE, {
          tenantId: tenant.toString(),
          hash: viewHash,
        })}`,
      [tenant, viewHash],
    );

    return (
      <Modal open={true}>
        <ModalContents
          title="Share View"
          styles={{ content: { minWidth: '450px' } }}
          confirmButtonText="Close"
          closeButtonCallback={() => onClose()}
          shouldCloseOnEsc={false}
          confirmButtonCallback={() => onClose()}
          dataTestIdCancel="modal-close-icon-delete-alert"
        >
          <ExportModalV2
            view={viewSnapshot}
            link={viewLink}
            viewHash={viewHash}
            isError={!!viewhashError}
            isLoading={isLoading}
          />
        </ModalContents>
      </Modal>
    );
  },
  (prevProps, nextProps) => {
    // Compare viewSnapshot deeply or based on key fields
    return JSON.stringify(prevProps.viewSnapshot) === JSON.stringify(nextProps.viewSnapshot);
  },
);

import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { TimeSelectContainer } from '../time-display/styles';

import { TimeRangeSelector } from './time-range-selector';
import { TimezoneSelector } from './timezone-selector';

import { ModalPanel, ModalPanelHeader } from '@controlrooms/components';

export const TimeSelector: React.FC = () => {
  const [isTimePresetsOpen, setIsTimePresetsOpen] = useState(false);
  const [isOtherTimezonesOpen, setIsOtherTimezonesOpen] = useState(false);

  const { reset } = useFormContext();

  useEffect(() => {
    return reset;
  }, [reset]);

  const closeSelectDropdowns = () => {
    setIsTimePresetsOpen(false);
    setIsOtherTimezonesOpen(false);
  };

  return (
    <TimeSelectContainer onClick={closeSelectDropdowns}>
      <ModalPanel width={70}>
        <ModalPanelHeader>Select a preset or custom timeframe</ModalPanelHeader>
        <TimeRangeSelector
          isSelectOpen={isTimePresetsOpen}
          setIsSelectOpen={setIsTimePresetsOpen}
        />
      </ModalPanel>
      <ModalPanel width={29}>
        <TimezoneSelector
          setIsSelectOpen={setIsOtherTimezonesOpen}
          isSelectOpen={isOtherTimezonesOpen}
        />
      </ModalPanel>
    </TimeSelectContainer>
  );
};

import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { isSupported } from 'tus-js-client';

import { useAnalytics } from '../../../analytics';
import { csvToArray, humanFileSize } from '../../../utils/text';
import { FileDataProps } from '../file-upload-context';

import { FilePreview } from './file-preview';
import { FileUploadProgress, UploaderContainer, UploadProgressContainer } from './styles';

import { Button, Modal, ModalContents, ModalOpenButton } from '@controlrooms/components';
import { DateFormats } from '@controlrooms/models';

interface Props {
  fileData: FileDataProps;
  fileId?: string;
  onBefore?: () => void;
  onSuccess?: () => void;
}

const PREVIEW_SIZE = 30;

const recursiveBlobRead = async (
  file: File,
  rows: number,
  reverse = false,
  size?: number,
): Promise<Array<string[]>> => {
  const _size = size ?? rows * 100;
  const text = reverse
    ? await file.slice(file.size - _size, file.size, 'utf-8').text()
    : await file.slice(0, _size, 'utf-8').text();
  const rowArray = csvToArray(text);
  if (_size > 1024 * 16) {
    throw new Error('Max blob size exceeded');
  } else if (file.size <= _size) {
    // File does not contain enough rows
    return reverse ? rowArray.reverse() : rowArray;
  } else if (
    rowArray.length < rows ||
    (!reverse && rowArray[rows - 2]?.length != rowArray[rows - 1].length) ||
    (reverse && rowArray[rowArray.length - rows].length != rowArray[rowArray.length - 1].length)
  ) {
    return await recursiveBlobRead(file, rows, reverse, _size * 2);
  } else {
    return (reverse ? rowArray.reverse() : rowArray).splice(0, rows);
  }
};

export const Uploader: React.FC<Props> = ({ fileData }) => {
  const file = fileData.file;
  const { track } = useAnalytics();
  if (!isSupported) {
    alert('This browser does not support uploads. Please use a modern browser instead.');
  }

  const progress = fileData.progress ?? 0;

  // File Preview
  const [leadingRows, setLeadingRows] = useState<Array<string[]>>([]);
  const [trailingRows, setTrailingRows] = useState<Array<string[]>>([]);

  /* Preview the upload content */
  useEffect(() => {
    recursiveBlobRead(file, PREVIEW_SIZE).then((rows) => setLeadingRows(rows));
    recursiveBlobRead(file, 10, true).then((rows) => setTrailingRows(rows));
  }, [file]);

  const fileBounds = useMemo(() => {
    if (leadingRows.length && trailingRows.length) {
      const start = leadingRows.find((r) => dayjs(r[0]).isValid())?.[0];
      const end = trailingRows.find((r) => dayjs(r[0]).isValid())?.[0];

      return start && end
        ? `${dayjs(start).format(DateFormats.DATETIME_ALT)} -
            ${dayjs(end).format(DateFormats.DATETIME_ALT)}`
        : null;
    }
    return null;
  }, [leadingRows, trailingRows]);

  return (
    <UploaderContainer>
      <UploadProgressContainer>
        <div className="upload-heading">
          <div data-testid="upload-filename" className="upload-filename">
            {file.name}
          </div>
          <div data-testid="upload-status" className="upload-status">
            {fileData.status}
          </div>
        </div>
        <div className="upload-details">
          <div className="file-bounds">{fileBounds}</div>
          <div data-testid="file-meta" className="file-meta">
            {dayjs(file.lastModified).format('M/D/YYYY h:mma')}
            ,&nbsp;
            {humanFileSize(file.size)}
          </div>
        </div>
        <div className="progress">
          <Modal unmountOnExit>
            <ModalOpenButton>
              <Button
                data-testid="preview-button"
                buttonSize="small"
                buttonType="text"
                iconName="search"
                onClick={() => {
                  track('Upload Files', {
                    previewProgress: 'clicked',
                  });
                }}
              >
                Preview
              </Button>
            </ModalOpenButton>
            <ModalContents
              title="Preview"
              subTitle="Check to ensure you're uploading the correct data"
              closeButtonText="Close"
              closeButtonCallback={() => {
                track('Upload Files', {
                  closePreviewProgress: 'clicked',
                });
              }}
            >
              <FilePreview file={file} rows={leadingRows} />
            </ModalContents>
          </Modal>
          <FileUploadProgress value={progress} max={100} />
        </div>
      </UploadProgressContainer>
    </UploaderContainer>
  );
};

import { TimeDisplayTheme } from 'styled-components';

import { colors } from '@controlrooms/design-tokens';

export const timeDisplayDarkTheme: TimeDisplayTheme = {
  primaryTextColor: 'rgb(250, 255, 218)',
  primaryTextHover: '#ffffff',
  secondaryTextColor: colors.content['neutral-3'],
  secondaryTextHover: '#ffffff',
  chevronColor: colors.content['neutral-2'],
};

export const timeDisplayLightTheme: TimeDisplayTheme = {
  primaryTextColor: colors.k[0],
  primaryTextHover: '#427275',
  secondaryTextColor: colors.k[0],
  secondaryTextHover: '#579EA3',
  chevronColor: colors.k[0],
};
export const timeDisplayDarkThemeV2: TimeDisplayTheme = {
  primaryTextColor: colors.content['neutral-1'],
  primaryTextHover: colors.content['neutral-2'],
  secondaryTextColor: colors.content['neutral-2'],
  secondaryTextHover: colors.content['neutral-3'],
  chevronColor: colors.content['neutral-2'],
};

export const timeDisplayLightThemeV2: TimeDisplayTheme = {
  primaryTextColor: colors.content['neutral-6'],
  primaryTextHover: colors.content['neutral-5'],
  secondaryTextColor: colors.content['neutral-5'],
  secondaryTextHover: colors.content['neutral-4'],
  chevronColor: colors.content['neutral-5'],
};

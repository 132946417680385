import styled from 'styled-components';

interface StyledSliderProps {
  leftPosition: string;
  railPosition: string;
}

export const StyledSlider = styled.div<StyledSliderProps>`
  .rc-slider {
    width: 95% !important;
  }
  .rc-slider-handle {
    background: ${({ theme }) => theme.slider.sliderHandle.background};
    border: 1px solid ${({ theme }) => theme.slider.sliderHandle.borderColor};
    box-shadow: ${({ theme }) => theme.slider.sliderHandle.boxShadow};
    border-radius: 3px;
    width: 30px;
    height: 10px;
    left: ${(props) => props.leftPosition} !important;
    bottom: 2px;

    .rc-slider-handle-dragging {
      background: ${({ theme }) => theme.slider.sliderHandle.selected.background};
      border: 1px solid ${({ theme }) => theme.slider.sliderHandle.selected.borderColor};
      box-shadow: ${({ theme }) => theme.slider.sliderHandle.selected.boxShadow};
    }

    &:focus {
      background: ${({ theme }) => theme.slider.sliderHandle.hover.background};
      border: 1px solid ${({ theme }) => theme.slider.sliderHandle.hover.borderColor};
      box-shadow: ${({ theme }) => theme.slider.sliderHandle.hover.boxShadow};
    }

    &:hover {
      background: ${({ theme }) => theme.slider.sliderHandle.hover.background};
      border: 1px solid ${({ theme }) => theme.slider.sliderHandle.hover.borderColor};
      box-shadow: ${({ theme }) => theme.slider.sliderHandle.hover.boxShadow};
    }
  }

  //visible slider rail
  .rc-slider-rail {
    background: ${({ theme }) => theme.slider.sliderRail.background};
    border: 1px solid ${({ theme }) => theme.slider.sliderRail.borderColor};
    box-shadow: ${({ theme }) => theme.slider.sliderRail.boxShadow};
    width: 125px !important;
    outline: 1px solid ${({ theme }) => theme.slider.sliderRail.outlineColor};
    height: 5px;
  }

  //when you slide, what is on the left
  .rc-slider-track {
    background: ${({ theme }) => theme.slider.sliderTrack.background};
    border: 1px solid ${({ theme }) => theme.slider.sliderTrack.borderColor};
    box-shadow: ${({ theme }) => theme.slider.sliderTrack.boxShadow};
    border-radius: 3px;
    height: 5px;
    width: ${(props) => props.railPosition} !important;
    left: 0;
  }

  .rc-slider-step {
    width: 90% !important;
  }
`;

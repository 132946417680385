import React, { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import styled from 'styled-components';

import UploadDataFormat from '../../../assets/upload_data_format.svg';
import UploadDataFormatLight from '../../../assets/upload_data_format_light.svg';
import { useAnalytics } from '../../analytics';
import { QueryParams } from '../../constants/paths';
import { useTheme } from '../../hooks/accounts';
import { useDownloadTemplate } from '../../hooks/template';
import { ThemeOptions } from '../account/user-preferences-modal/pages/theme';
import { ExpandIcon } from '../analyze-system-browser/styles/styles';

import { ModalFooter, Button, ModalContext } from '@controlrooms/components';

type ExpandedList = { [key in string]: boolean | undefined };

const ModalBodyContainer = styled.div`
  background: ${({ theme }) => theme.modal.secondaryBackground};
  width: 900px;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-bottom: 50px;
  padding: 0 40px;
  overflow-y: auto;
  max-height: calc(100vh - 200px);

  ${ModalFooter} {
    background: #5b7274;
  }
`;
const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 8px;
  .section {
    display: flex;
    align-items: center;
    .help-text {
      color: ${({ theme }) => theme.uploadInstructions.footer.textColor};
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 19px;
    }
  }
`;
const Title = styled.h2`
  color: ${({ theme }) => theme.uploadInstructions.body.titleColor};
  font-weight: 300;
  font-size: 31px;
  line-height: 37px;
  margin-bottom: 14px;
`;
const FaqSectionContainer = styled.div`
  margin: 12px 180px 0;
  .section-header {
    align-items: center;
    color: ${({ theme }) => theme.uploadInstructions.section.titleColor};
    font-size: 17px;
    font-weight: 400;
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    cursor: pointer;
    .icon-wrapper {
      text-align: right;
      padding-right: 0.5rem;

      .arrow-icon {
        display: inline-block;
        transform: rotate(0deg);
        transition: transform 0.2s linear;

        svg {
          fill: none;
          stroke: ${({ theme }) => theme.sidebar.folders.accordionArrowExpanded};
        }
      }
    }
  }
  .section-content {
    color: ${({ theme }) => theme.uploadInstructions.column.bodyTextColor};
    display: none;
    font-size: 13px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    line-height: 19px;
    padding-left: 20px;
    text-align: left;
    transition: transform 1s linear;
    div {
      margin-top: 10px;
    }
  }

  &.expanded {
    .section-header {
      .arrow-icon {
        transition: transform 0.2s linear;
        transform: rotate(90deg);

        svg {
          fill: ${({ theme }) => theme.sidebar.folders.accordionArrowExpanded};
          stroke: none;
        }
      }
    }

    .section-content {
      transition: transform 1s linear;
      display: block;
    }
  }
`;
const ImageContainer = styled.div`
  img {
    display: none;
    height: 0;
    transition: height 0.3s linear 0s;
  }
  .show-image {
    display: block;
    height: 245px;
    transition: height 0.3s linear 0s;
  }
`;
const ColumnContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 12px 0 50px;
`;
const Column = styled.div`
  align-items: center;
  flex-direction: column;
  padding: 0 24px;
  width: 255px;
`;
const ColumnHeader = styled.h2`
  color: ${({ theme }) => theme.uploadInstructions.column.headerTextColor};
  font-size: 17px;
  font-weight: 400;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 19px;
`;
const ColumnBody = styled.div`
  color: ${({ theme }) => theme.uploadInstructions.column.bodyTextColor};
  display: flex;
  font-size: 13px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  flex-direction: column;
  align-items: center;
`;
const ColumnCopy = styled.span`
  margin-top: 12px;
`;

export const UploadInstructions: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [downloadDisabled, setDownloadDisabled] = useState(false);
  const [expandedList, setExpandedList] = useState({} as ExpandedList);
  const [showDataFormatImage, setShowDataFormatImage] = useState(false);
  const { mutateAsync: downloadTemplate } = useDownloadTemplate();
  const [, themeName] = useTheme();
  const { track } = useAnalytics();
  const { setIsOpen } = useContext(ModalContext);

  useEffect(() => {
    setShowDataFormatImage(expandedList['2'] as boolean);
  }, [expandedList]);

  const handleExpand = (faqId: string) => {
    expandedList[faqId] = !expandedList[faqId];
    setExpandedList({ ...expandedList });

    const expandedFaq = faqList.find((faq) => faq.id === faqId);
    track('Upload Instructions', {
      expandedQuestion: expandedFaq?.question,
    });
  };

  const faqList = [
    {
      id: '1',
      dataTestId: 'instructions-best-data-question',
      question: 'What is the best data to export?',
      answers: [
        'Pick a sub-system and upload at least two to four weeks of data. Try to include data with both normal and anomalous behavior.',
      ],
    },
    {
      id: '2',
      dataTestId: 'instructions-format-data-question',
      question: 'How do I format my data?',
      answers: [
        'Export the data from your historian, DCS, or data archives into a wide format where each row is a time point and each column has the value for a tag at that time.',
        'Make one file per sub-system.',
      ],
    },
  ];

  return (
    <ModalBodyContainer>
      <Title>
        <div>ControlRooms is best in real-time...</div>
        <div>but you can kick the tires with file uploads</div>
      </Title>
      {faqList.map((faq) => (
        <FaqSectionContainer key={faq.id} className={expandedList[faq.id] ? 'expanded' : ''}>
          <div
            className="section-header"
            data-testid="section-header"
            onClick={() => handleExpand(faq.id)}
          >
            <div className="icon-wrapper">{<ExpandIcon height={'13'} width={'13'} />}</div>
            <div className="faq" data-testid={faq.dataTestId}>
              {faq.question}
            </div>
          </div>
          <div className="section-content" data-testid="section-content">
            {faq.answers.map((ans) => (
              <div key={ans}>{ans}</div>
            ))}
          </div>
        </FaqSectionContainer>
      ))}
      <ImageContainer>
        <img
          className={showDataFormatImage ? 'show-image' : ''}
          src={themeName === ThemeOptions.LIGHT ? UploadDataFormatLight : UploadDataFormat}
          alt="Control Rooms"
          height={245}
        />
      </ImageContainer>
      <ColumnContainer>
        <Column>
          <ColumnHeader>See a sample data file</ColumnHeader>
          <ColumnBody>
            <Button
              data-testid="download-demo-button"
              buttonSize="large"
              buttonType="primary"
              disabled={downloadDisabled}
              style={{ padding: '0 20px', height: '30px' }}
              onClick={() => {
                track('Upload Instructions', {
                  downloadDemoCSV: 'clicked',
                });
                setDownloadDisabled(true);
                downloadTemplate().finally(() => setDownloadDisabled(false));
              }}
            >
              Download demo CSV
            </Button>
            <ColumnCopy>
              Here’s a week of demo plant data to try out. You can also use this CSV file as a
              template.
            </ColumnCopy>
          </ColumnBody>
        </Column>
        <Column>
          <ColumnHeader>Then upload your plant data</ColumnHeader>
          <ColumnBody>
            <Button
              data-testid="upload-data-file"
              buttonSize="large"
              buttonType="primary"
              iconName="upload"
              style={{ padding: '0 20px', height: '30px' }}
              onClick={() => {
                track('Upload Instructions', {
                  uploadDataFile: 'clicked',
                });
                const _params = new URLSearchParams(searchParams);
                _params.delete(QueryParams.TRIAL_UPLOAD);
                _params.set(QueryParams.UPLOAD, 'true');
                setSearchParams(_params);
                setIsOpen(false);
              }}
            >
              Upload data file
            </Button>
            <ColumnCopy>
              Upload your data or try it with the sample. Upload button is always available up top.
            </ColumnCopy>
          </ColumnBody>
        </Column>
      </ColumnContainer>
    </ModalBodyContainer>
  );
};

export const UploadInstructionsFooter: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { setIsOpen } = useContext(ModalContext);
  const { show: showIntercom } = useIntercom();
  const { track } = useAnalytics();

  return (
    <FooterContainer>
      <div className="section">
        <span className="help-text">Need help from a colleague?</span>
        <Button
          data-testid="invite-your-data-specialist-button"
          buttonSize="small"
          buttonType="secondary"
          style={{ marginLeft: '12px' }}
          onClick={() => {
            track('Upload Instructions', {
              inviteDataSpecialist: 'clicked',
            });
            const _params = new URLSearchParams(searchParams);
            _params.delete(QueryParams.TRIAL_UPLOAD);
            _params.set(QueryParams.INVITE, 'true');
            setSearchParams(_params);
            setIsOpen(false);
          }}
        >
          Invite Your Data Specialist
        </Button>
      </div>
      <div className="section">
        <span className="help-text">We’re ready to help</span>
        <Button
          data-testid="talk-to-engineer-button"
          buttonSize="small"
          buttonType="secondary"
          style={{ marginLeft: '12px' }}
          onClick={() => {
            track('Upload Instructions', {
              chatWithEngineer: 'clicked',
            });
            showIntercom();
          }}
        >
          Talk to a ControlRooms Engineer
        </Button>
      </div>
    </FooterContainer>
  );
};

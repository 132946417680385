import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { Fields, TimeRanges, TimePresetKeys } from '@controlrooms/models';

export const ShiftReportProvider: React.FC = ({ children }) => {
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      type: TimeRanges.PRESET,
      [Fields.TIMERANGE]: TimeRanges.CUSTOM,
      [Fields.CUSTOM_TIMEFRAME]: '',
      [Fields.PRESET]: TimePresetKeys.MORNING_REPORT,
    },
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};

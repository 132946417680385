import { useEffect, useMemo, useRef, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { Paths } from '../../../app/constants/paths';
import { useTenant } from '../../../app/context/tenant-context';
import { customScrollPrimary } from '../../../app/global-styles';
import {
  useGetUserCollections,
  useGetUserSharedCollections,
  useGetUserSharedViewsV2,
  useGetUserViewsV2,
} from '../../../app/hooks/accounts';
import { useEnsembleModelsQuery } from '../../../app/hooks/tags';
import { TimeRangeUtil } from '../../../app/utils/time';
import { useLayoutContext } from '../../context/layout-context';
import { transformToViewState } from '../../context/view-context';

import { CollectionTab } from './CollectionTab';
import { SaveCollectionModal } from './SaveCollectionModal';

import { Icon, Tooltip } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { useClickOutside } from '@controlrooms/hooks';
import {
  DateFormats,
  PersistCollection,
  PersistView,
  TimeSelection,
  ViewType,
} from '@controlrooms/models';
import { TimeUtils } from '@controlrooms/utils';

const Tabs = styled.div`
  display: flex;
  // border-bottom: ${({ theme }) => `2px solid ${theme.v2.tab.tab.backgroundColor}`};
  .container {
    display: flex;

    &.hasMoreTabs {
      .label {
        display: none;
      }
    }
  }

  .add-new,
  .viewsets-option {
    width: 50px;
    padding: 0px;
    height: 100%;
  }

  .tabs {
    height: 50px;
    background: ${({ theme }) => theme.v2.tab.tab.tabContainerBG};
    flex-grow: 1;
    overflow: hidden;
    overflow-x: auto;
    white-space: nowrap;
    justify-content: flex-start;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera */
    }
  }
`;
// exported
export const TabBaseLine = styled.div`
  width: 100%;
  height: 2px;
  background: ${({ theme }) => theme.v2.tab.tab.backgroundColor};

  width: 100%;
  height: 2px;
  box-shadow: none;
`;

// exported
export const Tab = styled.button<{ isActive?: boolean }>`
  border: none;
  border: ${({ theme }) => `1px solid ${theme.v2.tab.tab.backgroundColor}`};
  border-right: ${({ theme }) => `1px solid ${theme.v2.tab.tab.tabContainerBG}`};
  background-color: ${({ theme }) => theme.v2.tab.tab.backgroundColor};
  border-bottom: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 10px 0px;
  flex-direction: column;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  .subMenu-link {
    padding: 10px;
  }
  ${({ isActive }) =>
    isActive &&
    `
    border-bottom: 2px solid #93BBC3;
    ${TabBaseLine} {
      background-color: #93BBC3;
      box-shadow: 0px -3px 10px 2px #93BBC3;
    }
  `}
  ${({ isActive, theme }) =>
    !isActive &&
    `
    .label {
      text-transform: capitalize;
      ::before {
        content: '';
        display: block;
        width: 100%;
        height: 20px;
        background-color: ${theme.v2.tab.tab.backgroundColor};
      }
    }
  `}
    &:hover {
    border-bottom: 2px solid #93bbc3;
    ${TabBaseLine} {
      background-color: #93bbc3;
      box-shadow: 0px -3px 10px 2px #93bbc3;
    }
  }

  &:focus {
    outline: none;
  }

  &.viewsets-option {
    padding: 5px;
  }
  .tab-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 2;
  }

  &.collection {
  }
  .label {
    text-transform: capitalize;
    max-width: 150px;
    display: flex;
    flex: 1 0 0;
    padding-left: 10px;
    overflow: hidden;
    color: ${({ theme }) => theme.v2.tab.tab.textColor};
    text-overflow: ellipsis;

    font-family: 'Open Sans';
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 13px;
    white-space: nowrap;
    align-items: center;

    ::before {
      content: '';
      display: block;
      width: 100%;
      height: 20px;
      background: ${({ theme }) => theme.v2.tab.tab.backgroundColor};
    }
  }
`;
// exported
export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  position: absolute;
  top: 40px;
  background-color: ${({ theme }) => theme.modal.contentBackgroundColor};
  border-radius: 4px;
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.6);

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    &.expanded-list {
      max-height: 300px;
      ${customScrollPrimary}
      overflow-y: auto;
    }

    li {
      display: flex;
      padding: 5px 25px;
      align-items: center;
      align-self: stretch;
      cursor: pointer;

      &.separator {
        border-bottom: ${({ theme }) => `1px solid ${theme.v2.tab.tab.backgroundColor}`};
        background-color: ${({ theme }) => theme.modal.contentBackgroundColor};
      }

      &.disabled {
        cursor-event: not-allowed;
        opacity: 0.5;
      }

      &.option {
        font-family: 'Open Sans';
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 13px;
        color: ${({ theme }) => theme.modal.panel.color};
        &:hover {
          background: ${({ theme }) => theme.sidebar.folders.hover.backgroundGradient};
        }
        .view-name {
          width: 300px;
          text-overflow: ellipsis;
          text-wrap: nowrap;
          overflow: hidden;
        }
        &:hover {
          .side-menu,
          .chevron {
            display: block;
          }
        }
        .chevron {
          display: none;
        }
        .side-menu {
          display: none;
          z-index: 2;
          position: fixed;
          color: ${({ theme }) => theme.modal.panel.color};
          border-radius: 3px;
          right: 10px;
          margin-top: 100px;
          background-color: ${({ theme }) => theme.modal.contentBackgroundColor};
          box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.6);
          li:hover {
            background: ${({ theme }) => theme.sidebar.folders.hover.backgroundGradient};
          }
        }
      }
      &.label {
        font-family: 'Open Sans';
        font-size: 9px;
        font-style: normal;
        font-weight: 700;
        line-height: 9px; /* 100% */
        text-transform: uppercase;
        background-color: ${({ theme }) => theme.sidebar.folders.headerBackgroundColor};
        color: ${({ theme }) => theme.modal.panel.color};
        margin-top: 0px;
      }
    }
  }
`;

export const FieldLabel = styled.label`
  color: #a0a38d;
  font-family: 'Open Sans';
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  line-height: 13px;
  margin-bottom: 5px;
  display: inline-block;
`;

export const preparePrimaryLabel = (timeselection: TimeSelection, customString = false) => {
  const hoursDisplay = TimeRangeUtil.calculateTimePreset(
    'label',
    timeselection.streamingTimeInSeconds || 0,
  );
  if (timeselection.nowSelected && !hoursDisplay) {
    return (
      <>
        {TimeUtils.toTimezone(timeselection.startTime, timeselection.timezone).format(
          DateFormats.DATE,
        )}{' '}
        - Now
      </>
    );
  }
  if (customString) {
    return (
      <span className="time">
        {TimeUtils.toTimezone(timeselection.startTime, timeselection.timezone).format(
          'MM-DD-YYYY HH:mm:ss z',
        )}{' '}
        -{' '}
        {TimeUtils.toTimezone(timeselection.endTime, timeselection.timezone).format(
          'MM-DD-YYYY HH:mm:ss z',
        )}
      </span>
    );
  }
  return (
    hoursDisplay || (
      <span className="time">
        {TimeUtils.toTimezone(timeselection.startTime, timeselection.timezone).format(
          'MM-DD-YYYY HH:mm:ss z',
        )}{' '}
        -{' '}
        {TimeUtils.toTimezone(timeselection.endTime, timeselection.timezone).format(
          'MM-DD-YYYY HH:mm:ss z',
        )}
      </span>
    )
  );
};

export const TabContainer = () => {
  //TODO: Add logic to list all viewsets on the options
  // const { data: viewsets } = useViewSet();
  const scrollRef = useRef<HTMLDivElement>(null);
  const { data: userSharedViews } = useGetUserSharedViewsV2();
  const { data: useSharedCollections } = useGetUserSharedCollections();
  const { data: _userViews } = useGetUserViewsV2();
  const { data: userCollections } = useGetUserCollections();
  const { ensembleFamilies } = useEnsembleModelsQuery();
  const { tenant } = useTenant();

  const userViews = useMemo(() => {
    return _userViews?.filter((v) => v.default_view === false) || [];
  }, [_userViews]);

  const sharedList = useMemo(() => {
    return [
      ...(userSharedViews || []).map((v) => {
        return {
          name: v.view.name,
          id: v.view_id || '',
          createdBy: v.created_by,
          userId: v.user_id,
          isCollection: false,
          entity: v,
        };
      }),
      ...(useSharedCollections || []).map((c) => {
        return {
          name: c.collection_name,
          id: c.collection_id || '',
          createdBy: '',
          userId: '',
          isCollection: true,
          entity: c,
        };
      }),
    ];
  }, [useSharedCollections, userSharedViews]);

  const { pathname } = useLocation();
  const isView = matchPath(Paths.VIEWS, pathname);

  const {
    setViewIds,
    setSavedViewIds,
    setActiveModes,
    activeModes,
    setActiveView,
    viewIds,
    viewCollection,
    setViewCollection,
  } = useLayoutContext();
  const [showCollectionSubmenu, setShowCollectionSubmenu] = useState(false);
  const [showSaveCollectionModal, setShowSaveCollectionModal] = useState(false);
  const hasNewTabs = viewIds.length > 3;

  const menuRef = useRef(null);
  useClickOutside(menuRef, () => setShowCollectionSubmenu(false));

  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      if (scrollRef.current) {
        event.preventDefault();
        scrollRef.current.scrollLeft += event.deltaY;
      }
    };

    const currentRef = scrollRef.current;
    if (currentRef) {
      currentRef.addEventListener('wheel', handleWheel);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('wheel', handleWheel);
      }
    };
  }, []);

  const handleAddNewView = () => {
    const uuid = uuidv4();
    setViewIds((prevItems) => [...prevItems, uuid]);
    setActiveModes((prev) => ({ ...prev, [uuid]: ViewType.MONITOR }));
    setActiveView(uuid);
    if (viewCollection.name !== '') {
      setViewCollection((prev) => ({ ...prev, isDirty: true }));
    }
  };

  const handleCloseAll = () => {
    setActiveView('default');
    viewIds.forEach((id) => {
      if (id !== 'default') sessionStorage.removeItem(id);
    });
    setViewIds(['default']);
    setSavedViewIds([]);
    setActiveModes({ default: activeModes['default'] });
    setViewCollection({
      name: '',
      viewsIds: [],
      views: [],
    });
  };

  const generateView = (view: PersistView) => {
    const ensembleFamily = ensembleFamilies.find(
      (e) => e.family_id === view?.view?.ensemble_family_id,
    );
    // view.view = {
    //   ...view.view,
    //   timeSelection: {
    //     ...view.view.timeSelection,
    //     timePreset: 'custom',
    //     timeRange: 'custom' as TimeRangesType,
    //     streamingTimeInSeconds: 0,
    //   },
    // };
    const viewState = transformToViewState(view, ensembleFamily || null, tenant);
    sessionStorage.setItem(view.view_id || 'error', JSON.stringify(viewState));
    return viewState;
  };

  const handleLoadView = (view: PersistView) => {
    const _viewState = generateView(view);
    setViewIds((prevItems) => Array.from(new Set([...prevItems, _viewState.view_id])));
    setActiveModes((prev) => ({ ...prev, [_viewState.view_id]: view.view.type }));
    setActiveView(_viewState.view_id);
    if (viewCollection.collectionId) {
      setViewCollection((prev) => ({
        ...prev,
        viewsIds: Array.from(new Set([...prev.viewsIds, _viewState.view_id])),
        views: [...prev.views.filter((v) => v.view_id !== _viewState.view_id), view],
        isDirty: true,
      }));
    }
  };

  const handleLoadCollection = (collection: PersistCollection) => {
    if (collection.views) {
      handleCloseAll();
      collection.views?.forEach((view: PersistView) => {
        const _viewState = generateView(view);
        setViewIds((prevItems) => [...prevItems, _viewState.view_id]);
        setActiveModes((prev) => ({ ...prev, [_viewState.view_id]: view.view.type }));
      });
      setActiveView(collection.views[0]?.view_id || 'default');
      const viewIds: string[] = collection.views
        .map((view) => view.view_id)
        .filter((id): id is string => id !== undefined);
      setViewCollection((prev) => ({
        ...prev,
        viewsIds: viewIds,
        name: collection.collection_name,
        description: collection.description,
        isDirty: false,
        collectionId: collection.collection_id,
      }));
    }
  };

  const handleCreateNewCollection = () => {
    setShowSaveCollectionModal(true);
  };

  return (
    <Tabs>
      <div
        className={`container default ${hasNewTabs || viewCollection.name ? 'hasMoreTabs' : ''}`}
        id="default-tab-container"
      ></div>
      {viewCollection.name !== '' && isView && <CollectionTab />}
      <div className="container tabs" id="tab-container" ref={scrollRef}></div>
      {isView && (
        <div className="container menu">
          <Tooltip label={'Add New View'}>
            <Tab className="add-new" onClick={() => handleAddNewView()}>
              <div className="tab-content">
                <Icon name={ICONS.Plus} width="15" height="20" />
              </div>
            </Tab>
          </Tooltip>
          <Tab
            className="viewsets-option"
            onClick={() => setShowCollectionSubmenu((prev) => !prev)}
          >
            <div className="tab-content">
              <Icon name={ICONS.Kebab} width="15" height="20" color="#A0A38D" />
            </div>
          </Tab>
          {showCollectionSubmenu && (
            <Menu
              ref={menuRef}
              style={{ right: '10px', zIndex: 2, color: '#faffda', width: '300px' }}
            >
              <ul>
                {viewIds.length > 1 && (
                  <li
                    className={`option`}
                    onClick={() => {
                      handleCloseAll();
                      setShowCollectionSubmenu(false);
                    }}
                  >
                    Close All Views
                  </li>
                )}
                <li
                  className={`option ${
                    viewIds.length === 1 && viewIds[0] === 'default' ? 'disabled' : ''
                  }`}
                  onClick={() => {
                    if (viewIds.length > 1) {
                      handleCreateNewCollection();
                      setShowCollectionSubmenu(false);
                    }
                  }}
                >
                  Save open views as new collection
                </li>
                {/* <li className={`option`}>Manage your views and collections</li> */}
                {userCollections?.length ? <li className="separator"></li> : null}
                {userCollections?.length ? <li className={`label`}>YOUR COLLECTIONS</li> : null}
                {userCollections?.length ? (
                  <ul className="expanded-list">
                    <Virtuoso
                      style={{
                        height: `${(userCollections?.length || 1) * 23}px`,
                        maxHeight: '300px',
                      }}
                      className="my-view-container"
                      totalCount={userCollections ? userCollections.length : 0}
                      overscan={10}
                      itemContent={(index) => {
                        if (userCollections?.length) {
                          const collection = userCollections[index];
                          return (
                            <Tooltip label={collection.collection_name || 'Unnamed Collection'}>
                              <li
                                className={`option`}
                                onClick={() => {
                                  handleLoadCollection(collection);
                                  setShowCollectionSubmenu(false);
                                }}
                              >
                                {collection.collection_name || 'Unnamed Collection'}
                              </li>
                            </Tooltip>
                          );
                        }
                      }}
                    />
                  </ul>
                ) : null}
                {userViews?.length ? <li className="separator"></li> : null}
                {userViews?.length ? <li className={`label`}>YOUR VIEWS</li> : null}
                {userViews?.length ? (
                  <ul className="expanded-list">
                    <Virtuoso
                      style={{
                        height: `${(userViews?.length || 1) * 23}px`,
                        maxHeight: '300px',
                      }}
                      className="my-view-container"
                      totalCount={userViews ? userViews.length : 0}
                      overscan={10}
                      itemContent={(index) => {
                        if (userViews?.length) {
                          const view = userViews[index];
                          return (
                            <li
                              className={`option`}
                              onClick={() => {
                                handleLoadView(view);
                                setShowCollectionSubmenu(false);
                              }}
                            >
                              <div className="view-name">
                                <Tooltip label={view?.view?.name || 'Unnamed view'}>
                                  {view?.view?.name || 'Unnamed view'}
                                </Tooltip>
                              </div>
                              {/* TODO: To be implemented */}
                              {/* <Icon
                                className="chevron"
                                name={ICONS.Chevron}
                                width="10"
                                height="10"
                                color="#A0A38D"
                                style={{ transform: 'rotate(270deg)' }}
                              /> */}
                              {/* <MenuOptions
                                entity={{ view, isView: true }}
                                onMenuClose={() => {
                                  setShowCollectionSubmenu(false);
                                }}
                              /> */}
                            </li>
                          );
                        }
                      }}
                    />
                  </ul>
                ) : null}
                {sharedList?.length ? <li className="separator"></li> : null}
                {sharedList?.length ? <li className={`label`}>SHARED WITH YOU</li> : null}
                {sharedList?.length ? (
                  <ul className="expanded-list">
                    <Virtuoso
                      style={{
                        height: `${(sharedList?.length || 1) * 23}px`,
                        maxHeight: '300px',
                      }}
                      className="my-view-container"
                      totalCount={sharedList ? sharedList.length : 0}
                      overscan={10}
                      itemContent={(index) => {
                        if (sharedList?.length) {
                          const viewCollection = sharedList[index];
                          return (
                            <li
                              className={`option`}
                              onClick={() => {
                                viewCollection.isCollection
                                  ? handleLoadCollection(viewCollection.entity as PersistCollection)
                                  : handleLoadView(viewCollection.entity as PersistView);
                                setShowCollectionSubmenu(false);
                              }}
                            >
                              {viewCollection.isCollection && (
                                <Icon name={ICONS.Folder} width="15" height="20" />
                              )}
                              <div
                                className="view-name"
                                style={{ marginLeft: viewCollection.isCollection ? '10px' : '0px' }}
                              >
                                {viewCollection.name || 'Unnamed'}
                              </div>
                            </li>
                          );
                        }
                      }}
                    />
                  </ul>
                ) : null}
              </ul>
            </Menu>
          )}
        </div>
      )}
      {showSaveCollectionModal ? (
        <SaveCollectionModal onClose={() => setShowSaveCollectionModal(false)} />
      ) : null}
    </Tabs>
  );
};

//TODO: Add menu options for view and collection submenu
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MenuOptions = ({
  entity,
  onMenuClose,
}: {
  entity: { view: PersistView; isView: boolean };
  onMenuClose: () => void;
}) => {
  console.log('entity-----', entity);
  return (
    <ul className="side-menu">
      <li
        onClick={() => {
          onMenuClose();
        }}
      >
        Open
      </li>
      <li
        onClick={() => {
          onMenuClose();
        }}
      >
        Manage
      </li>
      <li
        onClick={() => {
          onMenuClose();
        }}
      >
        Share
      </li>
      <li
        onClick={() => {
          onMenuClose();
        }}
      >
        Delete
      </li>
    </ul>
  );
};

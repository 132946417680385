import Request from './request';

import { ApiResult } from '@controlrooms/models';

export interface ReportItem {
  path: string;
}

export interface AnalyzeReportItem extends ReportItem {
  system: number;
}

export interface ReportParams {
  from: string;
  to: string;
  analyze: AnalyzeReportItem[];
  monitor: ReportItem | null;
}

const SERVICE_ROOT = '/cr-app-report';

export const downloadReport = (params: ReportParams): Promise<ApiResult<Blob>> => {
  return Request.post(`${SERVICE_ROOT}/api/v2/cr-export-report`, params, {
    responseType: 'blob',
  });
};

import styled from 'styled-components';

export const RadioLabel = styled.label<{ checked: boolean }>`
  align-self: center;
  cursor: pointer;
  color: ${({ checked, theme }) =>
    checked ? theme.modal.panel.selectedTextColor : theme.modal.panel.unselectedTextColor};
  font-style: normal;
  font-weight: ${({ checked }) => (checked ? '700' : '300')};
  font-size: 13px;
  line-height: 13px;
  padding-left: 10px;
  padding-top: 4px;
`;

export const Label = styled.label`
  display: grid;
  grid-template-columns: 10px auto;
  gap: 10px;
  margin-top: 3px;
  font-style: normal;
  font-weight: 200;
  font-size: 13px;
  font-family: 'Open Sans', sans-serif;
  line-height: 19px;
  color: ${({ theme }) => theme.modal.panel.color};
`;

export const OptionsGroup = styled.div`
  cursor: default;
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  font-family: 'Open Sans', sans-serif;
  line-height: 13px;
  color: ${({ theme }) => theme.modal.panel.optionsColor};
  border-bottom: 1px solid ${({ theme }) => theme.modal.panel.optionsColor};
  padding: 5px 0;
  padding-left: 12px;
  margin-bottom: 5px;
`;

export const OtherTimezoneContainer = styled.div`
  display: flex;

  .other-label {
    display: flex;
    align-items: center;
  }
`;

export const OtherTimezoneSelect = styled.div<{ isOpen: boolean }>`
  z-index: 100;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 301px;
  height: 400px;
  padding: 0;
  margin-top: 25px;
  background-color: ${({ theme }) => theme.modal.contentBackgroundColor};
  border: 1px solid black;
  border-radius: 4px;
  @media (max-width: 700px) {
    right: 0;
  }
  ${({ isOpen }) => {
    if (!isOpen) {
      return `
        display: none;
      `;
    }
  }}
`;

export const Button = styled.button`
  display: flex;
  margin: 0;
  padding: 0;
  background-color: inherit;
  border: none;
  color: inherit;
  font-style: inherit;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  &:hover {
    cursor: pointer;
  }
`;

export const ButtonGroup = styled.div`
  align-self: center;
  display: flex;
  margin-left: 5px;
  span {
    margin-right: 10px;
  }
`;

export const ChevronContainer = styled.div`
  padding: none;
  margin-left: 7px;
  width: 8px;
`;

export const SearchInput = styled.input`
  background-color: ${({ theme }) => theme.modal.panel.searchBackgroundColor};
  box-sizing: border-box;
  font-style: italic;
  width: 279px;
  align-self: center;
  margin-top: 10px;
  border: 1px solid ${({ theme }) => theme.modal.panel.searchBorderColor};
  border-radius: 4px;
  color: ${({ theme }) => theme.modal.panel.searchColor};
  padding-left: 10px;
  font-size: 11px;
  font-family: 'Open Sans', sans-serif;
  line-height: 22px;
`;

export const Ul = styled.ul`
  padding: 0;
  margin: 0;
  overflow: auto;
  margin: 10px 0;
  height: 400px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Li = styled.li`
  color: white;
  list-style: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 200;
  font-size: 11px;
  font-family: 'Open Sans', sans-serif;
  line-height: 17px;
  padding: 3px 0;
  padding-left: 0;
  margin-left: 22px;
  margin-right: 6px;
  color: ${({ theme }) => theme.modal.panel.color};
  &:first-child {
    border-bottom: 1px solid ${({ theme }) => theme.modal.panel.unselectedTextColor};
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  &:hover {
    background: ${({ theme }) => theme.sidebar.folders.hover.backgroundGradient};
  }
`;

export const TimezoneDisplayContainer = styled.div`
  display: flex;
  padding: 0 4px;
  justify-content: space-between;
`;

export const NoResults = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
  margin-top: -25px;
`;

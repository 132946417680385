import { FileUploadModalTheme } from 'styled-components';

import { colors } from '@controlrooms/design-tokens';

export const fileUploadModalDarkTheme: FileUploadModalTheme = {
  body: {
    titleColor: colors.k[100],
  },
  uploader: {
    headerText: colors.content['neutral-3'],
    subHeaderText: colors.focus.dark[2],
    backgroundAcceptColor: colors.content['neutral-6'],
    borderColor: colors.container['b-g-4'],

    file: {
      background: colors.container['b-g-8'],
      boxShadow: colors.elevation.dark[1],
      border: `1px solid ${colors.content['neutral-6']}`,
      heading: {
        color: colors.k[100],
      },
      bounds: {
        color: colors.k[80],
      },
      meta: {
        color: colors.k[55],
      },
    },
  },
  footer: {
    footerInfoColor: colors.content['neutral-2'],
  },
  progress: {
    backgroundColor: colors.content['neutral-5'],
    progressValueColor: colors.content['neutral-1'],
  },
  preview: {
    filenameColor: colors.k[100],
    fileMetaColor: colors.k[55],
    border: `1px solid ${colors.container['b-g-7']}`,
  },
};

export const fileUploadModalLightTheme: FileUploadModalTheme = {
  body: {
    titleColor: colors.content['neutral-6'],
  },
  uploader: {
    headerText: colors.content['neutral-5'],
    subHeaderText: colors.focus.light[2],
    backgroundAcceptColor: colors.container['b-g-3'],
    borderColor: colors.container['b-g-6'],

    file: {
      background: colors.container['b-g-1'],
      boxShadow: colors.elevation.light[1],
      border: `1px solid ${colors.container['b-g-3']}`,
      heading: {
        color: colors.k[5],
      },
      bounds: {
        color: colors.k[20],
      },
      meta: {
        color: colors.k[55],
      },
    },
  },
  footer: {
    footerInfoColor: colors.content['neutral-5'],
  },
  progress: {
    backgroundColor: colors.content['neutral-2'],
    progressValueColor: colors.content['neutral-6'],
  },
  preview: {
    filenameColor: colors.k[5],
    fileMetaColor: colors.k[55],
    border: `1px solid ${colors.container['b-g-3']}`,
  },
};

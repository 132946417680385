/**
 * @typedef {Object} DelayedMouseOutParams
 * @property {HTMLElement[]} elements - The elements to watch for mouse out events.
 * @property {Function} callback - The callback function to call after the delay.
 * @property {number} [delay=1000] - The delay in milliseconds.
 */

let mouseOutTimeoutId: ReturnType<typeof setTimeout>;

/**
 * Calls the callback function after a delay if the mouse is not over any of the provided elements.
 * @param {DelayedMouseOutParams} params - The parameters for the function.
 */
interface DelayedMouseOutParams {
  elements: HTMLElement[];
  callback: () => void;
  delay?: number;
}
export const delayedMouseOut = ({ elements, callback, delay = 1000 }: DelayedMouseOutParams) => {
  let currentX: number, currentY: number;

  /**
   * Mouse move event handler.
   * @param {MouseEvent} event - The mouse event.
   */
  const mouseMoveHandler = (event: MouseEvent) => {
    currentX = event.clientX;
    currentY = event.clientY;
  };

  // Clear any existing timeout to ensure the callback is only called once
  if (mouseOutTimeoutId) {
    clearTimeout(mouseOutTimeoutId);
  }

  mouseOutTimeoutId = setTimeout(() => {
    window.removeEventListener('mousemove', mouseMoveHandler);

    const elementUnderCursor = document.elementFromPoint(currentX, currentY);

    const isOverAnyElement = elements.some(
      (element: HTMLElement) =>
        element === elementUnderCursor || element.contains(elementUnderCursor),
    );

    if (!isOverAnyElement) {
      callback();
    }
  }, delay);

  window.addEventListener('mousemove', mouseMoveHandler);
};

import dayjs from 'dayjs';
import fileDownload from 'js-file-download';
import { useContext, useState } from 'react';
import { useMutation } from 'react-query';
import { generatePath } from 'react-router-dom';

import { Paths } from '../constants/paths';
import { EnsembleContext } from '../context/ensemble-context';
import { useTenant } from '../context/tenant-context';
import { getAnomalies } from '../services/anomalies';
import { AnalyzeReportItem, downloadReport, ReportItem, ReportParams } from '../services/report';

import { useUserId } from './accounts';
import { getSubsystemIds } from './anomalies';
import { usePlantFolders } from './folders';
import { useTenants } from './tenants';
import { useBuildSharedView, useSaveView } from './view';

import {
  TimeSelection,
  ParentTag,
  PersistTimeSelection,
  ViewType,
  DateFormats,
} from '@controlrooms/models';
import { TimeUtils } from '@controlrooms/utils';

export class GenerateReportOptions {
  startTime: string;
  endTime: string;
  analyze: AnalyzeReportItem[];
  monitor: ReportItem | null;
  timezone: string;
  tenantName: string;
  isShiftReport: boolean;

  constructor(
    startTime: string,
    endTime: string,
    analyze: AnalyzeReportItem[],
    monitor: ReportItem | null,
    timezone: string,
    tenantName: string,
    isShiftReport: boolean,
  ) {
    this.startTime = startTime;
    this.endTime = endTime;
    this.analyze = analyze;
    this.monitor = monitor;
    this.timezone = timezone;
    this.tenantName = tenantName;
    this.isShiftReport = isShiftReport;
  }

  public static toReportParams(opts: GenerateReportOptions): ReportParams {
    return {
      from: opts.startTime,
      to: opts.endTime,
      analyze: opts.analyze,
      monitor: opts.monitor,
      timezone: opts.timezone,
      tenantName: opts.tenantName,
      isShiftReport: opts.isShiftReport,
    } as ReportParams;
  }
}

export const useGenerateReport = (filename?: string) => {
  return useMutation(
    (opts: GenerateReportOptions) => downloadReport(GenerateReportOptions.toReportParams(opts)),
    {
      onSuccess: ({ result }) => {
        fileDownload(result, filename ?? 'report.pdf');
      },
    },
  );
};

const buildPath = (hash: string, tenant: number) => {
  return generatePath(Paths.SHARE, { tenantId: tenant.toString(), hash });
};

const buildPayload = (
  {
    analyze,
    monitor,
  }: {
    analyze?: AnalyzeReportItem[];
    monitor?: ReportItem;
  },
  timeSelection: PersistTimeSelection,
  tenantName: string,
  isShiftReport: boolean,
): GenerateReportOptions => {
  const startTime = TimeUtils.toTimezone(timeSelection.startTime, timeSelection.timezone);
  const endTime = TimeUtils.toTimezone(timeSelection.endTime, timeSelection.timezone);

  const _analyze = analyze ?? [];
  const _monitor = monitor ?? null;
  const _timezone = timeSelection.timezone ?? '';
  const _tenantName = tenantName ?? '';
  return {
    analyze: _analyze,
    monitor: _monitor,
    startTime: startTime.format(DateFormats.DATETIME_ZONE),
    endTime: endTime.format(DateFormats.DATETIME_ZONE),
    timezone: _timezone,
    tenantName: _tenantName,
    isShiftReport: isShiftReport,
  };
};

export const useExportMultiView = () => {
  const buildView = useBuildSharedView();
  const { tenant } = useTenant();
  const { currentTenant } = useTenants();
  const { view } = buildView();
  let fileName = `Export_Monitor_${dayjs().format('YYYY_MM_DD')}.pdf`;
  if (view.type === ViewType.ANALYZE) {
    fileName = `Export_Analyze_${dayjs().format('YYYY_MM_DD')}.pdf`;
  }
  const {
    mutateAsync: saveView,
    isLoading: isSaveViewLoading,
    isError: isSaveViewError,
    reset: resetViewState,
  } = useSaveView();
  const {
    mutateAsync: generateReport,
    isLoading: isReportLoading,
    isSuccess: isReportSuccess,
    isError: isReportError,
    reset: resetReportState,
  } = useGenerateReport(fileName);
  return {
    resetError: function () {
      if (isSaveViewError || isReportError) {
        resetViewState();
        resetReportState();
      }
    },
    generateReport: async () => {
      const { view, ...rest } = buildView();
      const pinnedTags = view.pinnedTags;
      const pinnedFolders = pinnedTags?.map((pt: ParentTag) => pt.folder) || [];
      const reportFolders = Array.from(new Set([...pinnedFolders, ...view.selectedFolders]));
      const isShiftReport = false;
      if (view.type === ViewType.ANALYZE && reportFolders.length === 1) {
        const analyzeView = await saveView({ ...rest, view }).then((hash) => ({
          system: reportFolders[0],
          path: buildPath(hash, tenant),
        }));
        const payload = buildPayload(
          { analyze: [analyzeView] },
          view.timeSelection,
          currentTenant.name,
          isShiftReport,
        );
        return generateReport(payload);
      }

      if (view.type === ViewType.ANALYZE) {
        const perFolderViews = reportFolders.map((folder) => ({
          ...view,
          selectedFolders: view.selectedFolders.includes(folder) ? [folder] : [],
          pinnedTags: view.pinnedTags?.filter((tag: ParentTag) => tag.folder === folder) || [],
        }));
        const analyzeViews = await Promise.all(
          perFolderViews.map((_view) =>
            saveView({ ...rest, view: _view }).then((hash) => ({
              system: _view.selectedFolders[0],
              path: buildPath(hash, tenant),
            })),
          ),
        );
        const payload = buildPayload(
          { analyze: analyzeViews },
          view.timeSelection,
          currentTenant.name,
          isShiftReport,
        );
        return generateReport(payload);
      }
      const monitorView = await saveView({ ...rest, view }).then((hash) => ({
        path: buildPath(hash, tenant),
      }));
      const payload = buildPayload(
        { monitor: monitorView },
        view.timeSelection,
        currentTenant.name,
        isShiftReport,
      );
      return generateReport(payload);
    },
    isLoading: isSaveViewLoading || isReportLoading,
    isSuccess: isReportSuccess,
    isError: isSaveViewError || isReportError,
  };
};

export const useShiftReport = () => {
  const { currentTenant } = useTenants();
  const currentUserId = useUserId();
  const { selectedEnsemble } = useContext(EnsembleContext);
  const { tenant } = useTenant();
  const fileName = `Export_Shift_Report_${dayjs().format('YYYY_MM_DD')}.pdf`;
  const { data: plant } = usePlantFolders();
  const {
    mutateAsync: saveView,
    isLoading: isSaveViewLoading,
    isError: isSaveViewError,
    reset: resetViewState,
  } = useSaveView();
  const {
    mutateAsync: generateReport,
    isLoading: isReportLoading,
    isSuccess: isReportSuccess,
    isError: isReportError,
    reset: resetReportState,
  } = useGenerateReport(fileName);
  const [isGenerating, setisGenerating] = useState(false);

  return {
    resetError: function () {
      if (isSaveViewError || isReportError) {
        resetViewState();
        resetReportState();
      }
    },
    generateReport: async (
      timeSelection: Pick<TimeSelection, 'startTime' | 'endTime' | 'timezone'>,
    ) => {
      setisGenerating(true);
      const isShiftReport = true;
      const view = {
        timeSelection: {
          startTime: timeSelection.startTime.toISOString(),
          endTime: timeSelection.endTime.toISOString(),
          timezone: timeSelection.timezone,
          streamingTimeInSeconds: undefined,
        },
        selectedFolders: [],
      };

      const anomalousFolders = getSubsystemIds(plant);

      const anomaliesInTime = await getAnomalies({
        folders: anomalousFolders,
        interval: 60,
        start_time: dayjs.utc(timeSelection.startTime).tz(timeSelection.timezone).toISOString(),
        end_time: dayjs.utc(timeSelection.endTime).tz(timeSelection.timezone).toISOString(),
        ensemble_family_id: selectedEnsemble?.family_id,
      });

      const reportViewFolders: number[] = [];

      anomalousFolders?.map((folderId: number) => {
        const { anomalies, folder, limits_exceeded } = anomaliesInTime.result[folderId];
        if ((anomalies && anomalies.some((a) => a.value > 0)) || limits_exceeded) {
          reportViewFolders.push(folder);
        }
      });

      const analyzeViews = await Promise.all(
        reportViewFolders.map((id) =>
          saveView({
            view: {
              ...view,
              selectedFolders: [Number(id)],
              type: ViewType.ANALYZE,
              name: 'shift_report_' + new Date().getTime().toString() + '_' + currentUserId,
            },
            shared: true,
          }).then((hash) => ({
            system: Number(id),
            path: buildPath(hash, tenant),
          })),
        ),
      );

      const monitorView = await saveView({
        view: {
          ...view,
          type: ViewType.MONITOR,
          name: 'shift_report_' + new Date().getTime().toString() + '_' + currentUserId,
        },
        shared: true,
      }).then((hash) => ({
        path: buildPath(hash, tenant),
      }));

      const payload = buildPayload(
        { monitor: monitorView, analyze: analyzeViews },
        view.timeSelection,
        currentTenant.name,
        isShiftReport,
      );
      const reprotResponse = await generateReport(payload);
      setisGenerating(false);
      return reprotResponse;
    },
    isLoading: isSaveViewLoading || isReportLoading,
    isGenerating: isGenerating,
    isSuccess: isReportSuccess,
    isError: isSaveViewError || isReportError,
  };
};

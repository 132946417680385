import * as d3 from 'd3';

import { Anomaly, SVGDefsSelection, TenantConfiguration } from '@controlrooms/models';

export const buildNoData = (
  container: SVGDefsSelection,
  chartData: Anomaly[],
  folderId: number,
  xScale: d3.ScaleTime<number, number, never>,
  tenantConfig: TenantConfiguration | undefined,
  params: { errorHeight: number; interval: number; noDataHeight: number; rowHeight: number },
) => {
  const { interval, noDataHeight, rowHeight } = params;

  const noDataLine = (d: Anomaly) => {
    const xStart = xScale(d.time);
    const xEnd = xScale(d.time + interval * 1000);
    const linePoints = getLine(xStart, xEnd, noDataHeight, rowHeight);
    return pointsToPath(linePoints);
  };

  const noDataValue = tenantConfig?.monitorNoData;
  return container
    .selectAll('polygon')
    .data(chartData)
    .join('polygon')
    .attr('points', (d: Anomaly) => {
      return noDataLine(d);
    })
    .classed('no-data', (d: Anomaly) => d.value === noDataValue);
};

export const buildErrors = (
  container: SVGDefsSelection,
  chartData: Anomaly[],
  folderId: number,
  xScale: d3.ScaleTime<number, number, never>,
  tenantConfig: TenantConfiguration | undefined,
  params: { errorHeight: number },
) => {
  const { errorHeight } = params;

  const noDataValue = tenantConfig?.monitorNoData;
  return container
    .selectAll('circle')
    .data(chartData)
    .enter()
    .append('circle')
    .attr('r', (d: Anomaly) => {
      return d.value === noDataValue ? 0 : 0.875;
    })
    .attr('cx', (d: Anomaly) => {
      return d.value === noDataValue ? 0 : xScale(d.time) + 4;
    })
    .attr('cy', (d: Anomaly) => {
      return d.value === noDataValue ? 0 : errorHeight;
    })
    .classed('error', (d: Anomaly) => d.value < 0 && d.value !== noDataValue);
};

type Point = [x: number, y: number];

export const getTriangle = (
  xStart: number,
  xEnd: number,
  height: number,
  rowHeight: number,
): [Point, Point, Point] => {
  if (height > rowHeight) throw Error('height must be less than row height');

  const xCenter = xStart + (xEnd - xStart) / 2;
  const width = Math.min(Math.floor(xEnd - xStart) - 1, height);
  const pt1 = [xCenter - width / 2, rowHeight / 2 + height / 2] as Point;
  const pt2 = [xCenter, rowHeight / 2 - height / 2] as Point;
  const pt3 = [xCenter + width / 2, rowHeight / 2 + height / 2] as Point;
  return [pt1, pt2, pt3];
};

export const getLine = (
  xStart: number,
  xEnd: number,
  height: number,
  rowHeight: number,
): [Point, Point] => {
  if (height > rowHeight) throw Error('height must be less than row height');

  const xCenter = xStart + (xEnd - xStart) / 2;
  const width = (xStart - xEnd) * 0.85;
  const pt1 = [xCenter - width / 2, rowHeight / 2] as Point;
  const pt2 = [xCenter + width / 2, rowHeight / 2] as Point;
  return [pt1, pt2];
};

export const pointsToPath = (points: Array<Point>) => points.map(([x, y]) => `${x},${y}`).join(' ');

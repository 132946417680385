import { useAnalytics } from '../../../../analytics';
import { useTheme, useUpdateUserPreferences } from '../../../../hooks/accounts';

import { RadioButton, RadioHeader } from '@controlrooms/components';

export enum ThemeOptions {
  DARK = 'Dark',
  LIGHT = 'Light',
  SYSTEM = 'System',
}

export const Theme: React.FC = () => {
  const optionValues = Object.values(ThemeOptions);
  const [, themeName] = useTheme();
  const { mutate: updateUserPreferences } = useUpdateUserPreferences();
  const { track } = useAnalytics();

  const getLabel = (key: ThemeOptions) => {
    if (key === ThemeOptions.SYSTEM) {
      const preferDark =
        window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
      return `System Setting (${preferDark ? 'Dark' : 'Light'})`;
    }

    return key;
  };

  return (
    <div>
      Select a theme color or use your default system theme.
      <div>
        <br />
        <RadioHeader>Default Theme</RadioHeader>
        {optionValues.map((key) => {
          return (
            <div data-testid={`${key}-theme-radio`} key={key}>
              <RadioButton
                checked={themeName === key}
                id={`${key}-theme`}
                value={key}
                onChange={() => {
                  const _prefs = { theme: key };
                  track('User Preferences', {
                    themeSelected: key,
                  });
                  updateUserPreferences(_prefs);
                }}
                key={key}
              >
                {getLabel(key as ThemeOptions)}
              </RadioButton>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const getQueryParams = (search: string): Record<string, string> => {
  const params = new URLSearchParams(search);
  const queryParams: Record<string, string> = {};

  for (const [key, value] of params.entries()) {
    queryParams[key] = value;
  }

  return queryParams;
};

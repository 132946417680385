import { DefaultTheme } from 'styled-components';

import { exportModalDarkTheme } from '../app/components/export-modal';
import { uploadInstructionsDarkTheme } from '../app/components/upload-instructions-modal';

import { alertDarkTheme } from './component-themes/alert-theme';
import { chartDarkTheme } from './component-themes/chart-theme';
import { checkboxDarkTheme } from './component-themes/checkbox-theme';
import { confirmEmailDarkTheme } from './component-themes/confirm-email';
import { datePickerDarkTheme } from './component-themes/date-picker-theme';
import { dateTimeRangePickerDarkTheme } from './component-themes/date-time-range-picker-theme';
import { deleteDataModalDarkTheme } from './component-themes/delete-data-modal-theme';
import { fileUploadModalDarkTheme } from './component-themes/file-upload-theme';
import { headerV2Dark } from './component-themes/header-theme';
import { headerDarkThemeSystem } from './component-themes/header-theme-system';
import { headerDarkThemeTag } from './component-themes/header-theme-tag';
import { heatmapDarkTheme } from './component-themes/heatmap-theme';
import { investigateDarkTheme } from './component-themes/investigate-theme';
import { limitDarkTheme } from './component-themes/limit-theme';
import { modalDarkTheme } from './component-themes/modal-theme';
import { radioButtonDarkTheme } from './component-themes/radio-button-theme';
import { scrollBarDarkTheme } from './component-themes/scrollbar-theme';
import { searchDarkTheme } from './component-themes/search-theme';
import { sidebarDarkTheme, sidebarV2Dark } from './component-themes/sidebar-theme';
import { sliderDarkTheme } from './component-themes/slider-theme';
import { switchDarkTheme } from './component-themes/switch-theme';
import { systemBrowserDarkTheme } from './component-themes/system-browser-theme';
import { tabDarkTheme } from './component-themes/tab-theme';
import { textInputDarkTheme } from './component-themes/text-input-theme';
import { timeDisplayDarkTheme, timeDisplayDarkThemeV2 } from './component-themes/timedisplay-theme';
import { timelineDarkTheme } from './component-themes/timeline-theme';
import { timeSearchDarkTheme } from './component-themes/timesearch-theme';
import { tooltipDarkTheme } from './component-themes/tooltip-theme';
import { userPreferencesDarkTheme } from './component-themes/user-preferences-theme';

import { selectDarkTheme } from '@controlrooms/components';
import { buttonDarkTheme } from '@controlrooms/components';
import { colors } from '@controlrooms/design-tokens';

export const darkTheme: DefaultTheme = {
  name: 'Dark',
  v2: {
    header: headerV2Dark,
    sidebar: sidebarV2Dark,
    timeDisplay: timeDisplayDarkThemeV2,
    tab: tabDarkTheme,
  },
  backgroundColor: colors.k[0],
  reportFilterbackgroundColor: colors.k[8],
  textColor: colors.k[100],
  linkColor: colors.focus.dark[2],
  navigation: {
    textColor: colors.k[40],
    borderColor: colors.k[30],
    active: {
      textColor: colors.k[90],
      borderColor: colors.k[80],
    },
  },
  button: buttonDarkTheme,
  sidebar: sidebarDarkTheme,
  chart: chartDarkTheme,
  checkbox: checkboxDarkTheme,
  datePicker: datePickerDarkTheme,
  dateTimeRangePicker: dateTimeRangePickerDarkTheme,
  deleteDataModal: deleteDataModalDarkTheme,
  exportModal: exportModalDarkTheme,
  tooltip: tooltipDarkTheme,
  heatmap: heatmapDarkTheme,
  modal: modalDarkTheme,
  uploadInstructions: uploadInstructionsDarkTheme,
  fileUploadModal: fileUploadModalDarkTheme,
  radioButton: radioButtonDarkTheme,
  select: selectDarkTheme,
  timeDisplay: timeDisplayDarkTheme,
  systemHeader: headerDarkThemeSystem,
  tagHeader: headerDarkThemeTag,
  switch: switchDarkTheme,
  systemBrowser: systemBrowserDarkTheme,
  scrollBar: scrollBarDarkTheme,
  search: searchDarkTheme,
  textInput: textInputDarkTheme,
  confirmEmail: confirmEmailDarkTheme,
  userPreferences: userPreferencesDarkTheme,
  timeLine: timelineDarkTheme,
  slider: sliderDarkTheme,
  alert: alertDarkTheme,
  timeSearch: timeSearchDarkTheme,
  limit: limitDarkTheme,
  investigate: investigateDarkTheme,
};

import { GRADIENTS } from '@controlrooms/constants';
import { MonitorAnomalyThresholds, SVGDefsSelection } from '@controlrooms/models';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const appendGradients = (svgDefs: SVGDefsSelection, theme: any) => {
  const { gradients } = theme.heatmap;

  // Low cell gradient
  const lowGradient = svgDefs
    .append('linearGradient')
    .attr('id', GRADIENTS.g1)
    .attr('gradientTransform', 'rotate(270, 1.1, 0)');

  lowGradient
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', gradients[GRADIENTS.g1].color1);
  lowGradient
    .append('stop')
    .attr('offset', '23.18%')
    .attr('stop-color', gradients[GRADIENTS.g1].color2);
  lowGradient
    .append('stop')
    .attr('offset', '66.15%')
    .attr('stop-color', gradients[GRADIENTS.g1].color3 || gradients[GRADIENTS.g1].color2);
  lowGradient
    .append('stop')
    .attr('offset', '100%')
    .attr(
      'stop-color',
      gradients[GRADIENTS.g1].color4 ||
        gradients[GRADIENTS.g1].color3 ||
        gradients[GRADIENTS.g1].color2,
    );

  // Medium cell gradient
  const mediumGradient = svgDefs
    .append('linearGradient')
    .attr('id', GRADIENTS.g2)
    .attr('gradientTransform', 'rotate(270, 1.1, 0)');

  mediumGradient
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', gradients[GRADIENTS.g2].color1);
  mediumGradient
    .append('stop')
    .attr('offset', '33.85%')
    .attr('stop-color', gradients[GRADIENTS.g2].color2);
  mediumGradient
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', gradients[GRADIENTS.g2].color3 || gradients[GRADIENTS.g2].color2);

  // High cell gradient
  const highGradient = svgDefs
    .append('linearGradient')
    .attr('id', GRADIENTS.g3)
    .attr('gradientTransform', 'rotate(270, 1.1, 0)');

  highGradient
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', gradients[GRADIENTS.g3].color1);
  highGradient
    .append('stop')
    .attr('offset', '26.04%')
    .attr('stop-color', gradients[GRADIENTS.g3].color2);
  highGradient
    .append('stop')
    .attr('offset', '66.15%')
    .attr('stop-color', gradients[GRADIENTS.g3].color3 || gradients[GRADIENTS.g3].color2);
  highGradient
    .append('stop')
    .attr('offset', '100%')
    .attr(
      'stop-color',
      gradients[GRADIENTS.g3].color4 ||
        gradients[GRADIENTS.g3].color3 ||
        gradients[GRADIENTS.g3].color2,
    );

  // active row gradient
  const selectedRow = svgDefs
    .append('linearGradient')
    .attr('id', GRADIENTS.selected)
    .attr('gradientTransform', 'rotate(90)');

  selectedRow
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', gradients[GRADIENTS.selected].color1);
  selectedRow
    .append('stop')
    .attr('offset', '92%')
    .attr('stop-color', gradients[GRADIENTS.selected].color2);
  selectedRow
    .append('stop')
    .attr('offset', '100%')
    .attr(
      'stop-color',
      gradients[GRADIENTS.selected].color3 || gradients[GRADIENTS.selected].color2,
    );

  const hoverRow = svgDefs
    .append('linearGradient')
    .attr('id', GRADIENTS.hover)
    .attr('gradientTransform', 'rotate(90)');

  hoverRow
    .append('stop')
    .attr('offset', '20%')
    .attr('stop-color', gradients[GRADIENTS.hover].color1);
  hoverRow
    .append('stop')
    .attr('offset', '75%')
    .attr('stop-color', gradients[GRADIENTS.hover].color2);
  hoverRow
    .append('stop')
    .attr('offset', '93%')
    .attr('stop-color', gradients[GRADIENTS.hover].color3 || gradients[GRADIENTS.hover].color2);
  hoverRow
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', gradients[GRADIENTS.hover].color4 || gradients[GRADIENTS.hover].color2);
};

export const getGradientClass = (val: number, thresholds: MonitorAnomalyThresholds | undefined) => {
  if (!thresholds) {
    return '';
  }

  switch (true) {
    case val >= thresholds.high:
      return GRADIENTS.g3;
    case val >= thresholds.medium:
      return GRADIENTS.g2;
    case val >= thresholds.low:
      return GRADIENTS.g1;
    case val === thresholds?.none:
    default:
      return '';
  }
};

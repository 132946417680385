import { UserPreferencesTheme } from 'styled-components';

import { colors } from '@controlrooms/design-tokens';

export const userPreferencesDarkTheme: UserPreferencesTheme = {
  textColor: colors.content['neutral-1'],
};

export const userPreferencesLightTheme: UserPreferencesTheme = {
  textColor: colors.content['neutral-6'],
};

import styled from 'styled-components';

import { Size, text } from '@controlrooms/design-tokens';

export const ModalBodyContainer = styled.div`
  width: 500px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-bottom: 40px;
  text-align: left;
`;

export const UploaderContainer = styled.div``;

export const UploadModalHeader = styled.h2`
  color: ${({ theme }) => theme.fileUploadModal.body.titleColor};
  font-size: ${text.size[Size.HEADER_19]};
  line-height: 23px;
  font-weight: 400;
  margin-bottom: 10px;
`;

export const UploadInstructionsContainer = styled.div`
  font-size: 11px;
  line-height: 13px;
`;

export const StyledProgress = styled.progress`
  margin-bottom: 10px;
  width: 100%;
  border-radius: 2px;
  height: 2px;
  color: ${({ theme }) => theme.fileUploadModal.progress.backgroundColor};
  &::-webkit-progress-value {
    border-radius: 5px;
    background-color: ${({ theme }) => theme.fileUploadModal.progress.progressValueColor};
    box-shadow: 0px 0px 2px #ffffff, 0px 0px 8px #80f7ff;
    transition: width 1s ease-in-out;
  }
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px;
`;

import { SliderTheme } from 'styled-components';

import { colors } from '@controlrooms/design-tokens';

export const sliderDarkTheme: SliderTheme = {
  labelColor: colors.k[85],
  sliderTrack: {
    background: colors.k[5],
    borderColor: colors.k[12],
    boxShadow: 'inset 1px 2px 4px rgba(0, 0, 0, 0.35)',
  },
  sliderHandle: {
    background: 'linear-gradient(180deg, #224B4E 0%, #29494B 49.48%, #1F3133 100%, #1F3133 100%)',
    borderColor: colors.focus.dark[5],
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3)',
    selected: {
      background: 'linear-gradient(180deg, #427275 0%, #17474B 100%)',
      borderColor: colors.focus.dark[5],
      boxShadow: '0px 0px 2px #FFFFFF, 0px 0px 8px #80F7FF',
    },
    hover: {
      background: 'linear-gradient(180deg, #427275 0%, #17474B 100%)',
      borderColor: colors.focus.dark[5],
      boxShadow: '0px 0px 2px #FFFFFF, 0px 0px 8px #80F7FF',
    },
  },
  sliderRail: {
    background: colors.focus.dark[3],
    borderColor: colors.k[12],
    boxShadow: 'inset 1px 2px 4px rgba(0, 0, 0, 0.35)',
    outlineColor: colors.k[12],
  },
  filters: {
    low: {
      background: 'linear-gradient(0deg, #202C2E 0%, #273133 23.18%, #303D40 100%)',
      boxShadow: '',
    },
    medium: {
      background: 'linear-gradient(0deg, #324A4D 0%, #3E6166 33.85%, #456D73 100%)',
      boxShadow: '',
    },
    high: {
      background: 'linear-gradient(0deg, #6DD9B5 0%, #A0ECC6 26.04%, #B4FFCD 66.15%, #E4FFAE 100%)',
      boxShadow: '0px 0px 4px 0.7px rgba(136, 80, 0, 0.3)',
    },
  },
};

export const sliderLightTheme: SliderTheme = {
  labelColor: colors.content['neutral-5'],
  sliderTrack: {
    background: colors.k[100],
    borderColor: colors.k[80],
    boxShadow: 'inset 1px 2px 4px rgba(0, 0, 0, 0.35)',
  },
  sliderHandle: {
    background: 'linear-gradient(180deg, #224B4E 0%, #29494B 49.48%, #1F3133 100%, #1F3133 100%)',
    borderColor: colors.focus.dark[5],
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3)',
    selected: {
      background: 'linear-gradient(180deg, #427275 0%, #17474B 100%)',
      borderColor: colors.focus.dark[5],
      boxShadow: '0px 0px 2px #FFFFFF, 0px 0px 8px #80F7FF',
    },
    hover: {
      background: 'linear-gradient(180deg, #427275 0%, #17474B 100%)',
      borderColor: colors.focus.dark[5],
      boxShadow: '0px 0px 2px #FFFFFF, 0px 0px 8px #80F7FF',
    },
  },
  sliderRail: {
    background: colors.focus.light[3],
    borderColor: colors.focus.light[3],
    boxShadow: 'inset 1px 2px 4px rgba(0, 0, 0, 0.35)',
    outlineColor: colors.k[65],
  },

  filters: {
    low: {
      background: 'linear-gradient(0deg, #B8CFD1 0%, #BAD0D1 26.04%, #C9D8D9 66.15%, #D0DFE0 100%)',
      boxShadow: '',
    },
    medium: {
      background: 'linear-gradient(0deg, #8097A5 0%, #90ABB3 33.85%, #9CB5C8 100%)',
      boxShadow: '',
    },
    high: {
      background: 'linear-gradient(0deg, #261A2D 0%, #32273D 30.21%, #4A435D 100%)',
      boxShadow: '0px 0px 4px 0.7px rgba(207, 42, 121, 0.4)',
    },
  },
};

import React from 'react';

import { useLayoutContext } from '../../context/layout-context';
import { useViewContext } from '../../context/view-context';

import { SeverityFilterContainer } from './styles';

import { Switch } from '@controlrooms/components';

interface Props {
  label?: string;
  showSlider?: boolean;
}

const LimitsToggle: React.FC<Props> = ({ label }) => {
  const { viewState, handleShowLimits } = useViewContext();
  const { activeModes, activeView } = useLayoutContext();
  const selectedMode = activeModes[activeView];

  const { showLimits } = viewState.view[selectedMode];

  return (
    <SeverityFilterContainer style={{ marginLeft: '1rem', gap: '0' }}>
      {label ? <span className="label">{label}</span> : <span className="label">Show Limits</span>}
      <div>
        <Switch
          selected={showLimits}
          onClick={(e, selected) => {
            handleShowLimits(selected);
          }}
          dataTestId={'limits'}
        />
      </div>
    </SeverityFilterContainer>
  );
};

export default LimitsToggle;

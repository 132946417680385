import React from 'react';

import {
  ModalBodyContainer,
  LegalHeader,
  LegalSubHeader,
  LegalContent,
  Section,
  SectionTitle,
  SectionText,
  SubSectionLabel,
} from './styles';

export const TermsConditionsModal: React.FC = () => {
  return (
    <ModalBodyContainer>
      <LegalHeader>CONTROLROOMS.AI TERMS OF SERVICE</LegalHeader>
      <LegalSubHeader>LAST UPDATED: 9 SEPTEMBER 2022</LegalSubHeader>
      <LegalContent>
        <Section>
          <SectionText>
            This Terms of Service Agreement (the “<b>Agreement”</b>), governs access to and use of
            the ControlRooms Solution and is entered into by and between you and Controlrooms.ai,
            Inc. (“<b>ControlRooms</b>"). By clicking on the “I Agree” button, completing the
            registration process, or otherwise accessing or using any portion of the ControlRooms
            Solution, you represent that (a) you have read, understand, and agree to be bound by the
            Agreement, (b) you are of legal age to form a binding contract with ControlRooms, and
            (c) you have the authority to enter into the Agreement personally or on behalf of the
            legal entity identified during the account registration process, and to bind that legal
            entity to the Agreement.{' '}
            <b>
              If you do not agree to be bound by the Agreement, you may not access or use any
              portion of the ControlRooms Solution.
            </b>
          </SectionText>
        </Section>

        <Section>
          <SectionText>
            If you are using the ControlRooms Solution as an employee, contractor, or agent of a
            corporation, partnership or similar entity, then you must be authorized to sign for and
            bind the entity in order to accept the terms of this Agreement, and you represent and
            warrant that you have the authority to do so. The rights granted under this agreement
            are expressly conditioned upon acceptance by such authorized personnel. The term “
            <b>Customer</b>” refers to such individual or legal entity, as applicable. All other
            capitalized terms will have the meanings set forth in Section 10 (Definitions), or in
            the section where they are first used.
          </SectionText>
        </Section>

        <Section>
          <SectionText>
            This Agreement is subject to change by ControlRooms in its sole discretion at any time.
            When changes are made, ControlRooms will make a copy of the updated Agreement available
            at this website and update the “Last Updated” date at the top of this Agreement. If
            ControlRooms makes any material changes to the Agreement, ControlRooms will provide
            notice of such material changes on this website and attempt to notify Customer by
            sending an email to the email address provided in Customer’s account registration. Any
            changes to the Agreement will be effective immediately for new users of the ControlRooms
            Solution and will be effective for existing Registered Users (defined in Section 1.1
            (Registering an Account)) upon the earlier of (a) thirty (30) days after the “Last
            Updated” date at the top of this Agreement, or (b) Customer’s consent to and acceptance
            of the updated Agreement if ControlRooms provides a mechanism for Customer’s immediate
            acceptance in a specified manner (such as a click-through acceptance), which
            ControlRooms may require before further use of the ControlRooms Solution is permitted.
            If Customer does not agree to the updated Agreement, Customer must stop using the
            ControlRooms Solution and all features thereof upon the effective date of the updated
            Agreement. Otherwise, Customer’s continued use of any portion of the ControlRooms
            Solution after the effective date of the updated Agreement constitutes Customer’s
            acceptance of the updated Agreement. Customer agrees that ControlRooms’ continued
            provision of the ControlRooms Solution is adequate consideration for the changes in the
            updated Agreement.
          </SectionText>
        </Section>

        <Section>
          <SectionTitle>1. ACCOUNT REGISTRATION</SectionTitle>
          <SectionText>
            <SubSectionLabel>1.1</SubSectionLabel>Registering an Account. In order to access certain
            features of the ControlRooms Solution, Customer may be required to become a Registered
            User. For purposes of this Agreement, a “Registered User” is a user who has registered
            an account with ControlRooms through the website (“Account”) or has a valid account on a
            third-party service (“Third-Party Service”) through which the user has connected to the
            ControlRooms Solution (each such account, a “Third-Party Service Account”).
          </SectionText>
        </Section>

        <Section>
          <SectionText>
            <SubSectionLabel>1.2</SubSectionLabel> <b>Access Through a Third-Party Service.</b> If
            Customer accesses the ControlRooms Solution through a Third-Party Service as part of the
            functionality of the ControlRooms Solution, Customer may link its Account with
            Third-Party Service Accounts by allowing ControlRooms to access Customer’s Third-Party
            Service Account, as is permitted under the applicable terms and conditions that govern
            such Third-Party Service Account. Customer represents that Customer is entitled to grant
            ControlRooms access to Customer’s Third-Party Service Account (including, but not
            limited to, for use for the purposes described herein) without breach by Customer of any
            of the terms and conditions that govern such Third-Party Service Account and without
            obligating ControlRooms to pay any fees or making ControlRooms subject to any usage
            limitations imposed by such Third-Party Service. By granting ControlRooms access to any
            Third-Party Service Accounts, Customer acknowledges that ControlRooms may access and
            store (if applicable) any Third-Party Service Content for purposes of registration for
            an Account. Depending on the Third-Party Service Accounts chosen by Customer and subject
            to the privacy settings in such Third-Party Service Accounts, personally identifiable
            information available within Customer’s Third-Party Service Accounts may be available on
            and through Customer’s Account on the ControlRooms Solution. In the event a Third-Party
            Service Account or associated service becomes unavailable, or ControlRooms’ access to
            such Third-Party Service Account is terminated by such Third-Party Service, then
            Third-Party Service Content will no longer be available on and through the ControlRooms
            Solution. Customer may disable the connection between Customer’s Account and Customer’s
            Third-Party Service Accounts at any time by accessing the “Settings” section of the
            ControlRooms Solution. CUSTOMER’S RELATIONSHIP WITH THE THIRD-PARTY SERVICE PROVIDERS
            ASSOCIATED WITH CUSTOMER’S THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY BY CUSTOMER’S
            AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE PROVIDERS, AND CONTROLROOMS DISCLAIMS ANY
            LIABILITY FOR PERSONALLY IDENTIFIABLE INFORMATION THAT MAY BE PROVIDED TO IT BY SUCH
            THIRD-PARTY SERVICE PROVIDERS IN VIOLATION OF THE PRIVACY SETTINGS THAT CUSTOMER HAS
            SELECTED IN SUCH THIRD-PARTY ACCOUNTS. ControlRooms makes no effort to review any
            Third-Party Service Content for any purpose, including but not limited to, for accuracy,
            legality or noninfringement, and ControlRooms is not responsible for any Third-Party
            Service Content.
          </SectionText>
        </Section>

        <Section>
          <SectionText>
            <SubSectionLabel>1.3</SubSectionLabel> <b>Registration Data.</b> In registering an
            Account, Customer agrees to (a) provide true, accurate, current and complete information
            as prompted by the registration form (the “Registration Data”); and (b) maintain and
            promptly update the Registration Data to keep it true, accurate, current and complete.
            Customer agrees not to create an Account using a false identity or information, or on
            behalf of a third party. If Customer provides any information that is untrue,
            inaccurate, not current or incomplete, or ControlRooms has reasonable grounds to suspect
            that any information provided by Customer is untrue, inaccurate, not current or
            incomplete, ControlRooms has the right to suspend or terminate Customer’s Account and
            refuse any and all current or future use of the ControlRooms Solution (or any portion
            thereof). Customer is responsible for all activities that occur under its Account,
            including but not limited to activities by all Authorized Users. Customer will use
            commercially reasonable efforts to prevent unauthorized access to, or use of, the
            ControlRooms Solution, and notify ControlRooms promptly of any such unauthorized use
            known to Customer. Customer agrees that it shall not have more than one (1) Account at
            any given time, and Authorized Users may not share Account login information or
            passwords. Customer agrees not to create an Account or use the ControlRooms Solution if
            Customer has been previously removed by ControlRooms, or if Customer has been previously
            banned from the ControlRooms Solution.
          </SectionText>
        </Section>

        <Section>
          <SectionText>
            <SubSectionLabel>1.4</SubSectionLabel> <b>Customer’s Account.</b> Notwithstanding
            anything to the contrary herein, Customer acknowledges and agrees that Customer shall
            have no ownership or other property interest in its Account, and Customer further
            acknowledges and agrees that all rights in and to its Account are and shall forever be
            owned by and inure to the benefit of ControlRooms. ControlRooms reserves the right to
            remove or reclaim any usernames at any time and for any reason, including but not
            limited to, claims by a third party that a username violates the third party’s rights.
          </SectionText>
        </Section>

        <Section>
          <SectionText>
            <SubSectionLabel>1.5</SubSectionLabel> Necessary Equipment and Software. Customer is
            solely responsible for any fees, including Internet connection fees, that are incurred
            by Customer when accessing the ControlRooms Solution. Nothing herein will be construed
            to require ControlRooms to provide, or bear any responsibility with respect to, any
            telecommunications or computer network hardware required by Customer or any Authorized
            User to access and use the ControlRooms Solution from the Internet.
          </SectionText>
        </Section>

        <Section>
          <SectionTitle>2. INTELLECTUAL PROPERTY</SectionTitle>
          <SectionText>
            <SubSectionLabel>2.1</SubSectionLabel> <b>License Grant.</b> Subject to the terms and
            conditions of this Agreement, ControlRooms grants to Customer a non-exclusive,
            non-transferable (except as permitted under Section 9.6 (No Assignment)) license during
            the Term (as defined in Section 8.1 (Term)), solely for Customer’s internal business
            purposes, (a) to access and use the ControlRooms Solution and in accordance with the
            Documentation; and (b) to use and reproduce a reasonable number of copies of the
            Documentation solely to support Customer’s use of the ControlRooms Solution. Customer
            may permit any Authorized Users to access and use the features and functions of the
            ControlRooms Solution as contemplated by this Agreement.
          </SectionText>
        </Section>

        <Section>
          <SectionText>
            <SubSectionLabel>2.2</SubSectionLabel> <b>Restrictions.</b> Customer will not, and will
            not permit any Authorized User or other party to: (a) allow any third party to access
            the ControlRooms Solution, Output or Documentation, except for Customer’s internal
            business purposes as expressly allowed herein; (b) modify, adapt, alter or translate the
            ControlRooms Solution, Output or Documentation; (c) sublicense, lease, sell, resell,
            rent, loan, distribute, transfer or otherwise allow the use of the ControlRooms Solution
            or Documentation for the benefit of any unauthorized third party; (d) reverse engineer,
            decompile, disassemble, or otherwise derive or determine or attempt to derive or
            determine the source code (or the underlying ideas, algorithms, structure or
            organization) of the ControlRooms Solution, except as permitted by law; (e) interfere in
            any manner with the operation of the ControlRooms Solution or the hardware and network
            used to operate the ControlRooms Solution; (f) modify, copy or make derivative works
            based on any part of the ControlRooms Solution or Documentation; (g) access or use the
            ControlRooms Solution to build a similar or competitive product or service; (h) attempt
            to access the ControlRooms Solution through any unapproved interface; or (i) otherwise
            use the ControlRooms Solution, Output, or Documentation in any manner that exceeds the
            scope of use permitted under Section 2.1 (License Grant) or in a manner inconsistent
            with applicable law, the Documentation, or this Agreement. Customer acknowledges and
            agrees that the ControlRooms Solution will not be used, and is not licensed for use, to
            enable any of Customer’s time-critical or mission-critical functions. Customer will not
            remove, alter, or obscure any proprietary notices (including copyright and trademark
            notices) of ControlRooms or its licensors on the Output or any copies thereof.
          </SectionText>
        </Section>

        <Section>
          <SectionText>
            <SubSectionLabel>2.3</SubSectionLabel> <b>Ownership.</b> The ControlRooms Solution,
            Output and Documentation, and all worldwide Intellectual Property Rights in each of the
            foregoing, are the exclusive property of ControlRooms and its suppliers. All rights in
            and to the ControlRooms Solution and Documentation not expressly granted to Customer in
            this Agreement are reserved by ControlRooms and its suppliers. Except as expressly set
            forth herein, no express or implied license or right of any kind is granted to Customer
            regarding the ControlRooms Solution, Documentation, or any part thereof.
          </SectionText>
        </Section>

        <Section>
          <SectionText>
            <SubSectionLabel>2.4</SubSectionLabel> <b>License to Output.</b> Subject to the terms
            and conditions of this Agreement, ControlRooms grants Customer a perpetual,
            royalty-free, fully-paid, nonexclusive, non-transferable (except as permitted under
            Section 9.6 (No Assignment)), non-sublicensable license to use the Output solely for
            Customer’s internal business purposes.
          </SectionText>
        </Section>

        <Section>
          <SectionText>
            <SubSectionLabel>2.5</SubSectionLabel> <b>Open Source Software.</b> Certain items of
            software may be provided to Customer with the ControlRooms Solution and are subject to
            “open source” or “free software” licenses (“Open Source Software”). Some of the Open
            Source Software is owned by third parties. The Open Source Software is not subject to
            the terms and conditions of Sections 2.1 (Ownership) or 7 (Indemnification). Instead,
            each item of Open Source Software is licensed under the terms of the end-user license
            that accompanies such Open Source Software. Nothing in this Agreement limits Customer’s
            rights under, or grants Customer rights that supersede, the terms and conditions of any
            applicable end user license for the Open Source Software. If required by any license for
            particular Open Source Software, ControlRooms makes such Open Source Software, and
            ControlRooms’ modifications to that Open Source Software, available by written request
            at the notice address specified below.
          </SectionText>
        </Section>

        <Section>
          <SectionText>
            <SubSectionLabel>2.6</SubSectionLabel> <b>Feedback.</b> Customer hereby grants to
            ControlRooms a royalty-free, worldwide, transferable, sublicensable, irrevocable,
            perpetual license to use or incorporate into the ControlRooms Solution any suggestions,
            enhancement requests, recommendations or other feedback provided by Customer, including
            Authorized Users, relating to the ControlRooms Solution. ControlRooms will not identify
            Customer as the source of any such feedback without Customer’s express prior written
            consent.
          </SectionText>
        </Section>

        <Section>
          <SectionTitle>3. CUSTOMER CONTENT AND RESPONSIBILITIES</SectionTitle>
          <SectionText>
            <SubSectionLabel>3.1</SubSectionLabel> <b>License; Ownership.</b> Customer is solely
            responsible for any and all obligations with respect to the accuracy, quality and
            legality of Customer Content. Customer will obtain all third party licenses, consents
            and permissions needed for ControlRooms to use the Customer Content to provide the
            ControlRooms Solution. Without limiting the foregoing, Customer will be solely
            responsible for obtaining from third parties all necessary rights for ControlRooms to
            use the Customer Content submitted by or on behalf of Customer for the purposes set
            forth in this Agreement. Customer grants ControlRooms a non-exclusive, worldwide,
            royalty-free and fully paid license during the Term (a) to use the Customer Content as
            necessary for purposes of providing and improving the ControlRooms Solution, and (b) to
            use the Customer trademarks, service marks, and logos as required to provide the
            ControlRooms Solution. Customer grants ControlRooms a non-exclusive, worldwide,
            royalty-free and fully paid license during and after the Term to use the Customer
            Content in an aggregated and anonymized form to: (i) improve the ControlRooms Solution
            and ControlRooms’ related products and services; (ii) provide analytics and benchmarking
            services; and (iii) generate and disclose statistics regarding use of the ControlRooms
            Solution, provided, however, that no Customer-only statistics will be disclosed to third
            parties without Customer’s consent. The Customer Content, and all worldwide Intellectual
            Property Rights in it, is the exclusive property of Customer. All rights in and to the
            Customer Content not expressly granted to ControlRooms in this Agreement are reserved by
            Customer.
          </SectionText>
        </Section>

        <Section>
          <SectionText>
            <SubSectionLabel>3.2</SubSectionLabel> <b>Customer Warranty.</b> Customer represents and
            warrants that any Customer Content will not (a) infringe any copyright, trademark, or
            patent; (b) misappropriate any trade secret; (c) be deceptive, defamatory, obscene,
            pornographic or unlawful; (d) contain any viruses, worms or other malicious computer
            programming codes intended to damage ControlRooms’ system or data; and (e) otherwise
            violate the rights of a third party. ControlRooms is not obligated to back up any
            Customer Content; the Customer is solely responsible for creating backup copies of any
            Customer Content at Customer’s sole cost and expense before submitting such Customer
            Content to the ControlRooms Solution. Customer agrees that any use of the ControlRooms
            Solution contrary to or in violation of the representations and warranties of Customer
            in this Section 3.2 (Customer Warranty) constitutes unauthorized and improper use of the
            ControlRooms Solution.
          </SectionText>
        </Section>

        <Section>
          <SectionText>
            <SubSectionLabel>3.3</SubSectionLabel>{' '}
            <b>Customer Responsibility for Data and Security.</b> Customer and its Authorized Users
            will have access to the Customer Content and will be responsible for all changes to
            and/or deletions of Customer Content and the security of all passwords and other Access
            Protocols required in order to access the ControlRooms Solution. Customer is encouraged
            to make its own back-ups of the Customer Content. Customer will have the sole
            responsibility for the accuracy, quality, integrity, legality, reliability, and
            appropriateness of all Customer Content. Prior to any use of the ControlRooms Solution,
            Customer shall ensure its equipment and systems comply with the minimum system and
            equipment requirements set forth in the Documentation.
          </SectionText>
        </Section>

        <Section>
          <SectionText>
            <SubSectionLabel>3.4</SubSectionLabel>{' '}
            <b>Customer Responsibility for Operational Decisions.</b> Customer is solely responsible
            for any decisions made or actions taken based on the Output or the ControlRooms
            Solution. Customer acknowledges that the ControlRooms Solution is not a safety protocol
            system or equipment control system, and Customer agrees it will not rely on the Output
            or ControlRooms Solution for any operational decisions related to Customer’s equipment,
            facilities, or business. Under no circumstances can the ControlRooms Solution replace
            professional judgment rendered by Customer and its employees and/or contractors.
          </SectionText>
        </Section>

        <Section>
          <SectionTitle>4. DISCLAIMER</SectionTitle>
          <SectionText>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICES, OUTPUT AND
            DOCUMENTATION ARE PROVIDED “AS IS,” AND CONTROLROOMS MAKES NO (AND HEREBY DISCLAIMS ALL)
            WARRANTIES, REPRESENTATIONS, OR CONDITIONS, WHETHER WRITTEN, ORAL, EXPRESS, IMPLIED OR
            STATUTORY, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF SATISFACTORY
            QUALITY, COURSE OF DEALING, TRADE USAGE OR PRACTICE, SYSTEM INTEGRATION, DATA ACCURACY,
            MERCHANTABILITY, TITLE, NONINFRINGEMENT, OR FITNESS FOR A PARTICULAR PURPOSE.
            CONTROLROOMS DOES NOT WARRANT THAT ALL ERRORS CAN BE CORRECTED, OR THAT OPERATION OF THE
            CONTROLROOMS SOLUTION WILL BE UNINTERRUPTED OR ERROR-FREE. CONTROLROOMS MAKES NO
            WARRANTY OR GUARANTEES REGARDING RESULTS, INCLUDING BUT NOT LIMITED TO THE ACCURACY OF
            ANY OUTPUT. WITHOUT LIMITING THE FOREGOING, CONTROLROOMS TAKES NO RESPONSIBILITY FOR AND
            DOES NOT GUARANTEE AGAINST CUSTOMER SYSTEM FAILURES, POWER SURGES, CUSTOMER SYSTEM OR
            EQUIPMENT DOWNTIME, THIRD-PARTY HARDWARE, CUSTOMER HARDWARE, ACCIDENTS, FIRE,
            EXPLOSIONS, EQUIPMENT FAILURE OR ANY OTHER PROBLEMS WITH CUSTOMER’S SYSTEMS AND
            EQUIPMENT AND SHALL HAVE NO LIABILITY WITH RESPECT TO ANY OF THE FOREGOING OR ANY
            SIMILAR SUCH EVENTS. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY
            CUSTOMER FROM THE CONTROLROOMS SOLUTION, DOCUMENTATION, OUTPUT, OR CONTROLROOMS WILL
            CREATE ANY WARRANTY THAT IS NOT EXPRESSLY STATED IN THIS AGREEMENT.
          </SectionText>
        </Section>

        <Section>
          <SectionTitle>5. LIMITATION OF LIABILITY</SectionTitle>
          <SectionText>
            <SubSectionLabel>5.1</SubSectionLabel> <b>Types of Damages.</b> EXCEPT FOR A PARTY’S
            BREACH OF ITS OBLIGATIONS SET FORTH IN SECTION 6 (Confidentiality) AND SECTION 7
            (Indemnification), IN NO EVENT WILL EITHER PARTY BE LIABLE TO THE OTHER PARTY FOR ANY
            INCIDENTAL, INDIRECT, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, REGARDLESS OF THE
            NATURE OF THE CLAIM, INCLUDING, WITHOUT LIMITATION, LOST PROFITS, COSTS OF DELAY, ANY
            FAILURE OF DELIVERY, BUSINESS INTERRUPTION, COSTS OF LOST OR DAMAGED DATA OR
            DOCUMENTATION, OR LIABILITIES TO THIRD PARTIES ARISING FROM ANY SOURCE, EVEN IF A PARTY
            HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THIS LIMITATION UPON DAMAGES AND
            CLAIMS IS INTENDED TO APPLY WITHOUT REGARD TO WHETHER OTHER PROVISIONS OF THIS AGREEMENT
            HAVE BEEN BREACHED OR HAVE PROVEN INEFFECTIVE.
          </SectionText>
        </Section>

        <Section>
          <SectionText>
            <SubSectionLabel>5.2</SubSectionLabel> <b>Amount of Damages.</b> EXCEPT FOR A PARTY’S
            BREACH OF ITS OBLIGATIONS SET FORTH IN SECTION 6 (Confidentiality) AND SECTION 7
            (Indemnification), THE MAXIMUM LIABILITY OF EITHER PARTY ARISING OUT OF OR IN ANY WAY
            CONNECTED TO THIS AGREEMENT WILL NOT EXCEED THE SUM OF FEES PAID AND PAYABLE BY CUSTOMER
            TO CONTROLROOMS DURING THE TWELVE (12) MONTHS PRECEDING THE ACT, OMISSION OR OCCURRENCE
            GIVING RISE TO SUCH LIABILITY. IN NO EVENT WILL CONTROLROOMS’ SUPPLIERS HAVE ANY
            LIABILITY ARISING OUT OF OR IN ANY WAY CONNECTED TO THIS AGREEMENT. NOTHING IN THIS
            AGREEMENT WILL LIMIT OR EXCLUDE EITHER PARTY’S LIABILITY FOR GROSS NEGLIGENCE OR
            INTENTIONAL MISCONDUCT OF A PARTY OR ITS EMPLOYEES OR AGENTS OR FOR DEATH OR PERSONAL
            INJURY. THE FOREGOING LIMITATION OF LIABILITY WILL NOT APPLY TO ANY AND ALL LOSSES AS
            THE RESULT OF ANY ONE OR MORE DATA SECURITY EVENT(S).
          </SectionText>
        </Section>

        <Section>
          <SectionText>
            <SubSectionLabel>5.3</SubSectionLabel> <b>Basis of the Bargain.</b> The parties agree
            that the limitations of liability set forth in this Section 4 (Limitation of Liability)
            will survive and continue in full force and effect despite any failure of consideration
            or of an exclusive remedy. The parties acknowledge that the prices have been set and the
            Agreement entered into in reliance upon these limitations of liability and that all such
            limitations form an essential basis of the bargain between the parties.
          </SectionText>
        </Section>

        <Section>
          <SectionTitle>6. CONFIDENTIALITY</SectionTitle>
          <SectionText>
            <SubSectionLabel>6.1</SubSectionLabel> <b>Confidential Information.</b> “
            <b>Confidential Information</b>” means any nonpublic information of a party (the
            “Disclosing Party”), whether disclosed orally or in written or digital media, that is
            identified as “confidential” or with a similar legend at the time of such disclosure or
            that the receiving party (the “<b>Receiving Party</b>”) knows or should have known is
            the confidential or proprietary information of the Disclosing Party based on the nature
            of the information and circumstances of disclosure. The ControlRooms Solution,
            Documentation, and all enhancements and improvements thereto will be considered
            Confidential Information of ControlRooms.
          </SectionText>
        </Section>

        <Section>
          <SectionText>
            <SubSectionLabel>6.2</SubSectionLabel> <b>Protection of Confidential Information.</b>{' '}
            The Receiving Party agrees that it will not use or disclose to any third party any
            Confidential Information of the Disclosing Party, except as expressly permitted under
            this Agreement. The Receiving Party will limit access to the Confidential Information to
            Authorized Users (with respect to Customer) or to those employees who have a need to
            know, who have confidentiality obligations no less restrictive than those set forth
            herein, and who have been informed of the confidential nature of such information (with
            respect to ControlRooms). In addition, the Receiving Party will protect the Disclosing
            Party’s Confidential Information from unauthorized use, access, or disclosure in the
            same manner that it protects its own proprietary information of a similar nature, but in
            no event with less than reasonable care. At the Disclosing Party’s request or upon
            termination or expiration of this Agreement, the Receiving Party will return to the
            Disclosing Party or destroy (or permanently erase in the case of electronic files) all
            copies of the Confidential Information that the Receiving Party does not have a
            continuing right to use under this Agreement, and the Receiving Party will, upon
            request, certify to the Disclosing Party its compliance with this sentence.
          </SectionText>
        </Section>

        <Section>
          <SectionText>
            <SubSectionLabel>6.3</SubSectionLabel> <b>Exceptions.</b> The confidentiality
            obligations set forth in Section 6.2 (Protection of Confidential Information) will not
            apply to any information that (a) is at the time of disclosure or becomes generally
            available to the public through no fault of the Receiving Party; (b) is lawfully
            provided to the Receiving Party by a third party free of any confidentiality duties or
            obligations; (c) was already known to the Receiving Party at the time of disclosure free
            of any confidentiality duties or obligations; or (d) the Receiving Party can
            demonstrate, by clear and convincing evidence, was independently developed by employees
            and contractors of the Receiving Party who had no access to the Confidential
            Information. In addition, the Receiving Party may disclose Confidential Information to
            the extent that such disclosure is necessary for the Receiving Party to enforce its
            rights under this Agreement or is required by law or by the order of a court or similar
            judicial or administrative body, provided that (to the extent legally permissible) the
            Receiving Party promptly notifies the Disclosing Party in writing of such required
            disclosure and cooperates with the Disclosing Party if the Disclosing Party seeks an
            appropriate protective order.
          </SectionText>
        </Section>

        <Section>
          <SectionTitle>7. INDEMNIFICATION</SectionTitle>
          <SectionText>
            <SubSectionLabel>7.1</SubSectionLabel> <b>By ControlRooms.</b> ControlRooms will defend
            at its expense any suit brought against Customer, and will pay any settlement
            ControlRooms makes or approves, or any damages finally awarded in such suit, insofar as
            such suit is based on a claim by any third party alleging that the ControlRooms Solution
            infringes such third party’s patents, copyrights or trade secret rights under applicable
            laws of any jurisdiction within the United States of America. If any portion of the
            ControlRooms Solution becomes, or in ControlRooms’ opinion is likely to become, the
            subject of a claim of infringement, ControlRooms may, at ControlRooms’ option: (a)
            procure for Customer the right to continue using the ControlRooms Solution; (b) replace
            the ControlRooms Solution with non-infringing software or services which do not
            materially impair the functionality of the ControlRooms Solution; (c) modify the
            ControlRooms Solution so that it becomes non-infringing; or (d) terminate this
            Agreement, and upon such termination, Customer will immediately cease all use of the
            ControlRooms Solution and Documentation. Notwithstanding the foregoing, ControlRooms
            will have no obligation under this section or otherwise with respect to any infringement
            claim based upon (i) any use of the ControlRooms Solution not in accordance with this
            Agreement or as specified in the Documentation; (ii) any use of the ControlRooms
            Solution in combination with other products, equipment, software or data not supplied by
            ControlRooms; or (iii) any modification of the ControlRooms Solution by any person other
            than ControlRooms or its authorized agents (collectively, the “<b>Exclusions</b>” and
            each, an “<b>Exclusion</b>”). This section states the sole and exclusive remedy of
            Customer and the entire liability of ControlRooms, or any of the officers, directors,
            employees, shareholders, contractors or representatives of the foregoing, for
            infringement claims and actions.
          </SectionText>
        </Section>

        <Section>
          <SectionText>
            <SubSectionLabel>7.2</SubSectionLabel> <b>By Customer.</b> Customer will defend at its
            expense any suit brought against ControlRooms, and will pay any settlement Customer
            makes or approves, or any damages finally awarded in such suit, insofar as such suit is
            based on a claim arising out of or relating to (a) an Exclusion, or (b) Customer’s
            breach or alleged breach of Sections 3.2 (Customer Warranty). This section states the
            sole and exclusive remedy of ControlRooms and the entire liability of Customer, or any
            of the officers, directors, employees, shareholders, contractors or representatives of
            the foregoing, for the claims and actions described herein.
          </SectionText>
        </Section>

        <Section>
          <SectionText>
            <SubSectionLabel>7.3</SubSectionLabel> <b>Procedure.</b> The indemnifying party’s
            obligations as set forth above are expressly conditioned upon each of the foregoing: (a)
            the indemnified party will promptly notify the indemnifying party in writing of any
            threatened or actual claim or suit; (b) the indemnifying party will have sole control of
            the defense or settlement of any claim or suit; and (c) the indemnified party will
            cooperate with the indemnifying party to facilitate the settlement or defense of any
            claim or suit.
          </SectionText>
        </Section>

        <Section>
          <SectionTitle>8. TERM AND TERMINATION</SectionTitle>
          <SectionText>
            <SubSectionLabel>8.1</SubSectionLabel> <b>Term.</b> This Agreement commences on the date
            when accepted by Customer (as described in the preamble above) and remain in full force
            and effect while Customer uses the ControlRooms Solution, unless terminated earlier in
            accordance with the Agreement (the “<b>Term</b>”).
          </SectionText>
        </Section>

        <Section>
          <SectionText>
            <SubSectionLabel>8.2</SubSectionLabel> <b>Termination by Customer.</b> Customer may
            terminate this Agreement upon written notice to ControlRooms by (a) notifying
            ControlRooms at any time using the contact information set forth in Section 9.1
            (Questions and Complaints), or (b) closing Customer’s Account within the ControlRooms
            Solution by accessing the “User Preferences” menu of the ControlRooms Solution.
          </SectionText>
        </Section>

        <Section>
          <SectionText>
            <SubSectionLabel>8.3</SubSectionLabel> <b>Termination by ControlRooms.</b> ControlRooms
            may terminate this Agreement upon written notice to Customer. Without limiting the
            foregoing, ControlRooms has the right to, immediately and without notice, suspend or
            terminate Customer’s use of any ControlRooms Solution upon any actual or suspected
            breach by Customer of Section 2.2 (Restrictions). Further, ControlRooms may immediately
            terminate this Agreement in the event ControlRooms is required to do so by law (e.g.,
            where the provision of the ControlRooms Solution is, or becomes, unlawful). Customer
            agrees that all terminations by ControlRooms shall be made in ControlRooms’ sole
            discretion and that ControlRooms shall not be liable to Customer or any third party for
            any termination of Customer’s Account.
          </SectionText>
        </Section>

        <Section>
          <SectionText>
            <SubSectionLabel>8.4</SubSectionLabel> <b>Termination for Breach.</b> Either party may
            terminate this Agreement immediately upon notice to the other party if the other party
            materially breaches this Agreement, and such breach remains uncured more than five (5)
            days after receipt of written notice of such breach.
          </SectionText>
        </Section>

        <Section>
          <SectionText>
            <SubSectionLabel>8.5</SubSectionLabel> <b>Effect of Termination.</b> Upon termination or
            expiration of this Agreement for any reason: (a) all licenses granted hereunder will
            immediately terminate; (b) promptly after the effective date of termination or
            expiration, each party will comply with the obligations to return all Confidential
            Information of the other party, as set forth in Section 6 (Confidentiality); and (c) any
            amounts owed to ControlRooms under this Agreement will become immediately due and
            payable. Sections 2.2 (Restrictions), 2.3 (Ownership), 2.5 (Open Source Software), 4
            (Disclaimer), 4 (Limitation of Liability), 6 (Confidentiality), 7 (Indemnification), 8.2
            (Termination for Breach), 8.5 (Effect of Termination), 9 (Miscellaneous), and 10
            (Definitions), will survive expiration or termination of this Agreement for any reason.
          </SectionText>
        </Section>

        <Section>
          <SectionTitle>9. MISCELLANEOUS</SectionTitle>
          <SectionText>
            <SubSectionLabel>9.1</SubSectionLabel> <b>Questions and Complaints.</b> Customer may
            direct any Account requests, questions, or complaints with respect to the ControlRooms
            Solution to: info@controlrooms.ai
          </SectionText>
        </Section>

        <Section>
          <SectionText>
            <SubSectionLabel>9.2</SubSectionLabel> <b>Governing Law and Venue.</b> This Agreement
            and any action related thereto will be governed and interpreted by and under the laws of
            the State of California, without giving effect to any conflicts of laws principles that
            require the application of the law of a different jurisdiction. Customer hereby
            expressly consents to the personal jurisdiction and venue in the state and federal
            courts for Santa Clara County, California for any lawsuit filed there against Customer
            by ControlRooms arising from or related to this Agreement. The United Nations Convention
            on Contracts for the International Sale of Goods does not apply to this Agreement.
          </SectionText>
        </Section>

        <Section>
          <SectionText>
            <SubSectionLabel>9.3</SubSectionLabel> <b>Export.</b> Customer may not use, export,
            import, or transfer any portion of the ControlRooms Solution except as authorized by
            U.S. law, the laws of the jurisdiction in which you obtained the ControlRooms Solution,
            and any other applicable laws. In particular, but without limitation, the ControlRooms
            Solution may not be exported or re-exported (a) into any United States embargoed
            countries, or (b) to anyone on the U.S. Treasury Department’s list of Specially
            Designated Nationals or the U.S. Department of Commerce’s Denied Person’s List or Entity
            List. By using the ControlRooms Solution, Customer represents and warrants that (i)
            Customer is not located in a country that is subject to a U.S. Government embargo, or
            that has been designated by the U.S. Government as a “terrorist supporting” country and
            (ii) Customer is not listed on any U.S. Government list of prohibited or restricted
            parties. Customer also will not use the ControlRooms Solution for any purpose prohibited
            by U.S. law, including the development, design, manufacture or production of missiles,
            nuclear, chemical or biological weapons. Customer acknowledges and agrees that products,
            services or technology provided by ControlRooms are subject to the export control laws
            and regulations of the United States. Customer shall comply with these laws and
            regulations and shall not, without prior U.S. government authorization, export,
            re-export, or transfer ControlRooms products, services or technology, either directly or
            indirectly, to any country in violation of such laws and regulations.
          </SectionText>
        </Section>

        <Section>
          <SectionText>
            <SubSectionLabel>9.4</SubSectionLabel> <b>Severability.</b> If any provision of this
            Agreement is, for any reason, held to be invalid or unenforceable, the other provisions
            of this Agreement will remain enforceable and the invalid or unenforceable provision
            will be deemed modified so that it is valid and enforceable to the maximum extent
            permitted by law.
          </SectionText>
        </Section>

        <Section>
          <SectionText>
            <SubSectionLabel>9.5</SubSectionLabel> <b>Waiver.</b> Any waiver or failure to enforce
            any provision of this Agreement on one occasion will not be deemed a waiver of any other
            provision or of such provision on any other occasion.
          </SectionText>
        </Section>

        <Section>
          <SectionText>
            <SubSectionLabel>9.6</SubSectionLabel> <b>No Assignment.</b> Neither party will assign,
            subcontract, delegate, or otherwise transfer this Agreement, or its rights and
            obligations herein, without obtaining the prior written consent of the other party, and
            any attempted assignment, subcontract, delegation, or transfer in violation of the
            foregoing will be null and void; provided, however, that either party may assign this
            Agreement in connection with a merger, acquisition, reorganization or sale of all or
            substantially all of its assets, or other operation of law, without any consent of the
            other party. The terms of this Agreement will be binding upon the parties and their
            respective successors and permitted assigns.
          </SectionText>
        </Section>

        <Section>
          <SectionText>
            <SubSectionLabel>9.7</SubSectionLabel> <b>Compliance with Law.</b> Customer shall comply
            with all international and domestic laws, ordinances, regulations, and statutes that are
            applicable to its purchase and use of the ControlRooms Solution, Output and
            Documentation.
          </SectionText>
        </Section>

        <Section>
          <SectionText>
            <SubSectionLabel>9.8</SubSectionLabel> <b>Force Majeure.</b> Any delay in the
            performance of any duties or obligations of either party will not be considered a breach
            of this Agreement if such delay is caused by a labor dispute, shortage of materials,
            fire, earthquake, flood, or any other event beyond the control of such party, provided
            that such party uses reasonable efforts, under the circumstances, to notify the other
            party of the cause of such delay and to resume performance as soon as possible.
          </SectionText>
        </Section>

        <Section>
          <SectionText>
            <SubSectionLabel>9.9</SubSectionLabel> <b>Independent Contractors.</b> Customer’s
            relationship to ControlRooms is that of an independent contractor, and neither party is
            an agent or partner of the other. Customer will not have, and will not represent to any
            third party that it has, any authority to act on behalf of ControlRooms.
          </SectionText>
        </Section>

        <Section>
          <SectionText>
            <SubSectionLabel>9.10</SubSectionLabel> <b>Notices.</b> Where ControlRooms requires that
            Customer provide an email address, Customer is responsible for providing ControlRooms
            with its most current email address. In the event that the last email address provided
            to ControlRooms by Customer is not valid, or for any reason is not capable of delivering
            to Customer any notices required by the Agreement, ControlRooms’ dispatch of the email
            containing such notice will nonetheless constitute effective notice. Customer may give
            notice to ControlRooms at the following address: 600 Congress Avenue, Austin, TX 78701.
            Such notice shall be deemed given when received by ControlRooms by letter delivered by
            nationally recognized overnight delivery service or first class postage prepaid mail at
            the above address.
          </SectionText>
        </Section>

        <Section>
          <SectionText>
            <SubSectionLabel>9.11</SubSectionLabel> <b>Counterparts.</b> This Agreement may be
            executed in one or more counterparts, each of which will be deemed an original and all
            of which will be taken together and deemed to be one instrument.
          </SectionText>
        </Section>

        <Section>
          <SectionText>
            <SubSectionLabel>9.12</SubSectionLabel> <b>Entire Agreement.</b> This Agreement is the
            final, complete and exclusive agreement of the parties with respect to the subject
            matters hereof and supersedes and merges all prior discussions between the parties with
            respect to such subject matters. No modification of or amendment to this Agreement, or
            any waiver of any rights under this Agreement, will be effective unless in writing and
            signed by an authorized signatory of Customer and the ControlRooms.
          </SectionText>
        </Section>

        <Section>
          <SectionTitle>10. DEFINITIONS</SectionTitle>
          <SectionText>
            <SubSectionLabel>10.1</SubSectionLabel> “<b>Access Protocols</b>” means the passwords,
            access codes, technical specifications, connectivity standards or protocols, or other
            relevant procedures, as may be necessary to allow Customer or any Authorized Users to
            access the ControlRooms Solution.
          </SectionText>
        </Section>

        <Section>
          <SectionText>
            <SubSectionLabel>10.2</SubSectionLabel> “<b>Authorized User</b>” means each of
            Customer’s employees, agents, and independent contractors who are authorized to access
            the ControlRooms Solution pursuant to Customer’s rights under this Agreement.
          </SectionText>
        </Section>

        <Section>
          <SectionText>
            <SubSectionLabel></SubSectionLabel>10.3 “<b>ControlRooms Solution</b>” means the
            ControlRooms software-as-a-service critical infrastructure monitoring application that
            allows Authorized Users to access certain features and functions through a web interface
          </SectionText>
        </Section>

        <Section>
          <SectionText>
            <SubSectionLabel>10.4</SubSectionLabel> “<b>Customer Content</b>” means any content and
            information provided or submitted by, or on behalf of, Customer or its Authorized Users
            for use with the ControlRooms Solution.
          </SectionText>
        </Section>

        <Section>
          <SectionText>
            <SubSectionLabel>10.5</SubSectionLabel> “<b>Documentation</b>” means the technical
            materials provided by ControlRooms to Customer in hard copy or electronic form
            describing the use and operation of the ControlRooms Solution.
          </SectionText>
        </Section>

        <Section>
          <SectionText>
            <SubSectionLabel>10.6</SubSectionLabel> “<b>Intellectual Property Rights</b>” means any
            and all now known or hereafter existing (a) rights associated with works of authorship,
            including copyrights, mask work rights, and moral rights; (b) trademark or service mark
            rights; (c) trade secret rights; (d) patents, patent rights, and industrial property
            rights; (e) layout design rights, design rights, and other proprietary rights of every
            kind and nature other than trademarks, service marks, trade dress, and similar rights;
            and (f) all registrations, applications, renewals, extensions, or reissues of the
            foregoing, in each case in any jurisdiction throughout the world.
          </SectionText>
        </Section>

        <Section>
          <SectionText>
            <SubSectionLabel>10.7</SubSectionLabel> “<b>Output</b>” means results, reports,
            materials and documentation made available by ControlRooms to Customer as part of the
            ControlRooms Solution.
          </SectionText>
        </Section>

        <Section>
          <SectionText>
            <SubSectionLabel>10.8</SubSectionLabel> “<b>Third-Party Service Content</b>” means any
            information, data, text, and other materials accessible through the ControlRooms
            Solution that Customer has provided to and stored in its Third-Party Service Account.
            All Third-Party Service Content shall be considered to be Customer Content for all
            purposes of this Agreement.
          </SectionText>
        </Section>
      </LegalContent>
    </ModalBodyContainer>
  );
};

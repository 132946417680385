import { HeatmapTheme } from 'styled-components';

import { colors } from '@controlrooms/design-tokens';

export const heatmapDarkTheme: HeatmapTheme = {
  headers: {
    fill: colors.content['neutral-6'],
    filter: 'inset-shadow',
  },
  noDataColor: colors.k[30],
  errorColor: colors.datavis['pink-1'],
  gradients: colors.heatmap.dark,
  svgBoxShadow: `5px 0 9px -9px ${colors.k[0]} inset`,
};

export const heatmapLightTheme: HeatmapTheme = {
  headers: {
    fill: '#75786f',
    filter: 'inset-shadow',
  },
  noDataColor: colors.k[30],
  errorColor: colors.datavis['pink-1'],
  gradients: colors.heatmap.light,
  svgBoxShadow: `5px 0 9px -9px ${colors.k[0]} inset`,
};

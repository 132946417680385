import { Dimensions } from '@controlrooms/models';

export const analyzeConfig: Dimensions = {
  chartMargin: { top: 4, right: 20, bottom: 7, left: 44 },
  timelineHeight: 30,
  timelineWidth: 0,
  timelineMargin: { top: 30, right: 49, bottom: 0, left: 44 },
  stepLineMarginTop: 4,
};

export const trendSearchConfig: Dimensions = {
  chartMargin: { top: 4, right: 20, bottom: 7, left: 44 },
  timelineHeight: 30,
  timelineWidth: 0,
  timelineMargin: { top: 30, right: 53, bottom: 0, left: 44 },
  stepLineMarginTop: 4,
};

export const reportAnalyzeConfig: Dimensions = {
  chartMargin: { top: 4, right: 40, bottom: 7, left: 40 },
  timelineHeight: 30,
  timelineWidth: 0,
  timelineMargin: { top: 30, right: 53, bottom: 0, left: 38 },
  stepLineMarginTop: 4,
};

export const monitorConfig: Dimensions = {
  chartMargin: { top: 0, right: 30, bottom: 0, left: 30 },
  timelineHeight: 30,
  timelineWidth: 0,
  timelineMargin: { top: 30, right: 28, bottom: 0, left: 30 },
  stepLineMarginTop: 4,
};

export const ChartTypes = {
  analyze: 'analyze',
  monitor: 'monitor',
};

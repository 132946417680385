import styled from 'styled-components';

import { CollapsibleBar } from '../../../../app/components/collapsible-folder/styles';

import { Li, Ul } from '@controlrooms/components';

export const Button = styled.button`
  font-family: inherit;
  display: flex;
  padding: 0;
  margin-top: 3px;
  background-color: inherit;
  border: none;
  color: ${({ theme }) => theme.modal.panel.color};
  width: 80%;
  font-weight: 200;
  font-size: 13px;
  line-height: 19px;
  cursor: pointer;
`;

export const ChevronContainer = styled.div`
  padding: unset;
  margin-top: 7px;
  margin-left: 0.5rem;
`;

export const SubMenuContainer = styled.div<{ position: { top: number; left: number } }>`
  position: absolute;
  height: 0;
  margin-top: 0;
  left: ${({ position }) => position.left}px;
`;

export const ViewOptionsUl = styled(Ul)`
  &.scrollable-container {
    max-height: 400px;
  }
`;

export const SubMenuUl = styled(Ul)`
  position: absolute;
  top: -50px;
  left: 100%;
  min-width: 100%;
`;

export const StyledLi = styled(Li)`
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 1rem;
  :hover {
    background: none;
  }
`;

export const StyledCollapsibleBar = styled(CollapsibleBar)`
  &:hover .sidebar-collapse {
    background: none;
  }
`;

import React, {
  useEffect,
  useRef,
  useState,
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
} from 'react';
import styled from 'styled-components';

export const PanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  overflow: hidden;
`;

const PanelLeftContainer = styled.div`
  height: 100%;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const PanelRightContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const Resizer = styled.div`
  width: 4px;
  background-color: #a7eef9;
  cursor: ew-resize;
  height: 100%;
  z-index: 2;
  :hover {
    background-color: #a7eef9;
  }
`;

interface PanelContextProps {
  sidebarWidth: number;
  setSidebarWidth: Dispatch<SetStateAction<number>>;
  sidebarRef: React.RefObject<HTMLDivElement>;
  isResizing: React.MutableRefObject<boolean>;
  defaultWidth: number;
}

const PanelContext = createContext<PanelContextProps | undefined>(undefined);

const Panel: React.FC<{ children: React.ReactNode }> & {
  Left: React.FC<{ children: React.ReactNode }>;
  Right: React.FC<{ children: React.ReactNode }>;
  Resizer: React.FC;
} = ({ children }) => {
  const defaultWidth = 320;
  const [sidebarWidth, setSidebarWidth] = useState<number>(defaultWidth);
  const sidebarRef = useRef<HTMLDivElement | null>(null);
  const isResizing = useRef<boolean>(false);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (!isResizing.current || !sidebarRef.current) return;

      const newWidth = e.clientX - sidebarRef.current.getBoundingClientRect().left;
      if (newWidth > 45 && newWidth < 500) {
        setSidebarWidth(newWidth);
      }
    };

    const handleMouseUp = () => {
      if (isResizing.current) {
        isResizing.current = false;
      }
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  return (
    <PanelContext.Provider
      value={{ sidebarWidth, setSidebarWidth, sidebarRef, isResizing, defaultWidth }}
    >
      {children}
    </PanelContext.Provider>
  );
};

const PanelLeft: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { sidebarWidth, sidebarRef } = usePanelContext();
  return (
    <PanelLeftContainer ref={sidebarRef} style={{ width: `${sidebarWidth}px` }}>
      {children}
    </PanelLeftContainer>
  );
};

const PanelRight: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { sidebarWidth } = usePanelContext();
  return (
    <PanelRightContainer style={{ width: `calc(100% - ${sidebarWidth + 50}px)` }}>
      {children}
    </PanelRightContainer>
  );
};

const PanelResizer: React.FC = () => {
  const { isResizing, setSidebarWidth, defaultWidth } = usePanelContext();

  const handleMouseDown = () => {
    isResizing.current = true;
  };

  return (
    <Resizer onMouseDown={handleMouseDown} onDoubleClick={() => setSidebarWidth(defaultWidth)} />
  );
};

Panel.Left = PanelLeft;
Panel.Right = PanelRight;
Panel.Resizer = PanelResizer;

export default Panel;

export const usePanelContext = (): PanelContextProps => {
  const context = useContext(PanelContext);
  if (!context) {
    throw new Error('usePanelContext must be used within a PanelContextProvider');
  }
  return context;
};

import { colors } from '@controlrooms/design-tokens';

export type ExportModalTheme = {
  headerColor: string;
  linkColor: string;
  borderColor: string;
  shareText: string;
  shareLabel: string;
};

export const exportModalDarkTheme: ExportModalTheme = {
  headerColor: colors.content['neutral-2'],
  linkColor: colors.content['neutral-3'],
  borderColor: colors.k[12],
  shareText: colors.content['neutral-3'],
  shareLabel: colors.k[80],
};

export const exportModalLightTheme: ExportModalTheme = {
  headerColor: colors.content['neutral-5'],
  linkColor: colors.k[40],
  borderColor: colors.k[65],
  shareText: colors.content['neutral-3'],
  shareLabel: colors.k[40],
};
